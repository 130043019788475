import React from 'react';
import ContractLockRulesTable from '../../../../shared/components/admin/tables/ContractLockRulesTable';
import DashboardHeader from '../../../../shared/components/DashboardHeader';


export default () =>
    <>
        <DashboardHeader title="Blokady"/>
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="card">
                    <ContractLockRulesTable/>
                </div>
            </div>
        </div>
    </>
