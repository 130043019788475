import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import './assets/styles/theme.scss';
import {AdminPage} from "./pages/admin/IndexPage";
import {useDispatch, useSelector} from 'react-redux'
import Loader from 'react-loader'
import {fetchUserAction} from "./store/auth/actions";
import {AuthRoute} from "./shared/navigation/AuthRoute";
import {LoginPage} from "./pages/LoginPage";
import {userHasRoles, UserRoleGroups} from "./routes/user-roles";
import {UserPage} from "./pages/user/IndexPage";
import {LogoutPage} from "./pages/LogoutPage";
import {AppDispatch} from "./store/reducers";
import {State} from "./models/State";
import {loadSettings} from "./store/settings/actions";
import Modal from 'react-modal'
import {NotificationContainer} from 'react-notifications';
import {ContractPage} from './pages/contract/ContractPage';
import {User} from "./models/User";

import '@fortawesome/fontawesome-free/scss/solid.scss';
import '@fortawesome/fontawesome-free/scss/brands.scss';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import { BillPage } from './pages/bill/BillPage';
import { ActivateAccountPage } from './pages/ActivateAccountPage';

export const App = () => {
    Modal.setAppElement('#root')

    const [loaded, setLoaded] = useState<boolean>(true)
    const dispatcher = useDispatch<AppDispatch>()
    const isLoggedIn = useSelector<State>(({auth}) => auth.isLoggedIn);
    const user: User | null = useSelector(({auth: {user}}: State) => user)

    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            setLoaded(false)
            dispatcher(fetchUserAction()).then(() => {
                dispatcher(loadSettings())
                setLoaded(true)
            })
        }
    }, [dispatcher])

    const IndexRedirect = () =>
        <Redirect exact
                  from="/"
                  to={user && userHasRoles(user, UserRoleGroups.hr)
                      ? "/admin"
                      : "/account"
                  }/>

    return (
        <>
            <NotificationContainer/>
            <Loader loaded={loaded}>
                <Router>
                    <Switch>
                        <Route path="/admin" render={() =>
                            isLoggedIn
                                ? <AuthRoute path="/admin" component={AdminPage} requiredRoles={UserRoleGroups.hr}/>
                                : <LoginPage/>}
                        />
                        <Route path="/account" render={() =>
                            isLoggedIn
                                ? <AuthRoute path="/account" component={UserPage} requiredRoles={UserRoleGroups.user}/>
                                : <LoginPage/>}
                        />
                        <Route path="/contract/:token" component={ContractPage}/>
                        <Route path="/bill/:token" component={BillPage}/>
                        <Route path="/logout" component={LogoutPage}/>
                        <Route path="/activate/:token" component={ActivateAccountPage}/>
                        <IndexRedirect/>
                    </Switch>
                </Router>
            </Loader>
        </>
    );
}