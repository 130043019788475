import React, {useState} from 'react';
import Modal from "./Modal";

export interface ConfirmButtonProps {
    readonly onCancel?: () => void,
    readonly onSubmit: () => void,
    readonly buttonText?: string,
    readonly buttonClasses?: string,
    readonly modalTitle?: string,
    readonly modalSubmitButtonText?: string,
    readonly modalCancelButtonText?: string,
}

export default ({
                    buttonText = 'Usuń',
                    modalTitle = 'Czy na pewno?',
                    modalSubmitButtonText = 'Usuń',
                    modalCancelButtonText = 'Anuluj',
                    buttonClasses = 'btn btn-sm btn-danger',
                    onSubmit,
                    onCancel
                }: ConfirmButtonProps) => {

    const [isOpen, setOpen] = useState<boolean>(false)

    return <>
        <button type="button" className={buttonClasses}
                onClick={() => setOpen(true)}>{buttonText}</button>
        <Modal isOpen={isOpen} title={modalTitle} onRequestClose={() => setOpen(false)}>
            {{
                footer: <>
                    <div className="float-right">
                        <button type="button" className="btn btn-sm btn-primary"
                                onClick={() => {
                                    onSubmit();
                                    setOpen(false)
                                }}>{modalSubmitButtonText}</button>
                        <button type="button" className="btn btn-sm btn-danger ml-1"
                                onClick={() => {
                                    onCancel && onCancel();
                                    setOpen(false)
                                }}>{modalCancelButtonText}</button>
                    </div>
                </>
            }}
        </Modal>
    </>
}