import React from 'react';

export default ({
                    title = 'Panel',
                    subtitle = 'Sally',
                    button = null
                }) => {
    return (
        <div className="header">
            <div>

                <div className="header-body">
                    <div className="row align-items-end">
                        <div className="col">

                            <h6 className="header-pretitle">
                                {subtitle}
                            </h6>

                            <h1 className="header-title">
                                {title}
                            </h1>

                        </div>
                        <div className="col-auto">

                            {button}

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}