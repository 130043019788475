import React, {useEffect, useState} from 'react';
import {Template} from "../../../../models/Template";
import AttributesClientTable from "../tables/AttributesClientTable";
import {Editor} from "@tinymce/tinymce-react";
import {NotificationManager} from 'react-notifications';
import { Eye } from 'react-feather';
import DashboardHeader from '../../DashboardHeader';
import MultiServerSelect from '../../MultiServerSelect';
import { ContractLockRule } from '../../../../models/ContractLockRule';
import { ContractWarningRule } from '../../../../models/ContractWarningRule';
import ContractLockRuleService from '../../../../services/ContractLockRuleService';
import ContractWarningRuleService from '../../../../services/ContractWarningRuleService';

export interface TemplateFormProps {
    readonly templateData?: Partial<Template> | null
    readonly onSubmitForm: (template: Template) => void
    readonly onPreviewForm: (template: Template) => void
    readonly onPreviewBillForm: (template: Template) => void
}

export default ({templateData = {}, onSubmitForm, onPreviewForm, onPreviewBillForm}: TemplateFormProps) => {
    const [errors, setErrors] = useState<string[]>([])
    const [form, setForm] = useState<Template>({
        name: '',
        content_template: '',
        bill_template: '',
        warning_rules: [],
        lock_rules: [],
        attributes: [],
        ...templateData
    })

    const contact_attrs = [
    { label: "PESEL", value: "[PESEL]"},
    { label: "Numer konta", value: "[NUMER_KONTA]"},
    { label: "Imię i nazwisko", value: "[BIORCA_NAZWA]"},
    { label: "Data urodzenia", value: "[BIORCA_DATA_UR]"},
    { label: "Telefon", value: "[BIORCA_TELEFON]"},
    { label: "E-mail", value: "[BIORCA_EMAIL]"},
    { label: "Facebook", value: "[BIORCA_FACEBOOK]"},
    { label: "Pełen Adres", value: "[BIORCA_ADRES]"},
    { label: "Miasto", value: "[BIORCA_MIASTO]"},
    { label: "Kod pocztowy", value: "[BIORCA_KOD_POCZTOWY]"},
    { label: "Ulica", value: "[BIORCA_ULICA]"},
    { label: "Nr lokalu", value: "[BIORCA_BUDYNEK]"},
    { label: "Gmina", value: "[BIORCA_GMINA]"},
    { label: "Powiat", value: "[BIORCA_POWIAT]"},
    { label: "Wojewodztwo", value: "[BIORCA_WOJEWODZTWO]"},
    { label: "Kraj", value: "[BIORCA_KRAJ]"},
    ];

    const status_attrs = [
    { label: "Urząd skarbowy", value: "[STATUS_US]"},
    { label: "Status nauki", value: "[STATUS_NAUKA]"},
    { label: "Status pracy", value: "[STATUS_PRACA]"},
    { label: "Jeśli pracuje na UOP (Prawda)", value: "[STATUS_PRACUJE_UOP_TAK][/STATUS_PRACUJE_UOP_TAK]"},
    { label: "Jeśli pracuje na UOP (Fałsz)", value: "[STATUS_PRACUJE_UOP_NIE][/STATUS_PRACUJE_UOP_NIE]"},
    { label: "Jeśli pracuje na inną umowę (Prawda)", value: "[STATUS_PRACUJE_INNE_TAK][/STATUS_PRACUJE_INNE_TAK]"},
    { label: "Jeśli pracuje na inną umowę (Fałsz)", value: "[STATUS_PRACUJE_INNE_NIE][/STATUS_PRACUJE_INNE_NIE]"},
    { label: "Jeśli prowadzi działalność (Prawda)", value: "[STATUS_FIRMA_TAK][/STATUS_FIRMA_TAK]"},
    { label: "Jeśli prowadzi działalność (Fałsz)", value: "[STATUS_FIRMA_NIE][/STATUS_FIRMA_NIE]"},
    { label: "Jeśli studiuje (Prawda)", value: "[STATUS_STUDIUJE_TAK][/STATUS_STUDIUJE_TAK]"},
    { label: "Jeśli studiuje (Fałsz)", value: "[STATUS_STUDIUJE_NIE][/STATUS_STUDIUJE_NIE]"},
    { label: "Nazwa pracodawcy", value: "[STATUS_PRACA_MIEJSCE]"},
    { label: "Adres pracodawcy", value: "[STATUS_PRACA_ADRES]"},
    { label: "NIP pracodawcy", value: "[STATUS_PRACA_NIP]"},
    { label: "JDG: Nazwa firmy", value: "[STATUS_B2B_NAZWA]"},
    { label: "JDG: Adres firmy", value: "[STATUS_B2B_ADRES]"},
    { label: "JDG: Numer NIP", value: "[STATUS_B2B_NIP]"},
    { label: "Status VAT (Prawda)", value: "[STATUS_PRACA_VAT_TAK][/STATUS_PRACA_VAT_TAK]"},
    { label: "Status VAT (Fałsz)", value: "[STATUS_PRACA_VAT_NIE][/STATUS_PRACA_VAT_NIE]"},
    { label: "Minimum krajowe (Prawda)", value: "[STATUS_PRACA_MIN_TAK][/STATUS_PRACA_MIN_TAK]"},
    { label: "Minimum krajowe (Fałsz)", value: "[STATUS_PRACA_MIN_NIE][/STATUS_PRACA_MIN_NIE]"},
    { label: "Nazwa szkoły/uczelni", value: "[STATUS_SZKOLA_NAZWA]"},
    { label: "Wydział/Profil", value: "[STATUS_SZKOLA_PROFIL]"},
    { label: "Numer legitymacji/albumu", value: "[STATUS_SZKOLA_NR]"},
    ];

    const hr_attrs = [
    { label: "Imię i nazwisko", value: "[REKRUTER_NAZWA]"},
    { label: "Telefon", value: "[REKRUTER_TELEFON]"},
    { label: "E-mail", value: "[REKRUTER_EMAIL]"},
    { label: "Facebook", value: "[REKRUTER_FACEBOOK]"},
    { label: "Pełen Adres", value: "[REKRUTER_ADRES]"},
    { label: "Miasto", value: "[REKRUTER_MIASTO]"},
    { label: "Kod pocztowy", value: "[REKRUTER_KOD_POCZTOWY]"},
    { label: "Ulica", value: "[REKRUTER_ULICA]"},
    { label: "Nr lokalu", value: "[REKRUTER_BUDYNEK]"},
    { label: "Kraj", value: "[REKRUTER_KRAJ]"}
    ];

    const client_attrs = [
    { label: "Nazwa firmy", value: "[FIRMA_NAZWA]"},
    { label: "Telefon", value: "[FIRMA_TELEFON]"},
    { label: "Pełen Adres", value: "[FIRMA_ADRES]"},
    { label: "Miasto", value: "[FIRMA_MIASTO]"},
    { label: "Kod pocztowy", value: "[FIRMA_KOD_POCZTOWY]"},
    { label: "Ulica", value: "[FIRMA_ULICA]"},
    { label: "Nr lokalu", value: "[FIRMA_BUDYNEK]"},
    { label: "Kraj", value: "[FIRMA_KRAJ]"},
    ];

    const contract_attrs = [
    { label: "Opis", value: "[OPIS]"},
    { label: "Data podpisania", value: "[DATA_ZAWARCIA]"},
    { label: "Numer umowy", value: "[NUMER_UMOWY]"},
    { label: "Ostateczna kwota", value: "[KWOTA]"},
    { label: "Kwota brutto", value: "[KWOTA_BRUTTO]"},
    { label: "Kwota netto", value: "[KWOTA_NETTO]"},
    { label: "Kwota wsparcia fundacji", value: "[FUNDACJA_KWOTA]"},
    ];

    const editorSettings = {
        height: 500,
        menubar: false,
        language: 'pl',
        plugins: [
            'autolink lists advlist link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
            ['undo redo | formatselect | fontselect bold italic forecolor backcolor fontsizeselect lineheight | help' ,
            'alignleft aligncenter alignright alignjustify | bullist numlist advlist | table | outdent indent | removeformat | mybutton | code',
            'klient umowa zleceniobiorca status'],
        extended_valid_elements : "pagebreak",
        custom_elements: "pagebreak",
        content_style: 'pagebreak { width: 100%; display: block; height: 3px; border-bottom: 3px dashed gray; } p, h1, h2, h3, h4, h5, h6, li, ul, em { margin: 0px 0px 0px 0px; }',
        fontsize_formats: '6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 20pt 24pt 28pt 32pt 36pt 48pt',
        lineheight_formats: '0.5 0.75 0.8 0.9 1 1.1 1.2 1.3 1.4 1.5 2',
        setup: function (editor) {
            var toggleState = false;
            editor.ui.registry.addMenuButton('mybutton', {
                text: 'Wstaw',
                fetch: function (callback) {
                var items = [
                    {
                        type: 'menuitem',
                        text: 'Przejście do nowej strony',
                        onAction: function () {
                            editor.insertContent('&nbsp;<pagebreak>&nbsp;</pagebreak>');
                        }
                    }
                ];
                callback(items);
                }
            });
        
            editor.ui.registry.addMenuButton('umowa', {
                text: 'Umowa',
                icon: 'paste-column-after',
                fetch: function (callback) {
                callback( contract_attrs.map((attr: any) => 
                    ({
                        type: 'menuitem',
                        text: attr.label,
                        onAction: function () {
                        editor.insertContent('&nbsp;'+attr.value);
                        }
                    })));
                }
            });
        
            editor.ui.registry.addMenuButton('zleceniobiorca', {
                text: 'Zleceniobiorca',
                icon: 'accessibility-check',
                fetch: function (callback) {
                callback( contact_attrs.map((attr: any) => 
                    ({
                        type: 'menuitem',
                        text: attr.label,
                        onAction: function () {
                        editor.insertContent('&nbsp;'+attr.value);
                        }
                    })));
                }
            });
        
            editor.ui.registry.addMenuButton('rekruter', {
                text: 'Zleceniobiorca',
                icon: 'preview',
                fetch: function (callback) {
                callback( hr_attrs.map((attr: any) => 
                    ({
                        type: 'menuitem',
                        text: attr.label,
                        onAction: function () {
                        editor.insertContent('&nbsp;'+attr.value);
                        }
                    })));
                }
            });
        
            editor.ui.registry.addMenuButton('klient', {
                text: 'Dane klienta',
                icon: 'comment',
                fetch: function (callback) {
                callback( client_attrs.map((attr: any) => 
                    ({
                        type: 'menuitem',
                        text: attr.label,
                        onAction: function () {
                        editor.insertContent('&nbsp;'+attr.value);
                        }
                    })));
                }
            });

            editor.ui.registry.addMenuButton('status', {
                text: 'Status pracy/nauki',
                icon: 'sharpen',
                fetch: function (callback) {
                callback( status_attrs.map((attr: any) => 
                    ({
                        type: 'menuitem',
                        text: attr.label,
                        onAction: function () {
                        editor.insertContent('&nbsp;'+attr.value);
                        }
                    })));
                }
            });

            editor.on('ExecCommand', function checkListNodes(evt) {  
                let cmd = evt.command;
                if (cmd === 'FontSize' || cmd === 'FontName') { 
                    let val = evt.value;
                    let node = evt.target.selection.getNode();
                    let nodeParent = node.parentNode;
                    if (node.nodeName === 'SPAN' && nodeParent.nodeName === 'LI') {
                        if (cmd === 'FontSize') {
                            editor.dom.setStyle(nodeParent, 'font-size', val);
                        }
                        if (cmd === 'FontName') {
                            editor.dom.setStyle(nodeParent, 'font-family', val);
                        }
                    } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
                        let li = editor.dom.select('li', node);
                        if (cmd === 'FontSize') {
                            editor.dom.setStyle(li, 'font-size', val);
                        }
                        if (cmd === 'FontName') {
                        editor.dom.setStyle(li, 'font-family', val);
                        }
                    }
                }
            });
        







            },
    }

    const handleInputForm = (input: Partial<Template>): void => {
        setForm(oldValue => ({...oldValue, ...input}))
    }

    useEffect((): void => {
        templateData && handleInputForm(templateData)
    }, [templateData])

    const onSubmit = (e): void => {
        e.preventDefault()
        !validate().length && onSubmitForm(form)
    }

    const onPreview = (e): void => {
        e.preventDefault()
        !validate().length && onPreviewForm(form)
    }

    const onPreviewInvoice = (e): void => {
        e.preventDefault()
        !validate().length && onPreviewBillForm(form)
    }

    const validate = (): string[] => {
        const errors: string[] = []
        !form.name.length && errors.push('name')
        !form.bill_template.length && errors.push('bill_template')
        !form.content_template && errors.push('content_template')
        setErrors(errors)
        errors.length && NotificationManager.error('Wypełnij wymagane pola.')
        return errors
    }

    return <>
        <DashboardHeader title={`Szablon ${form?.name || ''}`}/>
        <form className="form-inline">
            <div className="card">
                <div className="card-body">
                    <div className="form-group row justify-content-between mb-0">
                        <div className="col-sm-5 row">
                            <label className="col-auto col-form-label">Nazwa</label>
                            <div className="col">
                                <input type="text"
                                    className={`form-control ${errors.includes('name') && 'is-invalid'}`}
                                    placeholder="Nazwa"
                                    value={form.name}
                                    onChange={e => handleInputForm({name: e.target.value})}/>
                                {errors.includes('name') &&
                                <small className="text-danger">Nazwa nie może być pusta</small>}
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary float-right col-auto"
                                onClick={onSubmit}>Zapisz
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Szablon umowy</h3>
                            
                            <button type="button" className="btn btn-sm btn-light"
                                    onClick={onPreview}><Eye size={14} /> Podgląd
                            </button>
                        </div>
                        <div className="card-body">
                            <Editor
                                initialValue={form.content_template}
                                init={editorSettings}
                                apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                                onEditorChange={content_template => handleInputForm({content_template})}
                            />
                            {errors.includes('content_template') &&
                            <small className="text-danger">Szablon nie może być pusty</small>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Szablon rachunku</h3>
                            
                            <button type="button" className="btn btn-sm btn-light"
                                    onClick={onPreviewInvoice}><Eye size={14} /> Podgląd
                            </button>
                        </div>
                        <div className="card-body">
                            <Editor
                                initialValue={form.bill_template}
                                init={editorSettings}
                                onEditorChange={bill_template => handleInputForm({bill_template})}
                            />
                            {errors.includes('bill_template') &&
                            <small className="text-danger">Szablon nie może być pusty</small>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Domyślne blokady i ostrzeżenia</h3>
                        </div>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Blokady</label>
                                    <MultiServerSelect<ContractLockRule>
                                        value={form.lock_rules}
                                        onSelect={lock_rules => handleInputForm({lock_rules})}
                                        tableService={new ContractLockRuleService()}
                                        label={({message_admin}) => message_admin}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Ostrzeżenia</label>
                                    <MultiServerSelect<ContractWarningRule>
                                        value={form.warning_rules}
                                        onSelect={warning_rules => handleInputForm({warning_rules})}
                                        tableService={new ContractWarningRuleService()}
                                        label={({message_admin}) => message_admin}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Parametry umowy</h3>
                        </div>
                        <div className="card-body">
                            <AttributesClientTable
                                data={form.attributes}
                                onUpdate={
                                    attributes => handleInputForm({attributes})
                                }/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}