import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
import { IAttributeFieldProps } from './IAttributeField'

function AttributeFieldEditor({currValue, setCurrValue}: IAttributeFieldProps) {

  const editorSettings = {
    height: 300,
    menubar: false,
    language: 'pl',
    plugins: [
      'autolink lists advlist link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
        ['undo redo | formatselect | fontselect bold italic forecolor backcolor fontsizeselect lineheight | help' ,
        'alignleft aligncenter alignright alignjustify | bullist numlist advlist | table | outdent indent | removeformat'],
    fontsize_formats: '6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 20pt 24pt 28pt 32pt 36pt 48pt',
    lineheight_formats: '0.5 0.75 0.8 0.9 1 1.1 1.2 1.3 1.4 1.5 2',
  }

  return (
    <Editor
        initialValue={currValue}
        value={currValue}
        init={editorSettings}
        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
        onEditorChange={data => setCurrValue(data)}
    />
  )
}

export default AttributeFieldEditor