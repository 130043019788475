import React, {FunctionComponent, useMemo} from 'react';
import ServerTable, {TableHeadActionsProps} from "../../ServerTable";
import {TableColumn} from "../../../../models/Table";
import {Contract} from "../../../../models/Contract";
import ContractService from "../../../../services/ContractService";
import {Link} from 'react-router-dom';
import {printDictionary} from "../../../../helpers/renderer";
import {findStateColor} from "../../../../helpers/contract-state";

export default () => {
    const tableService = new ContractService()
    const columns = useMemo<TableColumn<Contract>[]>(() => [
        {
            Header: 'Identyfikator',
            accessor: ({number}) => number,
            sortBy: 'number'
        },
        {
            Header: 'Zleceniobiorca',
            accessor: model => model?.user?.full_name || '',
            sortBy: 'phone',
        },
        {
            Header: 'Klient',
            accessor: model => model?.client?.full_name || '',
            sortBy: 'client'
        },
        {
            Header: 'Termin wykonania',
            accessor: ({send_date}) => send_date || 'Brak',
            sortBy: 'send_date'
        },
        {
            Header: 'Status',
            accessor: ({state}) =>
                    <span className="badge" style={{
                        backgroundColor: findStateColor(state),
                        color: "black"
                    }}>{printDictionary(String(state), 'contract_state')}</span>,
            sortBy: 'state'
        },
        {
            Header: 'Opcje',
            accessor: ({id}) => <Link to={`/admin/agreements/${id}`}>
                <button className="btn btn-sm btn-primary">Edytuj</button>
            </Link>,
            sortBy: ''
        },
    ], [])

    const header: FunctionComponent<TableHeadActionsProps> = ({onInput}) =>
        <div className="input-group">
            <input className="form-control" type="text" placeholder="Szukaj..." aria-label="Szukaj..."
                   onChange={e => {
                       onInput({query: e.target.value})
                   }}/>
            <Link to={`/admin/agreements/add`}>
                <button className="btn btn-lg btn-primary">Dodaj nową</button>
            </Link>
        </div>

    return (
        <ServerTable<Contract> tableService={tableService} columns={columns}>
            {{
                header
            }}
        </ServerTable>
    )
}