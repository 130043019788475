import React, {FunctionComponent, useState} from 'react';

import {LoginData} from "../../models/Auth";
import {validateEmail} from "../../helpers/validator";

interface LoginFormProps {
    readonly username?: string
    readonly password?: string
    readonly onSubmit: (loginData: LoginData) => void
}

export const LoginForm: FunctionComponent<LoginFormProps> = (
    {
        username: initUsername = '',
        password: initPassword = '',
        onSubmit: emitSubmit
    }) => {
    const [username, setUsername] = useState<string>(initUsername)
    const [password, setPassword] = useState<string>(initPassword)
    const [errors, setErrors] = useState<string[]>([])

    const onChangeUsername = ({target}): void => setUsername(target.value)
    const onChangePassword = ({target}): void => setPassword(target.value)

    const onSubmit = (e): void => {
        e.preventDefault();
        !validate().length && emitSubmit({username, password})
    }

    const validate = (): string[] => {
        const errors: string[] = []
        !validateEmail(username) && errors.push('username')
        !password.length && errors.push('password')
        setErrors(errors)
        return errors
    }

    return (
        <form>
            <div className="form-group">
                <label className="form-label">
                    E-mail
                </label>
                <input type="email" className={`form-control ${errors.includes('username') && 'is-invalid'}`}
                       placeholder="name@example.com" onInput={onChangeUsername}/>
                {errors.includes('username') && <small className="text-danger">Nieprawidłowy adres email</small>}
            </div>
            <div className="form-group">
                <label className="form-label">
                    Hasło
                </label>
                <input className={`form-control ${errors.includes('password') && 'is-invalid'}`} type="password"
                       placeholder="Wpisz hasło" onInput={onChangePassword}/>
                {errors.includes('password') && <small className="text-danger">Wypełnij pole</small>}
            </div>
            <button type="submit" className="btn btn-lg btn-block btn-primary mb-3" onClick={onSubmit}>
                Zaloguj się
            </button>
            <div className="text-center">
                <small className="text-muted text-center">
                    <a href="/">Nie pamiętam hasła</a>
                </small>
            </div>
        </form>

    )
}