import {LOAD_FAIL, LOAD_SUCCESS, SettingsActionTypes} from "./types";
import {Settings} from "../../models/Settings";
import SettingsService from "../../services/SettingsService";

export const loadSettings = () => dispatch =>
    SettingsService.settings().then(settings => dispatch(setSettingData(settings)))

export const setSettingData = (settings: Settings | null): SettingsActionTypes =>
    ({
        type: settings !== null ? LOAD_SUCCESS : LOAD_FAIL,
        payload: {settings}
    })
