import Select from "react-select";
import React, {useMemo} from "react";
import {Dictionary, DictionaryValue} from "../../../../../../models/Settings";
import {useSelector} from 'react-redux'
import {State} from "../../../../../../models/State";
import {UserRole} from "../../../../../../routes/user-roles";

export interface ContractStateSelectProps {
    value: UserRole[],
    fixedOptions?: UserRole[]
    onChange: (value: UserRole[]) => void
}

export default ({value, onChange, fixedOptions = []}: ContractStateSelectProps) => {
    const dictionaries: Dictionary[] = useSelector(({settings}: State) => settings?.dictionaries || []);

    const options = useMemo(() => {
        if (dictionaries['user_role']) {
            return dictionaries['user_role']
                .map(({value, text: label}: DictionaryValue) => ({
                    label,
                    value,
                    isFixed: fixedOptions?.includes(value as UserRole)
                }))
        } else {
            return []
        }
    }, [dictionaries])

    const onChangeSelect = (value, {action, removedValue}) => {
        switch (action) {
            case 'remove-value':
            case 'pop-value':
                if (removedValue.isFixed) {
                    return;
                }
                break;
            case 'clear':
                value = options.filter(v => v.isFixed);
                break;
        }

        valueData.set(value)
    }

    const valueData = useMemo(() => ({
        get: () => {
            return options.filter(({value: searchesValue}) => value.includes(searchesValue))
        },
        set: (options): void => {
            const roles = options ? options.map(({value}) => value) : []
            onChange(roles)
        }
    }), [options, value])

    const styles = {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
    };

    return <Select isMulti
                   placeholder="Wybierz role"
                   value={valueData.get()}
                   options={options}
                   styles={styles}
                   className="basic-multi-select"
                   classNamePrefix="select"
                   onChange={(value, {action, removedValue}) => onChangeSelect(value, {action, removedValue})}/>
}
