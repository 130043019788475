import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { Attribute, AttributeWithValue } from '../../../models/Attribute';

export const ContractStep5 = ({
    nextStep,
    showStep,
    contract,
    attributes
}) => {

    const findInAttributes = (name: string) => {
        const find = attributes.find((item: AttributeWithValue) => item.name == name);
        return find?.value || 'b/d';
    }

    return (
        <div className={`step step-5 ${showStep ? 'step-visible' : ''}`}>
            <div className='row mb-5'>
                <div className='col col-12 mb-5'>
                    <div className='row'>
                        <div className='col col-auto'>
                            <Icon.CheckCircle size={48} color="green" />
                        </div>
                        <div className='col mw-650 text-success'>
                            <p className="lead">
                                <strong>
                                    Dziękujemy!<br />
                                    Umowa została zawarta :)
                                </strong>
                            </p>
                        </div>
                        <div className='col mw-650'>
                            <p className="">
                                Przesłaliśmy umowę na podany przez Ciebie adres e-mail.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                {attributes.filter((item: Attribute) => item.show_on_summary).map((item: Attribute) => (
                    <div key={`att_${item.name}`} className='col col-12 col-md-3 mb-5'>
                        <div className='row'>
                            <div className='col col-auto'>
                                {item?.icon || ''}
                            </div>
                            <div className='col mw-650'>
                                <p>
                                    <small>{item.display_name}:</small>
                                </p>
                                <p>
                                    {item.value}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}