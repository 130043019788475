import {AxiosResponse} from 'axios'
import {authHeader} from "./auth-header";
import {TableFilters, TableResponse, TableService} from "../models/Table";
import {Contract} from "../models/Contract";
import Api from "./Api";
import {ObjectAssignBySchema} from "../helpers/init-data";

export default class SignService {

    static preview = (token: string): Promise<any> =>
        Api.get(`/sign/contract/${token}/preview`, {headers: authHeader()})
            .then((response: AxiosResponse<any>) => response.data)
            .catch(error => null)

    static previewBill = (token: string): Promise<any> =>
        Api.get(`/sign/contract/${token}/preview-bill`, {headers: authHeader()})
            .then((response: AxiosResponse<any>) => response.data)
            .catch(error => null)

    static getContractData = (token: string): Promise<Contract|null> =>
            Api.get(`/sign/contract/${token}`, {headers: authHeader()})
                .then((response: AxiosResponse<Contract>) => response.data)
                .catch(error => null)

    static getBillData = (token: string): Promise<Contract|null> =>
            Api.get(`/sign/contract/${token}/bill`, {headers: authHeader()})
                .then((response: AxiosResponse<Contract>) => response.data)
                .catch(error => null)

    static postContractData = (token: string, model: Partial<Contract>): Promise<Contract|null> =>
            Api.put(`/sign/contract/${token}/details`, SignService.initSignBody(model), {headers: authHeader()})
                .then((response: AxiosResponse<Contract>) => response.data)
                .catch(error => null)

    static signContract = (token: string, register: boolean): Promise<Contract|null> =>
            Api.put(`/sign/contract/${token}${(register ? '?register=1' : '')}`, null, {headers: authHeader()})
                .then((response: AxiosResponse<Contract>) => response.data)
                .catch(error => null)

    static signBill = (token: string): Promise<Contract|null> =>
            Api.put(`/sign/contract/${token}/bill`, null, {headers: authHeader()})
                .then((response: AxiosResponse<Contract>) => response.data)
                .catch(error => null)

    static initSignBody = (model: Partial<Contract>): Partial<Contract> =>
        ObjectAssignBySchema({
            "bank_account": "",
            "pesel": "",
            "personal_details": {
                "full_name": "",
                "phone": "",
                "birthday": "",
                "email": "",
                "workplace": null,
                "school": null,
                "fb_profile": "",
                "address": {
                    "street": "",
                    "postal_code": "",
                    "city": "",
                    "country": "Polska",
                    "building": "",
                    "voivodeship": "",
                    "powiat": "",
                    "gmina": "",
                },
                "parent_full_name": "",
                "parent_phone": "",
                "parent_agree": false
            },
            "us": "",
            "company_name": "",
            "company_address": "",
            "company_nip": "",
            "company_custom_name": "",
            "company_custom_address": "",
            "company_custom_nip": "",
            "company_is_vat": false,
            "company_is_min": false,
            "school_name": "",
            "school_profile": "",
            "school_number": "",
            "amount_charity": 0,
        }, model)

}

