import React, {useEffect, useMemo, useState} from 'react';

import {useParams} from "react-router-dom";
import TemplatesService from "../../../services/TemplatesService";
import Loader from "react-loader";
import {Template} from "../../../models/Template";
import TemplateForm from "../../../shared/components/admin/forms/TemplateForm";
import {NotificationManager} from 'react-notifications';
import NotificationsService from '../../../services/NotificationsService';

export default () => {
    const {id = null} = useParams<any>();
    const [loaded, setLoaded] = useState<boolean>(!id)
    const [formData, setFormData] = useState<Template | null>(null)

    useEffect((): void => {
        id && TemplatesService.template(Number(id)).then(template => {
            template && setFormData(() => template)
            setLoaded(true)
        })
    }, [id])

    const method: (model: Template) => Promise<Template | null> = useMemo(() =>
            id ? TemplatesService.update : TemplatesService.store,
        [id, TemplatesService]
    )

    const handleSubmitForm = (template: Template): void => {
        setFormData(() => template)
        setLoaded(false)
        method(template).then(response => {
                if (response) {
                    NotificationManager.success('Szablon zapisany pomyślnie.')
                    window.location.href = '/admin/templates'
                } else {
                    setLoaded(true)
                }
            }
        )
    }

    const handlePreviewForm = (template: Template): void => {
        setFormData(() => template)
        setLoaded(false) 
        method(template).then(async (response: any) => {
                if (response) {
                    NotificationManager.success('Szablon zapisany pomyślnie.')
                    await TemplatesService.preview(template);
                    setTimeout(() => {
                        window.location.href = '/admin/templates/'+template.id
                    }, 3000);
                } else {
                    setLoaded(true)
                }
            }
        )
    }

    const handlePreviewBillForm = (template: Template): void => {
        setFormData(() => template)
        setLoaded(false) 
        method(template).then(async (response: any) => {
                if (response) {
                    NotificationManager.success('Szablon zapisany pomyślnie.')
                    await TemplatesService.previewBill(template);
                    setTimeout(() => {
                        window.location.href = '/admin/templates/'+template.id
                    }, 3000);
                } else {
                    setLoaded(true)
                }
            }
        )
    }

    return (
        <Loader loaded={loaded}>
            <TemplateForm templateData={formData} onPreviewBillForm={handlePreviewBillForm} onSubmitForm={handleSubmitForm} onPreviewForm={handlePreviewForm}/>
        </Loader>
    )
}