import React, {FunctionComponent, useMemo} from 'react';
import ServerTable, {TableHeadActionsProps} from "../../ServerTable";
import {User} from "../../../../models/User";
import UserService from "../../../../services/UserService";
import {TableColumn, TableFilters} from "../../../../models/Table";
import {Link} from 'react-router-dom';
import {printDictionary} from "../../../../helpers/renderer";
import { UserRole } from '../../../../routes/user-roles';

export default () => {
    const tableService = new UserService()

    const columns = useMemo<TableColumn<User>[]>(() => [
        {
            Header: 'Imię i nazwisko',
            accessor: ({full_name}) => full_name,
            sortBy: 'model_name'
        },
        {
            Header: 'Rola',
            accessor: ({roles}) => roles.map(role => printDictionary(role, 'user_role')).join(', '),
            sortBy: 'roles'
        },
        {
            Header: 'Telefon',
            accessor: ({phone}) => phone,
            sortBy: 'phone'

        },
        {
            Header: 'Email',
            accessor: ({email}) => email,
            sortBy: 'email'
        },
        {
            Header: 'Opcje',
            accessor: ({id}) => <Link to={`/admin/users/${id}`}>
                <button className="btn btn-sm btn-primary">Edytuj</button>
            </Link>,
            sortBy: ''
        },
    ], [])

    const header: FunctionComponent<TableHeadActionsProps> = ({onInput}) =>
        <div className="input-group">
            <input className="form-control" type="text" placeholder="Szukaj..." aria-label="Szukaj..."
                   onChange={e => {
                       onInput({query: e.target.value})
                   }}/>
            <Link to={`/admin/users/add`}>
                <button className="btn btn-lg btn-primary">Dodaj</button>
            </Link>
        </div>

    const filters: TableFilters = {
        roles: [UserRole.ROLE_USER]
    }

    return (
        <ServerTable<User> tableService={tableService} columns={columns} filters={filters}>
            {{
                header
            }}
        </ServerTable>
    )
}