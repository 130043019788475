import React from 'react';
import DashboardHeader from '../../../shared/components/DashboardHeader';
import ContractsTable from "../../../shared/components/admin/tables/ContractsTable";

export default () =>
    <>
        <DashboardHeader title="Umowy"/>
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="card">
                    <ContractsTable/>
                </div>
            </div>
        </div>
    </>