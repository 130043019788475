import {TableFilters, TableService} from "../../models/Table";
import React, {useMemo} from "react";
import AsyncSelect from 'react-select/async';

export interface ServerSelectProps<T extends object> {
    readonly value: T | null,
    readonly label: (model: T) => string,
    readonly placeholder?: string,
    readonly required?: boolean,
    readonly onSelect: (model: T | null) => void,
    readonly tableService: TableService<T>,
    readonly additionalFilters?: TableFilters,
}

export default <T extends object>(
    {
        value,
        tableService,
        onSelect,
        label: displayLabel,
        placeholder = "Wybierz...",
        required = false,
        additionalFilters = {limit: 10}
    }: ServerSelectProps<T>) => {

    const valueData = useMemo(() => ({
        get: () => ({value, label: value ? displayLabel(value) : placeholder}),
        set: (newVal): void => {
            onSelect(newVal?.value || null)
        }
    }), [value])

    const options =
        (query: string) =>
        tableService.tableData({
            ...additionalFilters,
            query
        }).then(response => response?.items || []).then(results => results.map(value => ({
            value,
            label: displayLabel(value)
        })))

    return <AsyncSelect cacheOptions
                        defaultOptions
                        required={required}
                        value={valueData.get()}
                        loadOptions={options}
                        onChange={e => valueData.set(e)}
                        placeholder={placeholder} />
}