import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useTable} from "react-table";
import {TableColumn, TableFilters, TableService} from "../../models/Table";
import {NotificationManager} from 'react-notifications';

export interface ServerTableProps<T extends object> {
    readonly columns: TableColumn<T>[],
    readonly tableService: TableService<T>,
    readonly filters?: Partial<TableFilters>
    readonly children?: {
        readonly header?: FunctionComponent<TableHeadActionsProps>
    }
}

export interface TableHeadActionsProps {
    readonly onInput: (filters: TableFilters) => void
}

export default <T extends object>(
    {
        columns,
        tableService,
        filters: externalFilters = {},
        children: {header: Header} = {}
    }: ServerTableProps<T>) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<T[]>([])
    const [activePage, setActivePage] = useState<number>(1)
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [tableFilters, setTableFilters] = useState<TableFilters>({offset: 0, limit: 10})
    const allFilters = useMemo<TableFilters>(() => Object.assign(tableFilters, externalFilters), [tableFilters, externalFilters])
    const {headerGroups, rows, prepareRow, getTableProps} = useTable({columns, data})

    useEffect((): void => {
        setLoading(true)
        tableService
            .tableData(allFilters)
            .then((tableData): void => {
                if(tableData) {
                    setTotalRecords(tableData?.total || 0)
                    setData(tableData?.items || [])
                    setLoading(false)
                } else {
                    NotificationManager.error('Wystąpił błąd podczas pobierania danych')
                }
            })
    }, [allFilters, tableService])

    useEffect(() => {
        handleSetOffset(activePage)
    }, [activePage])

    const pages = useMemo<number>(() => totalRecords ? Math.ceil(Number(totalRecords) / Number(allFilters.limit)) : 0
        , [totalRecords, allFilters.limit])

    const handleClickSort = (e, column) => {
        const orderBy = !allFilters.orderBy
            ? 'ASC'
            : allFilters.orderBy === 'DESC'
                ? 'ASC'
                : 'DESC'
        setTableFilters(oldFilters => ({...oldFilters, sortBy: column.sortBy, orderBy}))
    }

    const updateFilters = (filters: Partial<TableFilters>): void => {
        setTableFilters(oldFilters => ({...oldFilters, ...filters}))
    }

    const handleSetOffset = (page: number): void => {
        const offset = (page -1) * Number(allFilters.limit)
        updateFilters({offset})
    }

    return (
        <>
            {Header
                ? <Header onInput={updateFilters}/>
                : <div className="input-group">
                    <input className="form-control" type="text" placeholder="Szukaj..." aria-label="Szukaj..."
                           onChange={e => {
                               updateFilters({query: e.target.value})
                           }}/>
                </div>}

            <table className="table table-sm table-nowrap table-hover card-table" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                <span style={{cursor: 'pointer'}} className="text-muted list-sort" data-sort="products-product"
                                   key={column.id} onClick={e => handleClickSort(e, column)}>
                                    {column.render('Header')}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody className="list">
                {!loading
                    ? data.length
                        ? rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        : <tr>
                            <th>Brak danych</th>
                        </tr>
                    : <tr>
                        <th>Pobieranie danych...</th>
                    </tr>}
                </tbody>
            </table>
            {!loading && pages > 1 && <nav aria-label="Page navigation example" className="ml-4">
                <ul className="pagination pagination-sm">
                    {Number(allFilters.offset) > 0 && <span style={{cursor: 'pointer'}} className="page-link" onClick={() => {
                        setActivePage(activePage - 1)
                    }
                    }>{'<'}</span>}
                    {Array.from(Array(pages), (_, page) => (
                        page > (activePage - 3)
                        && page < (activePage + 3)
                        && <li key={page} className={`page-item ${activePage === (page + 1) && 'active'}`}>
                            <span style={{cursor: 'pointer'}} className="page-link" onClick={() => {
                                setActivePage(page + 1)
                            }}>{page + 1}</span>
                        </li>
                    ))}
                    {activePage < pages && <li className="page-item">
                        <span style={{cursor: 'pointer'}} className="page-link" onClick={() => {
                            setActivePage(activePage + 1)
                        }}>&gt;</span>
                    </li>}
                </ul>
            </nav>}
        </>
    )
}