import React, {useMemo, useState} from 'react';
import ClientTable from "../../ClientTable";
import {Attribute} from "../../../../models/Attribute";
import {Column} from "react-table";
import ConfirmButton from "../../ConfirmButton";
import {NotificationManager} from 'react-notifications';
import AttributesAddEditModal from "../modals/AttributesAddEditModal";
import {computedArray} from "../../../../helpers/init-data";
import ClientTableSortable from '../../ClientTableSortable';

export interface AttributesClientTableProps {
    readonly data: Attribute[]
    readonly onUpdate: (data: Attribute[]) => void
}

export default ({data, onUpdate}: AttributesClientTableProps) => {

    const [isOpenModal, setOpenModal] = useState<boolean>(false)
    const [modalData, setModalData] = useState<Attribute | null>(null)
    const rows = computedArray<Attribute>(data, onUpdate)

    const renderValueInfo = (default_value) => {
        if (default_value.length === 1) {
            return default_value[0]?.value
        } else if (default_value.length > 1) {
            return `${default_value.length} domyślne odpowiedzi`
        } else {
            return '(Brak)'
        }
    }

    const handleAddEditAttribute = (newAttribute: Attribute): void => {
        const indexToUpdate = findAttribute(newAttribute)
        if (indexToUpdate > -1) {
            rows.update(newAttribute, indexToUpdate)
            NotificationManager.success(`Pomyślnie zaktualizowano atrybut ${newAttribute.name}`)
        } else {
            rows.add(newAttribute)
            NotificationManager.success(`Pomyślnie dodano atrybut ${newAttribute.name}`)
        }
    }

    const handleDeleteAttribute = (attributeToDelete: Attribute): void => {
        const indexToDelete = findAttribute(attributeToDelete)
        if (indexToDelete > -1) {
            if(attributeToDelete?.saved_values) {
                const newAttribute = {...attributeToDelete, archived: true};
                rows.update(newAttribute, indexToDelete)
                NotificationManager.success(`Pomyślnie zarchiwizowano atrybut ${attributeToDelete.name}`)
            } else {
                rows.delete(indexToDelete)
                NotificationManager.success(`Pomyślnie usunięto atrybut ${attributeToDelete.name}`)

            }
        }
    }

    const findAttribute = (searchesAttribute: Attribute) =>
        rows.get().findIndex(({id, uniqueId}) => {
            if (id) return id === searchesAttribute.id
            if (uniqueId) return uniqueId === searchesAttribute.uniqueId
            return false
        })

    const rowsSorted = (newRows: Attribute[]) => {
        const orderAdded = newRows.map((item, index) => ({
            ...item,
            list_order: index
        }))
        rows.set(orderAdded);
    }

    const columns = useMemo<Column<Attribute>[]>(() => [
        {
            Header: 'Znacznik',
            accessor: ({name}) => name,
        },
        {
            Header: 'Wyświetlane',
            accessor: ({display_name, icon}) => <>{icon ? (icon) : ''} {display_name}</>,
        },
        {
            Header: 'Treść domyślna',
            accessor: ({default_value}) => <div>{renderValueInfo(default_value)}</div>,
        },
        {
            Header: 'Opcje',
            accessor: attribute => <>
                <button type="button" className="btn btn-sm btn-primary mr-1"
                        onClick={() => {
                            setModalData(attribute)
                            setOpenModal(true)
                        }}>Edytuj
                </button>
                <ConfirmButton onSubmit={() => handleDeleteAttribute(attribute)} buttonText={attribute?.saved_values ? 'Archiwizuj' : 'Usuń'}
                            modalTitle={`Czy na pewno chcesz usunąć ${attribute.name}?`}/>
            </>,
        }
    ], [handleDeleteAttribute, setModalData])

    const displayFilter = (row) => {
        return !row?.archived
    }

    return <>
        <ClientTableSortable<Attribute> data={data} columns={columns} rowsSorted={rowsSorted} displayFilter={displayFilter}  />
        <button type="button" className="btn btn-sm btn-primary" onClick={() => {
            setModalData(() => null)
            setOpenModal(true)
        }}>Dodaj
        </button>
        <AttributesAddEditModal
            isOpen={isOpenModal}
            attribute={modalData}
            onCancel={() => {
                setOpenModal(false)
            }}
            onSubmit={(attribute) => {
                setOpenModal(false);
                handleAddEditAttribute(attribute)
                setModalData(() => null)
            }}/>
    </>
}