import React, { useState } from 'react';

import {
    useParams,
    useRouteMatch,
} from "react-router-dom";

import logoImage from '../../assets/images/sally_full_logo.svg';
import { AttributeValue, AttributeWithValue } from '../../models/Attribute';
import { Contract } from '../../models/Contract';
import { ContractLockRule, ContractLockRuleState } from '../../models/ContractLockRule';
import { ContractWarningRule, ContractWarningRuleState } from '../../models/ContractWarningRule';
import SignService from '../../services/SignService';
import { BillStep1 } from './step1/BillStep1';
import { BillStep2 } from './step2/BillStep2';
import { BillStep3 } from './step3/BillStep3';

export const BillPage = () => {

    const {path} = useRouteMatch();

    let { token } = useParams<any>();

    const [step, setStep] = useState(0);
    const [contract, setContract] = useState<Contract|null>(null);
    const [attributes, setAttributes] = useState<AttributeWithValue[]>([]);

    const fetchContract = async () => {
        const fetchContract = await SignService.getBillData(token);
        if(fetchContract) {
            setContract(fetchContract);
            setAttributes(fetchContract.attribute_values.map((av: AttributeValue) => ({
                value: av.value,
                name: av.attribute.name,
                display_name: av.attribute.display_name,
                icon: av.attribute?.icon,
                show_on_summary: av.attribute?.show_on_summary || false
            })));
        } else {
            setStep(-1);
        }
    }

    React.useEffect(() => {
        fetchContract();
    }, []);

    const nextStep = () => {
        setStep((step < 2) ? step + 1 : 2);
    }

    const prevStep = () => {
        setStep((step > 0) ? step - 1 : 0);
    }

    return (
        <div className='h-100 min-vh-100 contract-page'>
            <div className="main-content">
                <nav className="navbar navbar-expand-md navbar-dark d-flex" id="topbar">
                    <div className="container justify-content-center">
                        <div className="navbar-brand">
                            <img src={logoImage} className="navbar-brand-img" alt="Sally" />
                            <span>Umowa z Sally</span>
                        </div>
                        <div className="w-100 text-center text-md-right">
                            {step == 0 && <span>Witamy!</span>}
                            {step == 1 && <span>Podgląd rachunku</span>}
                            {step == 2 && <span>Podsumowanie</span>}
                        </div>
                    </div>
                </nav>
                <div className="container h-100" id="steps-container">
                    {!contract && step >= 0 && <p>Wczytuję informacje...</p>}
                    {!contract && step < 0 && <p>Umowa jest nieprawidłowa lub nie można pobrać informacji. Skontaktuj się z obsługą.</p>}
                    {contract && <>
                    <BillStep1 showStep={step == 0} nextStep={nextStep} contract={contract} />
                    <BillStep2 showStep={step == 1} nextStep={nextStep} prevStep={prevStep} contract={contract} token={token} />
                    <BillStep3 showStep={step == 2} nextStep={nextStep} contract={contract} attributes={attributes} />
                    </>}
                </div>
            </div>
            <footer className="footer py-5">
                <div className="container text-center text-md-left">
                    <p>© {(new Date().getFullYear())} Sally</p>
                </div>
            </footer>
        </div>
    )
}