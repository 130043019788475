import React, {useEffect, useRef, useState} from 'react';
import {Contract} from "../../../../models/Contract";
import ServerSelect from "../../ServerSelect";
import {Template} from "../../../../models/Template";
import TemplatesService from "../../../../services/TemplatesService";
import ContractAttributesClientTable from "../tables/ContractAttributesClientTable";
import TemplatePreviewModal from "../modals/TemplatePreviewModal";
import {Attribute} from "../../../../models/Attribute";
import {NotificationManager} from 'react-notifications';
import ContractStateSelect from "./fields/select/ContractStateSelect";
import {User} from "../../../../models/User";
import UserService from "../../../../services/UserService";
import {useSelector} from "react-redux";
import {State} from "../../../../models/State";
import {UserRole} from "../../../../routes/user-roles";
import {Client} from "../../../../models/Client";
import ClientService from "../../../../services/ClientService";
import ContractService from '../../../../services/ContractService';
import { useLocation } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ContractLockRule } from '../../../../models/ContractLockRule';
import ContractLockRuleService from '../../../../services/ContractLockRuleService';
import MultiServerSelect from '../../MultiServerSelect';
import ContractWarningRuleService from '../../../../services/ContractWarningRuleService';
import { ContractWarningRule } from '../../../../models/ContractWarningRule';
import DashboardHeader from '../../DashboardHeader';

export interface ContractFormProps {
    readonly contractData?: Partial<Contract> | null
    readonly onSubmitForm: (contract: Contract) => void
    readonly isNew?: boolean
}

export default ({contractData = {}, onSubmitForm, isNew = false}: ContractFormProps) => {
    const user: User | null = useSelector(({auth}: State) => auth.user);
    const [isPreview, setPreview] = useState<boolean>(false)
    const [errors, setErrors] = useState<string[]>([])
    const [form, setForm] = useState<Contract>({
        number: '',
        description: '',
        state: 'draft',
        link: '',
        link_bill: '',
        bill_sent: null,
        bank_account: '',
        pesel: '',
        amount_brutto: '',
        amount_netto: '',
        send_date: '',
        sign_date: '',
        metadata: [],
        user: null,
        recruiter: user?.roles.includes(UserRole.ROLE_HR) ? user : null,
        client: null,
        template: null,
        attribute_values: [],
        warning_rules: [],
        lock_rules: [],
        attachments: []
        , ...contractData
    })

    const handleUpdateAttributes = async (attributes: Attribute[]): Promise<void> => {
        if (form.template) {
            const template = await TemplatesService.update({...form.template, attributes})
            if (template) {
                NotificationManager.success(`Pomyślnie zaktualizowano atrybut`)
                handleInputForm({template})
            } else {
                NotificationManager.error(`Nie udało się zaktualizować atrybutu`)
            }
        }
    }

    const sendMessage = async (): Promise<void> => {
        if (contractData?.id) {
            const contract = await ContractService.send(contractData?.id)
            if (contract) {
                NotificationManager.success(`Pomyślnie wysłano link!`)
            } else {
                NotificationManager.error(`Nie udało się wysłać linku!`)
            }
        }
    }

    const handleInputForm = (input: Partial<Contract>): void => {
        setForm(oldValue => ({...oldValue, ...input}))
    }

    useEffect((): void => {
        contractData && handleInputForm(contractData)
    }, [contractData])

    const handleTemplateChange = (template: Template|null) => {
        if(!form.warning_rules?.length && !form.lock_rules?.length) {
            handleInputForm({
                template,
                warning_rules: template?.warning_rules || [],
                lock_rules: template?.lock_rules || []
            })
        } else {
            handleInputForm({template})
        }
    }

    const onSubmit = (e): void => {
        e.preventDefault()
        !validate().length && onSubmitForm(form)
    }

    let location = useLocation();

    const linkInputRef = useRef<any>(null);

    const copied = () => {
        NotificationManager.success(`Link skopiowany do schowka!`)
    };

    const validate = (): string[] => {
        const errors: string[] = []
        contractData?.id && !form.number.length && errors.push('number')
        !form.recruiter && errors.push('recruiter')
        !form.template && errors.push('template')
        setErrors(errors)
        errors.length && NotificationManager.error('Wypełnij wymagane pola.')
        return errors
    }

    return <>
        <DashboardHeader title={`Umowa ${form?.number || ''}`}/>
        <form className="form-inline">
            <div className="row">
                <div className="col-7">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Dane dotyczące umowy</h3>
                        </div>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Numer</label>
                                    <input type="text"
                                        disabled={!isNew}
                                        placeholder="Numer"
                                        className={`form-control ${errors.includes('number') && 'is-invalid'}`}
                                        value={form.number}
                                        onChange={e => handleInputForm({number: e.target.value})}/>
                                    {errors.includes('number') &&
                                    <small className="text-danger">Numer nie może być pusty</small>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Status</label>
                                    <ContractStateSelect value={form.state} onChange={state => handleInputForm({state})}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Rekruter</label>
                                    <ServerSelect<User>
                                        value={form.recruiter}
                                        additionalFilters={{roles: [UserRole.ROLE_HR]}}
                                        onSelect={recruiter => handleInputForm({recruiter})}
                                        tableService={new UserService()}
                                        label={({full_name}) => full_name}
                                    />
                                    {errors.includes('recruiter') &&
                                    <small className="text-danger">Pole rekruter nie może być puste</small>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Zleceniobiorca</label>
                                    <ServerSelect<User>
                                        value={form.user}
                                        additionalFilters={{roles: [UserRole.ROLE_USER]}}
                                        onSelect={user => handleInputForm({user})}
                                        tableService={new UserService()}
                                        label={({full_name}) => full_name}
                                    />
                                    {errors.includes('user') &&
                                    <small className="text-danger">Pole zleceniobiorca nie jest poprawnie wypełnione</small>}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Klient</label>
                                    <ServerSelect<Client>
                                        value={form.client}
                                        required={true} 
                                        onSelect={client => handleInputForm({client})}
                                        tableService={new ClientService()}
                                        label={({full_name}) => full_name}
                                    />
                                    {errors.includes('client') &&
                                    <small className="text-danger">Pole klient nie może być puste</small>}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Kwota wynagrodzenia brutto</label>
                                    <input className="form-control"
                                        placeholder="Kwota brutto (bez waluty)"
                                        value={form.amount_brutto}
                                        type="number"
                                        onChange={({target: {value: amount_brutto}}) => {
                                            handleInputForm({amount_brutto})
                                        }}/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Kwota wynagrodzenia netto</label>
                                    <input className="form-control"
                                        placeholder="Kwota netto (bez waluty)"
                                        value={form.amount_netto}
                                        type="number"
                                        onChange={({target: {value: amount_netto}}) => {
                                            handleInputForm({amount_netto})
                                        }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-5">
                    {!isNew && <div className="form-group">
                        <label>Link do umowy:</label>
                        <div className="input-group mb-3">
                            <input className="form-control form-control-sm"
                               placeholder="Link"
                               ref={linkInputRef}
                               disabled={true}
                               value={`${window?.location?.origin}/contract/${form.link}`}/>  
                            <div className="input-group-append">
                            <CopyToClipboard text={`${window?.location?.origin}/contract/${form.link}`} onCopy={() => copied()}>
                                <button className="btn btn-outline-primary btn-sm" type="button"><i className="fe fe-clipboard"/> Kopiuj</button>
                            </CopyToClipboard>
                            </div>
                        </div>
                       
                    </div>}
                    {(form?.link_bill && !form?.bill_sent) && <div className="form-group">
                        <label>Link do rachunku:</label>
                        <div className="input-group mb-3">
                            <input className="form-control form-control-sm"
                               placeholder="Link do rachunku"
                               ref={linkInputRef}
                               disabled={true}
                               value={`${window?.location?.origin}/bill/${form.link_bill}`}/>  
                            <div className="input-group-append">
                            <CopyToClipboard text={`${window?.location?.origin}/bill/${form.link_bill}`} onCopy={() => copied()}>
                                <button className="btn btn-outline-primary btn-sm" type="button"><i className="fe fe-clipboard"/> Kopiuj</button>
                            </CopyToClipboard>
                            </div>
                        </div>
                       
                    </div>}
                    {(contractData?.state == 'signed' && form.state == 'signed') && 
                    <div className="form-group">
                        <p>Wybranie stanu podpisana wymaga podpisania umowy przez użytkownika i nie może być wywołane ręcznie.</p>
                    </div>
                    }
                    <div className="float-right">
                        <button type="button" className="btn btn-white" disabled={!form.template}
                                onClick={() => setPreview(true)}>
                            Podgląd
                        </button>
                        <button type="submit" className="btn btn-primary ml-1" onClick={onSubmit} disabled={form.state == 'signed'}>
                            Zapisz
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Blokady i ostrzeżenia</h3>
                        </div>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Blokady</label>
                                    <MultiServerSelect<ContractLockRule>
                                        value={form.lock_rules}
                                        onSelect={lock_rules => handleInputForm({lock_rules})}
                                        tableService={new ContractLockRuleService()}
                                        label={({message_admin}) => message_admin}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Ostrzeżenia</label>
                                    <MultiServerSelect<ContractWarningRule>
                                        value={form.warning_rules}
                                        onSelect={warning_rules => handleInputForm({warning_rules})}
                                        tableService={new ContractWarningRuleService()}
                                        label={({message_admin}) => message_admin}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Szablon i atrybuty</h3>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <ServerSelect<Template>
                                    value={form.template}
                                    onSelect={template => handleTemplateChange(template)}
                                    tableService={new TemplatesService()}
                                    label={({name}) => name}
                                />
                                {errors.includes('template') &&
                                <small className="text-danger">Pole szablon nie może być puste</small>}
                            </div>
                            <ContractAttributesClientTable
                                values={form.attribute_values}
                                attributes={form.template?.attributes || []}
                                onUpdateVal={
                                    attribute_values => handleInputForm({attribute_values})
                                }
                                onUpdateAttr={handleUpdateAttributes}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
        {form.template &&
        <TemplatePreviewModal
            isOpen={isPreview}
            contractData={contractData}
            template={form.template}
            attributeValues={form.attribute_values}
            onClose={() => setPreview(false)}/>}
    </>
}