import React, {useEffect, useMemo, useState} from 'react';

import {useParams} from "react-router-dom";
import Loader from "react-loader";
import {NotificationManager} from 'react-notifications';
import {User} from "../../../models/User";
import UserService from "../../../services/UserService";
import UsersForm from "../../../shared/components/admin/forms/UsersForm";
import { UserRole } from '../../../routes/user-roles';

export default () => {
    const {id = null} = useParams<any>();
    const [loaded, setLoaded] = useState<boolean>(!id)
    const [formData, setFormData] = useState<User | null>(null)

    const initData = async (): Promise<void> => {
        if (id) {
            const user = await UserService.user(Number(id))
            user && setFormData(() => user)
        }
    }

    const method: (model: User) => Promise<User | null> = useMemo(() =>
            id ? UserService.update : UserService.store,
        [id, UserService]
    )

    const handleSubmitForm = (user: User): void => {
        setFormData(() => user)
        setLoaded(false)
        method(user).then(response => {
                if (response) {
                    NotificationManager.success('Użytkownik zapisany pomyślnie.')
                    window.location.href = '/admin/users'
                } else {
                    NotificationManager.error('Nie udało się zapisać użytkownika.')
                    setLoaded(true)
                }
            }
        )
    }

    useEffect((): void => {
        initData().then(() => setLoaded(true))
    }, [])

    return (
        <Loader loaded={loaded}>
            <UsersForm fixedRoles={[UserRole.ROLE_USER]} userData={formData} onSubmitForm={handleSubmitForm}/>
        </Loader>
    )
}