export const officesGetVoivodeships = () => officesStatic.map(item => item.woj)?.map(item => ({label: item, value: item})) || [];
export const officesGetPowiaty = (woj) => {
  return (officesStatic.find(item => item.woj == woj)
            ?.powiaty
            ?.map(item => ({label: item.powiat, value: item.powiat})) )
            || []
};
export const officesGetGminy = (woj, powiat) => officesStatic.find(item => item.woj == woj)?.powiaty?.find(item => item.powiat == powiat)?.gminy?.map(item => ({label: item.gmina, value: item.gmina})) || [];
export const officesGetUS = (woj, powiat, gmina) => officesStatic.find(item => item.woj == woj)?.powiaty?.find(item => item.powiat == powiat)?.gminy?.find(item => item.gmina == gmina)?.us?.map(item => ({label: item, value: item})) || [];

export const officesStatic = [ {
    "woj" : "dolnośląskie",
    "powiaty" : [ {
      "powiat" : "Jelenia Góra",
      "gminy" : [ {
        "gmina" : "Jelenia Góra",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      } ]
    }, {
      "powiat" : "Legnica",
      "gminy" : [ {
        "gmina" : "Legnica",
        "us" : [ "Urząd Skarbowy w Legnicy" ]
      } ]
    }, {
      "powiat" : "Wałbrzych",
      "gminy" : [ {
        "gmina" : "Wałbrzych",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      } ]
    }, {
      "powiat" : "Wrocław",
      "gminy" : [ {
        "gmina" : "Wrocław",
        "us" : [ "Urząd Skarbowy Wrocław-Fabryczna", "Urząd Skarbowy Wrocław-Krzyki", "Urząd Skarbowy Wrocław-Psie Pole", "Urząd Skarbowy Wrocław-Stare Miasto", "Urząd Skarbowy Wrocław-Śródmieście" ]
      } ]
    }, {
      "powiat" : "bolesławiecki",
      "gminy" : [ {
        "gmina" : "Bolesławiec",
        "us" : [ "Urząd Skarbowy w Bolesławcu" ]
      }, {
        "gmina" : "Gromadka",
        "us" : [ "Urząd Skarbowy w Bolesławcu" ]
      }, {
        "gmina" : "Nowogrodziec",
        "us" : [ "Urząd Skarbowy w Bolesławcu" ]
      }, {
        "gmina" : "Osiecznica",
        "us" : [ "Urząd Skarbowy w Bolesławcu" ]
      }, {
        "gmina" : "Warta Bolesławiecka",
        "us" : [ "Urząd Skarbowy w Bolesławcu" ]
      } ]
    }, {
      "powiat" : "dzierżoniowski",
      "gminy" : [ {
        "gmina" : "Bielawa",
        "us" : [ "Urząd Skarbowy w Dzierżoniowie" ]
      }, {
        "gmina" : "Dzierżoniów",
        "us" : [ "Urząd Skarbowy w Dzierżoniowie" ]
      }, {
        "gmina" : "Niemcza",
        "us" : [ "Urząd Skarbowy w Dzierżoniowie" ]
      }, {
        "gmina" : "Pieszyce",
        "us" : [ "Urząd Skarbowy w Dzierżoniowie" ]
      }, {
        "gmina" : "Piława Górna",
        "us" : [ "Urząd Skarbowy w Dzierżoniowie" ]
      }, {
        "gmina" : "Łagiewniki",
        "us" : [ "Urząd Skarbowy w Dzierżoniowie" ]
      } ]
    }, {
      "powiat" : "górowski",
      "gminy" : [ {
        "gmina" : "Góra",
        "us" : [ "Urząd Skarbowy w Górze" ]
      }, {
        "gmina" : "Jemielno",
        "us" : [ "Urząd Skarbowy w Górze" ]
      }, {
        "gmina" : "Niechlów",
        "us" : [ "Urząd Skarbowy w Górze" ]
      }, {
        "gmina" : "Wąsosz",
        "us" : [ "Urząd Skarbowy w Górze" ]
      } ]
    }, {
      "powiat" : "głogowski",
      "gminy" : [ {
        "gmina" : "Głogów",
        "us" : [ "Urząd Skarbowy w Głogowie" ]
      }, {
        "gmina" : "Jerzmanowa",
        "us" : [ "Urząd Skarbowy w Głogowie" ]
      }, {
        "gmina" : "Kotla",
        "us" : [ "Urząd Skarbowy w Głogowie" ]
      }, {
        "gmina" : "Pęcław",
        "us" : [ "Urząd Skarbowy w Głogowie" ]
      }, {
        "gmina" : "Żukowice",
        "us" : [ "Urząd Skarbowy w Głogowie" ]
      } ]
    }, {
      "powiat" : "jaworski",
      "gminy" : [ {
        "gmina" : "Bolków",
        "us" : [ "Urząd Skarbowy w Jaworze" ]
      }, {
        "gmina" : "Jawor",
        "us" : [ "Urząd Skarbowy w Jaworze" ]
      }, {
        "gmina" : "Męcinka",
        "us" : [ "Urząd Skarbowy w Jaworze" ]
      }, {
        "gmina" : "Mściwojów",
        "us" : [ "Urząd Skarbowy w Jaworze" ]
      }, {
        "gmina" : "Paszowice",
        "us" : [ "Urząd Skarbowy w Jaworze" ]
      }, {
        "gmina" : "Wądroże Wielkie",
        "us" : [ "Urząd Skarbowy w Jaworze" ]
      } ]
    }, {
      "powiat" : "jeleniogórski",
      "gminy" : [ {
        "gmina" : "Janowice Wielkie",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      }, {
        "gmina" : "Jeżów Sudecki",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      }, {
        "gmina" : "Karpacz",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      }, {
        "gmina" : "Kowary",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      }, {
        "gmina" : "Mysłakowice",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      }, {
        "gmina" : "Piechowice",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      }, {
        "gmina" : "Podgórzyn",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      }, {
        "gmina" : "Stara Kamienica",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      }, {
        "gmina" : "Szklarska Poręba",
        "us" : [ "Urząd Skarbowy w Jeleniej Górze" ]
      } ]
    }, {
      "powiat" : "kamiennogórski",
      "gminy" : [ {
        "gmina" : "Kamienna Góra",
        "us" : [ "Urząd Skarbowy w Kamiennej Górze" ]
      }, {
        "gmina" : "Lubawka",
        "us" : [ "Urząd Skarbowy w Kamiennej Górze" ]
      }, {
        "gmina" : "Marciszów",
        "us" : [ "Urząd Skarbowy w Kamiennej Górze" ]
      } ]
    }, {
      "powiat" : "kłodzki",
      "gminy" : [ {
        "gmina" : "Bystrzyca Kłodzka",
        "us" : [ "Urząd Skarbowy w Bystrzycy Kłodzkiej" ]
      }, {
        "gmina" : "Duszniki-Zdrój",
        "us" : [ "Urząd Skarbowy w Kłodzku" ]
      }, {
        "gmina" : "Kudowa-Zdrój",
        "us" : [ "Urząd Skarbowy w Kłodzku" ]
      }, {
        "gmina" : "Kłodzko",
        "us" : [ "Urząd Skarbowy w Kłodzku" ]
      }, {
        "gmina" : "Lewin Kłodzki",
        "us" : [ "Urząd Skarbowy w Kłodzku" ]
      }, {
        "gmina" : "Lądek-Zdrój",
        "us" : [ "Urząd Skarbowy w Bystrzycy Kłodzkiej" ]
      }, {
        "gmina" : "Międzylesie",
        "us" : [ "Urząd Skarbowy w Bystrzycy Kłodzkiej" ]
      }, {
        "gmina" : "Nowa Ruda",
        "us" : [ "Urząd Skarbowy w Nowej Rudzie" ]
      }, {
        "gmina" : "Polanica-Zdrój",
        "us" : [ "Urząd Skarbowy w Kłodzku" ]
      }, {
        "gmina" : "Radków",
        "us" : [ "Urząd Skarbowy w Nowej Rudzie" ]
      }, {
        "gmina" : "Stronie Śląskie",
        "us" : [ "Urząd Skarbowy w Bystrzycy Kłodzkiej" ]
      }, {
        "gmina" : "Szczytna",
        "us" : [ "Urząd Skarbowy w Kłodzku" ]
      } ]
    }, {
      "powiat" : "legnicki",
      "gminy" : [ {
        "gmina" : "Chojnów",
        "us" : [ "Urząd Skarbowy w Legnicy" ]
      }, {
        "gmina" : "Krotoszyce",
        "us" : [ "Urząd Skarbowy w Legnicy" ]
      }, {
        "gmina" : "Kunice",
        "us" : [ "Urząd Skarbowy w Legnicy" ]
      }, {
        "gmina" : "Legnickie Pole",
        "us" : [ "Urząd Skarbowy w Legnicy" ]
      }, {
        "gmina" : "Miłkowice",
        "us" : [ "Urząd Skarbowy w Legnicy" ]
      }, {
        "gmina" : "Prochowice",
        "us" : [ "Urząd Skarbowy w Legnicy" ]
      }, {
        "gmina" : "Ruja",
        "us" : [ "Urząd Skarbowy w Legnicy" ]
      } ]
    }, {
      "powiat" : "lubański",
      "gminy" : [ {
        "gmina" : "Leśna",
        "us" : [ "Urząd Skarbowy w Lubaniu" ]
      }, {
        "gmina" : "Lubań",
        "us" : [ "Urząd Skarbowy w Lubaniu" ]
      }, {
        "gmina" : "Olszyna",
        "us" : [ "Urząd Skarbowy w Lubaniu" ]
      }, {
        "gmina" : "Platerówka",
        "us" : [ "Urząd Skarbowy w Lubaniu" ]
      }, {
        "gmina" : "Siekierczyn",
        "us" : [ "Urząd Skarbowy w Lubaniu" ]
      }, {
        "gmina" : "Świeradów-Zdrój",
        "us" : [ "Urząd Skarbowy w Lubaniu" ]
      } ]
    }, {
      "powiat" : "lubiński",
      "gminy" : [ {
        "gmina" : "Lubin",
        "us" : [ "Urząd Skarbowy w Lubinie" ]
      }, {
        "gmina" : "Rudna",
        "us" : [ "Urząd Skarbowy w Lubinie" ]
      }, {
        "gmina" : "Ścinawa",
        "us" : [ "Urząd Skarbowy w Lubinie" ]
      } ]
    }, {
      "powiat" : "lwówecki",
      "gminy" : [ {
        "gmina" : "Gryfów Śląski",
        "us" : [ "Urząd Skarbowy w Lwówku Śląskim" ]
      }, {
        "gmina" : "Lubomierz",
        "us" : [ "Urząd Skarbowy w Lwówku Śląskim" ]
      }, {
        "gmina" : "Lwówek Śląski",
        "us" : [ "Urząd Skarbowy w Lwówku Śląskim" ]
      }, {
        "gmina" : "Mirsk",
        "us" : [ "Urząd Skarbowy w Lwówku Śląskim" ]
      }, {
        "gmina" : "Wleń",
        "us" : [ "Urząd Skarbowy w Lwówku Śląskim" ]
      } ]
    }, {
      "powiat" : "milicki",
      "gminy" : [ {
        "gmina" : "Cieszków",
        "us" : [ "Urząd Skarbowy w Milicz" ]
      }, {
        "gmina" : "Krośnice",
        "us" : [ "Urząd Skarbowy w Milicz" ]
      }, {
        "gmina" : "Milicz",
        "us" : [ "Urząd Skarbowy w Milicz" ]
      } ]
    }, {
      "powiat" : "oleśnicki",
      "gminy" : [ {
        "gmina" : "Bierutów",
        "us" : [ "Urząd Skarbowy w Oleśnicy" ]
      }, {
        "gmina" : "Dobroszyce",
        "us" : [ "Urząd Skarbowy w Oleśnicy" ]
      }, {
        "gmina" : "Dziadowa Kłoda",
        "us" : [ "Urząd Skarbowy w Oleśnicy" ]
      }, {
        "gmina" : "Międzybórz",
        "us" : [ "Urząd Skarbowy w Oleśnicy" ]
      }, {
        "gmina" : "Oleśnica",
        "us" : [ "Urząd Skarbowy w Oleśnicy" ]
      }, {
        "gmina" : "Syców",
        "us" : [ "Urząd Skarbowy w Oleśnicy" ]
      }, {
        "gmina" : "Twardogóra",
        "us" : [ "Urząd Skarbowy w Oleśnicy" ]
      } ]
    }, {
      "powiat" : "oławski",
      "gminy" : [ {
        "gmina" : "Domaniów",
        "us" : [ "Urząd Skarbowy w Oławie" ]
      }, {
        "gmina" : "Jelcz-Laskowice",
        "us" : [ "Urząd Skarbowy w Oławie" ]
      }, {
        "gmina" : "Oława",
        "us" : [ "Urząd Skarbowy w Oławie" ]
      } ]
    }, {
      "powiat" : "polkowicki",
      "gminy" : [ {
        "gmina" : "Chocianów",
        "us" : [ "Urząd Skarbowy w Polkowicach" ]
      }, {
        "gmina" : "Gaworzyce",
        "us" : [ "Urząd Skarbowy w Polkowicach" ]
      }, {
        "gmina" : "Grębocice",
        "us" : [ "Urząd Skarbowy w Polkowicach" ]
      }, {
        "gmina" : "Polkowice",
        "us" : [ "Urząd Skarbowy w Polkowicach" ]
      }, {
        "gmina" : "Przemków",
        "us" : [ "Urząd Skarbowy w Polkowicach" ]
      }, {
        "gmina" : "Radwanice",
        "us" : [ "Urząd Skarbowy w Polkowicach" ]
      } ]
    }, {
      "powiat" : "strzeliński",
      "gminy" : [ {
        "gmina" : "Borów",
        "us" : [ "Urząd Skarbowy w Strzelinie" ]
      }, {
        "gmina" : "Kondratowice",
        "us" : [ "Urząd Skarbowy w Strzelinie" ]
      }, {
        "gmina" : "Przeworno",
        "us" : [ "Urząd Skarbowy w Strzelinie" ]
      }, {
        "gmina" : "Strzelin",
        "us" : [ "Urząd Skarbowy w Strzelinie" ]
      }, {
        "gmina" : "Wiązów",
        "us" : [ "Urząd Skarbowy w Strzelinie" ]
      } ]
    }, {
      "powiat" : "trzebnicki",
      "gminy" : [ {
        "gmina" : "Oborniki Śląskie",
        "us" : [ "Urząd Skarbowy w Trzebnicy" ]
      }, {
        "gmina" : "Prusice",
        "us" : [ "Urząd Skarbowy w Trzebnicy" ]
      }, {
        "gmina" : "Trzebnica",
        "us" : [ "Urząd Skarbowy w Trzebnicy" ]
      }, {
        "gmina" : "Wisznia Mała",
        "us" : [ "Urząd Skarbowy w Trzebnicy" ]
      }, {
        "gmina" : "Zawonia",
        "us" : [ "Urząd Skarbowy w Trzebnicy" ]
      }, {
        "gmina" : "Żmigród",
        "us" : [ "Urząd Skarbowy w Trzebnicy" ]
      } ]
    }, {
      "powiat" : "wałbrzyski",
      "gminy" : [ {
        "gmina" : "Boguszów-Gorce",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      }, {
        "gmina" : "Czarny Bór",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      }, {
        "gmina" : "Głuszyca",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      }, {
        "gmina" : "Jedlina-Zdrój",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      }, {
        "gmina" : "Mieroszów",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      }, {
        "gmina" : "Stare Bogaczowice",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      }, {
        "gmina" : "Szczawno-Zdrój",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      }, {
        "gmina" : "Walim",
        "us" : [ "Urząd Skarbowy w Wałbrzychu" ]
      } ]
    }, {
      "powiat" : "wołowski",
      "gminy" : [ {
        "gmina" : "Brzeg Dolny",
        "us" : [ "Urząd Skarbowy w Wołowie" ]
      }, {
        "gmina" : "Wińsko",
        "us" : [ "Urząd Skarbowy w Wołowie" ]
      }, {
        "gmina" : "Wołów",
        "us" : [ "Urząd Skarbowy w Wołowie" ]
      } ]
    }, {
      "powiat" : "wrocławski",
      "gminy" : [ {
        "gmina" : "Czernica",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      }, {
        "gmina" : "Długołęka",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      }, {
        "gmina" : "Jordanów Śląski",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      }, {
        "gmina" : "Kobierzyce",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      }, {
        "gmina" : "Kąty Wrocławskie",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      }, {
        "gmina" : "Mietków",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      }, {
        "gmina" : "Siechnice",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      }, {
        "gmina" : "Sobótka",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      }, {
        "gmina" : "Żórawina",
        "us" : [ "Pierwszy Urząd Skarbowy we Wrocławiu" ]
      } ]
    }, {
      "powiat" : "zgorzelecki",
      "gminy" : [ {
        "gmina" : "Bogatynia",
        "us" : [ "Urząd Skarbowy w Zgorzelcu" ]
      }, {
        "gmina" : "Pieńsk",
        "us" : [ "Urząd Skarbowy w Zgorzelcu" ]
      }, {
        "gmina" : "Sulików",
        "us" : [ "Urząd Skarbowy w Zgorzelcu" ]
      }, {
        "gmina" : "Węgliniec",
        "us" : [ "Urząd Skarbowy w Zgorzelcu" ]
      }, {
        "gmina" : "Zawidów",
        "us" : [ "Urząd Skarbowy w Zgorzelcu" ]
      }, {
        "gmina" : "Zgorzelec",
        "us" : [ "Urząd Skarbowy w Zgorzelcu" ]
      } ]
    }, {
      "powiat" : "ząbkowicki",
      "gminy" : [ {
        "gmina" : "Bardo",
        "us" : [ "Urząd Skarbowy w Ząbkowicach Śląskich" ]
      }, {
        "gmina" : "Ciepłowody",
        "us" : [ "Urząd Skarbowy w Ząbkowicach Śląskich" ]
      }, {
        "gmina" : "Kamieniec Ząbkowicki",
        "us" : [ "Urząd Skarbowy w Ząbkowicach Śląskich" ]
      }, {
        "gmina" : "Stoszowice",
        "us" : [ "Urząd Skarbowy w Ząbkowicach Śląskich" ]
      }, {
        "gmina" : "Ziębice",
        "us" : [ "Urząd Skarbowy w Ząbkowicach Śląskich" ]
      }, {
        "gmina" : "Ząbkowice Śląskie",
        "us" : [ "Urząd Skarbowy w Ząbkowicach Śląskich" ]
      }, {
        "gmina" : "Złoty Stok",
        "us" : [ "Urząd Skarbowy w Ząbkowicach Śląskich" ]
      } ]
    }, {
      "powiat" : "złotoryjski",
      "gminy" : [ {
        "gmina" : "Pielgrzymka",
        "us" : [ "Urząd Skarbowy w Złotoryi" ]
      }, {
        "gmina" : "Wojcieszów",
        "us" : [ "Urząd Skarbowy w Złotoryi" ]
      }, {
        "gmina" : "Zagrodno",
        "us" : [ "Urząd Skarbowy w Złotoryi" ]
      }, {
        "gmina" : "Złotoryja",
        "us" : [ "Urząd Skarbowy w Złotoryi" ]
      }, {
        "gmina" : "Świerzawa",
        "us" : [ "Urząd Skarbowy w Złotoryi" ]
      } ]
    }, {
      "powiat" : "średzki",
      "gminy" : [ {
        "gmina" : "Kostomłoty",
        "us" : [ "Urząd Skarbowy w Środzie Śląskiej" ]
      }, {
        "gmina" : "Malczyce",
        "us" : [ "Urząd Skarbowy w Środzie Śląskiej" ]
      }, {
        "gmina" : "Miękinia",
        "us" : [ "Urząd Skarbowy w Środzie Śląskiej" ]
      }, {
        "gmina" : "Udanin",
        "us" : [ "Urząd Skarbowy w Środzie Śląskiej" ]
      }, {
        "gmina" : "Środa Śląska",
        "us" : [ "Urząd Skarbowy w Środzie Śląskiej" ]
      } ]
    }, {
      "powiat" : "świdnicki",
      "gminy" : [ {
        "gmina" : "Dobromierz",
        "us" : [ "Urząd Skarbowy w Świdnicy" ]
      }, {
        "gmina" : "Jaworzyna Śląska",
        "us" : [ "Urząd Skarbowy w Świdnicy" ]
      }, {
        "gmina" : "Marcinowice",
        "us" : [ "Urząd Skarbowy w Świdnicy" ]
      }, {
        "gmina" : "Strzegom",
        "us" : [ "Urząd Skarbowy w Świdnicy" ]
      }, {
        "gmina" : "Świdnica",
        "us" : [ "Urząd Skarbowy w Świdnicy" ]
      }, {
        "gmina" : "Świebodzice",
        "us" : [ "Urząd Skarbowy w Świdnicy" ]
      }, {
        "gmina" : "Żarów",
        "us" : [ "Urząd Skarbowy w Świdnicy" ]
      } ]
    } ]
  }, {
    "woj" : "kujawsko-pomorskie",
    "powiaty" : [ {
      "powiat" : "Bydgoszcz",
      "gminy" : [ {
        "gmina" : "Bydgoszcz",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy", "Drugi Urząd Skarbowy w Bydgoszczy", "Trzeci Urząd Skarbowy w Bydgoszczy" ]
      } ]
    }, {
      "powiat" : "Toruń",
      "gminy" : [ {
        "gmina" : "Toruń",
        "us" : [ "Pierwszy Urząd Skarbowy w Toruniu", "Drugi Urząd Skarbowy w Toruniu" ]
      } ]
    }, {
      "powiat" : "Włocławek",
      "gminy" : [ {
        "gmina" : "Włocławek",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      } ]
    }, {
      "powiat" : "aleksandrowski",
      "gminy" : [ {
        "gmina" : "Aleksandrów Kujawski",
        "us" : [ "Urząd Skarbowy w Aleksandrowie Kujawskim" ]
      }, {
        "gmina" : "Bądkowo",
        "us" : [ "Urząd Skarbowy w Aleksandrowie Kujawskim" ]
      }, {
        "gmina" : "Ciechocinek",
        "us" : [ "Urząd Skarbowy w Aleksandrowie Kujawskim" ]
      }, {
        "gmina" : "Koneck",
        "us" : [ "Urząd Skarbowy w Aleksandrowie Kujawskim" ]
      }, {
        "gmina" : "Nieszawa",
        "us" : [ "Urząd Skarbowy w Aleksandrowie Kujawskim" ]
      }, {
        "gmina" : "Raciążek",
        "us" : [ "Urząd Skarbowy w Aleksandrowie Kujawskim" ]
      }, {
        "gmina" : "Waganiec",
        "us" : [ "Urząd Skarbowy w Aleksandrowie Kujawskim" ]
      }, {
        "gmina" : "Zakrzewo",
        "us" : [ "Urząd Skarbowy w Aleksandrowie Kujawskim" ]
      } ]
    }, {
      "powiat" : "brodnicki",
      "gminy" : [ {
        "gmina" : "Bartniczka",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      }, {
        "gmina" : "Bobrowo",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      }, {
        "gmina" : "Brodnica",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      }, {
        "gmina" : "Brzozie",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      }, {
        "gmina" : "Górzno",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      }, {
        "gmina" : "Jabłonowo Pomorskie",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      }, {
        "gmina" : "Osiek",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      }, {
        "gmina" : "Zbiczno",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      }, {
        "gmina" : "Świedziebnia",
        "us" : [ "Urząd Skarbowy w Brodnicy" ]
      } ]
    }, {
      "powiat" : "bydgoski",
      "gminy" : [ {
        "gmina" : "Białe Błota",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy" ]
      }, {
        "gmina" : "Dobrcz",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy" ]
      }, {
        "gmina" : "Dąbrowa Chełmińska",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy" ]
      }, {
        "gmina" : "Koronowo",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy" ]
      }, {
        "gmina" : "Nowa Wieś Wielka",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy" ]
      }, {
        "gmina" : "Osielsko",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy" ]
      }, {
        "gmina" : "Sicienko",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy" ]
      }, {
        "gmina" : "Solec Kujawski",
        "us" : [ "Pierwszy Urząd Skarbowy w Bydgoszczy" ]
      } ]
    }, {
      "powiat" : "chełmiński",
      "gminy" : [ {
        "gmina" : "Chełmno",
        "us" : [ "Urząd Skarbowy w Chełmnie" ]
      }, {
        "gmina" : "Kijewo Królewskie",
        "us" : [ "Urząd Skarbowy w Chełmnie" ]
      }, {
        "gmina" : "Lisewo",
        "us" : [ "Urząd Skarbowy w Chełmnie" ]
      }, {
        "gmina" : "Papowo Biskupie",
        "us" : [ "Urząd Skarbowy w Chełmnie" ]
      }, {
        "gmina" : "Stolno",
        "us" : [ "Urząd Skarbowy w Chełmnie" ]
      }, {
        "gmina" : "Unisław",
        "us" : [ "Urząd Skarbowy w Chełmnie" ]
      } ]
    }, {
      "powiat" : "golubsko-dobrzyński",
      "gminy" : [ {
        "gmina" : "Ciechocin",
        "us" : [ "Urząd Skarbowy w Golubiu-Dobrzyniu" ]
      }, {
        "gmina" : "Golub-Dobrzyń",
        "us" : [ "Urząd Skarbowy w Golubiu-Dobrzyniu" ]
      }, {
        "gmina" : "Kowalewo Pomorskie",
        "us" : [ "Urząd Skarbowy w Golubiu-Dobrzyniu" ]
      }, {
        "gmina" : "Radomin",
        "us" : [ "Urząd Skarbowy w Golubiu-Dobrzyniu" ]
      }, {
        "gmina" : "Zbójno",
        "us" : [ "Urząd Skarbowy w Golubiu-Dobrzyniu" ]
      } ]
    }, {
      "powiat" : "grudziądzki",
      "gminy" : [ {
        "gmina" : "Grudziądz",
        "us" : [ "Urząd Skarbowy w Grudziądzu" ]
      }, {
        "gmina" : "Gruta",
        "us" : [ "Urząd Skarbowy w Grudziądzu" ]
      }, {
        "gmina" : "Radzyń Chełmiński",
        "us" : [ "Urząd Skarbowy w Grudziądzu" ]
      }, {
        "gmina" : "Rogóźno",
        "us" : [ "Urząd Skarbowy w Grudziądzu" ]
      }, {
        "gmina" : "Łasin",
        "us" : [ "Urząd Skarbowy w Grudziądzu" ]
      }, {
        "gmina" : "Świecie nad Osą",
        "us" : [ "Urząd Skarbowy w Grudziądzu" ]
      } ]
    }, {
      "powiat" : "inowrocławski",
      "gminy" : [ {
        "gmina" : "Dąbrowa Biskupia",
        "us" : [ "Urząd Skarbowy w Inowrocławiu" ]
      }, {
        "gmina" : "Gniewkowo",
        "us" : [ "Urząd Skarbowy w Inowrocławiu" ]
      }, {
        "gmina" : "Inowrocław",
        "us" : [ "Urząd Skarbowy w Inowrocławiu" ]
      }, {
        "gmina" : "Janikowo",
        "us" : [ "Urząd Skarbowy w Inowrocławiu" ]
      }, {
        "gmina" : "Kruszwica",
        "us" : [ "Urząd Skarbowy w Inowrocławiu" ]
      }, {
        "gmina" : "Pakość",
        "us" : [ "Urząd Skarbowy w Inowrocławiu" ]
      }, {
        "gmina" : "Rojewo",
        "us" : [ "Urząd Skarbowy w Inowrocławiu" ]
      }, {
        "gmina" : "Złotniki Kujawskie",
        "us" : [ "Urząd Skarbowy w Inowrocławiu" ]
      } ]
    }, {
      "powiat" : "lipnowski",
      "gminy" : [ {
        "gmina" : "Bobrowniki",
        "us" : [ "Urząd Skarbowy w Lipnie" ]
      }, {
        "gmina" : "Chrostkowo",
        "us" : [ "Urząd Skarbowy w Lipnie" ]
      }, {
        "gmina" : "Dobrzyń nad Wisłą",
        "us" : [ "Urząd Skarbowy w Lipnie" ]
      }, {
        "gmina" : "Kikół",
        "us" : [ "Urząd Skarbowy w Lipnie" ]
      }, {
        "gmina" : "Lipno",
        "us" : [ "Urząd Skarbowy w Lipnie" ]
      }, {
        "gmina" : "Skępe",
        "us" : [ "Urząd Skarbowy w Lipnie" ]
      }, {
        "gmina" : "Tłuchowo",
        "us" : [ "Urząd Skarbowy w Lipnie" ]
      }, {
        "gmina" : "Wielgie",
        "us" : [ "Urząd Skarbowy w Lipnie" ]
      } ]
    }, {
      "powiat" : "mogileński",
      "gminy" : [ {
        "gmina" : "Dąbrowa",
        "us" : [ "Urząd Skarbowy w Mogilnie" ]
      }, {
        "gmina" : "Jeziora Wielkie",
        "us" : [ "Urząd Skarbowy w Mogilnie" ]
      }, {
        "gmina" : "Mogilno",
        "us" : [ "Urząd Skarbowy w Mogilnie" ]
      }, {
        "gmina" : "Strzelno",
        "us" : [ "Urząd Skarbowy w Mogilnie" ]
      } ]
    }, {
      "powiat" : "nakielski",
      "gminy" : [ {
        "gmina" : "Kcynia",
        "us" : [ "Urząd Skarbowy w Nagle nad Notecią" ]
      }, {
        "gmina" : "Mrocza",
        "us" : [ "Urząd Skarbowy w Nagle nad Notecią" ]
      }, {
        "gmina" : "Nakło nad Notecią",
        "us" : [ "Urząd Skarbowy w Nagle nad Notecią" ]
      }, {
        "gmina" : "Sadki",
        "us" : [ "Urząd Skarbowy w Nagle nad Notecią" ]
      }, {
        "gmina" : "Szubin",
        "us" : [ "Urząd Skarbowy w Nagle nad Notecią" ]
      } ]
    }, {
      "powiat" : "radziejowski",
      "gminy" : [ {
        "gmina" : "Bytoń",
        "us" : [ "Urząd Skarbowy w Radziejowie" ]
      }, {
        "gmina" : "Dobre",
        "us" : [ "Urząd Skarbowy w Radziejowie" ]
      }, {
        "gmina" : "Osięciny",
        "us" : [ "Urząd Skarbowy w Radziejowie" ]
      }, {
        "gmina" : "Piotrków Kujawski",
        "us" : [ "Urząd Skarbowy w Radziejowie" ]
      }, {
        "gmina" : "Radziejów",
        "us" : [ "Urząd Skarbowy w Radziejowie" ]
      }, {
        "gmina" : "Topólka",
        "us" : [ "Urząd Skarbowy w Radziejowie" ]
      } ]
    }, {
      "powiat" : "rypiński",
      "gminy" : [ {
        "gmina" : "Brzuze",
        "us" : [ "Urząd Skarbowy w Rypinie" ]
      }, {
        "gmina" : "Rogowo",
        "us" : [ "Urząd Skarbowy w Rypinie" ]
      }, {
        "gmina" : "Rypin",
        "us" : [ "Urząd Skarbowy w Rypinie" ]
      }, {
        "gmina" : "Skrwilno",
        "us" : [ "Urząd Skarbowy w Rypinie" ]
      }, {
        "gmina" : "Wąpielsk",
        "us" : [ "Urząd Skarbowy w Rypinie" ]
      } ]
    }, {
      "powiat" : "sępoleński",
      "gminy" : [ {
        "gmina" : "Kamień Krajeński",
        "us" : [ "Urząd Skarbowy w Sępólnie Krajeńskim" ]
      }, {
        "gmina" : "Sośno",
        "us" : [ "Urząd Skarbowy w Sępólnie Krajeńskim" ]
      }, {
        "gmina" : "Sępólno Krajeńskie",
        "us" : [ "Urząd Skarbowy w Sępólnie Krajeńskim" ]
      }, {
        "gmina" : "Więcbork",
        "us" : [ "Urząd Skarbowy w Sępólnie Krajeńskim" ]
      } ]
    }, {
      "powiat" : "toruński",
      "gminy" : [ {
        "gmina" : "Chełmża",
        "us" : [ "Drugi Urząd Skarbowy w Toruniu" ]
      }, {
        "gmina" : "Czernikowo",
        "us" : [ "Drugi Urząd Skarbowy w Toruniu" ]
      }, {
        "gmina" : "Lubicz",
        "us" : [ "Drugi Urząd Skarbowy w Toruniu" ]
      }, {
        "gmina" : "Obrowo",
        "us" : [ "Drugi Urząd Skarbowy w Toruniu" ]
      }, {
        "gmina" : "Wielka Nieszawka",
        "us" : [ "Drugi Urząd Skarbowy w Toruniu" ]
      }, {
        "gmina" : "Zławieś Wielka",
        "us" : [ "Drugi Urząd Skarbowy w Toruniu" ]
      }, {
        "gmina" : "Łubianka",
        "us" : [ "Drugi Urząd Skarbowy w Toruniu" ]
      }, {
        "gmina" : "Łysomice",
        "us" : [ "Drugi Urząd Skarbowy w Toruniu" ]
      } ]
    }, {
      "powiat" : "tucholski",
      "gminy" : [ {
        "gmina" : "Cekcyn",
        "us" : [ "Urząd Skarbowy w Tucholi" ]
      }, {
        "gmina" : "Gostycyn",
        "us" : [ "Urząd Skarbowy w Tucholi" ]
      }, {
        "gmina" : "Kęsowo",
        "us" : [ "Urząd Skarbowy w Tucholi" ]
      }, {
        "gmina" : "Lubiewo",
        "us" : [ "Urząd Skarbowy w Tucholi" ]
      }, {
        "gmina" : "Tuchola",
        "us" : [ "Urząd Skarbowy w Tucholi" ]
      }, {
        "gmina" : "Śliwice",
        "us" : [ "Urząd Skarbowy w Tucholi" ]
      } ]
    }, {
      "powiat" : "wąbrzeski",
      "gminy" : [ {
        "gmina" : "Dębowa Łąka",
        "us" : [ "Urząd Skarbowy w Wąbrzeźnie" ]
      }, {
        "gmina" : "Książki",
        "us" : [ "Urząd Skarbowy w Wąbrzeźnie" ]
      }, {
        "gmina" : "Płużnica",
        "us" : [ "Urząd Skarbowy w Wąbrzeźnie" ]
      }, {
        "gmina" : "Ryńsk",
        "us" : [ "Urząd Skarbowy w Wąbrzeźnie" ]
      }, {
        "gmina" : "Wąbrzeźno",
        "us" : [ "Urząd Skarbowy w Wąbrzeźnie" ]
      } ]
    }, {
      "powiat" : "włocławski",
      "gminy" : [ {
        "gmina" : "Baruchowo",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Boniewo",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Brześć Kujawski",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Choceń",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Chodecz",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Fabianki",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Izbica Kujawska",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Kowal",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Lubanie",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Lubień Kujawski",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Lubraniec",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      }, {
        "gmina" : "Włocławek",
        "us" : [ "Urząd Skarbowy we Włocławku" ]
      } ]
    }, {
      "powiat" : "świecki",
      "gminy" : [ {
        "gmina" : "Bukowiec",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Dragacz",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Drzycim",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Jeżewo",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Lniano",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Nowe",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Osie",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Pruszcz",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Warlubie",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Świecie",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      }, {
        "gmina" : "Świekatowo",
        "us" : [ "Urząd Skarbowy w Świeciu" ]
      } ]
    }, {
      "powiat" : "żniński",
      "gminy" : [ {
        "gmina" : "Barcin",
        "us" : [ "Urząd Skarbowy w Żninie" ]
      }, {
        "gmina" : "Gąsawa",
        "us" : [ "Urząd Skarbowy w Żninie" ]
      }, {
        "gmina" : "Janowiec Wielkopolski",
        "us" : [ "Urząd Skarbowy w Żninie" ]
      }, {
        "gmina" : "Rogowo",
        "us" : [ "Urząd Skarbowy w Żninie" ]
      }, {
        "gmina" : "Łabiszyn",
        "us" : [ "Urząd Skarbowy w Żninie" ]
      }, {
        "gmina" : "Żnin",
        "us" : [ "Urząd Skarbowy w Żninie" ]
      } ]
    } ]
  }, {
    "woj" : "lubelskie",
    "powiaty" : [ {
      "powiat" : "Biała Podlaska",
      "gminy" : [ {
        "gmina" : "Biała Podlaska",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      } ]
    }, {
      "powiat" : "Chełm",
      "gminy" : [ {
        "gmina" : "Chełm",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      } ]
    }, {
      "powiat" : "Lublin",
      "gminy" : [ {
        "gmina" : "Lublin",
        "us" : [ "Pierwszy Urząd Skarbowy w Lublinie", "Drugi Urząd Skarbowy w Lublinie", "Trzeci Urząd Skarbowy w Lublinie" ]
      } ]
    }, {
      "powiat" : "Zamość",
      "gminy" : [ {
        "gmina" : "Zamość",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      } ]
    }, {
      "powiat" : "bialski",
      "gminy" : [ {
        "gmina" : "Biała Podlaska",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Drelów",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Janów Podlaski",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Kodeń",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Konstantynów",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Leśna Podlaska",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Międzyrzec Podlaski",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Piszczac",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Rokitno",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Rossosz",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Sosnówka",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Sławatycze",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Terespol",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Tuczna",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Wisznice",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Zalesie",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      }, {
        "gmina" : "Łomazy",
        "us" : [ "Urząd Skarbowy w Białej Podlaskiej" ]
      } ]
    }, {
      "powiat" : "biłgorajski",
      "gminy" : [ {
        "gmina" : "Aleksandrów",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Biszcza",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Biłgoraj",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Frampol",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Goraj",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Józefów",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Księżpol",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Obsza",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Potok Górny",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Tarnogród",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Tereszpol",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Turobin",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      }, {
        "gmina" : "Łukowa",
        "us" : [ "Urząd Skarbowy w Biłgoraju" ]
      } ]
    }, {
      "powiat" : "chełmski",
      "gminy" : [ {
        "gmina" : "Białopole",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Chełm",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Dorohusk",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Dubienka",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Kamień",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Leśniowice",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Rejowiec",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Rejowiec Fabryczny",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Ruda-Huta",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Sawin",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Siedliszcze",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Wierzbica",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Wojsławice",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      }, {
        "gmina" : "Żmudź",
        "us" : [ "Urząd Skarbowy w Chełmie" ]
      } ]
    }, {
      "powiat" : "hrubieszowski",
      "gminy" : [ {
        "gmina" : "Dołhobyczów",
        "us" : [ "Urząd Skarbowy w Hrubieszowie" ]
      }, {
        "gmina" : "Horodło",
        "us" : [ "Urząd Skarbowy w Hrubieszowie" ]
      }, {
        "gmina" : "Hrubieszów",
        "us" : [ "Urząd Skarbowy w Hrubieszowie" ]
      }, {
        "gmina" : "Mircze",
        "us" : [ "Urząd Skarbowy w Hrubieszowie" ]
      }, {
        "gmina" : "Trzeszczany",
        "us" : [ "Urząd Skarbowy w Hrubieszowie" ]
      }, {
        "gmina" : "Uchanie",
        "us" : [ "Urząd Skarbowy w Hrubieszowie" ]
      }, {
        "gmina" : "Werbkowice",
        "us" : [ "Urząd Skarbowy w Hrubieszowie" ]
      } ]
    }, {
      "powiat" : "janowski",
      "gminy" : [ {
        "gmina" : "Batorz",
        "us" : [ "Urząd Skarbowy w Janowie Lubelskim" ]
      }, {
        "gmina" : "Chrzanów",
        "us" : [ "Urząd Skarbowy w Janowie Lubelskim" ]
      }, {
        "gmina" : "Dzwola",
        "us" : [ "Urząd Skarbowy w Janowie Lubelskim" ]
      }, {
        "gmina" : "Godziszów",
        "us" : [ "Urząd Skarbowy w Janowie Lubelskim" ]
      }, {
        "gmina" : "Janów Lubelski",
        "us" : [ "Urząd Skarbowy w Janowie Lubelskim" ]
      }, {
        "gmina" : "Modliborzyce",
        "us" : [ "Urząd Skarbowy w Janowie Lubelskim" ]
      }, {
        "gmina" : "Potok Wielki",
        "us" : [ "Urząd Skarbowy w Janowie Lubelskim" ]
      } ]
    }, {
      "powiat" : "krasnostawski",
      "gminy" : [ {
        "gmina" : "Fajsławice",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      }, {
        "gmina" : "Gorzków",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      }, {
        "gmina" : "Izbica",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      }, {
        "gmina" : "Krasnystaw",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      }, {
        "gmina" : "Kraśniczyn",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      }, {
        "gmina" : "Rudnik",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      }, {
        "gmina" : "Siennica Różana",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      }, {
        "gmina" : "Łopiennik Górny",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      }, {
        "gmina" : "Żółkiewka",
        "us" : [ "Urząd Skarbowy w Krasnymstawie" ]
      } ]
    }, {
      "powiat" : "kraśnicki",
      "gminy" : [ {
        "gmina" : "Annopol",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      }, {
        "gmina" : "Dzierzkowice",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      }, {
        "gmina" : "Gościeradów",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      }, {
        "gmina" : "Kraśnik",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      }, {
        "gmina" : "Szastarka",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      }, {
        "gmina" : "Trzydnik Duży",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      }, {
        "gmina" : "Urzędów",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      }, {
        "gmina" : "Wilkołaz",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      }, {
        "gmina" : "Zakrzówek",
        "us" : [ "Urząd Skarbowy w Kraśniku" ]
      } ]
    }, {
      "powiat" : "lubartowski",
      "gminy" : [ {
        "gmina" : "Abramów",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Firlej",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Jeziorzany",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Kamionka",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Kock",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Lubartów",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Michów",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Niedźwiada",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Ostrów Lubelski",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Ostrówek",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Serniki",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      }, {
        "gmina" : "Uścimów",
        "us" : [ "Urząd Skarbowy w Lubartowie" ]
      } ]
    }, {
      "powiat" : "lubelski",
      "gminy" : [ {
        "gmina" : "Bełżyce",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Borzechów",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Bychawa",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Garbów",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Głusk",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Jabłonna",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Jastków",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Konopnica",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Krzczonów",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Niedrzwica Duża",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Niemce",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Strzyżewice",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Wojciechów",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Wysokie",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Wólka",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Zakrzew",
        "us" : [ "Drugi Urząd Skarbowy w Lublinie" ]
      } ]
    }, {
      "powiat" : "opolski",
      "gminy" : [ {
        "gmina" : "Chodel",
        "us" : [ "Urząd Skarbowy w Opolu Lubelskim" ]
      }, {
        "gmina" : "Józefów nad Wisłą",
        "us" : [ "Urząd Skarbowy w Opolu Lubelskim" ]
      }, {
        "gmina" : "Karczmiska",
        "us" : [ "Urząd Skarbowy w Opolu Lubelskim" ]
      }, {
        "gmina" : "Opole Lubelskie",
        "us" : [ "Urząd Skarbowy w Opolu Lubelskim" ]
      }, {
        "gmina" : "Poniatowa",
        "us" : [ "Urząd Skarbowy w Opolu Lubelskim" ]
      }, {
        "gmina" : "Wilków",
        "us" : [ "Urząd Skarbowy w Opolu Lubelskim" ]
      }, {
        "gmina" : "Łaziska",
        "us" : [ "Urząd Skarbowy w Opolu Lubelskim" ]
      } ]
    }, {
      "powiat" : "parczewski",
      "gminy" : [ {
        "gmina" : "Dębowa Kłoda",
        "us" : [ "Urząd Skarbowy w Parczewie" ]
      }, {
        "gmina" : "Jabłoń",
        "us" : [ "Urząd Skarbowy w Parczewie" ]
      }, {
        "gmina" : "Milanów",
        "us" : [ "Urząd Skarbowy w Parczewie" ]
      }, {
        "gmina" : "Parczew",
        "us" : [ "Urząd Skarbowy w Parczewie" ]
      }, {
        "gmina" : "Podedwórze",
        "us" : [ "Urząd Skarbowy w Parczewie" ]
      }, {
        "gmina" : "Siemień",
        "us" : [ "Urząd Skarbowy w Parczewie" ]
      }, {
        "gmina" : "Sosnowica",
        "us" : [ "Urząd Skarbowy w Parczewie" ]
      } ]
    }, {
      "powiat" : "puławski",
      "gminy" : [ {
        "gmina" : "Baranów",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Janowiec",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Kazimierz Dolny",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Końskowola",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Kurów",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Markuszów",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Nałęczów",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Puławy",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Wąwolnica",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      }, {
        "gmina" : "Żyrzyn",
        "us" : [ "Urząd Skarbowy w Puławach" ]
      } ]
    }, {
      "powiat" : "radzyński",
      "gminy" : [ {
        "gmina" : "Borki",
        "us" : [ "Urząd Skarbowy w Radzyniu Podlaskim" ]
      }, {
        "gmina" : "Czemierniki",
        "us" : [ "Urząd Skarbowy w Radzyniu Podlaskim" ]
      }, {
        "gmina" : "Komarówka Podlaska",
        "us" : [ "Urząd Skarbowy w Radzyniu Podlaskim" ]
      }, {
        "gmina" : "Kąkolewnica",
        "us" : [ "Urząd Skarbowy w Radzyniu Podlaskim" ]
      }, {
        "gmina" : "Radzyń Podlaski",
        "us" : [ "Urząd Skarbowy w Radzyniu Podlaskim" ]
      }, {
        "gmina" : "Ulan-Majorat",
        "us" : [ "Urząd Skarbowy w Radzyniu Podlaskim" ]
      }, {
        "gmina" : "Wohyń",
        "us" : [ "Urząd Skarbowy w Radzyniu Podlaskim" ]
      } ]
    }, {
      "powiat" : "rycki",
      "gminy" : [ {
        "gmina" : "Dęblin",
        "us" : [ "Urząd Skarbowy w Rykach" ]
      }, {
        "gmina" : "Kłoczew",
        "us" : [ "Urząd Skarbowy w Rykach" ]
      }, {
        "gmina" : "Nowodwór",
        "us" : [ "Urząd Skarbowy w Rykach" ]
      }, {
        "gmina" : "Ryki",
        "us" : [ "Urząd Skarbowy w Rykach" ]
      }, {
        "gmina" : "Stężyca",
        "us" : [ "Urząd Skarbowy w Rykach" ]
      }, {
        "gmina" : "Ułęż",
        "us" : [ "Urząd Skarbowy w Rykach" ]
      } ]
    }, {
      "powiat" : "tomaszowski",
      "gminy" : [ {
        "gmina" : "Bełżec",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Jarczów",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Krynice",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Lubycza Królewska",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Rachanie",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Susiec",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Tarnawatka",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Telatyn",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Tomaszów Lubelski",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Tyszowce",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Ulhówek",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      }, {
        "gmina" : "Łaszczów",
        "us" : [ "Urząd Skarbowy w Tomaszowie Lubelskim" ]
      } ]
    }, {
      "powiat" : "włodawski",
      "gminy" : [ {
        "gmina" : "Hanna",
        "us" : [ "Urząd Skarbowy we Włodawie" ]
      }, {
        "gmina" : "Hańsk",
        "us" : [ "Urząd Skarbowy we Włodawie" ]
      }, {
        "gmina" : "Stary Brus",
        "us" : [ "Urząd Skarbowy we Włodawie" ]
      }, {
        "gmina" : "Urszulin",
        "us" : [ "Urząd Skarbowy we Włodawie" ]
      }, {
        "gmina" : "Wola Uhruska",
        "us" : [ "Urząd Skarbowy we Włodawie" ]
      }, {
        "gmina" : "Wyryki",
        "us" : [ "Urząd Skarbowy we Włodawie" ]
      }, {
        "gmina" : "Włodawa",
        "us" : [ "Urząd Skarbowy we Włodawie" ]
      } ]
    }, {
      "powiat" : "zamojski",
      "gminy" : [ {
        "gmina" : "Adamów",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Grabowiec",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Komarów-Osada",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Krasnobród",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Miączyn",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Nielisz",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Radecznica",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Sitno",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Skierbieszów",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Stary Zamość",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Sułów",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Szczebrzeszyn",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Zamość",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Zwierzyniec",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      }, {
        "gmina" : "Łabunie",
        "us" : [ "Urząd Skarbowy w Zamościu" ]
      } ]
    }, {
      "powiat" : "łukowski",
      "gminy" : [ {
        "gmina" : "Adamów",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      }, {
        "gmina" : "Krzywda",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      }, {
        "gmina" : "Serokomla",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      }, {
        "gmina" : "Stanin",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      }, {
        "gmina" : "Stoczek Łukowski",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      }, {
        "gmina" : "Trzebieszów",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      }, {
        "gmina" : "Wojcieszków",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      }, {
        "gmina" : "Wola Mysłowska",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      }, {
        "gmina" : "Łuków",
        "us" : [ "Urząd Skarbowy w Łukowie" ]
      } ]
    }, {
      "powiat" : "łęczyński",
      "gminy" : [ {
        "gmina" : "Cyców",
        "us" : [ "Urząd Skarbowy w Łęcznej" ]
      }, {
        "gmina" : "Ludwin",
        "us" : [ "Urząd Skarbowy w Łęcznej" ]
      }, {
        "gmina" : "Milejów",
        "us" : [ "Urząd Skarbowy w Łęcznej" ]
      }, {
        "gmina" : "Puchaczów",
        "us" : [ "Urząd Skarbowy w Łęcznej" ]
      }, {
        "gmina" : "Spiczyn",
        "us" : [ "Urząd Skarbowy w Łęcznej" ]
      }, {
        "gmina" : "Łęczna",
        "us" : [ "Urząd Skarbowy w Łęcznej" ]
      } ]
    }, {
      "powiat" : "świdnicki",
      "gminy" : [ {
        "gmina" : "Mełgiew",
        "us" : [ "Pierwszy Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Piaski",
        "us" : [ "Pierwszy Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Rybczewice",
        "us" : [ "Pierwszy Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Trawniki",
        "us" : [ "Pierwszy Urząd Skarbowy w Lublinie" ]
      }, {
        "gmina" : "Świdnik",
        "us" : [ "Pierwszy Urząd Skarbowy w Lublinie" ]
      } ]
    } ]
  }, {
    "woj" : "lubuskie",
    "powiaty" : [ {
      "powiat" : "Gorzów Wielkopolski",
      "gminy" : [ {
        "gmina" : "Gorzów Wielkopolski",
        "us" : [ "Urząd Skarbowy w Gorzowie Wielkopolskim" ]
      } ]
    }, {
      "powiat" : "Zielona Góra",
      "gminy" : [ {
        "gmina" : "Zielona Góra",
        "us" : [ "Pierwszy Urząd Skarbowy w Zielonej Górze", "Drugi Urząd Skarbowy w Zielonej Górze" ]
      } ]
    }, {
      "powiat" : "gorzowski",
      "gminy" : [ {
        "gmina" : "Bogdaniec",
        "us" : [ "Urząd Skarbowy w Gorzowie Wielkopolskim" ]
      }, {
        "gmina" : "Deszczno",
        "us" : [ "Urząd Skarbowy w Gorzowie Wielkopolskim" ]
      }, {
        "gmina" : "Kostrzyn nad Odrą",
        "us" : [ "Urząd Skarbowy w Gorzowie Wielkopolskim" ]
      }, {
        "gmina" : "Kłodawa",
        "us" : [ "Urząd Skarbowy w Gorzowie Wielkopolskim" ]
      }, {
        "gmina" : "Lubiszyn",
        "us" : [ "Urząd Skarbowy w Gorzowie Wielkopolskim" ]
      }, {
        "gmina" : "Santok",
        "us" : [ "Urząd Skarbowy w Gorzowie Wielkopolskim" ]
      }, {
        "gmina" : "Witnica",
        "us" : [ "Urząd Skarbowy w Gorzowie Wielkopolskim" ]
      } ]
    }, {
      "powiat" : "krośnieński",
      "gminy" : [ {
        "gmina" : "Bobrowice",
        "us" : [ "Urząd Skarbowy w Krośnie Odrzańskim" ]
      }, {
        "gmina" : "Bytnica",
        "us" : [ "Urząd Skarbowy w Krośnie Odrzańskim" ]
      }, {
        "gmina" : "Dąbie",
        "us" : [ "Urząd Skarbowy w Krośnie Odrzańskim" ]
      }, {
        "gmina" : "Gubin",
        "us" : [ "Urząd Skarbowy w Krośnie Odrzańskim" ]
      }, {
        "gmina" : "Krosno Odrzańskie",
        "us" : [ "Urząd Skarbowy w Krośnie Odrzańskim" ]
      }, {
        "gmina" : "Maszewo",
        "us" : [ "Urząd Skarbowy w Krośnie Odrzańskim" ]
      } ]
    }, {
      "powiat" : "międzyrzecki",
      "gminy" : [ {
        "gmina" : "Bledzew",
        "us" : [ "Urząd Skarbowy w Międzyrzeczu" ]
      }, {
        "gmina" : "Międzyrzecz",
        "us" : [ "Urząd Skarbowy w Międzyrzeczu" ]
      }, {
        "gmina" : "Przytoczna",
        "us" : [ "Urząd Skarbowy w Międzyrzeczu" ]
      }, {
        "gmina" : "Pszczew",
        "us" : [ "Urząd Skarbowy w Międzyrzeczu" ]
      }, {
        "gmina" : "Skwierzyna",
        "us" : [ "Urząd Skarbowy w Międzyrzeczu" ]
      }, {
        "gmina" : "Trzciel",
        "us" : [ "Urząd Skarbowy w Międzyrzeczu" ]
      } ]
    }, {
      "powiat" : "nowosolski",
      "gminy" : [ {
        "gmina" : "Bytom Odrzański",
        "us" : [ "Urząd Skarbowy w Nowej Soli" ]
      }, {
        "gmina" : "Kolsko",
        "us" : [ "Urząd Skarbowy w Nowej Soli" ]
      }, {
        "gmina" : "Kożuchów",
        "us" : [ "Urząd Skarbowy w Nowej Soli" ]
      }, {
        "gmina" : "Nowa Sól",
        "us" : [ "Urząd Skarbowy w Nowej Soli" ]
      }, {
        "gmina" : "Nowe Miasteczko",
        "us" : [ "Urząd Skarbowy w Nowej Soli" ]
      }, {
        "gmina" : "Otyń",
        "us" : [ "Urząd Skarbowy w Nowej Soli" ]
      }, {
        "gmina" : "Siedlisko",
        "us" : [ "Urząd Skarbowy w Nowej Soli" ]
      } ]
    }, {
      "powiat" : "strzelecko-drezdenecki",
      "gminy" : [ {
        "gmina" : "Dobiegniew",
        "us" : [ "Urząd Skarbowy w Drezdenku" ]
      }, {
        "gmina" : "Drezdenko",
        "us" : [ "Urząd Skarbowy w Drezdenku" ]
      }, {
        "gmina" : "Stare Kurowo",
        "us" : [ "Urząd Skarbowy w Drezdenku" ]
      }, {
        "gmina" : "Strzelce Krajeńskie",
        "us" : [ "Urząd Skarbowy w Drezdenku" ]
      }, {
        "gmina" : "Zwierzyn",
        "us" : [ "Urząd Skarbowy w Drezdenku" ]
      } ]
    }, {
      "powiat" : "sulęciński",
      "gminy" : [ {
        "gmina" : "Krzeszyce",
        "us" : [ "Urząd Skarbowy w Sulęcinie" ]
      }, {
        "gmina" : "Lubniewice",
        "us" : [ "Urząd Skarbowy w Sulęcinie" ]
      }, {
        "gmina" : "Sulęcin",
        "us" : [ "Urząd Skarbowy w Sulęcinie" ]
      }, {
        "gmina" : "Słońsk",
        "us" : [ "Urząd Skarbowy w Sulęcinie" ]
      }, {
        "gmina" : "Torzym",
        "us" : [ "Urząd Skarbowy w Sulęcinie" ]
      } ]
    }, {
      "powiat" : "słubicki",
      "gminy" : [ {
        "gmina" : "Cybinka",
        "us" : [ "Urząd Skarbowy w Słubicach" ]
      }, {
        "gmina" : "Górzyca",
        "us" : [ "Urząd Skarbowy w Słubicach" ]
      }, {
        "gmina" : "Ośno Lubuskie",
        "us" : [ "Urząd Skarbowy w Słubicach" ]
      }, {
        "gmina" : "Rzepin",
        "us" : [ "Urząd Skarbowy w Słubicach" ]
      }, {
        "gmina" : "Słubice",
        "us" : [ "Urząd Skarbowy w Słubicach" ]
      } ]
    }, {
      "powiat" : "wschowski",
      "gminy" : [ {
        "gmina" : "Szlichtyngowa",
        "us" : [ "Urząd Skarbowy we Wschowie" ]
      }, {
        "gmina" : "Sława",
        "us" : [ "Urząd Skarbowy we Wschowie" ]
      }, {
        "gmina" : "Wschowa",
        "us" : [ "Urząd Skarbowy we Wschowie" ]
      } ]
    }, {
      "powiat" : "zielonogórski",
      "gminy" : [ {
        "gmina" : "Babimost",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      }, {
        "gmina" : "Bojadła",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      }, {
        "gmina" : "Czerwieńsk",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      }, {
        "gmina" : "Kargowa",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      }, {
        "gmina" : "Nowogród Bobrzański",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      }, {
        "gmina" : "Sulechów",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      }, {
        "gmina" : "Trzebiechów",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      }, {
        "gmina" : "Zabór",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      }, {
        "gmina" : "Świdnica",
        "us" : [ "Drugi Urząd Skarbowy w Zielonej Górze" ]
      } ]
    }, {
      "powiat" : "świebodziński",
      "gminy" : [ {
        "gmina" : "Lubrza",
        "us" : [ "Urząd Skarbowy w Świebodzinie" ]
      }, {
        "gmina" : "Skąpe",
        "us" : [ "Urząd Skarbowy w Świebodzinie" ]
      }, {
        "gmina" : "Szczaniec",
        "us" : [ "Urząd Skarbowy w Świebodzinie" ]
      }, {
        "gmina" : "Zbąszynek",
        "us" : [ "Urząd Skarbowy w Świebodzinie" ]
      }, {
        "gmina" : "Łagów",
        "us" : [ "Urząd Skarbowy w Świebodzinie" ]
      }, {
        "gmina" : "Świebodzin",
        "us" : [ "Urząd Skarbowy w Świebodzinie" ]
      } ]
    }, {
      "powiat" : "żagański",
      "gminy" : [ {
        "gmina" : "Brzeźnica",
        "us" : [ "Urząd Skarbowy w Żaganiu" ]
      }, {
        "gmina" : "Gozdnica",
        "us" : [ "Urząd Skarbowy w Żaganiu" ]
      }, {
        "gmina" : "Iłowa",
        "us" : [ "Urząd Skarbowy w Żaganiu" ]
      }, {
        "gmina" : "Małomice",
        "us" : [ "Urząd Skarbowy w Żaganiu" ]
      }, {
        "gmina" : "Niegosławice",
        "us" : [ "Urząd Skarbowy w Żaganiu" ]
      }, {
        "gmina" : "Szprotawa",
        "us" : [ "Urząd Skarbowy w Żaganiu" ]
      }, {
        "gmina" : "Wymiarki",
        "us" : [ "Urząd Skarbowy w Żaganiu" ]
      }, {
        "gmina" : "Żagań",
        "us" : [ "Urząd Skarbowy w Żaganiu" ]
      } ]
    }, {
      "powiat" : "żarski",
      "gminy" : [ {
        "gmina" : "Brody",
        "us" : [ "Urząd Skarbowy w Żary" ]
      }, {
        "gmina" : "Jasień",
        "us" : [ "Urząd Skarbowy w Żary" ]
      }, {
        "gmina" : "Lipinki Łużyckie",
        "us" : [ "Urząd Skarbowy w Żary" ]
      }, {
        "gmina" : "Lubsko",
        "us" : [ "Urząd Skarbowy w Żary" ]
      }, {
        "gmina" : "Przewóz",
        "us" : [ "Urząd Skarbowy w Żary" ]
      }, {
        "gmina" : "Trzebiel",
        "us" : [ "Urząd Skarbowy w Żary" ]
      }, {
        "gmina" : "Tuplice",
        "us" : [ "Urząd Skarbowy w Żary" ]
      }, {
        "gmina" : "Łęknica",
        "us" : [ "Urząd Skarbowy w Żary" ]
      }, {
        "gmina" : "Żary",
        "us" : [ "Urząd Skarbowy w Żary" ]
      } ]
    } ]
  }, {
    "woj" : "mazowieckie",
    "powiaty" : [ {
      "powiat" : "Ostrołęka",
      "gminy" : [ {
        "gmina" : "Ostrołęka",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      } ]
    }, {
      "powiat" : "Płock",
      "gminy" : [ {
        "gmina" : "Płock",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      } ]
    }, {
      "powiat" : "Radom",
      "gminy" : [ {
        "gmina" : "Radom",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu", "Drugi Urząd Skarbowy w Radomiu" ]
      } ]
    }, {
      "powiat" : "Siedlce",
      "gminy" : [ {
        "gmina" : "Siedlce",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      } ]
    }, {
      "powiat" : "Warszawa",
      "gminy" : [ {
        "gmina" : "Warszawa",
        "us" : [ "Pierwszy Urząd Skarbowy Warszawa-Śródmieście", "Drugi Urząd Skarbowy Warszawa-Śródmieście", "Trzeci Urząd Skarbowy Warszawa-Śródmieście", "Urząd Skarbowy Warszawa-Bemowo", "Urząd Skarbowy Warszawa-Bielany", "Urząd Skarbowy Warszawa-Mokotów", "Urząd Skarbowy Warszawa-Praga", "Urząd Skarbowy Warszawa-Targówek", "Urząd Skarbowy Warszawa-Ursynów", "Urząd Skarbowy Warszawa-Wawer", "Urząd Skarbowy Warszawa-Wola" ]
      } ]
    }, {
      "powiat" : "białobrzeski",
      "gminy" : [ {
        "gmina" : "Białobrzegi",
        "us" : [ "Urząd Skarbowy w Białobrzegach" ]
      }, {
        "gmina" : "Promna",
        "us" : [ "Urząd Skarbowy w Białobrzegach" ]
      }, {
        "gmina" : "Radzanów",
        "us" : [ "Urząd Skarbowy w Białobrzegach" ]
      }, {
        "gmina" : "Stara Błotnica",
        "us" : [ "Urząd Skarbowy w Białobrzegach" ]
      }, {
        "gmina" : "Stromiec",
        "us" : [ "Urząd Skarbowy w Białobrzegach" ]
      }, {
        "gmina" : "Wyśmierzyce",
        "us" : [ "Urząd Skarbowy w Białobrzegach" ]
      } ]
    }, {
      "powiat" : "ciechanowski",
      "gminy" : [ {
        "gmina" : "Ciechanów",
        "us" : [ "Urząd Skarbowy w Ciechanowie" ]
      }, {
        "gmina" : "Glinojeck",
        "us" : [ "Urząd Skarbowy w Ciechanowie" ]
      }, {
        "gmina" : "Gołymin-Ośrodek",
        "us" : [ "Urząd Skarbowy w Ciechanowie" ]
      }, {
        "gmina" : "Grudusk",
        "us" : [ "Urząd Skarbowy w Ciechanowie" ]
      }, {
        "gmina" : "Ojrzeń",
        "us" : [ "Urząd Skarbowy w Ciechanowie" ]
      }, {
        "gmina" : "Opinogóra Górna",
        "us" : [ "Urząd Skarbowy w Ciechanowie" ]
      }, {
        "gmina" : "Regimin",
        "us" : [ "Urząd Skarbowy w Ciechanowie" ]
      }, {
        "gmina" : "Sońsk",
        "us" : [ "Urząd Skarbowy w Ciechanowie" ]
      } ]
    }, {
      "powiat" : "garwoliński",
      "gminy" : [ {
        "gmina" : "Borowie",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Garwolin",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Gostynin",
        "us" : [ "Urząd Skarbowy w Gostyninie" ]
      }, {
        "gmina" : "Górzno",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Maciejowice",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Miastków Kościelny",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Pacyna",
        "us" : [ "Urząd Skarbowy w Gostyninie" ]
      }, {
        "gmina" : "Parysów",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Pilawa",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Sanniki",
        "us" : [ "Urząd Skarbowy w Gostyninie" ]
      }, {
        "gmina" : "Sobolew",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Szczawin Kościelny",
        "us" : [ "Urząd Skarbowy w Gostyninie" ]
      }, {
        "gmina" : "Trojanów",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Wilga",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Łaskarzew",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      }, {
        "gmina" : "Żelechów",
        "us" : [ "Urząd Skarbowy w Garwolinie" ]
      } ]
    }, {
      "powiat" : "grodziski",
      "gminy" : [ {
        "gmina" : "Baranów",
        "us" : [ "Urząd Skarbowy w Grodzisku Mazowieckim" ]
      }, {
        "gmina" : "Grodzisk Mazowiecki",
        "us" : [ "Urząd Skarbowy w Grodzisku Mazowieckim" ]
      }, {
        "gmina" : "Jaktorów",
        "us" : [ "Urząd Skarbowy w Grodzisku Mazowieckim" ]
      }, {
        "gmina" : "Milanówek",
        "us" : [ "Urząd Skarbowy w Grodzisku Mazowieckim" ]
      }, {
        "gmina" : "Podkowa Leśna",
        "us" : [ "Urząd Skarbowy w Grodzisku Mazowieckim" ]
      }, {
        "gmina" : "Żabia Wola",
        "us" : [ "Urząd Skarbowy w Grodzisku Mazowieckim" ]
      } ]
    }, {
      "powiat" : "grójecki",
      "gminy" : [ {
        "gmina" : "Belsk Duży",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Błędów",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Chynów",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Goszczyn",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Grójec",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Jasieniec",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Mogielnica",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Nowe Miasto nad Pilicą",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Pniewy",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      }, {
        "gmina" : "Warka",
        "us" : [ "Urząd Skarbowy w Grójcu" ]
      } ]
    }, {
      "powiat" : "kozienicki",
      "gminy" : [ {
        "gmina" : "Garbatka-Letnisko",
        "us" : [ "Urząd Skarbowy w Kozienicach" ]
      }, {
        "gmina" : "Gniewoszów",
        "us" : [ "Urząd Skarbowy w Kozienicach" ]
      }, {
        "gmina" : "Grabów nad Pilicą",
        "us" : [ "Urząd Skarbowy w Kozienicach" ]
      }, {
        "gmina" : "Głowaczów",
        "us" : [ "Urząd Skarbowy w Kozienicach" ]
      }, {
        "gmina" : "Jabłonna",
        "us" : [ "Urząd Skarbowy w Legionowie" ]
      }, {
        "gmina" : "Kozienice",
        "us" : [ "Urząd Skarbowy w Kozienicach" ]
      }, {
        "gmina" : "Legionowo",
        "us" : [ "Urząd Skarbowy w Legionowie" ]
      }, {
        "gmina" : "Magnuszew",
        "us" : [ "Urząd Skarbowy w Kozienicach" ]
      }, {
        "gmina" : "Nieporęt",
        "us" : [ "Urząd Skarbowy w Legionowie" ]
      }, {
        "gmina" : "Serock",
        "us" : [ "Urząd Skarbowy w Legionowie" ]
      }, {
        "gmina" : "Sieciechów",
        "us" : [ "Urząd Skarbowy w Kozienicach" ]
      }, {
        "gmina" : "Wieliszew",
        "us" : [ "Urząd Skarbowy w Legionowie" ]
      } ]
    }, {
      "powiat" : "lipski",
      "gminy" : [ {
        "gmina" : "Chotcza",
        "us" : [ "Urząd Skarbowy w Lipsku" ]
      }, {
        "gmina" : "Ciepielów",
        "us" : [ "Urząd Skarbowy w Lipsku" ]
      }, {
        "gmina" : "Lipsko",
        "us" : [ "Urząd Skarbowy w Lipsku" ]
      }, {
        "gmina" : "Rzeczniów",
        "us" : [ "Urząd Skarbowy w Lipsku" ]
      }, {
        "gmina" : "Sienno",
        "us" : [ "Urząd Skarbowy w Lipsku" ]
      }, {
        "gmina" : "Solec nad Wisłą",
        "us" : [ "Urząd Skarbowy w Lipsku" ]
      } ]
    }, {
      "powiat" : "makowski",
      "gminy" : [ {
        "gmina" : "Czerwonka",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Karniewo",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Krasnosielc",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Maków Mazowiecki",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Młynarze",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Płoniawy-Bramura",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Rzewnie",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Różan",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Sypniewo",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      }, {
        "gmina" : "Szelków",
        "us" : [ "Urząd Skarbowy w Makowie Mazowieckim" ]
      } ]
    }, {
      "powiat" : "miński",
      "gminy" : [ {
        "gmina" : "Cegłów",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Dobre",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Dębe Wielkie",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Halinów",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Jakubów",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Kałuszyn",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Latowicz",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Mińsk Mazowiecki",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Mrozy",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Siennica",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Stanisławów",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      }, {
        "gmina" : "Sulejówek",
        "us" : [ "Urząd Skarbowy w Mińsku Mazowieckim" ]
      } ]
    }, {
      "powiat" : "mławski",
      "gminy" : [ {
        "gmina" : "Dzierzgowo",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Lipowiec Kościelny",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Mława",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Radzanów",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Strzegowo",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Stupsk",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Szreńsk",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Szydłowo",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Wieczfnia Kościelna",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      }, {
        "gmina" : "Wiśniewo",
        "us" : [ "Urząd Skarbowy w Mławie" ]
      } ]
    }, {
      "powiat" : "nowodworski",
      "gminy" : [ {
        "gmina" : "Czosnów",
        "us" : [ "Urząd Skarbowy w Nowym Dworze Mazowieckim" ]
      }, {
        "gmina" : "Leoncin",
        "us" : [ "Urząd Skarbowy w Nowym Dworze Mazowieckim" ]
      }, {
        "gmina" : "Nasielsk",
        "us" : [ "Urząd Skarbowy w Nowym Dworze Mazowieckim" ]
      }, {
        "gmina" : "Nowy Dwór Mazowiecki",
        "us" : [ "Urząd Skarbowy w Nowym Dworze Mazowieckim" ]
      }, {
        "gmina" : "Pomiechówek",
        "us" : [ "Urząd Skarbowy w Nowym Dworze Mazowieckim" ]
      }, {
        "gmina" : "Zakroczym",
        "us" : [ "Urząd Skarbowy w Nowym Dworze Mazowieckim" ]
      } ]
    }, {
      "powiat" : "ostrowski",
      "gminy" : [ {
        "gmina" : "Andrzejewo",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Boguty-Pianki",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Brok",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Małkinia Górna",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Nur",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Ostrów Mazowiecka",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Stary Lubotyń",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Szulborze Wielkie",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Wąsewo",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      }, {
        "gmina" : "Zaręby Kościelne",
        "us" : [ "Urząd Skarbowy w Ostrowi Mazowieckiej" ]
      } ]
    }, {
      "powiat" : "ostrołęcki",
      "gminy" : [ {
        "gmina" : "Baranowo",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Czarnia",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Czerwin",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Goworowo",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Kadzidło",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Lelis",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Myszyniec",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Olszewo-Borki",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Rzekuń",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Troszyn",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      }, {
        "gmina" : "Łyse",
        "us" : [ "Urząd Skarbowy w Ostrołęce" ]
      } ]
    }, {
      "powiat" : "otwocki",
      "gminy" : [ {
        "gmina" : "Celestynów",
        "us" : [ "Urząd Skarbowy w Otwocku" ]
      }, {
        "gmina" : "Józefów",
        "us" : [ "Urząd Skarbowy w Otwocku" ]
      }, {
        "gmina" : "Karczew",
        "us" : [ "Urząd Skarbowy w Otwocku" ]
      }, {
        "gmina" : "Kołbiel",
        "us" : [ "Urząd Skarbowy w Otwocku" ]
      }, {
        "gmina" : "Osieck",
        "us" : [ "Urząd Skarbowy w Otwocku" ]
      }, {
        "gmina" : "Otwock",
        "us" : [ "Urząd Skarbowy w Otwocku" ]
      }, {
        "gmina" : "Sobienie-Jeziory",
        "us" : [ "Urząd Skarbowy w Otwocku" ]
      }, {
        "gmina" : "Wiązowna",
        "us" : [ "Urząd Skarbowy w Otwocku" ]
      } ]
    }, {
      "powiat" : "piaseczyński",
      "gminy" : [ {
        "gmina" : "Góra Kalwaria",
        "us" : [ "Urząd Skarbowy w Piasecznie" ]
      }, {
        "gmina" : "Konstancin-Jeziorna",
        "us" : [ "Urząd Skarbowy w Piasecznie" ]
      }, {
        "gmina" : "Lesznowola",
        "us" : [ "Urząd Skarbowy w Piasecznie" ]
      }, {
        "gmina" : "Piaseczno",
        "us" : [ "Urząd Skarbowy w Piasecznie" ]
      }, {
        "gmina" : "Prażmów",
        "us" : [ "Urząd Skarbowy w Piasecznie" ]
      }, {
        "gmina" : "Tarczyn",
        "us" : [ "Urząd Skarbowy w Piasecznie" ]
      } ]
    }, {
      "powiat" : "pruszkowski",
      "gminy" : [ {
        "gmina" : "Brwinów",
        "us" : [ "Urząd Skarbowy w Pruszkowie" ]
      }, {
        "gmina" : "Michałowice",
        "us" : [ "Urząd Skarbowy w Pruszkowie" ]
      }, {
        "gmina" : "Nadarzyn",
        "us" : [ "Urząd Skarbowy w Pruszkowie" ]
      }, {
        "gmina" : "Piastów",
        "us" : [ "Urząd Skarbowy w Pruszkowie" ]
      }, {
        "gmina" : "Pruszków",
        "us" : [ "Urząd Skarbowy w Pruszkowie" ]
      }, {
        "gmina" : "Raszyn",
        "us" : [ "Urząd Skarbowy w Pruszkowie" ]
      } ]
    }, {
      "powiat" : "przasnyski",
      "gminy" : [ {
        "gmina" : "Chorzele",
        "us" : [ "Urząd Skarbowy w Przasnyszu" ]
      }, {
        "gmina" : "Czernice Borowe",
        "us" : [ "Urząd Skarbowy w Przasnyszu" ]
      }, {
        "gmina" : "Jednorożec",
        "us" : [ "Urząd Skarbowy w Przasnyszu" ]
      }, {
        "gmina" : "Krasne",
        "us" : [ "Urząd Skarbowy w Przasnyszu" ]
      }, {
        "gmina" : "Krzynowłoga Mała",
        "us" : [ "Urząd Skarbowy w Przasnyszu" ]
      }, {
        "gmina" : "Przasnysz",
        "us" : [ "Urząd Skarbowy w Przasnyszu" ]
      } ]
    }, {
      "powiat" : "przysuski",
      "gminy" : [ {
        "gmina" : "Borkowice",
        "us" : [ "Urząd Skarbowy w Przysusze" ]
      }, {
        "gmina" : "Gielniów",
        "us" : [ "Urząd Skarbowy w Przysusze" ]
      }, {
        "gmina" : "Gzy",
        "us" : [ "Urząd Skarbowy w Pułtusku" ]
      }, {
        "gmina" : "Klwów",
        "us" : [ "Urząd Skarbowy w Przysusze" ]
      }, {
        "gmina" : "Obryte",
        "us" : [ "Urząd Skarbowy w Pułtusku" ]
      }, {
        "gmina" : "Odrzywół",
        "us" : [ "Urząd Skarbowy w Przysusze" ]
      }, {
        "gmina" : "Pokrzywnica",
        "us" : [ "Urząd Skarbowy w Pułtusku" ]
      }, {
        "gmina" : "Potworów",
        "us" : [ "Urząd Skarbowy w Przysusze" ]
      }, {
        "gmina" : "Przysucha",
        "us" : [ "Urząd Skarbowy w Przysusze" ]
      }, {
        "gmina" : "Pułtusk",
        "us" : [ "Urząd Skarbowy w Pułtusku" ]
      }, {
        "gmina" : "Rusinów",
        "us" : [ "Urząd Skarbowy w Przysusze" ]
      }, {
        "gmina" : "Wieniawa",
        "us" : [ "Urząd Skarbowy w Przysusze" ]
      }, {
        "gmina" : "Winnica",
        "us" : [ "Urząd Skarbowy w Pułtusku" ]
      }, {
        "gmina" : "Zatory",
        "us" : [ "Urząd Skarbowy w Pułtusku" ]
      }, {
        "gmina" : "Świercze",
        "us" : [ "Urząd Skarbowy w Pułtusku" ]
      } ]
    }, {
      "powiat" : "płocki",
      "gminy" : [ {
        "gmina" : "Bielsk",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Bodzanów",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Brudzeń Duży",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Bulkowo",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Drobin",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Gąbin",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Mała Wieś",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Nowy Duninów",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Radzanowo",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Stara Biała",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Staroźreby",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Słubice",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Słupno",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Wyszogród",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      }, {
        "gmina" : "Łąck",
        "us" : [ "Urząd Skarbowy w Płocku" ]
      } ]
    }, {
      "powiat" : "płoński",
      "gminy" : [ {
        "gmina" : "Baboszewo",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Czerwińsk nad Wisłą",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Dzierzążnia",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Joniec",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Naruszewo",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Nowe Miasto",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Płońsk",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Raciąż",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Sochocin",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      }, {
        "gmina" : "Załuski",
        "us" : [ "Urząd Skarbowy w Płońsku" ]
      } ]
    }, {
      "powiat" : "radomski",
      "gminy" : [ {
        "gmina" : "Gózd",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Iłża",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Jastrzębia",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Jedlińsk",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Jedlnia-Letnisko",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Kowala",
        "us" : [ "Drugi Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Pionki",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Przytyk",
        "us" : [ "Drugi Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Skaryszew",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Wierzbica",
        "us" : [ "Drugi Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Wolanów",
        "us" : [ "Drugi Urząd Skarbowy w Radomiu" ]
      }, {
        "gmina" : "Zakrzew",
        "us" : [ "Pierwszy Urząd Skarbowy w Radomiu" ]
      } ]
    }, {
      "powiat" : "siedlecki",
      "gminy" : [ {
        "gmina" : "Domanice",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Korczew",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Kotuń",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Mokobody",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Mordy",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Paprotnia",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Przesmyki",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Siedlce",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Skórzec",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Suchożebry",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Wiśniew",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Wodynie",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      }, {
        "gmina" : "Zbuczyn",
        "us" : [ "Urząd Skarbowy w Siedlcach" ]
      } ]
    }, {
      "powiat" : "sierpecki",
      "gminy" : [ {
        "gmina" : "Gozdowo",
        "us" : [ "Urząd Skarbowy w Sierpcu" ]
      }, {
        "gmina" : "Mochowo",
        "us" : [ "Urząd Skarbowy w Sierpcu" ]
      }, {
        "gmina" : "Rościszewo",
        "us" : [ "Urząd Skarbowy w Sierpcu" ]
      }, {
        "gmina" : "Sierpc",
        "us" : [ "Urząd Skarbowy w Sierpcu" ]
      }, {
        "gmina" : "Szczutowo",
        "us" : [ "Urząd Skarbowy w Sierpcu" ]
      }, {
        "gmina" : "Zawidz",
        "us" : [ "Urząd Skarbowy w Sierpcu" ]
      } ]
    }, {
      "powiat" : "sochaczewski",
      "gminy" : [ {
        "gmina" : "Brochów",
        "us" : [ "Urząd Skarbowy w Sochaczewie" ]
      }, {
        "gmina" : "Iłów",
        "us" : [ "Urząd Skarbowy w Sochaczewie" ]
      }, {
        "gmina" : "Młodzieszyn",
        "us" : [ "Urząd Skarbowy w Sochaczewie" ]
      }, {
        "gmina" : "Nowa Sucha",
        "us" : [ "Urząd Skarbowy w Sochaczewie" ]
      }, {
        "gmina" : "Rybno",
        "us" : [ "Urząd Skarbowy w Sochaczewie" ]
      }, {
        "gmina" : "Sochaczew",
        "us" : [ "Urząd Skarbowy w Sochaczewie" ]
      }, {
        "gmina" : "Teresin",
        "us" : [ "Urząd Skarbowy w Sochaczewie" ]
      } ]
    }, {
      "powiat" : "sokołowski",
      "gminy" : [ {
        "gmina" : "Bielany",
        "us" : [ "Urząd Skarbowy w Sokołowie Podlaskim" ]
      }, {
        "gmina" : "Ceranów",
        "us" : [ "Urząd Skarbowy w Sokołowie Podlaskim" ]
      }, {
        "gmina" : "Jabłonna Lacka",
        "us" : [ "Urząd Skarbowy w Sokołowie Podlaskim" ]
      }, {
        "gmina" : "Kosów Lacki",
        "us" : [ "Urząd Skarbowy w Sokołowie Podlaskim" ]
      }, {
        "gmina" : "Repki",
        "us" : [ "Urząd Skarbowy w Sokołowie Podlaskim" ]
      }, {
        "gmina" : "Sabnie",
        "us" : [ "Urząd Skarbowy w Sokołowie Podlaskim" ]
      }, {
        "gmina" : "Sokołów Podlaski",
        "us" : [ "Urząd Skarbowy w Sokołowie Podlaskim" ]
      }, {
        "gmina" : "Sterdyń",
        "us" : [ "Urząd Skarbowy w Sokołowie Podlaskim" ]
      } ]
    }, {
      "powiat" : "szydłowiecki",
      "gminy" : [ {
        "gmina" : "Chlewiska",
        "us" : [ "Urząd Skarbowy w Szydłowcu" ]
      }, {
        "gmina" : "Jastrząb",
        "us" : [ "Urząd Skarbowy w Szydłowcu" ]
      }, {
        "gmina" : "Mirów",
        "us" : [ "Urząd Skarbowy w Szydłowcu" ]
      }, {
        "gmina" : "Orońsko",
        "us" : [ "Urząd Skarbowy w Szydłowcu" ]
      }, {
        "gmina" : "Szydłowiec",
        "us" : [ "Urząd Skarbowy w Szydłowcu" ]
      } ]
    }, {
      "powiat" : "warszawski zachodni",
      "gminy" : [ {
        "gmina" : "Błonie",
        "us" : [ "Urząd Skarbowy Warszawa-Bemowo" ]
      }, {
        "gmina" : "Izabelin",
        "us" : [ "Urząd Skarbowy Warszawa-Bemowo" ]
      }, {
        "gmina" : "Kampinos",
        "us" : [ "Urząd Skarbowy Warszawa-Bemowo" ]
      }, {
        "gmina" : "Leszno",
        "us" : [ "Urząd Skarbowy Warszawa-Bemowo" ]
      }, {
        "gmina" : "Ożarów Mazowiecki",
        "us" : [ "Urząd Skarbowy Warszawa-Bemowo" ]
      }, {
        "gmina" : "Stare Babice",
        "us" : [ "Urząd Skarbowy Warszawa-Bemowo" ]
      }, {
        "gmina" : "Łomianki",
        "us" : [ "Urząd Skarbowy Warszawa-Bemowo" ]
      } ]
    }, {
      "powiat" : "wołomiński",
      "gminy" : [ {
        "gmina" : "Dąbrówka",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Jadów",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Klembów",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Kobyłka",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Marki",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Poświętne",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Radzymin",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Strachówka",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Tłuszcz",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Wołomin",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Zielonka",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      }, {
        "gmina" : "Ząbki",
        "us" : [ "Urząd Skarbowy w Wołominie" ]
      } ]
    }, {
      "powiat" : "wyszkowski",
      "gminy" : [ {
        "gmina" : "Brańszczyk",
        "us" : [ "Urząd Skarbowy w Wyszkowie" ]
      }, {
        "gmina" : "Długosiodło",
        "us" : [ "Urząd Skarbowy w Wyszkowie" ]
      }, {
        "gmina" : "Rząśnik",
        "us" : [ "Urząd Skarbowy w Wyszkowie" ]
      }, {
        "gmina" : "Somianka",
        "us" : [ "Urząd Skarbowy w Wyszkowie" ]
      }, {
        "gmina" : "Wyszków",
        "us" : [ "Urząd Skarbowy w Wyszkowie" ]
      }, {
        "gmina" : "Zabrodzie",
        "us" : [ "Urząd Skarbowy w Wyszkowie" ]
      } ]
    }, {
      "powiat" : "węgrowski",
      "gminy" : [ {
        "gmina" : "Grębków",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      }, {
        "gmina" : "Korytnica",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      }, {
        "gmina" : "Liw",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      }, {
        "gmina" : "Miedzna",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      }, {
        "gmina" : "Sadowne",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      }, {
        "gmina" : "Stoczek",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      }, {
        "gmina" : "Wierzbno",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      }, {
        "gmina" : "Węgrów",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      }, {
        "gmina" : "Łochów",
        "us" : [ "Urząd Skarbowy w Węgrowie" ]
      } ]
    }, {
      "powiat" : "zwoleński",
      "gminy" : [ {
        "gmina" : "Kazanów",
        "us" : [ "Urząd Skarbowy w Zwoleniu" ]
      }, {
        "gmina" : "Policzna",
        "us" : [ "Urząd Skarbowy w Zwoleniu" ]
      }, {
        "gmina" : "Przyłęk",
        "us" : [ "Urząd Skarbowy w Zwoleniu" ]
      }, {
        "gmina" : "Tczów",
        "us" : [ "Urząd Skarbowy w Zwoleniu" ]
      }, {
        "gmina" : "Zwoleń",
        "us" : [ "Urząd Skarbowy w Zwoleniu" ]
      } ]
    }, {
      "powiat" : "łosicki",
      "gminy" : [ {
        "gmina" : "Huszlew",
        "us" : [ "Urząd Skarbowy w Łosicach" ]
      }, {
        "gmina" : "Olszanka",
        "us" : [ "Urząd Skarbowy w Łosicach" ]
      }, {
        "gmina" : "Platerów",
        "us" : [ "Urząd Skarbowy w Łosicach" ]
      }, {
        "gmina" : "Sarnaki",
        "us" : [ "Urząd Skarbowy w Łosicach" ]
      }, {
        "gmina" : "Stara Kornica",
        "us" : [ "Urząd Skarbowy w Łosicach" ]
      }, {
        "gmina" : "Łosice",
        "us" : [ "Urząd Skarbowy w Łosicach" ]
      } ]
    }, {
      "powiat" : "żuromiński",
      "gminy" : [ {
        "gmina" : "Bieżuń",
        "us" : [ "Urząd Skarbowy w Żurominie" ]
      }, {
        "gmina" : "Kuczbork-Osada",
        "us" : [ "Urząd Skarbowy w Żurominie" ]
      }, {
        "gmina" : "Lubowidz",
        "us" : [ "Urząd Skarbowy w Żurominie" ]
      }, {
        "gmina" : "Lutocin",
        "us" : [ "Urząd Skarbowy w Żurominie" ]
      }, {
        "gmina" : "Siemiątkowo",
        "us" : [ "Urząd Skarbowy w Żurominie" ]
      }, {
        "gmina" : "Żuromin",
        "us" : [ "Urząd Skarbowy w Żurominie" ]
      } ]
    }, {
      "powiat" : "żyrardowski",
      "gminy" : [ {
        "gmina" : "Mszczonów",
        "us" : [ "Urząd Skarbowy w Żyrardowie" ]
      }, {
        "gmina" : "Puszcza Mariańska",
        "us" : [ "Urząd Skarbowy w Żyrardowie" ]
      }, {
        "gmina" : "Radziejowice",
        "us" : [ "Urząd Skarbowy w Żyrardowie" ]
      }, {
        "gmina" : "Wiskitki",
        "us" : [ "Urząd Skarbowy w Żyrardowie" ]
      }, {
        "gmina" : "Żyrardów",
        "us" : [ "Urząd Skarbowy w Żyrardowie" ]
      } ]
    } ]
  }, {
    "woj" : "małopolskie",
    "powiaty" : [ {
      "powiat" : "Kraków",
      "gminy" : [ {
        "gmina" : "Kraków",
        "us" : [ "Urząd Skarbowy Kraków-Krowodrza", "Urząd Skarbowy Kraków-Nowa Huta", "Urząd Skarbowy Kraków-Podgórze", "Urząd Skarbowy Kraków-Prądnik", "Urząd Skarbowy Kraków-Stare Miasto", "Urząd Skarbowy Kraków-Śródmieście" ]
      } ]
    }, {
      "powiat" : "Nowy Sącz",
      "gminy" : [ {
        "gmina" : "Nowy Sącz",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      } ]
    }, {
      "powiat" : "Tarnów",
      "gminy" : [ {
        "gmina" : "Tarnów",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      } ]
    }, {
      "powiat" : "bocheński",
      "gminy" : [ {
        "gmina" : "Bochnia",
        "us" : [ "Urząd Skarbowy w Bochni" ]
      }, {
        "gmina" : "Drwinia",
        "us" : [ "Urząd Skarbowy w Bochni" ]
      }, {
        "gmina" : "Lipnica Murowana",
        "us" : [ "Urząd Skarbowy w Bochni" ]
      }, {
        "gmina" : "Nowy Wiśnicz",
        "us" : [ "Urząd Skarbowy w Bochni" ]
      }, {
        "gmina" : "Rzezawa",
        "us" : [ "Urząd Skarbowy w Bochni" ]
      }, {
        "gmina" : "Trzciana",
        "us" : [ "Urząd Skarbowy w Bochni" ]
      }, {
        "gmina" : "Łapanów",
        "us" : [ "Urząd Skarbowy w Bochni" ]
      }, {
        "gmina" : "Żegocina",
        "us" : [ "Urząd Skarbowy w Bochni" ]
      } ]
    }, {
      "powiat" : "brzeski",
      "gminy" : [ {
        "gmina" : "Borzęcin",
        "us" : [ "Urząd Skarbowy w Brzesku" ]
      }, {
        "gmina" : "Brzesko",
        "us" : [ "Urząd Skarbowy w Brzesku" ]
      }, {
        "gmina" : "Czchów",
        "us" : [ "Urząd Skarbowy w Brzesku" ]
      }, {
        "gmina" : "Dębno",
        "us" : [ "Urząd Skarbowy w Brzesku" ]
      }, {
        "gmina" : "Gnojnik",
        "us" : [ "Urząd Skarbowy w Brzesku" ]
      }, {
        "gmina" : "Iwkowa",
        "us" : [ "Urząd Skarbowy w Brzesku" ]
      }, {
        "gmina" : "Szczurowa",
        "us" : [ "Urząd Skarbowy w Brzesku" ]
      } ]
    }, {
      "powiat" : "chrzanowski",
      "gminy" : [ {
        "gmina" : "Alwernia",
        "us" : [ "Urząd Skarbowy w Chrzanowie" ]
      }, {
        "gmina" : "Babice",
        "us" : [ "Urząd Skarbowy w Chrzanowie" ]
      }, {
        "gmina" : "Chrzanów",
        "us" : [ "Urząd Skarbowy w Chrzanowie" ]
      }, {
        "gmina" : "Libiąż",
        "us" : [ "Urząd Skarbowy w Chrzanowie" ]
      }, {
        "gmina" : "Trzebinia",
        "us" : [ "Urząd Skarbowy w Chrzanowie" ]
      } ]
    }, {
      "powiat" : "dąbrowski",
      "gminy" : [ {
        "gmina" : "Bolesław",
        "us" : [ "Urząd Skarbowy w Dąbrowie Tarnowskiej" ]
      }, {
        "gmina" : "Dąbrowa Tarnowska",
        "us" : [ "Urząd Skarbowy w Dąbrowie Tarnowskiej" ]
      }, {
        "gmina" : "Gręboszów",
        "us" : [ "Urząd Skarbowy w Dąbrowie Tarnowskiej" ]
      }, {
        "gmina" : "Mędrzechów",
        "us" : [ "Urząd Skarbowy w Dąbrowie Tarnowskiej" ]
      }, {
        "gmina" : "Olesno",
        "us" : [ "Urząd Skarbowy w Dąbrowie Tarnowskiej" ]
      }, {
        "gmina" : "Radgoszcz",
        "us" : [ "Urząd Skarbowy w Dąbrowie Tarnowskiej" ]
      }, {
        "gmina" : "Szczucin",
        "us" : [ "Urząd Skarbowy w Dąbrowie Tarnowskiej" ]
      } ]
    }, {
      "powiat" : "gorlicki",
      "gminy" : [ {
        "gmina" : "Biecz",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      }, {
        "gmina" : "Bobowa",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      }, {
        "gmina" : "Gorlice",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      }, {
        "gmina" : "Lipinki",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      }, {
        "gmina" : "Moszczenica",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      }, {
        "gmina" : "Ropa",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      }, {
        "gmina" : "Sękowa",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      }, {
        "gmina" : "Uście Gorlickie",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      }, {
        "gmina" : "Łużna",
        "us" : [ "Urząd Skarbowy w Gorlicach" ]
      } ]
    }, {
      "powiat" : "krakowski",
      "gminy" : [ {
        "gmina" : "Czernichów",
        "us" : [ "Pierwszy Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Igołomia-Wawrzeńczyce",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Iwanowice",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Jerzmanowice-Przeginia",
        "us" : [ "Drugi Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Kocmyrzów-Luborzyca",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Krzeszowice",
        "us" : [ "Drugi Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Liszki",
        "us" : [ "Pierwszy Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Michałowice",
        "us" : [ "Drugi Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Mogilany",
        "us" : [ "Pierwszy Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Skawina",
        "us" : [ "Pierwszy Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Skała",
        "us" : [ "Drugi Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Sułoszowa",
        "us" : [ "Drugi Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Słomniki",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Wielka Wieś",
        "us" : [ "Drugi Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Zabierzów",
        "us" : [ "Drugi Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Zielonki",
        "us" : [ "Drugi Urząd Skarbowy Kraków" ]
      }, {
        "gmina" : "Świątniki Górne",
        "us" : [ "Pierwszy Urząd Skarbowy Kraków" ]
      } ]
    }, {
      "powiat" : "limanowski",
      "gminy" : [ {
        "gmina" : "Dobra",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Jodłownik",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Kamienica",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Laskowa",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Limanowa",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Mszana Dolna",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Niedźwiedź",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Słopnice",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Tymbark",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      }, {
        "gmina" : "Łukowica",
        "us" : [ "Urząd Skarbowy w Limanowej" ]
      } ]
    }, {
      "powiat" : "miechowski",
      "gminy" : [ {
        "gmina" : "Charsznica",
        "us" : [ "Urząd Skarbowy w Miechowie" ]
      }, {
        "gmina" : "Gołcza",
        "us" : [ "Urząd Skarbowy w Miechowie" ]
      }, {
        "gmina" : "Kozłów",
        "us" : [ "Urząd Skarbowy w Miechowie" ]
      }, {
        "gmina" : "Książ Wielki",
        "us" : [ "Urząd Skarbowy w Miechowie" ]
      }, {
        "gmina" : "Miechów",
        "us" : [ "Urząd Skarbowy w Miechowie" ]
      }, {
        "gmina" : "Racławice",
        "us" : [ "Urząd Skarbowy w Miechowie" ]
      }, {
        "gmina" : "Słaboszów",
        "us" : [ "Urząd Skarbowy w Miechowie" ]
      } ]
    }, {
      "powiat" : "myślenicki",
      "gminy" : [ {
        "gmina" : "Dobczyce",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      }, {
        "gmina" : "Lubień",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      }, {
        "gmina" : "Myślenice",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      }, {
        "gmina" : "Pcim",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      }, {
        "gmina" : "Raciechowice",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      }, {
        "gmina" : "Siepraw",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      }, {
        "gmina" : "Sułkowice",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      }, {
        "gmina" : "Tokarnia",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      }, {
        "gmina" : "Wiśniowa",
        "us" : [ "Urząd Skarbowy w Myślenicach" ]
      } ]
    }, {
      "powiat" : "nowosądecki",
      "gminy" : [ {
        "gmina" : "Chełmiec",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Grybów",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Gródek nad Dunajcem",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Kamionka Wielka",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Korzenna",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Krynica-Zdrój",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Muszyna",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Nawojowa",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Piwniczna-Zdrój",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Podegrodzie",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Rytro",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Stary Sącz",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Łabowa",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Łososina Dolna",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      }, {
        "gmina" : "Łącko",
        "us" : [ "Urząd Skarbowy w Nowym Sączu" ]
      } ]
    }, {
      "powiat" : "nowotarski",
      "gminy" : [ {
        "gmina" : "Czarny Dunajec",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Czorsztyn",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Jabłonka",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Krościenko nad Dunajcem",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Lipnica Wielka",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Nowy Targ",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Ochotnica Dolna",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Raba Wyżna",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Rabka-Zdrój",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Spytkowice",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Szaflary",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Szczawnica",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      }, {
        "gmina" : "Łapsze Niżne",
        "us" : [ "Urząd Skarbowy w Nowym Targu" ]
      } ]
    }, {
      "powiat" : "olkuski",
      "gminy" : [ {
        "gmina" : "Bolesław",
        "us" : [ "Urząd Skarbowy w Olkuszu" ]
      }, {
        "gmina" : "Bukowno",
        "us" : [ "Urząd Skarbowy w Olkuszu" ]
      }, {
        "gmina" : "Klucze",
        "us" : [ "Urząd Skarbowy w Olkuszu" ]
      }, {
        "gmina" : "Olkusz",
        "us" : [ "Urząd Skarbowy w Olkuszu" ]
      }, {
        "gmina" : "Trzyciąż",
        "us" : [ "Urząd Skarbowy w Olkuszu" ]
      }, {
        "gmina" : "Wolbrom",
        "us" : [ "Urząd Skarbowy w Olkuszu" ]
      } ]
    }, {
      "powiat" : "oświęcimski",
      "gminy" : [ {
        "gmina" : "Brzeszcze",
        "us" : [ "Urząd Skarbowy w Oświęcimiu" ]
      }, {
        "gmina" : "Chełmek",
        "us" : [ "Urząd Skarbowy w Oświęcimiu" ]
      }, {
        "gmina" : "Kęty",
        "us" : [ "Urząd Skarbowy w Oświęcimiu" ]
      }, {
        "gmina" : "Osiek",
        "us" : [ "Urząd Skarbowy w Oświęcimiu" ]
      }, {
        "gmina" : "Oświęcim",
        "us" : [ "Urząd Skarbowy w Oświęcimiu" ]
      }, {
        "gmina" : "Polanka Wielka",
        "us" : [ "Urząd Skarbowy w Oświęcimiu" ]
      }, {
        "gmina" : "Przeciszów",
        "us" : [ "Urząd Skarbowy w Oświęcimiu" ]
      }, {
        "gmina" : "Zator",
        "us" : [ "Urząd Skarbowy w Oświęcimiu" ]
      } ]
    }, {
      "powiat" : "proszowicki",
      "gminy" : [ {
        "gmina" : "Koniusza",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Koszyce",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Nowe Brzesko",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Pałecznica",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Proszowice",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      }, {
        "gmina" : "Radziemice",
        "us" : [ "Urząd Skarbowy w Proszowicach" ]
      } ]
    }, {
      "powiat" : "suski",
      "gminy" : [ {
        "gmina" : "Budzów",
        "us" : [ "Urząd Skarbowy w Suchej Beskidzkiej" ]
      }, {
        "gmina" : "Bystra-Sidzina",
        "us" : [ "Urząd Skarbowy w Suchej Beskidzkiej" ]
      }, {
        "gmina" : "Jordanów",
        "us" : [ "Urząd Skarbowy w Suchej Beskidzkiej" ]
      }, {
        "gmina" : "Maków Podhalański",
        "us" : [ "Urząd Skarbowy w Suchej Beskidzkiej" ]
      }, {
        "gmina" : "Stryszawa",
        "us" : [ "Urząd Skarbowy w Suchej Beskidzkiej" ]
      }, {
        "gmina" : "Sucha Beskidzka",
        "us" : [ "Urząd Skarbowy w Suchej Beskidzkiej" ]
      }, {
        "gmina" : "Zawoja",
        "us" : [ "Urząd Skarbowy w Suchej Beskidzkiej" ]
      }, {
        "gmina" : "Zembrzyce",
        "us" : [ "Urząd Skarbowy w Suchej Beskidzkiej" ]
      } ]
    }, {
      "powiat" : "tarnowski",
      "gminy" : [ {
        "gmina" : "Ciężkowice",
        "us" : [ "Pierwszy Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Gromnik",
        "us" : [ "Pierwszy Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Lisia Góra",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Pleśna",
        "us" : [ "Pierwszy Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Radłów",
        "us" : [ "Pierwszy Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Ryglice",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Rzepiennik Strzyżewski",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Skrzyszów",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Szerzyny",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Tarnów",
        "us" : [ "Pierwszy Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Tuchów",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Wierzchosławice",
        "us" : [ "Pierwszy Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Wietrzychowice",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Wojnicz",
        "us" : [ "Pierwszy Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Zakliczyn",
        "us" : [ "Pierwszy Urząd Skarbowy w Tarnowie" ]
      }, {
        "gmina" : "Żabno",
        "us" : [ "Drugi Urząd Skarbowy w Tarnowie" ]
      } ]
    }, {
      "powiat" : "tatrzański",
      "gminy" : [ {
        "gmina" : "Biały Dunajec",
        "us" : [ "Urząd Skarbowy w Zakopanem" ]
      }, {
        "gmina" : "Bukowina Tatrzańska",
        "us" : [ "Urząd Skarbowy w Zakopanem" ]
      }, {
        "gmina" : "Kościelisko",
        "us" : [ "Urząd Skarbowy w Zakopanem" ]
      }, {
        "gmina" : "Poronin",
        "us" : [ "Urząd Skarbowy w Zakopanem" ]
      }, {
        "gmina" : "Zakopane",
        "us" : [ "Urząd Skarbowy w Zakopanem" ]
      } ]
    }, {
      "powiat" : "wadowicki",
      "gminy" : [ {
        "gmina" : "Andrychów",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Brzeźnica",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Kalwaria Zebrzydowska",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Lanckorona",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Mucharz",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Spytkowice",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Stryszów",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Tomice",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Wadowice",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      }, {
        "gmina" : "Wieprz",
        "us" : [ "Urząd Skarbowy w Wadowicach" ]
      } ]
    }, {
      "powiat" : "wielicki",
      "gminy" : [ {
        "gmina" : "Biskupice",
        "us" : [ "Urząd Skarbowy w Wieliczce" ]
      }, {
        "gmina" : "Gdów",
        "us" : [ "Urząd Skarbowy w Wieliczce" ]
      }, {
        "gmina" : "Kłaj",
        "us" : [ "Urząd Skarbowy w Wieliczce" ]
      }, {
        "gmina" : "Niepołomice",
        "us" : [ "Urząd Skarbowy w Wieliczce" ]
      }, {
        "gmina" : "Wieliczka",
        "us" : [ "Urząd Skarbowy w Wieliczce" ]
      } ]
    } ]
  }, {
    "woj" : "opolskie",
    "powiaty" : [ {
      "powiat" : "Opole",
      "gminy" : [ {
        "gmina" : "Opole",
        "us" : [ "Pierwszy Urząd Skarbowy w Opolu", "Drugi Urząd Skarbowy w Opolu" ]
      } ]
    }, {
      "powiat" : "brzeski",
      "gminy" : [ {
        "gmina" : "Brzeg",
        "us" : [ "Urząd Skarbowy w Brzegu" ]
      }, {
        "gmina" : "Grodków",
        "us" : [ "Urząd Skarbowy w Brzegu" ]
      }, {
        "gmina" : "Lewin Brzeski",
        "us" : [ "Urząd Skarbowy w Brzegu" ]
      }, {
        "gmina" : "Lubsza",
        "us" : [ "Urząd Skarbowy w Brzegu" ]
      }, {
        "gmina" : "Olszanka",
        "us" : [ "Urząd Skarbowy w Brzegu" ]
      }, {
        "gmina" : "Skarbimierz",
        "us" : [ "Urząd Skarbowy w Brzegu" ]
      } ]
    }, {
      "powiat" : "głubczycki",
      "gminy" : [ {
        "gmina" : "Baborów",
        "us" : [ "Urząd Skarbowy w Głubczycach" ]
      }, {
        "gmina" : "Branice",
        "us" : [ "Urząd Skarbowy w Głubczycach" ]
      }, {
        "gmina" : "Głubczyce",
        "us" : [ "Urząd Skarbowy w Głubczycach" ]
      }, {
        "gmina" : "Kietrz",
        "us" : [ "Urząd Skarbowy w Głubczycach" ]
      } ]
    }, {
      "powiat" : "kluczborski",
      "gminy" : [ {
        "gmina" : "Byczyna",
        "us" : [ "Urząd Skarbowy w Kluczborku" ]
      }, {
        "gmina" : "Kluczbork",
        "us" : [ "Urząd Skarbowy w Kluczborku" ]
      }, {
        "gmina" : "Lasowice Wielkie",
        "us" : [ "Urząd Skarbowy w Kluczborku" ]
      }, {
        "gmina" : "Wołczyn",
        "us" : [ "Urząd Skarbowy w Kluczborku" ]
      } ]
    }, {
      "powiat" : "krapkowicki",
      "gminy" : [ {
        "gmina" : "Gogolin",
        "us" : [ "Urząd Skarbowy w Krapkowicach" ]
      }, {
        "gmina" : "Krapkowice",
        "us" : [ "Urząd Skarbowy w Krapkowicach" ]
      }, {
        "gmina" : "Strzeleczki",
        "us" : [ "Urząd Skarbowy w Krapkowicach" ]
      }, {
        "gmina" : "Walce",
        "us" : [ "Urząd Skarbowy w Krapkowicach" ]
      }, {
        "gmina" : "Zdzieszowice",
        "us" : [ "Urząd Skarbowy w Krapkowicach" ]
      } ]
    }, {
      "powiat" : "kędzierzyńsko-kozielski",
      "gminy" : [ {
        "gmina" : "Bierawa",
        "us" : [ "Urząd Skarbowy w Kędzierzynie-Koźlu" ]
      }, {
        "gmina" : "Cisek",
        "us" : [ "Urząd Skarbowy w Kędzierzynie-Koźlu" ]
      }, {
        "gmina" : "Kędzierzyn-Koźle",
        "us" : [ "Urząd Skarbowy w Kędzierzynie-Koźlu" ]
      }, {
        "gmina" : "Pawłowiczki",
        "us" : [ "Urząd Skarbowy w Kędzierzynie-Koźlu" ]
      }, {
        "gmina" : "Polska Cerekiew",
        "us" : [ "Urząd Skarbowy w Kędzierzynie-Koźlu" ]
      }, {
        "gmina" : "Reńska Wieś",
        "us" : [ "Urząd Skarbowy w Kędzierzynie-Koźlu" ]
      } ]
    }, {
      "powiat" : "namysłowski",
      "gminy" : [ {
        "gmina" : "Domaszowice",
        "us" : [ "Urząd Skarbowy w Namysłowie" ]
      }, {
        "gmina" : "Namysłów",
        "us" : [ "Urząd Skarbowy w Namysłowie" ]
      }, {
        "gmina" : "Pokój",
        "us" : [ "Urząd Skarbowy w Namysłowie" ]
      }, {
        "gmina" : "Wilków",
        "us" : [ "Urząd Skarbowy w Namysłowie" ]
      }, {
        "gmina" : "Świerczów",
        "us" : [ "Urząd Skarbowy w Namysłowie" ]
      } ]
    }, {
      "powiat" : "nyski",
      "gminy" : [ {
        "gmina" : "Głuchołazy",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      }, {
        "gmina" : "Kamiennik",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      }, {
        "gmina" : "Korfantów",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      }, {
        "gmina" : "Nysa",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      }, {
        "gmina" : "Otmuchów",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      }, {
        "gmina" : "Paczków",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      }, {
        "gmina" : "Pakosławice",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      }, {
        "gmina" : "Skoroszyce",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      }, {
        "gmina" : "Łambinowice",
        "us" : [ "Urząd Skarbowy w Nysie" ]
      } ]
    }, {
      "powiat" : "oleski",
      "gminy" : [ {
        "gmina" : "Dobrodzień",
        "us" : [ "Urząd Skarbowy w Oleśnie" ]
      }, {
        "gmina" : "Gorzów Śląski",
        "us" : [ "Urząd Skarbowy w Oleśnie" ]
      }, {
        "gmina" : "Olesno",
        "us" : [ "Urząd Skarbowy w Oleśnie" ]
      }, {
        "gmina" : "Praszka",
        "us" : [ "Urząd Skarbowy w Oleśnie" ]
      }, {
        "gmina" : "Radłów",
        "us" : [ "Urząd Skarbowy w Oleśnie" ]
      }, {
        "gmina" : "Rudniki",
        "us" : [ "Urząd Skarbowy w Oleśnie" ]
      }, {
        "gmina" : "Zębowice",
        "us" : [ "Urząd Skarbowy w Oleśnie" ]
      } ]
    }, {
      "powiat" : "opolski",
      "gminy" : [ {
        "gmina" : "Chrząstowice",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Dobrzeń Wielki",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Dąbrowa",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Komprachcice",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Murów",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Niemodlin",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Ozimek",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Popielów",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Prószków",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Tarnów Opolski",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Turawa",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Tułowice",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      }, {
        "gmina" : "Łubniany",
        "us" : [ "Drugi Urząd Skarbowy w Opolu" ]
      } ]
    }, {
      "powiat" : "prudnicki",
      "gminy" : [ {
        "gmina" : "Biała",
        "us" : [ "Urząd Skarbowy w Prudniku" ]
      }, {
        "gmina" : "Głogówek",
        "us" : [ "Urząd Skarbowy w Prudniku" ]
      }, {
        "gmina" : "Lubrza",
        "us" : [ "Urząd Skarbowy w Prudniku" ]
      }, {
        "gmina" : "Prudnik",
        "us" : [ "Urząd Skarbowy w Prudniku" ]
      } ]
    }, {
      "powiat" : "strzelecki",
      "gminy" : [ {
        "gmina" : "Izbicko",
        "us" : [ "Urząd Skarbowy w Strzelcach Opolskich" ]
      }, {
        "gmina" : "Jemielnica",
        "us" : [ "Urząd Skarbowy w Strzelcach Opolskich" ]
      }, {
        "gmina" : "Kolonowskie",
        "us" : [ "Urząd Skarbowy w Strzelcach Opolskich" ]
      }, {
        "gmina" : "Leśnica",
        "us" : [ "Urząd Skarbowy w Strzelcach Opolskich" ]
      }, {
        "gmina" : "Strzelce Opolskie",
        "us" : [ "Urząd Skarbowy w Strzelcach Opolskich" ]
      }, {
        "gmina" : "Ujazd",
        "us" : [ "Urząd Skarbowy w Strzelcach Opolskich" ]
      }, {
        "gmina" : "Zawadzkie",
        "us" : [ "Urząd Skarbowy w Strzelcach Opolskich" ]
      } ]
    } ]
  }, {
    "woj" : "podkarpackie",
    "powiaty" : [ {
      "powiat" : "Krosno",
      "gminy" : [ {
        "gmina" : "Krosno",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      } ]
    }, {
      "powiat" : "Przemyśl",
      "gminy" : [ {
        "gmina" : "Przemyśl",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      } ]
    }, {
      "powiat" : "Rzeszów",
      "gminy" : [ {
        "gmina" : "Rzeszów",
        "us" : [ "Pierwszy Urząd Skarbowy w Rzeszowie", "Drugi Urząd Skarbowy w Rzeszowie" ]
      } ]
    }, {
      "powiat" : "Tarnobrzeg",
      "gminy" : [ {
        "gmina" : "Tarnobrzeg",
        "us" : [ "Urząd Skarbowy w Tarnobrzegu" ]
      } ]
    }, {
      "powiat" : "bieszczadzki",
      "gminy" : [ {
        "gmina" : "Czarna",
        "us" : [ "Urząd Skarbowy w Ustrzykach Dolnych" ]
      }, {
        "gmina" : "Lutowiska",
        "us" : [ "Urząd Skarbowy w Ustrzykach Dolnych" ]
      }, {
        "gmina" : "Ustrzyki Dolne",
        "us" : [ "Urząd Skarbowy w Ustrzykach Dolnych" ]
      } ]
    }, {
      "powiat" : "brzozowski",
      "gminy" : [ {
        "gmina" : "Brzozów",
        "us" : [ "Urząd Skarbowy w Brzozowie" ]
      }, {
        "gmina" : "Domaradz",
        "us" : [ "Urząd Skarbowy w Brzozowie" ]
      }, {
        "gmina" : "Dydnia",
        "us" : [ "Urząd Skarbowy w Brzozowie" ]
      }, {
        "gmina" : "Haczów",
        "us" : [ "Urząd Skarbowy w Brzozowie" ]
      }, {
        "gmina" : "Jasienica Rosielna",
        "us" : [ "Urząd Skarbowy w Brzozowie" ]
      }, {
        "gmina" : "Nozdrzec",
        "us" : [ "Urząd Skarbowy w Brzozowie" ]
      } ]
    }, {
      "powiat" : "dębicki",
      "gminy" : [ {
        "gmina" : "Brzostek",
        "us" : [ "Urząd Skarbowy w Dębicy" ]
      }, {
        "gmina" : "Czarna",
        "us" : [ "Urząd Skarbowy w Dębicy" ]
      }, {
        "gmina" : "Dębica",
        "us" : [ "Urząd Skarbowy w Dębicy" ]
      }, {
        "gmina" : "Jodłowa",
        "us" : [ "Urząd Skarbowy w Dębicy" ]
      }, {
        "gmina" : "Pilzno",
        "us" : [ "Urząd Skarbowy w Dębicy" ]
      }, {
        "gmina" : "Żyraków",
        "us" : [ "Urząd Skarbowy w Dębicy" ]
      } ]
    }, {
      "powiat" : "jarosławski",
      "gminy" : [ {
        "gmina" : "Chłopice",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      }, {
        "gmina" : "Jarosław",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      }, {
        "gmina" : "Laszki",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      }, {
        "gmina" : "Pawłosiów",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      }, {
        "gmina" : "Pruchnik",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      }, {
        "gmina" : "Radymno",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      }, {
        "gmina" : "Rokietnica",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      }, {
        "gmina" : "Roźwienica",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      }, {
        "gmina" : "Wiązownica",
        "us" : [ "Urząd Skarbowy w Jarosławcu" ]
      } ]
    }, {
      "powiat" : "jasielski",
      "gminy" : [ {
        "gmina" : "Brzyska",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      }, {
        "gmina" : "Dębowiec",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      }, {
        "gmina" : "Jasło",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      }, {
        "gmina" : "Kołaczyce",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      }, {
        "gmina" : "Krempna",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      }, {
        "gmina" : "Nowy Żmigród",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      }, {
        "gmina" : "Osiek Jasielski",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      }, {
        "gmina" : "Skołyszyn",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      }, {
        "gmina" : "Tarnowiec",
        "us" : [ "Urząd Skarbowy w Jaśle" ]
      } ]
    }, {
      "powiat" : "kolbuszowski",
      "gminy" : [ {
        "gmina" : "Cmolas",
        "us" : [ "Urząd Skarbowy w Kolbuszowej" ]
      }, {
        "gmina" : "Dzikowiec",
        "us" : [ "Urząd Skarbowy w Kolbuszowej" ]
      }, {
        "gmina" : "Kolbuszowa",
        "us" : [ "Urząd Skarbowy w Kolbuszowej" ]
      }, {
        "gmina" : "Majdan Królewski",
        "us" : [ "Urząd Skarbowy w Kolbuszowej" ]
      }, {
        "gmina" : "Niwiska",
        "us" : [ "Urząd Skarbowy w Kolbuszowej" ]
      }, {
        "gmina" : "Raniżów",
        "us" : [ "Urząd Skarbowy w Kolbuszowej" ]
      } ]
    }, {
      "powiat" : "krośnieński",
      "gminy" : [ {
        "gmina" : "Chorkówka",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Dukla",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Iwonicz-Zdrój",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Jaśliska",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Jedlicze",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Korczyna",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Krościenko Wyżne",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Miejsce Piastowe",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Rymanów",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      }, {
        "gmina" : "Wojaszówka",
        "us" : [ "Urząd Skarbowy w Krośnie" ]
      } ]
    }, {
      "powiat" : "leski",
      "gminy" : [ {
        "gmina" : "Baligród",
        "us" : [ "Urząd Skarbowy w Lesku" ]
      }, {
        "gmina" : "Cisna",
        "us" : [ "Urząd Skarbowy w Lesku" ]
      }, {
        "gmina" : "Lesko",
        "us" : [ "Urząd Skarbowy w Lesku" ]
      }, {
        "gmina" : "Olszanica",
        "us" : [ "Urząd Skarbowy w Lesku" ]
      }, {
        "gmina" : "Solina",
        "us" : [ "Urząd Skarbowy w Lesku" ]
      } ]
    }, {
      "powiat" : "leżajski",
      "gminy" : [ {
        "gmina" : "Grodzisko Dolne",
        "us" : [ "Urząd Skarbowy w Leżajsku" ]
      }, {
        "gmina" : "Kuryłówka",
        "us" : [ "Urząd Skarbowy w Leżajsku" ]
      }, {
        "gmina" : "Leżajsk",
        "us" : [ "Urząd Skarbowy w Leżajsku" ]
      }, {
        "gmina" : "Nowa Sarzyna",
        "us" : [ "Urząd Skarbowy w Leżajsku" ]
      } ]
    }, {
      "powiat" : "lubaczowski",
      "gminy" : [ {
        "gmina" : "Cieszanów",
        "us" : [ "Urząd Skarbowy w Lubaczowie" ]
      }, {
        "gmina" : "Horyniec-Zdrój",
        "us" : [ "Urząd Skarbowy w Lubaczowie" ]
      }, {
        "gmina" : "Lubaczów",
        "us" : [ "Urząd Skarbowy w Lubaczowie" ]
      }, {
        "gmina" : "Narol",
        "us" : [ "Urząd Skarbowy w Lubaczowie" ]
      }, {
        "gmina" : "Oleszyce",
        "us" : [ "Urząd Skarbowy w Lubaczowie" ]
      }, {
        "gmina" : "Stary Dzików",
        "us" : [ "Urząd Skarbowy w Lubaczowie" ]
      }, {
        "gmina" : "Wielkie Oczy",
        "us" : [ "Urząd Skarbowy w Lubaczowie" ]
      } ]
    }, {
      "powiat" : "mielecki",
      "gminy" : [ {
        "gmina" : "Borowa",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      }, {
        "gmina" : "Czermin",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      }, {
        "gmina" : "Gawłuszowice",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      }, {
        "gmina" : "Mielec",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      }, {
        "gmina" : "Padew Narodowa",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      }, {
        "gmina" : "Przecław",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      }, {
        "gmina" : "Radomyśl Wielki",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      }, {
        "gmina" : "Tuszów Narodowy",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      }, {
        "gmina" : "Wadowice Górne",
        "us" : [ "Urząd Skarbowy w Mielcu" ]
      } ]
    }, {
      "powiat" : "niżański",
      "gminy" : [ {
        "gmina" : "Harasiuki",
        "us" : [ "Urząd Skarbowy w Nisku" ]
      }, {
        "gmina" : "Jarocin",
        "us" : [ "Urząd Skarbowy w Nisku" ]
      }, {
        "gmina" : "Jeżowe",
        "us" : [ "Urząd Skarbowy w Nisku" ]
      }, {
        "gmina" : "Krzeszów",
        "us" : [ "Urząd Skarbowy w Nisku" ]
      }, {
        "gmina" : "Nisko",
        "us" : [ "Urząd Skarbowy w Nisku" ]
      }, {
        "gmina" : "Rudnik nad Sanem",
        "us" : [ "Urząd Skarbowy w Nisku" ]
      }, {
        "gmina" : "Ulanów",
        "us" : [ "Urząd Skarbowy w Nisku" ]
      } ]
    }, {
      "powiat" : "przemyski",
      "gminy" : [ {
        "gmina" : "Bircza",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Dubiecko",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Fredropol",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Krasiczyn",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Krzywcza",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Medyka",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Orły",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Przemyśl",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Stubno",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      }, {
        "gmina" : "Żurawica",
        "us" : [ "Urząd Skarbowy w Przemyślu" ]
      } ]
    }, {
      "powiat" : "przeworski",
      "gminy" : [ {
        "gmina" : "Adamówka",
        "us" : [ "Urząd Skarbowy w Przeworsku" ]
      }, {
        "gmina" : "Gać",
        "us" : [ "Urząd Skarbowy w Przeworsku" ]
      }, {
        "gmina" : "Jawornik Polski",
        "us" : [ "Urząd Skarbowy w Przeworsku" ]
      }, {
        "gmina" : "Kańczuga",
        "us" : [ "Urząd Skarbowy w Przeworsku" ]
      }, {
        "gmina" : "Przeworsk",
        "us" : [ "Urząd Skarbowy w Przeworsku" ]
      }, {
        "gmina" : "Sieniawa",
        "us" : [ "Urząd Skarbowy w Przeworsku" ]
      }, {
        "gmina" : "Tryńcza",
        "us" : [ "Urząd Skarbowy w Przeworsku" ]
      }, {
        "gmina" : "Zarzecze",
        "us" : [ "Urząd Skarbowy w Przeworsku" ]
      } ]
    }, {
      "powiat" : "ropczycko-sędziszowski",
      "gminy" : [ {
        "gmina" : "Iwierzyce",
        "us" : [ "Urząd Skarbowy w Ropczycach" ]
      }, {
        "gmina" : "Ostrów",
        "us" : [ "Urząd Skarbowy w Ropczycach" ]
      }, {
        "gmina" : "Ropczyce",
        "us" : [ "Urząd Skarbowy w Ropczycach" ]
      }, {
        "gmina" : "Sędziszów Małopolski",
        "us" : [ "Urząd Skarbowy w Ropczycach" ]
      }, {
        "gmina" : "Wielopole Skrzyńskie",
        "us" : [ "Urząd Skarbowy w Ropczycach" ]
      } ]
    }, {
      "powiat" : "rzeszowski",
      "gminy" : [ {
        "gmina" : "Boguchwała",
        "us" : [ "Drugi Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Błażowa",
        "us" : [ "Pierwszy Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Chmielnik",
        "us" : [ "Pierwszy Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Dynów",
        "us" : [ "Pierwszy Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Głogów Małopolski",
        "us" : [ "Drugi Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Hyżne",
        "us" : [ "Pierwszy Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Kamień",
        "us" : [ "Drugi Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Krasne",
        "us" : [ "Drugi Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Lubenia",
        "us" : [ "Pierwszy Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Sokołów Małopolski",
        "us" : [ "Drugi Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Trzebownisko",
        "us" : [ "Drugi Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Tyczyn",
        "us" : [ "Pierwszy Urząd Skarbowy w Rzeszowie" ]
      }, {
        "gmina" : "Świlcza",
        "us" : [ "Drugi Urząd Skarbowy w Rzeszowie" ]
      } ]
    }, {
      "powiat" : "sanocki",
      "gminy" : [ {
        "gmina" : "Besko",
        "us" : [ "Urząd Skarbowy w Sanoku" ]
      }, {
        "gmina" : "Bukowsko",
        "us" : [ "Urząd Skarbowy w Sanoku" ]
      }, {
        "gmina" : "Komańcza",
        "us" : [ "Urząd Skarbowy w Sanoku" ]
      }, {
        "gmina" : "Sanok",
        "us" : [ "Urząd Skarbowy w Sanoku" ]
      }, {
        "gmina" : "Tyrawa Wołoska",
        "us" : [ "Urząd Skarbowy w Sanoku" ]
      }, {
        "gmina" : "Zagórz",
        "us" : [ "Urząd Skarbowy w Sanoku" ]
      }, {
        "gmina" : "Zarszyn",
        "us" : [ "Urząd Skarbowy w Sanoku" ]
      } ]
    }, {
      "powiat" : "stalowowolski",
      "gminy" : [ {
        "gmina" : "Bojanów",
        "us" : [ "Urząd Skarbowy w Stalowej Woli" ]
      }, {
        "gmina" : "Pysznica",
        "us" : [ "Urząd Skarbowy w Stalowej Woli" ]
      }, {
        "gmina" : "Radomyśl nad Sanem",
        "us" : [ "Urząd Skarbowy w Stalowej Woli" ]
      }, {
        "gmina" : "Stalowa Wola",
        "us" : [ "Urząd Skarbowy w Stalowej Woli" ]
      }, {
        "gmina" : "Zaklików",
        "us" : [ "Urząd Skarbowy w Stalowej Woli" ]
      }, {
        "gmina" : "Zaleszany",
        "us" : [ "Urząd Skarbowy w Stalowej Woli" ]
      } ]
    }, {
      "powiat" : "strzyżowski",
      "gminy" : [ {
        "gmina" : "Czudec",
        "us" : [ "Urząd Skarbowy w Strzyżowie" ]
      }, {
        "gmina" : "Frysztak",
        "us" : [ "Urząd Skarbowy w Strzyżowie" ]
      }, {
        "gmina" : "Niebylec",
        "us" : [ "Urząd Skarbowy w Strzyżowie" ]
      }, {
        "gmina" : "Strzyżów",
        "us" : [ "Urząd Skarbowy w Strzyżowie" ]
      }, {
        "gmina" : "Wiśniowa",
        "us" : [ "Urząd Skarbowy w Strzyżowie" ]
      } ]
    }, {
      "powiat" : "tarnobrzeski",
      "gminy" : [ {
        "gmina" : "Baranów Sandomierski",
        "us" : [ "Urząd Skarbowy w Tarnobrzegu" ]
      }, {
        "gmina" : "Gorzyce",
        "us" : [ "Urząd Skarbowy w Tarnobrzegu" ]
      }, {
        "gmina" : "Grębów",
        "us" : [ "Urząd Skarbowy w Tarnobrzegu" ]
      }, {
        "gmina" : "Nowa Dęba",
        "us" : [ "Urząd Skarbowy w Tarnobrzegu" ]
      } ]
    }, {
      "powiat" : "łańcucki",
      "gminy" : [ {
        "gmina" : "Białobrzegi",
        "us" : [ "Urząd Skarbowy w Łańcucie" ]
      }, {
        "gmina" : "Czarna",
        "us" : [ "Urząd Skarbowy w Łańcucie" ]
      }, {
        "gmina" : "Markowa",
        "us" : [ "Urząd Skarbowy w Łańcucie" ]
      }, {
        "gmina" : "Rakszawa",
        "us" : [ "Urząd Skarbowy w Łańcucie" ]
      }, {
        "gmina" : "Łańcut",
        "us" : [ "Urząd Skarbowy w Łańcucie" ]
      }, {
        "gmina" : "Żołynia",
        "us" : [ "Urząd Skarbowy w Łańcucie" ]
      } ]
    } ]
  }, {
    "woj" : "podlaskie",
    "powiaty" : [ {
      "powiat" : "Białystok",
      "gminy" : [ {
        "gmina" : "Białystok",
        "us" : [ "Pierwszy Urząd Skarbowy w Białymstoku", "Drugi Urząd Skarbowy w Białymstoku" ]
      } ]
    }, {
      "powiat" : "Suwałki",
      "gminy" : [ {
        "gmina" : "Suwałki",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      } ]
    }, {
      "powiat" : "augustowski",
      "gminy" : [ {
        "gmina" : "Augustów",
        "us" : [ "Urząd Skarbowy w Augustowie" ]
      }, {
        "gmina" : "Bargłów Kościelny",
        "us" : [ "Urząd Skarbowy w Augustowie" ]
      }, {
        "gmina" : "Lipsk",
        "us" : [ "Urząd Skarbowy w Augustowie" ]
      }, {
        "gmina" : "Nowinka",
        "us" : [ "Urząd Skarbowy w Augustowie" ]
      }, {
        "gmina" : "Płaska",
        "us" : [ "Urząd Skarbowy w Augustowie" ]
      }, {
        "gmina" : "Sztabin",
        "us" : [ "Urząd Skarbowy w Augustowie" ]
      } ]
    }, {
      "powiat" : "białostocki",
      "gminy" : [ {
        "gmina" : "Choroszcz",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Czarna Białostocka",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Dobrzyniewo Duże",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Gródek",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Juchnowiec Kościelny",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Michałowo",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Poświętne",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Supraśl",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Suraż",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Turośń Kościelna",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Tykocin",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Wasilków",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Zabłudów",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Zawady",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      }, {
        "gmina" : "Łapy",
        "us" : [ "Drugi Urząd Skarbowy w Białymstoku" ]
      } ]
    }, {
      "powiat" : "bielski",
      "gminy" : [ {
        "gmina" : "Bielsk Podlaski",
        "us" : [ "Urząd Skarbowy w Bielsku Podlaskim" ]
      }, {
        "gmina" : "Boćki",
        "us" : [ "Urząd Skarbowy w Bielsku Podlaskim" ]
      }, {
        "gmina" : "Brańsk",
        "us" : [ "Urząd Skarbowy w Bielsku Podlaskim" ]
      }, {
        "gmina" : "Orla",
        "us" : [ "Urząd Skarbowy w Bielsku Podlaskim" ]
      }, {
        "gmina" : "Rudka",
        "us" : [ "Urząd Skarbowy w Bielsku Podlaskim" ]
      }, {
        "gmina" : "Wyszki",
        "us" : [ "Urząd Skarbowy w Bielsku Podlaskim" ]
      } ]
    }, {
      "powiat" : "grajewski",
      "gminy" : [ {
        "gmina" : "Grajewo",
        "us" : [ "Urząd Skarbowy w Grajewie" ]
      }, {
        "gmina" : "Radziłów",
        "us" : [ "Urząd Skarbowy w Grajewie" ]
      }, {
        "gmina" : "Rajgród",
        "us" : [ "Urząd Skarbowy w Grajewie" ]
      }, {
        "gmina" : "Szczuczyn",
        "us" : [ "Urząd Skarbowy w Grajewie" ]
      }, {
        "gmina" : "Wąsosz",
        "us" : [ "Urząd Skarbowy w Grajewie" ]
      } ]
    }, {
      "powiat" : "hajnowski",
      "gminy" : [ {
        "gmina" : "Białowieża",
        "us" : [ "Urząd Skarbowy w Hajnówce" ]
      }, {
        "gmina" : "Czeremcha",
        "us" : [ "Urząd Skarbowy w Hajnówce" ]
      }, {
        "gmina" : "Czyże",
        "us" : [ "Urząd Skarbowy w Hajnówce" ]
      }, {
        "gmina" : "Dubicze Cerkiewne",
        "us" : [ "Urząd Skarbowy w Hajnówce" ]
      }, {
        "gmina" : "Hajnówka",
        "us" : [ "Urząd Skarbowy w Hajnówce" ]
      }, {
        "gmina" : "Kleszczele",
        "us" : [ "Urząd Skarbowy w Hajnówce" ]
      }, {
        "gmina" : "Narew",
        "us" : [ "Urząd Skarbowy w Hajnówce" ]
      }, {
        "gmina" : "Narewka",
        "us" : [ "Urząd Skarbowy w Hajnówce" ]
      } ]
    }, {
      "powiat" : "kolneński",
      "gminy" : [ {
        "gmina" : "Grabowo",
        "us" : [ "Urząd Skarbowy w Kolnie" ]
      }, {
        "gmina" : "Kolno",
        "us" : [ "Urząd Skarbowy w Kolnie" ]
      }, {
        "gmina" : "Mały Płock",
        "us" : [ "Urząd Skarbowy w Kolnie" ]
      }, {
        "gmina" : "Stawiski",
        "us" : [ "Urząd Skarbowy w Kolnie" ]
      }, {
        "gmina" : "Turośl",
        "us" : [ "Urząd Skarbowy w Kolnie" ]
      } ]
    }, {
      "powiat" : "moniecki",
      "gminy" : [ {
        "gmina" : "Goniądz",
        "us" : [ "Urząd Skarbowy w Mońkach" ]
      }, {
        "gmina" : "Jasionówka",
        "us" : [ "Urząd Skarbowy w Mońkach" ]
      }, {
        "gmina" : "Jaświły",
        "us" : [ "Urząd Skarbowy w Mońkach" ]
      }, {
        "gmina" : "Knyszyn",
        "us" : [ "Urząd Skarbowy w Mońkach" ]
      }, {
        "gmina" : "Krypno",
        "us" : [ "Urząd Skarbowy w Mońkach" ]
      }, {
        "gmina" : "Mońki",
        "us" : [ "Urząd Skarbowy w Mońkach" ]
      }, {
        "gmina" : "Trzcianne",
        "us" : [ "Urząd Skarbowy w Mońkach" ]
      } ]
    }, {
      "powiat" : "sejneński",
      "gminy" : [ {
        "gmina" : "Giby",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Krasnopol",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Puńsk",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Sejny",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      } ]
    }, {
      "powiat" : "siemiatycki",
      "gminy" : [ {
        "gmina" : "Drohiczyn",
        "us" : [ "Urząd Skarbowy w Siemiatyczach" ]
      }, {
        "gmina" : "Dziadkowice",
        "us" : [ "Urząd Skarbowy w Siemiatyczach" ]
      }, {
        "gmina" : "Grodzisk",
        "us" : [ "Urząd Skarbowy w Siemiatyczach" ]
      }, {
        "gmina" : "Mielnik",
        "us" : [ "Urząd Skarbowy w Siemiatyczach" ]
      }, {
        "gmina" : "Milejczyce",
        "us" : [ "Urząd Skarbowy w Siemiatyczach" ]
      }, {
        "gmina" : "Nurzec-Stacja",
        "us" : [ "Urząd Skarbowy w Siemiatyczach" ]
      }, {
        "gmina" : "Perlejewo",
        "us" : [ "Urząd Skarbowy w Siemiatyczach" ]
      }, {
        "gmina" : "Siemiatycze",
        "us" : [ "Urząd Skarbowy w Siemiatyczach" ]
      } ]
    }, {
      "powiat" : "sokólski",
      "gminy" : [ {
        "gmina" : "Dąbrowa Białostocka",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Janów",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Korycin",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Krynki",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Kuźnica",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Nowy Dwór",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Sidra",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Sokółka",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Suchowola",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      }, {
        "gmina" : "Szudziałowo",
        "us" : [ "Urząd Skarbowy w Sokółce" ]
      } ]
    }, {
      "powiat" : "suwalski",
      "gminy" : [ {
        "gmina" : "Bakałarzewo",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Filipów",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Jeleniewo",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Przerośl",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Raczki",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Rutka-Tartak",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Suwałki",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Szypliszki",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      }, {
        "gmina" : "Wiżajny",
        "us" : [ "Urząd Skarbowy w Suwałkach" ]
      } ]
    }, {
      "powiat" : "wysokomazowiecki",
      "gminy" : [ {
        "gmina" : "Ciechanowiec",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      }, {
        "gmina" : "Czyżew",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      }, {
        "gmina" : "Klukowo",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      }, {
        "gmina" : "Kobylin-Borzymy",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      }, {
        "gmina" : "Kulesze Kościelne",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      }, {
        "gmina" : "Nowe Piekuty",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      }, {
        "gmina" : "Sokoły",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      }, {
        "gmina" : "Szepietowo",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      }, {
        "gmina" : "Wysokie Mazowieckie",
        "us" : [ "Urząd Skarbowy w Wysokiem Mazowieckiem" ]
      } ]
    }, {
      "powiat" : "zambrowski",
      "gminy" : [ {
        "gmina" : "Kołaki Kościelne",
        "us" : [ "Urząd Skarbowy w Zambrowie" ]
      }, {
        "gmina" : "Rutki",
        "us" : [ "Urząd Skarbowy w Zambrowie" ]
      }, {
        "gmina" : "Szumowo",
        "us" : [ "Urząd Skarbowy w Zambrowie" ]
      }, {
        "gmina" : "Zambrów",
        "us" : [ "Urząd Skarbowy w Zambrowie" ]
      } ]
    }, {
      "powiat" : "Łomża",
      "gminy" : [ {
        "gmina" : "Łomża",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      } ]
    }, {
      "powiat" : "łomżyński",
      "gminy" : [ {
        "gmina" : "Jedwabne",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      }, {
        "gmina" : "Miastkowo",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      }, {
        "gmina" : "Nowogród",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      }, {
        "gmina" : "Piątnica",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      }, {
        "gmina" : "Przytuły",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      }, {
        "gmina" : "Wizna",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      }, {
        "gmina" : "Zbójna",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      }, {
        "gmina" : "Łomża",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      }, {
        "gmina" : "Śniadowo",
        "us" : [ "Urząd Skarbowy w Łomży" ]
      } ]
    } ]
  }, {
    "woj" : "pomorskie",
    "powiaty" : [ {
      "powiat" : "Gdańsk",
      "gminy" : [ {
        "gmina" : "Gdańsk",
        "us" : [ "Pierwszy Urząd Skarbowy w Gdańsku", "Drugi Urząd Skarbowy w Gdańsku", "Trzeci Urząd Skarbowy w Gdańsku" ]
      } ]
    }, {
      "powiat" : "Gdynia",
      "gminy" : [ {
        "gmina" : "Gdynia",
        "us" : [ "Pierwszy Urząd Skarbowy w Gdyni", "Drugi Urząd Skarbowy w Gdyni" ]
      } ]
    }, {
      "powiat" : "Sopot",
      "gminy" : [ {
        "gmina" : "Sopot",
        "us" : [ "Urząd Skarbowy w Sopocie" ]
      } ]
    }, {
      "powiat" : "Słupsk",
      "gminy" : [ {
        "gmina" : "Słupsk",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      } ]
    }, {
      "powiat" : "bytowski",
      "gminy" : [ {
        "gmina" : "Borzytuchom",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Bytów",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Czarna Dąbrówka",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Kołczygłowy",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Lipnica",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Miastko",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Parchowo",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Studzienice",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Trzebielino",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      }, {
        "gmina" : "Tuchomie",
        "us" : [ "Urząd Skarbowy w Bytowie" ]
      } ]
    }, {
      "powiat" : "chojnicki",
      "gminy" : [ {
        "gmina" : "Brusy",
        "us" : [ "Urząd Skarbowy w Chojnicach" ]
      }, {
        "gmina" : "Chojnice",
        "us" : [ "Urząd Skarbowy w Chojnicach" ]
      }, {
        "gmina" : "Czersk",
        "us" : [ "Urząd Skarbowy w Chojnicach" ]
      }, {
        "gmina" : "Konarzyny",
        "us" : [ "Urząd Skarbowy w Chojnicach" ]
      } ]
    }, {
      "powiat" : "człuchowski",
      "gminy" : [ {
        "gmina" : "Czarne",
        "us" : [ "Urząd Skarbowy w Człuchowie" ]
      }, {
        "gmina" : "Człuchów",
        "us" : [ "Urząd Skarbowy w Człuchowie" ]
      }, {
        "gmina" : "Debrzno",
        "us" : [ "Urząd Skarbowy w Człuchowie" ]
      }, {
        "gmina" : "Koczała",
        "us" : [ "Urząd Skarbowy w Człuchowie" ]
      }, {
        "gmina" : "Przechlewo",
        "us" : [ "Urząd Skarbowy w Człuchowie" ]
      }, {
        "gmina" : "Rzeczenica",
        "us" : [ "Urząd Skarbowy w Człuchowie" ]
      } ]
    }, {
      "powiat" : "gdański",
      "gminy" : [ {
        "gmina" : "Cedry Wielkie",
        "us" : [ "Urząd Skarbowy w Pruszczu Gdańskim" ]
      }, {
        "gmina" : "Kolbudy",
        "us" : [ "Urząd Skarbowy w Pruszczu Gdańskim" ]
      }, {
        "gmina" : "Pruszcz Gdański",
        "us" : [ "Urząd Skarbowy w Pruszczu Gdańskim" ]
      }, {
        "gmina" : "Przywidz",
        "us" : [ "Urząd Skarbowy w Pruszczu Gdańskim" ]
      }, {
        "gmina" : "Pszczółki",
        "us" : [ "Urząd Skarbowy w Pruszczu Gdańskim" ]
      }, {
        "gmina" : "Suchy Dąb",
        "us" : [ "Urząd Skarbowy w Pruszczu Gdańskim" ]
      }, {
        "gmina" : "Trąbki Wielkie",
        "us" : [ "Urząd Skarbowy w Pruszczu Gdańskim" ]
      } ]
    }, {
      "powiat" : "kartuski",
      "gminy" : [ {
        "gmina" : "Chmielno",
        "us" : [ "Urząd Skarbowy w Kartuzach" ]
      }, {
        "gmina" : "Kartuzy",
        "us" : [ "Urząd Skarbowy w Kartuzach" ]
      }, {
        "gmina" : "Przodkowo",
        "us" : [ "Urząd Skarbowy w Kartuzach" ]
      }, {
        "gmina" : "Sierakowice",
        "us" : [ "Urząd Skarbowy w Kartuzach" ]
      }, {
        "gmina" : "Somonino",
        "us" : [ "Urząd Skarbowy w Kartuzach" ]
      }, {
        "gmina" : "Stężyca",
        "us" : [ "Urząd Skarbowy w Kartuzach" ]
      }, {
        "gmina" : "Sulęczyno",
        "us" : [ "Urząd Skarbowy w Kartuzach" ]
      }, {
        "gmina" : "Żukowo",
        "us" : [ "Urząd Skarbowy w Kartuzach" ]
      } ]
    }, {
      "powiat" : "kościerski",
      "gminy" : [ {
        "gmina" : "Dziemiany",
        "us" : [ "Urząd Skarbowy w Kościerzynie" ]
      }, {
        "gmina" : "Karsin",
        "us" : [ "Urząd Skarbowy w Kościerzynie" ]
      }, {
        "gmina" : "Kościerzyna",
        "us" : [ "Urząd Skarbowy w Kościerzynie" ]
      }, {
        "gmina" : "Liniewo",
        "us" : [ "Urząd Skarbowy w Kościerzynie" ]
      }, {
        "gmina" : "Lipusz",
        "us" : [ "Urząd Skarbowy w Kościerzynie" ]
      }, {
        "gmina" : "Nowa Karczma",
        "us" : [ "Urząd Skarbowy w Kościerzynie" ]
      }, {
        "gmina" : "Stara Kiszewa",
        "us" : [ "Urząd Skarbowy w Kościerzynie" ]
      } ]
    }, {
      "powiat" : "kwidzyński",
      "gminy" : [ {
        "gmina" : "Gardeja",
        "us" : [ "Urząd Skarbowy w Kwidzynie" ]
      }, {
        "gmina" : "Kwidzyn",
        "us" : [ "Urząd Skarbowy w Kwidzynie" ]
      }, {
        "gmina" : "Prabuty",
        "us" : [ "Urząd Skarbowy w Kwidzynie" ]
      }, {
        "gmina" : "Ryjewo",
        "us" : [ "Urząd Skarbowy w Kwidzynie" ]
      }, {
        "gmina" : "Sadlinki",
        "us" : [ "Urząd Skarbowy w Kwidzynie" ]
      } ]
    }, {
      "powiat" : "lęborski",
      "gminy" : [ {
        "gmina" : "Cewice",
        "us" : [ "Urząd Skarbowy w Lęborku" ]
      }, {
        "gmina" : "Lębork",
        "us" : [ "Urząd Skarbowy w Lęborku" ]
      }, {
        "gmina" : "Nowa Wieś Lęborska",
        "us" : [ "Urząd Skarbowy w Lęborku" ]
      }, {
        "gmina" : "Wicko",
        "us" : [ "Urząd Skarbowy w Lęborku" ]
      }, {
        "gmina" : "Łeba",
        "us" : [ "Urząd Skarbowy w Lęborku" ]
      } ]
    }, {
      "powiat" : "malborski",
      "gminy" : [ {
        "gmina" : "Lichnowy",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Malbork",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Miłoradz",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Nowy Staw",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Stare Pole",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      } ]
    }, {
      "powiat" : "nowodworski",
      "gminy" : [ {
        "gmina" : "Krynica Morska",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Nowy Dwór Gdański",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Ostaszewo",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Stegna",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Sztutowo",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      } ]
    }, {
      "powiat" : "pucki",
      "gminy" : [ {
        "gmina" : "Hel",
        "us" : [ "Urząd Skarbowy w Pucku" ]
      }, {
        "gmina" : "Jastarnia",
        "us" : [ "Urząd Skarbowy w Pucku" ]
      }, {
        "gmina" : "Kosakowo",
        "us" : [ "Urząd Skarbowy w Pucku" ]
      }, {
        "gmina" : "Krokowa",
        "us" : [ "Urząd Skarbowy w Pucku" ]
      }, {
        "gmina" : "Puck",
        "us" : [ "Urząd Skarbowy w Pucku" ]
      }, {
        "gmina" : "Władysławowo",
        "us" : [ "Urząd Skarbowy w Pucku" ]
      } ]
    }, {
      "powiat" : "starogardzki",
      "gminy" : [ {
        "gmina" : "Bobowo",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Czarna Woda",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Kaliska",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Lubichowo",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Osieczna",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Osiek",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Skarszewy",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Skórcz",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Smętowo Graniczne",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Starogard Gdański",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      }, {
        "gmina" : "Zblewo",
        "us" : [ "Urząd Skarbowy w Starogardzie Gdańskim" ]
      } ]
    }, {
      "powiat" : "sztumski",
      "gminy" : [ {
        "gmina" : "Dzierzgoń",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Mikołajki Pomorskie",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Stary Dzierzgoń",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Stary Targ",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      }, {
        "gmina" : "Sztum",
        "us" : [ "Urząd Skarbowy w Malborku" ]
      } ]
    }, {
      "powiat" : "słupski",
      "gminy" : [ {
        "gmina" : "Damnica",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      }, {
        "gmina" : "Dębnica Kaszubska",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      }, {
        "gmina" : "Główczyce",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      }, {
        "gmina" : "Kobylnica",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      }, {
        "gmina" : "Kępice",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      }, {
        "gmina" : "Potęgowo",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      }, {
        "gmina" : "Smołdzino",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      }, {
        "gmina" : "Słupsk",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      }, {
        "gmina" : "Ustka",
        "us" : [ "Urząd Skarbowy w Słupsku" ]
      } ]
    }, {
      "powiat" : "tczewski",
      "gminy" : [ {
        "gmina" : "Gniew",
        "us" : [ "Urząd Skarbowy w Tczewie" ]
      }, {
        "gmina" : "Morzeszczyn",
        "us" : [ "Urząd Skarbowy w Tczewie" ]
      }, {
        "gmina" : "Pelplin",
        "us" : [ "Urząd Skarbowy w Tczewie" ]
      }, {
        "gmina" : "Subkowy",
        "us" : [ "Urząd Skarbowy w Tczewie" ]
      }, {
        "gmina" : "Tczew",
        "us" : [ "Urząd Skarbowy w Tczewie" ]
      } ]
    }, {
      "powiat" : "wejherowski",
      "gminy" : [ {
        "gmina" : "Choczewo",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      }, {
        "gmina" : "Gniewino",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      }, {
        "gmina" : "Linia",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      }, {
        "gmina" : "Luzino",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      }, {
        "gmina" : "Reda",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      }, {
        "gmina" : "Rumia",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      }, {
        "gmina" : "Szemud",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      }, {
        "gmina" : "Wejherowo",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      }, {
        "gmina" : "Łęczyce",
        "us" : [ "Urząd Skarbowy w Wejherowie" ]
      } ]
    } ]
  }, {
    "woj" : "warmińsko-mazurskie",
    "powiaty" : [ {
      "powiat" : "Olsztyn",
      "gminy" : [ {
        "gmina" : "Olsztyn",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      } ]
    }, {
      "powiat" : "bartoszycki",
      "gminy" : [ {
        "gmina" : "Bartoszyce",
        "us" : [ "Urząd Skarbowy w Bartoszycach" ]
      }, {
        "gmina" : "Bisztynek",
        "us" : [ "Urząd Skarbowy w Bartoszycach" ]
      }, {
        "gmina" : "Górowo Iławeckie",
        "us" : [ "Urząd Skarbowy w Bartoszycach" ]
      }, {
        "gmina" : "Sępopol",
        "us" : [ "Urząd Skarbowy w Bartoszycach" ]
      } ]
    }, {
      "powiat" : "braniewski",
      "gminy" : [ {
        "gmina" : "Braniewo",
        "us" : [ "Urząd Skarbowy w Braniewie" ]
      }, {
        "gmina" : "Frombork",
        "us" : [ "Urząd Skarbowy w Braniewie" ]
      }, {
        "gmina" : "Lelkowo",
        "us" : [ "Urząd Skarbowy w Braniewie" ]
      }, {
        "gmina" : "Pieniężno",
        "us" : [ "Urząd Skarbowy w Braniewie" ]
      }, {
        "gmina" : "Płoskinia",
        "us" : [ "Urząd Skarbowy w Braniewie" ]
      }, {
        "gmina" : "Wilczęta",
        "us" : [ "Urząd Skarbowy w Braniewie" ]
      } ]
    }, {
      "powiat" : "działdowski",
      "gminy" : [ {
        "gmina" : "Działdowo",
        "us" : [ "Urząd Skarbowy w Działdowie" ]
      }, {
        "gmina" : "Iłowo-Osada",
        "us" : [ "Urząd Skarbowy w Działdowie" ]
      }, {
        "gmina" : "Lidzbark",
        "us" : [ "Urząd Skarbowy w Działdowie" ]
      }, {
        "gmina" : "Płośnica",
        "us" : [ "Urząd Skarbowy w Działdowie" ]
      }, {
        "gmina" : "Rybno",
        "us" : [ "Urząd Skarbowy w Działdowie" ]
      } ]
    }, {
      "powiat" : "elbląski",
      "gminy" : [ {
        "gmina" : "Elbląg",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      }, {
        "gmina" : "Godkowo",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      }, {
        "gmina" : "Gronowo Elbląskie",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      }, {
        "gmina" : "Markusy",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      }, {
        "gmina" : "Milejewo",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      }, {
        "gmina" : "Młynary",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      }, {
        "gmina" : "Pasłęk",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      }, {
        "gmina" : "Rychliki",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      }, {
        "gmina" : "Tolkmicko",
        "us" : [ "Urząd Skarbowy w Elblągu" ]
      } ]
    }, {
      "powiat" : "ełcki",
      "gminy" : [ {
        "gmina" : "Ełk",
        "us" : [ "Urząd Skarbowy w Ełku" ]
      }, {
        "gmina" : "Kalinowo",
        "us" : [ "Urząd Skarbowy w Ełku" ]
      }, {
        "gmina" : "Prostki",
        "us" : [ "Urząd Skarbowy w Ełku" ]
      }, {
        "gmina" : "Stare Juchy",
        "us" : [ "Urząd Skarbowy w Ełku" ]
      } ]
    }, {
      "powiat" : "giżycki",
      "gminy" : [ {
        "gmina" : "Giżycko",
        "us" : [ "Urząd Skarbowy w Giżycku" ]
      }, {
        "gmina" : "Kruklanki",
        "us" : [ "Urząd Skarbowy w Giżycku" ]
      }, {
        "gmina" : "Miłki",
        "us" : [ "Urząd Skarbowy w Giżycku" ]
      }, {
        "gmina" : "Ryn",
        "us" : [ "Urząd Skarbowy w Giżycku" ]
      }, {
        "gmina" : "Wydminy",
        "us" : [ "Urząd Skarbowy w Giżycku" ]
      } ]
    }, {
      "powiat" : "gołdapski",
      "gminy" : [ {
        "gmina" : "Banie Mazurskie",
        "us" : [ "Urząd Skarbowy w Olecku" ]
      }, {
        "gmina" : "Dubeninki",
        "us" : [ "Urząd Skarbowy w Olecku" ]
      }, {
        "gmina" : "Gołdap",
        "us" : [ "Urząd Skarbowy w Olecku" ]
      } ]
    }, {
      "powiat" : "iławski",
      "gminy" : [ {
        "gmina" : "Iława",
        "us" : [ "Urząd Skarbowy w Iławie" ]
      }, {
        "gmina" : "Kisielice",
        "us" : [ "Urząd Skarbowy w Iławie" ]
      }, {
        "gmina" : "Lubawa",
        "us" : [ "Urząd Skarbowy w Iławie" ]
      }, {
        "gmina" : "Susz",
        "us" : [ "Urząd Skarbowy w Iławie" ]
      }, {
        "gmina" : "Zalewo",
        "us" : [ "Urząd Skarbowy w Iławie" ]
      } ]
    }, {
      "powiat" : "kętrzyński",
      "gminy" : [ {
        "gmina" : "Barciany",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      }, {
        "gmina" : "Korsze",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      }, {
        "gmina" : "Kętrzyn",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      }, {
        "gmina" : "Reszel",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      }, {
        "gmina" : "Srokowo",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      } ]
    }, {
      "powiat" : "lidzbarski",
      "gminy" : [ {
        "gmina" : "Kiwity",
        "us" : [ "Urząd Skarbowy w Bartoszycach" ]
      }, {
        "gmina" : "Lidzbark Warmiński",
        "us" : [ "Urząd Skarbowy w Bartoszycach" ]
      }, {
        "gmina" : "Lubomino",
        "us" : [ "Urząd Skarbowy w Bartoszycach" ]
      }, {
        "gmina" : "Orneta",
        "us" : [ "Urząd Skarbowy w Bartoszycach" ]
      } ]
    }, {
      "powiat" : "mrągowski",
      "gminy" : [ {
        "gmina" : "Mikołajki",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      }, {
        "gmina" : "Mrągowo",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      }, {
        "gmina" : "Piecki",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      }, {
        "gmina" : "Sorkwity",
        "us" : [ "Urząd Skarbowy w Kętrzynie" ]
      } ]
    }, {
      "powiat" : "nidzicki",
      "gminy" : [ {
        "gmina" : "Janowiec Kościelny",
        "us" : [ "Urząd Skarbowy w Nidzicy" ]
      }, {
        "gmina" : "Janowo",
        "us" : [ "Urząd Skarbowy w Nidzicy" ]
      }, {
        "gmina" : "Kozłowo",
        "us" : [ "Urząd Skarbowy w Nidzicy" ]
      }, {
        "gmina" : "Nidzica",
        "us" : [ "Urząd Skarbowy w Nidzicy" ]
      } ]
    }, {
      "powiat" : "nowomiejski",
      "gminy" : [ {
        "gmina" : "Biskupiec",
        "us" : [ "Urząd Skarbowy w Nowym Mieście Lubawskim" ]
      }, {
        "gmina" : "Grodziczno",
        "us" : [ "Urząd Skarbowy w Nowym Mieście Lubawskim" ]
      }, {
        "gmina" : "Kurzętnik",
        "us" : [ "Urząd Skarbowy w Nowym Mieście Lubawskim" ]
      }, {
        "gmina" : "Nowe Miasto Lubawskie",
        "us" : [ "Urząd Skarbowy w Nowym Mieście Lubawskim" ]
      } ]
    }, {
      "powiat" : "olecki",
      "gminy" : [ {
        "gmina" : "Kowale Oleckie",
        "us" : [ "Urząd Skarbowy w Olecku" ]
      }, {
        "gmina" : "Olecko",
        "us" : [ "Urząd Skarbowy w Olecku" ]
      }, {
        "gmina" : "Wieliczki",
        "us" : [ "Urząd Skarbowy w Olecku" ]
      }, {
        "gmina" : "Świętajno",
        "us" : [ "Urząd Skarbowy w Olecku" ]
      } ]
    }, {
      "powiat" : "olsztyński",
      "gminy" : [ {
        "gmina" : "Barczewo",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Biskupiec",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Dobre Miasto",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Dywity",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Gietrzwałd",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Jeziorany",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Jonkowo",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Kolno",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Olsztynek",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Purda",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Stawiguda",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      }, {
        "gmina" : "Świątki",
        "us" : [ "Urząd Skarbowy w Olsztynie" ]
      } ]
    }, {
      "powiat" : "ostródzki",
      "gminy" : [ {
        "gmina" : "Dąbrówno",
        "us" : [ "Urząd Skarbowy w Ostródzie" ]
      }, {
        "gmina" : "Grunwald",
        "us" : [ "Urząd Skarbowy w Ostródzie" ]
      }, {
        "gmina" : "Małdyty",
        "us" : [ "Urząd Skarbowy w Ostródzie" ]
      }, {
        "gmina" : "Miłakowo",
        "us" : [ "Urząd Skarbowy w Ostródzie" ]
      }, {
        "gmina" : "Miłomłyn",
        "us" : [ "Urząd Skarbowy w Ostródzie" ]
      }, {
        "gmina" : "Morąg",
        "us" : [ "Urząd Skarbowy w Ostródzie" ]
      }, {
        "gmina" : "Ostróda",
        "us" : [ "Urząd Skarbowy w Ostródzie" ]
      }, {
        "gmina" : "Łukta",
        "us" : [ "Urząd Skarbowy w Ostródzie" ]
      } ]
    }, {
      "powiat" : "piski",
      "gminy" : [ {
        "gmina" : "Biała Piska",
        "us" : [ "Urząd Skarbowy w Piszu" ]
      }, {
        "gmina" : "Orzysz",
        "us" : [ "Urząd Skarbowy w Piszu" ]
      }, {
        "gmina" : "Pisz",
        "us" : [ "Urząd Skarbowy w Piszu" ]
      }, {
        "gmina" : "Ruciane-Nida",
        "us" : [ "Urząd Skarbowy w Piszu" ]
      } ]
    }, {
      "powiat" : "szczycieński",
      "gminy" : [ {
        "gmina" : "Dźwierzuty",
        "us" : [ "Urząd Skarbowy w Szczytnie" ]
      }, {
        "gmina" : "Jedwabno",
        "us" : [ "Urząd Skarbowy w Szczytnie" ]
      }, {
        "gmina" : "Pasym",
        "us" : [ "Urząd Skarbowy w Szczytnie" ]
      }, {
        "gmina" : "Rozogi",
        "us" : [ "Urząd Skarbowy w Szczytnie" ]
      }, {
        "gmina" : "Szczytno",
        "us" : [ "Urząd Skarbowy w Szczytnie" ]
      }, {
        "gmina" : "Wielbark",
        "us" : [ "Urząd Skarbowy w Szczytnie" ]
      }, {
        "gmina" : "Świętajno",
        "us" : [ "Urząd Skarbowy w Szczytnie" ]
      } ]
    }, {
      "powiat" : "węgorzewski",
      "gminy" : [ {
        "gmina" : "Budry",
        "us" : [ "Urząd Skarbowy w Giżycku" ]
      }, {
        "gmina" : "Pozezdrze",
        "us" : [ "Urząd Skarbowy w Giżycku" ]
      }, {
        "gmina" : "Węgorzewo",
        "us" : [ "Urząd Skarbowy w Giżycku" ]
      } ]
    } ]
  }, {
    "woj" : "wielkopolskie",
    "powiaty" : [ {
      "powiat" : "Kalisz",
      "gminy" : [ {
        "gmina" : "Kalisz",
        "us" : [ "Pierwszy Urząd Skarbowy w Kaliszu", "Drugi Urząd Skarbowy w Kaliszu" ]
      } ]
    }, {
      "powiat" : "Konin",
      "gminy" : [ {
        "gmina" : "Konin",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      } ]
    }, {
      "powiat" : "Leszno",
      "gminy" : [ {
        "gmina" : "Leszno",
        "us" : [ "Urząd Skarbowy w Lesznie" ]
      } ]
    }, {
      "powiat" : "Poznań",
      "gminy" : [ {
        "gmina" : "Poznań",
        "us" : [ "Urząd Skarbowy Poznań-Grunwald", "Urząd Skarbowy Poznań-Jeżyce", "Urząd Skarbowy Poznań-Nowe Miasto", "Urząd Skarbowy Poznań-Wilda", "Urząd Skarbowy Poznań-Winogrady" ]
      } ]
    }, {
      "powiat" : "chodzieski",
      "gminy" : [ {
        "gmina" : "Budzyń",
        "us" : [ "Urząd Skarbowy w Chodzieży" ]
      }, {
        "gmina" : "Chodzież",
        "us" : [ "Urząd Skarbowy w Chodzieży" ]
      }, {
        "gmina" : "Margonin",
        "us" : [ "Urząd Skarbowy w Chodzieży" ]
      }, {
        "gmina" : "Szamocin",
        "us" : [ "Urząd Skarbowy w Chodzieży" ]
      } ]
    }, {
      "powiat" : "czarnkowsko-trzcianecki",
      "gminy" : [ {
        "gmina" : "Czarnków",
        "us" : [ "Urząd Skarbowy w Czarnkowie" ]
      }, {
        "gmina" : "Drawsko",
        "us" : [ "Urząd Skarbowy w Czarnkowie" ]
      }, {
        "gmina" : "Krzyż Wielkopolski",
        "us" : [ "Urząd Skarbowy w Czarnkowie" ]
      }, {
        "gmina" : "Lubasz",
        "us" : [ "Urząd Skarbowy w Czarnkowie" ]
      }, {
        "gmina" : "Połajewo",
        "us" : [ "Urząd Skarbowy w Czarnkowie" ]
      }, {
        "gmina" : "Trzcianka",
        "us" : [ "Urząd Skarbowy w Czarnkowie" ]
      }, {
        "gmina" : "Wieleń",
        "us" : [ "Urząd Skarbowy w Czarnkowie" ]
      } ]
    }, {
      "powiat" : "gnieźnieński",
      "gminy" : [ {
        "gmina" : "Czerniejewo",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      }, {
        "gmina" : "Gniezno",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      }, {
        "gmina" : "Kiszkowo",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      }, {
        "gmina" : "Kłecko",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      }, {
        "gmina" : "Mieleszyn",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      }, {
        "gmina" : "Niechanowo",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      }, {
        "gmina" : "Trzemeszno",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      }, {
        "gmina" : "Witkowo",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      }, {
        "gmina" : "Łubowo",
        "us" : [ "Urząd Skarbowy w Gnieźnie" ]
      } ]
    }, {
      "powiat" : "gostyński",
      "gminy" : [ {
        "gmina" : "Borek Wielkopolski",
        "us" : [ "Urząd Skarbowy w Gostyniu" ]
      }, {
        "gmina" : "Gostyń",
        "us" : [ "Urząd Skarbowy w Gostyniu" ]
      }, {
        "gmina" : "Krobia",
        "us" : [ "Urząd Skarbowy w Gostyniu" ]
      }, {
        "gmina" : "Piaski",
        "us" : [ "Urząd Skarbowy w Gostyniu" ]
      }, {
        "gmina" : "Pogorzela",
        "us" : [ "Urząd Skarbowy w Gostyniu" ]
      }, {
        "gmina" : "Poniec",
        "us" : [ "Urząd Skarbowy w Gostyniu" ]
      }, {
        "gmina" : "Pępowo",
        "us" : [ "Urząd Skarbowy w Gostyniu" ]
      } ]
    }, {
      "powiat" : "grodziski",
      "gminy" : [ {
        "gmina" : "Granowo",
        "us" : [ "Urząd Skarbowy w Grodzisku Wielkopolskim" ]
      }, {
        "gmina" : "Grodzisk Wielkopolski",
        "us" : [ "Urząd Skarbowy w Grodzisku Wielkopolskim" ]
      }, {
        "gmina" : "Kamieniec",
        "us" : [ "Urząd Skarbowy w Grodzisku Wielkopolskim" ]
      }, {
        "gmina" : "Rakoniewice",
        "us" : [ "Urząd Skarbowy w Grodzisku Wielkopolskim" ]
      }, {
        "gmina" : "Wielichowo",
        "us" : [ "Urząd Skarbowy w Grodzisku Wielkopolskim" ]
      } ]
    }, {
      "powiat" : "jarociński",
      "gminy" : [ {
        "gmina" : "Jaraczewo",
        "us" : [ "Urząd Skarbowy w Jarocinie" ]
      }, {
        "gmina" : "Jarocin",
        "us" : [ "Urząd Skarbowy w Jarocinie" ]
      }, {
        "gmina" : "Kotlin",
        "us" : [ "Urząd Skarbowy w Jarocinie" ]
      }, {
        "gmina" : "Żerków",
        "us" : [ "Urząd Skarbowy w Jarocinie" ]
      } ]
    }, {
      "powiat" : "kaliski",
      "gminy" : [ {
        "gmina" : "Blizanów",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Brzeziny",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Ceków-Kolonia",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Godziesze Wielkie",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Koźminek",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Lisków",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Mycielin",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Opatówek",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Stawiszyn",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Szczytniki",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      }, {
        "gmina" : "Żelazków",
        "us" : [ "Drugi Urząd Skarbowy w Kaliszu" ]
      } ]
    }, {
      "powiat" : "kolski",
      "gminy" : [ {
        "gmina" : "Babiak",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Chodów",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Dąbie",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Grzegorzew",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Koło",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Kościelec",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Kłodawa",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Olszówka",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Osiek Mały",
        "us" : [ "Urząd Skarbowy w Kole" ]
      }, {
        "gmina" : "Przedecz",
        "us" : [ "Urząd Skarbowy w Kole" ]
      } ]
    }, {
      "powiat" : "koniński",
      "gminy" : [ {
        "gmina" : "Golina",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Grodziec",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Kazimierz Biskupi",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Kleczew",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Kramsk",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Krzymów",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Rychwał",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Rzgów",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Skulsk",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Sompolno",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Stare Miasto",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Wierzbinek",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Wilczyn",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      }, {
        "gmina" : "Ślesin",
        "us" : [ "Urząd Skarbowy w Koninie" ]
      } ]
    }, {
      "powiat" : "kościański",
      "gminy" : [ {
        "gmina" : "Czempiń",
        "us" : [ "Urząd Skarbowy w Kościanie" ]
      }, {
        "gmina" : "Kościan",
        "us" : [ "Urząd Skarbowy w Kościanie" ]
      }, {
        "gmina" : "Krzywiń",
        "us" : [ "Urząd Skarbowy w Kościanie" ]
      }, {
        "gmina" : "Śmigiel",
        "us" : [ "Urząd Skarbowy w Kościanie" ]
      } ]
    }, {
      "powiat" : "krotoszyński",
      "gminy" : [ {
        "gmina" : "Kobylin",
        "us" : [ "Urząd Skarbowy w Krotoszynie" ]
      }, {
        "gmina" : "Koźmin Wielkopolski",
        "us" : [ "Urząd Skarbowy w Krotoszynie" ]
      }, {
        "gmina" : "Krotoszyn",
        "us" : [ "Urząd Skarbowy w Krotoszynie" ]
      }, {
        "gmina" : "Rozdrażew",
        "us" : [ "Urząd Skarbowy w Krotoszynie" ]
      }, {
        "gmina" : "Sulmierzyce",
        "us" : [ "Urząd Skarbowy w Krotoszynie" ]
      }, {
        "gmina" : "Zduny",
        "us" : [ "Urząd Skarbowy w Krotoszynie" ]
      } ]
    }, {
      "powiat" : "kępiński",
      "gminy" : [ {
        "gmina" : "Baranów",
        "us" : [ "Urząd Skarbowy w Kępnie" ]
      }, {
        "gmina" : "Bralin",
        "us" : [ "Urząd Skarbowy w Kępnie" ]
      }, {
        "gmina" : "Kępno",
        "us" : [ "Urząd Skarbowy w Kępnie" ]
      }, {
        "gmina" : "Perzów",
        "us" : [ "Urząd Skarbowy w Kępnie" ]
      }, {
        "gmina" : "Rychtal",
        "us" : [ "Urząd Skarbowy w Kępnie" ]
      }, {
        "gmina" : "Trzcinica",
        "us" : [ "Urząd Skarbowy w Kępnie" ]
      }, {
        "gmina" : "Łęka Opatowska",
        "us" : [ "Urząd Skarbowy w Kępnie" ]
      } ]
    }, {
      "powiat" : "leszczyński",
      "gminy" : [ {
        "gmina" : "Krzemieniewo",
        "us" : [ "Urząd Skarbowy w Lesznie" ]
      }, {
        "gmina" : "Lipno",
        "us" : [ "Urząd Skarbowy w Lesznie" ]
      }, {
        "gmina" : "Osieczna",
        "us" : [ "Urząd Skarbowy w Lesznie" ]
      }, {
        "gmina" : "Rydzyna",
        "us" : [ "Urząd Skarbowy w Lesznie" ]
      }, {
        "gmina" : "Wijewo",
        "us" : [ "Urząd Skarbowy w Lesznie" ]
      }, {
        "gmina" : "Włoszakowice",
        "us" : [ "Urząd Skarbowy w Lesznie" ]
      }, {
        "gmina" : "Święciechowa",
        "us" : [ "Urząd Skarbowy w Lesznie" ]
      } ]
    }, {
      "powiat" : "międzychodzki",
      "gminy" : [ {
        "gmina" : "Chrzypsko Wielkie",
        "us" : [ "Urząd Skarbowy w Międzychodzie" ]
      }, {
        "gmina" : "Kwilcz",
        "us" : [ "Urząd Skarbowy w Międzychodzie" ]
      }, {
        "gmina" : "Międzychód",
        "us" : [ "Urząd Skarbowy w Międzychodzie" ]
      }, {
        "gmina" : "Sieraków",
        "us" : [ "Urząd Skarbowy w Międzychodzie" ]
      } ]
    }, {
      "powiat" : "nowotomyski",
      "gminy" : [ {
        "gmina" : "Kuślin",
        "us" : [ "Urząd Skarbowy w Nowym Tomyślu" ]
      }, {
        "gmina" : "Lwówek",
        "us" : [ "Urząd Skarbowy w Nowym Tomyślu" ]
      }, {
        "gmina" : "Miedzichowo",
        "us" : [ "Urząd Skarbowy w Nowym Tomyślu" ]
      }, {
        "gmina" : "Nowy Tomyśl",
        "us" : [ "Urząd Skarbowy w Nowym Tomyślu" ]
      }, {
        "gmina" : "Opalenica",
        "us" : [ "Urząd Skarbowy w Nowym Tomyślu" ]
      }, {
        "gmina" : "Zbąszyń",
        "us" : [ "Urząd Skarbowy w Nowym Tomyślu" ]
      } ]
    }, {
      "powiat" : "obornicki",
      "gminy" : [ {
        "gmina" : "Oborniki",
        "us" : [ "Urząd Skarbowy w Obornikach" ]
      }, {
        "gmina" : "Rogoźno",
        "us" : [ "Urząd Skarbowy w Obornikach" ]
      }, {
        "gmina" : "Ryczywół",
        "us" : [ "Urząd Skarbowy w Obornikach" ]
      } ]
    }, {
      "powiat" : "ostrowski",
      "gminy" : [ {
        "gmina" : "Nowe Skalmierzyce",
        "us" : [ "Urząd Skarbowy w Ostrowie Wielkopolskim" ]
      }, {
        "gmina" : "Odolanów",
        "us" : [ "Urząd Skarbowy w Ostrowie Wielkopolskim" ]
      }, {
        "gmina" : "Ostrów Wielkopolski",
        "us" : [ "Urząd Skarbowy w Ostrowie Wielkopolskim" ]
      }, {
        "gmina" : "Przygodzice",
        "us" : [ "Urząd Skarbowy w Ostrowie Wielkopolskim" ]
      }, {
        "gmina" : "Raszków",
        "us" : [ "Urząd Skarbowy w Ostrowie Wielkopolskim" ]
      }, {
        "gmina" : "Sieroszewice",
        "us" : [ "Urząd Skarbowy w Ostrowie Wielkopolskim" ]
      }, {
        "gmina" : "Sośnie",
        "us" : [ "Urząd Skarbowy w Ostrowie Wielkopolskim" ]
      } ]
    }, {
      "powiat" : "ostrzeszowski",
      "gminy" : [ {
        "gmina" : "Czajków",
        "us" : [ "Urząd Skarbowy w Ostrzeszowie" ]
      }, {
        "gmina" : "Doruchów",
        "us" : [ "Urząd Skarbowy w Ostrzeszowie" ]
      }, {
        "gmina" : "Grabów nad Prosną",
        "us" : [ "Urząd Skarbowy w Ostrzeszowie" ]
      }, {
        "gmina" : "Kobyla Góra",
        "us" : [ "Urząd Skarbowy w Ostrzeszowie" ]
      }, {
        "gmina" : "Kraszewice",
        "us" : [ "Urząd Skarbowy w Ostrzeszowie" ]
      }, {
        "gmina" : "Mikstat",
        "us" : [ "Urząd Skarbowy w Ostrzeszowie" ]
      }, {
        "gmina" : "Ostrzeszów",
        "us" : [ "Urząd Skarbowy w Ostrzeszowie" ]
      } ]
    }, {
      "powiat" : "pilski",
      "gminy" : [ {
        "gmina" : "Białośliwie",
        "us" : [ "Urząd Skarbowy w Pile" ]
      }, {
        "gmina" : "Kaczory",
        "us" : [ "Urząd Skarbowy w Pile" ]
      }, {
        "gmina" : "Miasteczko Krajeńskie",
        "us" : [ "Urząd Skarbowy w Pile" ]
      }, {
        "gmina" : "Piła",
        "us" : [ "Urząd Skarbowy w Pile" ]
      }, {
        "gmina" : "Szydłowo",
        "us" : [ "Urząd Skarbowy w Pile" ]
      }, {
        "gmina" : "Ujście",
        "us" : [ "Urząd Skarbowy w Pile" ]
      }, {
        "gmina" : "Wyrzysk",
        "us" : [ "Urząd Skarbowy w Pile" ]
      }, {
        "gmina" : "Wysoka",
        "us" : [ "Urząd Skarbowy w Pile" ]
      }, {
        "gmina" : "Łobżenica",
        "us" : [ "Urząd Skarbowy w Pile" ]
      } ]
    }, {
      "powiat" : "pleszewski",
      "gminy" : [ {
        "gmina" : "Chocz",
        "us" : [ "Urząd Skarbowy w Pleszewie" ]
      }, {
        "gmina" : "Czermin",
        "us" : [ "Urząd Skarbowy w Pleszewie" ]
      }, {
        "gmina" : "Dobrzyca",
        "us" : [ "Urząd Skarbowy w Pleszewie" ]
      }, {
        "gmina" : "Gizałki",
        "us" : [ "Urząd Skarbowy w Pleszewie" ]
      }, {
        "gmina" : "Gołuchów",
        "us" : [ "Urząd Skarbowy w Pleszewie" ]
      }, {
        "gmina" : "Pleszew",
        "us" : [ "Urząd Skarbowy w Pleszewie" ]
      } ]
    }, {
      "powiat" : "poznański",
      "gminy" : [ {
        "gmina" : "Buk",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Czerwonak",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Dopiewo",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Kleszczewo",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Komorniki",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Kostrzyn",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Kórnik",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Luboń",
        "us" : [ "Urząd Skarbowy Poznań-Wilda" ]
      }, {
        "gmina" : "Mosina",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Murowana Goślina",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Pobiedziska",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Puszczykowo",
        "us" : [ "Urząd Skarbowy Poznań-Wilda" ]
      }, {
        "gmina" : "Rokietnica",
        "us" : [ "Urząd Skarbowy Poznań-Jeżyce" ]
      }, {
        "gmina" : "Stęszew",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Suchy Las",
        "us" : [ "Urząd Skarbowy Poznań-Winogrady" ]
      }, {
        "gmina" : "Swarzędz",
        "us" : [ "Pierwszy Urząd Skarbowy w Poznaniu" ]
      }, {
        "gmina" : "Tarnowo Podgórne",
        "us" : [ "Urząd Skarbowy Poznań-Jeżyce" ]
      } ]
    }, {
      "powiat" : "rawicki",
      "gminy" : [ {
        "gmina" : "Bojanowo",
        "us" : [ "Urząd Skarbowy w Rawiczu" ]
      }, {
        "gmina" : "Jutrosin",
        "us" : [ "Urząd Skarbowy w Rawiczu" ]
      }, {
        "gmina" : "Miejska Górka",
        "us" : [ "Urząd Skarbowy w Rawiczu" ]
      }, {
        "gmina" : "Pakosław",
        "us" : [ "Urząd Skarbowy w Rawiczu" ]
      }, {
        "gmina" : "Rawicz",
        "us" : [ "Urząd Skarbowy w Rawiczu" ]
      } ]
    }, {
      "powiat" : "szamotulski",
      "gminy" : [ {
        "gmina" : "Duszniki",
        "us" : [ "Urząd Skarbowy w Szamotułach" ]
      }, {
        "gmina" : "Kaźmierz",
        "us" : [ "Urząd Skarbowy w Szamotułach" ]
      }, {
        "gmina" : "Obrzycko",
        "us" : [ "Urząd Skarbowy w Szamotułach" ]
      }, {
        "gmina" : "Ostroróg",
        "us" : [ "Urząd Skarbowy w Szamotułach" ]
      }, {
        "gmina" : "Pniewy",
        "us" : [ "Urząd Skarbowy w Szamotułach" ]
      }, {
        "gmina" : "Szamotuły",
        "us" : [ "Urząd Skarbowy w Szamotułach" ]
      }, {
        "gmina" : "Wronki",
        "us" : [ "Urząd Skarbowy w Szamotułach" ]
      } ]
    }, {
      "powiat" : "słupecki",
      "gminy" : [ {
        "gmina" : "Lądek",
        "us" : [ "Urząd Skarbowy w Słupcy" ]
      }, {
        "gmina" : "Orchowo",
        "us" : [ "Urząd Skarbowy w Słupcy" ]
      }, {
        "gmina" : "Ostrowite",
        "us" : [ "Urząd Skarbowy w Słupcy" ]
      }, {
        "gmina" : "Powidz",
        "us" : [ "Urząd Skarbowy w Słupcy" ]
      }, {
        "gmina" : "Strzałkowo",
        "us" : [ "Urząd Skarbowy w Słupcy" ]
      }, {
        "gmina" : "Słupca",
        "us" : [ "Urząd Skarbowy w Słupcy" ]
      }, {
        "gmina" : "Zagórów",
        "us" : [ "Urząd Skarbowy w Słupcy" ]
      } ]
    }, {
      "powiat" : "turecki",
      "gminy" : [ {
        "gmina" : "Brudzew",
        "us" : [ "Urząd Skarbowy w Turku" ]
      }, {
        "gmina" : "Dobra",
        "us" : [ "Urząd Skarbowy w Turku" ]
      }, {
        "gmina" : "Kawęczyn",
        "us" : [ "Urząd Skarbowy w Turku" ]
      }, {
        "gmina" : "Malanów",
        "us" : [ "Urząd Skarbowy w Turku" ]
      }, {
        "gmina" : "Przykona",
        "us" : [ "Urząd Skarbowy w Turku" ]
      }, {
        "gmina" : "Tuliszków",
        "us" : [ "Urząd Skarbowy w Turku" ]
      }, {
        "gmina" : "Turek",
        "us" : [ "Urząd Skarbowy w Turku" ]
      }, {
        "gmina" : "Władysławów",
        "us" : [ "Urząd Skarbowy w Turku" ]
      } ]
    }, {
      "powiat" : "wolsztyński",
      "gminy" : [ {
        "gmina" : "Przemęt",
        "us" : [ "Urząd Skarbowy w Wolsztynie" ]
      }, {
        "gmina" : "Siedlec",
        "us" : [ "Urząd Skarbowy w Wolsztynie" ]
      }, {
        "gmina" : "Wolsztyn",
        "us" : [ "Urząd Skarbowy w Wolsztynie" ]
      } ]
    }, {
      "powiat" : "wrzesiński",
      "gminy" : [ {
        "gmina" : "Kołaczkowo",
        "us" : [ "Urząd Skarbowy we Wrześni" ]
      }, {
        "gmina" : "Miłosław",
        "us" : [ "Urząd Skarbowy we Wrześni" ]
      }, {
        "gmina" : "Nekla",
        "us" : [ "Urząd Skarbowy we Wrześni" ]
      }, {
        "gmina" : "Pyzdry",
        "us" : [ "Urząd Skarbowy we Wrześni" ]
      }, {
        "gmina" : "Września",
        "us" : [ "Urząd Skarbowy we Wrześni" ]
      } ]
    }, {
      "powiat" : "wągrowiecki",
      "gminy" : [ {
        "gmina" : "Damasławek",
        "us" : [ "Urząd Skarbowy w Wągrowcu" ]
      }, {
        "gmina" : "Gołańcz",
        "us" : [ "Urząd Skarbowy w Wągrowcu" ]
      }, {
        "gmina" : "Mieścisko",
        "us" : [ "Urząd Skarbowy w Wągrowcu" ]
      }, {
        "gmina" : "Skoki",
        "us" : [ "Urząd Skarbowy w Wągrowcu" ]
      }, {
        "gmina" : "Wapno",
        "us" : [ "Urząd Skarbowy w Wągrowcu" ]
      }, {
        "gmina" : "Wągrowiec",
        "us" : [ "Urząd Skarbowy w Wągrowcu" ]
      } ]
    }, {
      "powiat" : "złotowski",
      "gminy" : [ {
        "gmina" : "Jastrowie",
        "us" : [ "Urząd Skarbowy w Złotowie" ]
      }, {
        "gmina" : "Krajenka",
        "us" : [ "Urząd Skarbowy w Złotowie" ]
      }, {
        "gmina" : "Lipka",
        "us" : [ "Urząd Skarbowy w Złotowie" ]
      }, {
        "gmina" : "Okonek",
        "us" : [ "Urząd Skarbowy w Złotowie" ]
      }, {
        "gmina" : "Tarnówka",
        "us" : [ "Urząd Skarbowy w Złotowie" ]
      }, {
        "gmina" : "Zakrzewo",
        "us" : [ "Urząd Skarbowy w Złotowie" ]
      }, {
        "gmina" : "Złotów",
        "us" : [ "Urząd Skarbowy w Złotowie" ]
      } ]
    }, {
      "powiat" : "średzki",
      "gminy" : [ {
        "gmina" : "Dominowo",
        "us" : [ "Urząd Skarbowy w Środzie Wielkopolskiej" ]
      }, {
        "gmina" : "Krzykosy",
        "us" : [ "Urząd Skarbowy w Środzie Wielkopolskiej" ]
      }, {
        "gmina" : "Nowe Miasto nad Wartą",
        "us" : [ "Urząd Skarbowy w Środzie Wielkopolskiej" ]
      }, {
        "gmina" : "Zaniemyśl",
        "us" : [ "Urząd Skarbowy w Środzie Wielkopolskiej" ]
      }, {
        "gmina" : "Środa Wielkopolska",
        "us" : [ "Urząd Skarbowy w Środzie Wielkopolskiej" ]
      } ]
    }, {
      "powiat" : "śremski",
      "gminy" : [ {
        "gmina" : "Brodnica",
        "us" : [ "Urząd Skarbowy w Śremie" ]
      }, {
        "gmina" : "Dolsk",
        "us" : [ "Urząd Skarbowy w Śremie" ]
      }, {
        "gmina" : "Książ Wielkopolski",
        "us" : [ "Urząd Skarbowy w Śremie" ]
      }, {
        "gmina" : "Śrem",
        "us" : [ "Urząd Skarbowy w Śremie" ]
      } ]
    } ]
  }, {
    "woj" : "zachodniopomorskie",
    "powiaty" : [ {
      "powiat" : "Koszalin",
      "gminy" : [ {
        "gmina" : "Koszalin",
        "us" : [ "Pierwszy Urząd Skarbowy w Koszalinie", "Drugi Urząd Skarbowy w Koszalinie" ]
      } ]
    }, {
      "powiat" : "Szczecin",
      "gminy" : [ {
        "gmina" : "Szczecin",
        "us" : [ "Pierwszy Urząd Skarbowy w Szczecinie", "Drugi Urząd Skarbowy w Szczecinie", "Trzeci Urząd Skarbowy w Szczecinie" ]
      } ]
    }, {
      "powiat" : "białogardzki",
      "gminy" : [ {
        "gmina" : "Białogard",
        "us" : [ "Urząd Skarbowy w Białogardzie" ]
      }, {
        "gmina" : "Karlino",
        "us" : [ "Urząd Skarbowy w Białogardzie" ]
      }, {
        "gmina" : "Tychowo",
        "us" : [ "Urząd Skarbowy w Białogardzie" ]
      } ]
    }, {
      "powiat" : "choszczeński",
      "gminy" : [ {
        "gmina" : "Bierzwnik",
        "us" : [ "Urząd Skarbowy w Choszcznie" ]
      }, {
        "gmina" : "Choszczno",
        "us" : [ "Urząd Skarbowy w Choszcznie" ]
      }, {
        "gmina" : "Drawno",
        "us" : [ "Urząd Skarbowy w Choszcznie" ]
      }, {
        "gmina" : "Krzęcin",
        "us" : [ "Urząd Skarbowy w Choszcznie" ]
      }, {
        "gmina" : "Pełczyce",
        "us" : [ "Urząd Skarbowy w Choszcznie" ]
      }, {
        "gmina" : "Recz",
        "us" : [ "Urząd Skarbowy w Choszcznie" ]
      } ]
    }, {
      "powiat" : "drawski",
      "gminy" : [ {
        "gmina" : "Czaplinek",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Drawsko Pomorskie",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Kalisz Pomorski",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Ostrowice",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Wierzchowo",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Złocieniec",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      } ]
    }, {
      "powiat" : "goleniowski",
      "gminy" : [ {
        "gmina" : "Goleniów",
        "us" : [ "Urząd Skarbowy w Goleniowie" ]
      }, {
        "gmina" : "Maszewo",
        "us" : [ "Urząd Skarbowy w Goleniowie" ]
      }, {
        "gmina" : "Nowogard",
        "us" : [ "Urząd Skarbowy w Goleniowie" ]
      }, {
        "gmina" : "Osina",
        "us" : [ "Urząd Skarbowy w Goleniowie" ]
      }, {
        "gmina" : "Przybiernów",
        "us" : [ "Urząd Skarbowy w Goleniowie" ]
      }, {
        "gmina" : "Stepnica",
        "us" : [ "Urząd Skarbowy w Goleniowie" ]
      } ]
    }, {
      "powiat" : "gryficki",
      "gminy" : [ {
        "gmina" : "Brojce",
        "us" : [ "Urząd Skarbowy w Gryficach" ]
      }, {
        "gmina" : "Gryfice",
        "us" : [ "Urząd Skarbowy w Gryficach" ]
      }, {
        "gmina" : "Karnice",
        "us" : [ "Urząd Skarbowy w Gryficach" ]
      }, {
        "gmina" : "Płoty",
        "us" : [ "Urząd Skarbowy w Gryficach" ]
      }, {
        "gmina" : "Rewal",
        "us" : [ "Urząd Skarbowy w Gryficach" ]
      }, {
        "gmina" : "Trzebiatów",
        "us" : [ "Urząd Skarbowy w Gryficach" ]
      } ]
    }, {
      "powiat" : "gryfiński",
      "gminy" : [ {
        "gmina" : "Banie",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      }, {
        "gmina" : "Cedynia",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      }, {
        "gmina" : "Chojna",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      }, {
        "gmina" : "Gryfino",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      }, {
        "gmina" : "Mieszkowice",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      }, {
        "gmina" : "Moryń",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      }, {
        "gmina" : "Stare Czarnowo",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      }, {
        "gmina" : "Trzcińsko-Zdrój",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      }, {
        "gmina" : "Widuchowa",
        "us" : [ "Urząd Skarbowy w Gryfinie" ]
      } ]
    }, {
      "powiat" : "kamieński",
      "gminy" : [ {
        "gmina" : "Dziwnów",
        "us" : [ "Urząd Skarbowy w Kamieniu Pomorskim" ]
      }, {
        "gmina" : "Golczewo",
        "us" : [ "Urząd Skarbowy w Kamieniu Pomorskim" ]
      }, {
        "gmina" : "Kamień Pomorski",
        "us" : [ "Urząd Skarbowy w Kamieniu Pomorskim" ]
      }, {
        "gmina" : "Międzyzdroje",
        "us" : [ "Urząd Skarbowy w Kamieniu Pomorskim" ]
      }, {
        "gmina" : "Wolin",
        "us" : [ "Urząd Skarbowy w Kamieniu Pomorskim" ]
      }, {
        "gmina" : "Świerzno",
        "us" : [ "Urząd Skarbowy w Kamieniu Pomorskim" ]
      } ]
    }, {
      "powiat" : "koszaliński",
      "gminy" : [ {
        "gmina" : "Biesiekierz",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Bobolice",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Będzino",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Manowo",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Mielno",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Polanów",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Sianów",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Świeszyno",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      } ]
    }, {
      "powiat" : "kołobrzeski",
      "gminy" : [ {
        "gmina" : "Dygowo",
        "us" : [ "Urząd Skarbowy w Kołobrzegu" ]
      }, {
        "gmina" : "Gościno",
        "us" : [ "Urząd Skarbowy w Kołobrzegu" ]
      }, {
        "gmina" : "Kołobrzeg",
        "us" : [ "Urząd Skarbowy w Kołobrzegu" ]
      }, {
        "gmina" : "Rymań",
        "us" : [ "Urząd Skarbowy w Kołobrzegu" ]
      }, {
        "gmina" : "Siemyśl",
        "us" : [ "Urząd Skarbowy w Kołobrzegu" ]
      }, {
        "gmina" : "Ustronie Morskie",
        "us" : [ "Urząd Skarbowy w Kołobrzegu" ]
      } ]
    }, {
      "powiat" : "myśliborski",
      "gminy" : [ {
        "gmina" : "Barlinek",
        "us" : [ "Urząd Skarbowy w Myśliborzu" ]
      }, {
        "gmina" : "Boleszkowice",
        "us" : [ "Urząd Skarbowy w Myśliborzu" ]
      }, {
        "gmina" : "Dębno",
        "us" : [ "Urząd Skarbowy w Myśliborzu" ]
      }, {
        "gmina" : "Myślibórz",
        "us" : [ "Urząd Skarbowy w Myśliborzu" ]
      }, {
        "gmina" : "Nowogródek Pomorski",
        "us" : [ "Urząd Skarbowy w Myśliborzu" ]
      } ]
    }, {
      "powiat" : "policki",
      "gminy" : [ {
        "gmina" : "Dobra",
        "us" : [ "Pierwszy Urząd Skarbowy w Szczecinie" ]
      }, {
        "gmina" : "Kołbaskowo",
        "us" : [ "Pierwszy Urząd Skarbowy w Szczecinie" ]
      }, {
        "gmina" : "Nowe Warpno",
        "us" : [ "Pierwszy Urząd Skarbowy w Szczecinie" ]
      }, {
        "gmina" : "Police",
        "us" : [ "Pierwszy Urząd Skarbowy w Szczecinie" ]
      } ]
    }, {
      "powiat" : "pyrzycki",
      "gminy" : [ {
        "gmina" : "Bielice",
        "us" : [ "Urząd Skarbowy w Pyrzycach" ]
      }, {
        "gmina" : "Kozielice",
        "us" : [ "Urząd Skarbowy w Pyrzycach" ]
      }, {
        "gmina" : "Lipiany",
        "us" : [ "Urząd Skarbowy w Pyrzycach" ]
      }, {
        "gmina" : "Przelewice",
        "us" : [ "Urząd Skarbowy w Pyrzycach" ]
      }, {
        "gmina" : "Pyrzyce",
        "us" : [ "Urząd Skarbowy w Pyrzycach" ]
      }, {
        "gmina" : "Warnice",
        "us" : [ "Urząd Skarbowy w Pyrzycach" ]
      } ]
    }, {
      "powiat" : "stargardzki",
      "gminy" : [ {
        "gmina" : "Chociwel",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      }, {
        "gmina" : "Dobrzany",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      }, {
        "gmina" : "Dolice",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      }, {
        "gmina" : "Ińsko",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      }, {
        "gmina" : "Kobylanka",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      }, {
        "gmina" : "Marianowo",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      }, {
        "gmina" : "Stara Dąbrowa",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      }, {
        "gmina" : "Stargard",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      }, {
        "gmina" : "Suchań",
        "us" : [ "Urząd Skarbowy w Stargardzie" ]
      } ]
    }, {
      "powiat" : "szczecinecki",
      "gminy" : [ {
        "gmina" : "Barwice",
        "us" : [ "Urząd Skarbowy w Szczecinku" ]
      }, {
        "gmina" : "Biały Bór",
        "us" : [ "Urząd Skarbowy w Szczecinku" ]
      }, {
        "gmina" : "Borne Sulinowo",
        "us" : [ "Urząd Skarbowy w Szczecinku" ]
      }, {
        "gmina" : "Grzmiąca",
        "us" : [ "Urząd Skarbowy w Szczecinku" ]
      }, {
        "gmina" : "Szczecinek",
        "us" : [ "Urząd Skarbowy w Szczecinku" ]
      } ]
    }, {
      "powiat" : "sławieński",
      "gminy" : [ {
        "gmina" : "Darłowo",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Malechowo",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Postomino",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      }, {
        "gmina" : "Sławno",
        "us" : [ "Drugi Urząd Skarbowy w Koszalinie" ]
      } ]
    }, {
      "powiat" : "wałecki",
      "gminy" : [ {
        "gmina" : "Człopa",
        "us" : [ "Urząd Skarbowy w Wałczu" ]
      }, {
        "gmina" : "Mirosławiec",
        "us" : [ "Urząd Skarbowy w Wałczu" ]
      }, {
        "gmina" : "Tuczno",
        "us" : [ "Urząd Skarbowy w Wałczu" ]
      }, {
        "gmina" : "Wałcz",
        "us" : [ "Urząd Skarbowy w Wałczu" ]
      } ]
    }, {
      "powiat" : "łobeski",
      "gminy" : [ {
        "gmina" : "Dobra",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Radowo Małe",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Resko",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Węgorzyno",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      }, {
        "gmina" : "Łobez",
        "us" : [ "Urząd Skarbowy w Drawsku Pomorskim" ]
      } ]
    }, {
      "powiat" : "Świnoujście",
      "gminy" : [ {
        "gmina" : "Świnoujście",
        "us" : [ "Urząd Skarbowy w Świnoujściu" ]
      } ]
    }, {
      "powiat" : "świdwiński",
      "gminy" : [ {
        "gmina" : "Brzeżno",
        "us" : [ "Urząd Skarbowy w Białogardzie" ]
      }, {
        "gmina" : "Połczyn-Zdrój",
        "us" : [ "Urząd Skarbowy w Białogardzie" ]
      }, {
        "gmina" : "Rąbino",
        "us" : [ "Urząd Skarbowy w Białogardzie" ]
      }, {
        "gmina" : "Sławoborze",
        "us" : [ "Urząd Skarbowy w Białogardzie" ]
      }, {
        "gmina" : "Świdwin",
        "us" : [ "Urząd Skarbowy w Białogardzie" ]
      } ]
    } ]
  }, {
    "woj" : "łódzkie",
    "powiaty" : [ {
      "powiat" : "Piotrków Trybunalski",
      "gminy" : [ {
        "gmina" : "Piotrków Trybunalski",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      } ]
    }, {
      "powiat" : "Skierniewice",
      "gminy" : [ {
        "gmina" : "Skierniewice",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      } ]
    }, {
      "powiat" : "bełchatowski",
      "gminy" : [ {
        "gmina" : "Bełchatów",
        "us" : [ "Urząd Skarbowy w Bełchatowie" ]
      }, {
        "gmina" : "Drużbice",
        "us" : [ "Urząd Skarbowy w Bełchatowie" ]
      }, {
        "gmina" : "Kleszczów",
        "us" : [ "Urząd Skarbowy w Bełchatowie" ]
      }, {
        "gmina" : "Kluki",
        "us" : [ "Urząd Skarbowy w Bełchatowie" ]
      }, {
        "gmina" : "Rusiec",
        "us" : [ "Urząd Skarbowy w Bełchatowie" ]
      }, {
        "gmina" : "Szczerców",
        "us" : [ "Urząd Skarbowy w Bełchatowie" ]
      }, {
        "gmina" : "Zelów",
        "us" : [ "Urząd Skarbowy w Bełchatowie" ]
      } ]
    }, {
      "powiat" : "brzeziński",
      "gminy" : [ {
        "gmina" : "Brzeziny",
        "us" : [ "Urząd Skarbowy w Brzezinach" ]
      }, {
        "gmina" : "Dmosin",
        "us" : [ "Urząd Skarbowy w Brzezinach" ]
      }, {
        "gmina" : "Jeżów",
        "us" : [ "Urząd Skarbowy w Brzezinach" ]
      }, {
        "gmina" : "Rogów",
        "us" : [ "Urząd Skarbowy w Brzezinach" ]
      } ]
    }, {
      "powiat" : "kutnowski",
      "gminy" : [ {
        "gmina" : "Bedlno",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Dąbrowice",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Krośniewice",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Krzyżanów",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Kutno",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Nowe Ostrowy",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Oporów",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Strzelce",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Łanięta",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      }, {
        "gmina" : "Żychlin",
        "us" : [ "Urząd Skarbowy w Kutnie" ]
      } ]
    }, {
      "powiat" : "opoczyński",
      "gminy" : [ {
        "gmina" : "Białaczów",
        "us" : [ "Urząd Skarbowy w Opocznie" ]
      }, {
        "gmina" : "Drzewica",
        "us" : [ "Urząd Skarbowy w Opocznie" ]
      }, {
        "gmina" : "Mniszków",
        "us" : [ "Urząd Skarbowy w Opocznie" ]
      }, {
        "gmina" : "Opoczno",
        "us" : [ "Urząd Skarbowy w Opocznie" ]
      }, {
        "gmina" : "Paradyż",
        "us" : [ "Urząd Skarbowy w Opocznie" ]
      }, {
        "gmina" : "Poświętne",
        "us" : [ "Urząd Skarbowy w Opocznie" ]
      }, {
        "gmina" : "Sławno",
        "us" : [ "Urząd Skarbowy w Opocznie" ]
      }, {
        "gmina" : "Żarnów",
        "us" : [ "Urząd Skarbowy w Opocznie" ]
      } ]
    }, {
      "powiat" : "pabianicki",
      "gminy" : [ {
        "gmina" : "Dobroń",
        "us" : [ "Urząd Skarbowy w Pabianicach" ]
      }, {
        "gmina" : "Dłutów",
        "us" : [ "Urząd Skarbowy w Pabianicach" ]
      }, {
        "gmina" : "Konstantynów Łódzki",
        "us" : [ "Urząd Skarbowy w Pabianicach" ]
      }, {
        "gmina" : "Ksawerów",
        "us" : [ "Urząd Skarbowy w Pabianicach" ]
      }, {
        "gmina" : "Lutomiersk",
        "us" : [ "Urząd Skarbowy w Pabianicach" ]
      }, {
        "gmina" : "Pabianice",
        "us" : [ "Urząd Skarbowy w Pabianicach" ]
      } ]
    }, {
      "powiat" : "pajęczański",
      "gminy" : [ {
        "gmina" : "Działoszyn",
        "us" : [ "Urząd Skarbowy w Pajęcznie" ]
      }, {
        "gmina" : "Kiełczygłów",
        "us" : [ "Urząd Skarbowy w Pajęcznie" ]
      }, {
        "gmina" : "Nowa Brzeźnica",
        "us" : [ "Urząd Skarbowy w Pajęcznie" ]
      }, {
        "gmina" : "Pajęczno",
        "us" : [ "Urząd Skarbowy w Pajęcznie" ]
      }, {
        "gmina" : "Rząśnia",
        "us" : [ "Urząd Skarbowy w Pajęcznie" ]
      }, {
        "gmina" : "Siemkowice",
        "us" : [ "Urząd Skarbowy w Pajęcznie" ]
      }, {
        "gmina" : "Strzelce Wielkie",
        "us" : [ "Urząd Skarbowy w Pajęcznie" ]
      }, {
        "gmina" : "Sulmierzyce",
        "us" : [ "Urząd Skarbowy w Pajęcznie" ]
      } ]
    }, {
      "powiat" : "piotrkowski",
      "gminy" : [ {
        "gmina" : "Aleksandrów",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Czarnocin",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Gorzkowice",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Grabica",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Moszczenica",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Rozprza",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Ręczno",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Sulejów",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Wola Krzysztoporska",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Wolbórz",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      }, {
        "gmina" : "Łęki Szlacheckie",
        "us" : [ "Urząd Skarbowy w Piotrkowie Trybunalskim" ]
      } ]
    }, {
      "powiat" : "poddębicki",
      "gminy" : [ {
        "gmina" : "Dalików",
        "us" : [ "Urząd Skarbowy w Poddębicach" ]
      }, {
        "gmina" : "Poddębice",
        "us" : [ "Urząd Skarbowy w Poddębicach" ]
      }, {
        "gmina" : "Pęczniew",
        "us" : [ "Urząd Skarbowy w Poddębicach" ]
      }, {
        "gmina" : "Uniejów",
        "us" : [ "Urząd Skarbowy w Poddębicach" ]
      }, {
        "gmina" : "Wartkowice",
        "us" : [ "Urząd Skarbowy w Poddębicach" ]
      }, {
        "gmina" : "Zadzim",
        "us" : [ "Urząd Skarbowy w Poddębicach" ]
      } ]
    }, {
      "powiat" : "radomszczański",
      "gminy" : [ {
        "gmina" : "Dobryszyce",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Gidle",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Gomunice",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Kamieńsk",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Kobiele Wielkie",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Kodrąb",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Lgota Wielka",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Masłowice",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Przedbórz",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Radomsko",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Wielgomłyny",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Ładzice",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      }, {
        "gmina" : "Żytno",
        "us" : [ "Urząd Skarbowy w Radomsku" ]
      } ]
    }, {
      "powiat" : "rawski",
      "gminy" : [ {
        "gmina" : "Biała Rawska",
        "us" : [ "Urząd Skarbowy w Rawie Mazowieckiej" ]
      }, {
        "gmina" : "Cielądz",
        "us" : [ "Urząd Skarbowy w Rawie Mazowieckiej" ]
      }, {
        "gmina" : "Rawa Mazowiecka",
        "us" : [ "Urząd Skarbowy w Rawie Mazowieckiej" ]
      }, {
        "gmina" : "Regnów",
        "us" : [ "Urząd Skarbowy w Rawie Mazowieckiej" ]
      }, {
        "gmina" : "Sadkowice",
        "us" : [ "Urząd Skarbowy w Rawie Mazowieckiej" ]
      } ]
    }, {
      "powiat" : "sieradzki",
      "gminy" : [ {
        "gmina" : "Brzeźnio",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Brąszewice",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Burzenin",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Błaszki",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Goszczanów",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Klonowa",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Sieradz",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Warta",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Wróblew",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      }, {
        "gmina" : "Złoczew",
        "us" : [ "Urząd Skarbowy w Sieradzu" ]
      } ]
    }, {
      "powiat" : "skierniewicki",
      "gminy" : [ {
        "gmina" : "Bolimów",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      }, {
        "gmina" : "Godzianów",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      }, {
        "gmina" : "Głuchów",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      }, {
        "gmina" : "Kowiesy",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      }, {
        "gmina" : "Lipce Reymontowskie",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      }, {
        "gmina" : "Maków",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      }, {
        "gmina" : "Nowy Kawęczyn",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      }, {
        "gmina" : "Skierniewice",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      }, {
        "gmina" : "Słupia",
        "us" : [ "Urząd Skarbowy w Skierniewicach" ]
      } ]
    }, {
      "powiat" : "tomaszowski",
      "gminy" : [ {
        "gmina" : "Budziszewice",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Będków",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Czerniewice",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Inowłódz",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Lubochnia",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Rokiciny",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Rzeczyca",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Tomaszów Mazowiecki",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Ujazd",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      }, {
        "gmina" : "Żelechlinek",
        "us" : [ "Urząd Skarbowy w Tomaszowie Mazowieckim" ]
      } ]
    }, {
      "powiat" : "wieluński",
      "gminy" : [ {
        "gmina" : "Biała",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Czarnożyły",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Konopnica",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Mokrsko",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Osjaków",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Ostrówek",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Pątnów",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Skomlin",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Wieluń",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      }, {
        "gmina" : "Wierzchlas",
        "us" : [ "Urząd Skarbowy w Wieluniu" ]
      } ]
    }, {
      "powiat" : "wieruszowski",
      "gminy" : [ {
        "gmina" : "Bolesławiec",
        "us" : [ "Urząd Skarbowy w Wieruszowie" ]
      }, {
        "gmina" : "Czastary",
        "us" : [ "Urząd Skarbowy w Wieruszowie" ]
      }, {
        "gmina" : "Galewice",
        "us" : [ "Urząd Skarbowy w Wieruszowie" ]
      }, {
        "gmina" : "Lututów",
        "us" : [ "Urząd Skarbowy w Wieruszowie" ]
      }, {
        "gmina" : "Sokolniki",
        "us" : [ "Urząd Skarbowy w Wieruszowie" ]
      }, {
        "gmina" : "Wieruszów",
        "us" : [ "Urząd Skarbowy w Wieruszowie" ]
      }, {
        "gmina" : "Łubnice",
        "us" : [ "Urząd Skarbowy w Wieruszowie" ]
      } ]
    }, {
      "powiat" : "zduńskowolski",
      "gminy" : [ {
        "gmina" : "Szadek",
        "us" : [ "Urząd Skarbowy w Zduńskiej Woli" ]
      }, {
        "gmina" : "Zapolice",
        "us" : [ "Urząd Skarbowy w Zduńskiej Woli" ]
      }, {
        "gmina" : "Zduńska Wola",
        "us" : [ "Urząd Skarbowy w Zduńskiej Woli" ]
      } ]
    }, {
      "powiat" : "zgierski",
      "gminy" : [ {
        "gmina" : "Aleksandrów Łódzki",
        "us" : [ "Urząd Skarbowy w Zgierzu" ]
      }, {
        "gmina" : "Głowno",
        "us" : [ "Urząd Skarbowy w Głownie" ]
      }, {
        "gmina" : "Ozorków",
        "us" : [ "Urząd Skarbowy w Zgierzu" ]
      }, {
        "gmina" : "Parzęczew",
        "us" : [ "Urząd Skarbowy w Zgierzu" ]
      }, {
        "gmina" : "Stryków",
        "us" : [ "Urząd Skarbowy w Głownie" ]
      }, {
        "gmina" : "Zgierz",
        "us" : [ "Urząd Skarbowy w Zgierzu" ]
      } ]
    }, {
      "powiat" : "Łódź",
      "gminy" : [ {
        "gmina" : "Łódź",
        "us" : [ "Pierwszy Urząd Skarbowy Łódź-Bałuty", "Drugi Urząd Skarbowy Łódź-Bałuty", "Pierwszy Urząd Skarbowy Łódź-Górna", "Drugi Urząd Skarbowy Łódź-Górna", "Urząd Skarbowy Łódź-Polesie", "Urząd Skarbowy Łódź-Śródmieście", "Urząd Skarbowy Łódź-Widzew" ]
      } ]
    }, {
      "powiat" : "łaski",
      "gminy" : [ {
        "gmina" : "Buczek",
        "us" : [ "Urząd Skarbowy w Łasku" ]
      }, {
        "gmina" : "Sędziejowice",
        "us" : [ "Urząd Skarbowy w Łasku" ]
      }, {
        "gmina" : "Widawa",
        "us" : [ "Urząd Skarbowy w Łasku" ]
      }, {
        "gmina" : "Wodzierady",
        "us" : [ "Urząd Skarbowy w Łasku" ]
      }, {
        "gmina" : "Łask",
        "us" : [ "Urząd Skarbowy w Łasku" ]
      } ]
    }, {
      "powiat" : "łowicki",
      "gminy" : [ {
        "gmina" : "Bielawy",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      }, {
        "gmina" : "Chąśno",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      }, {
        "gmina" : "Domaniewice",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      }, {
        "gmina" : "Kiernozia",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      }, {
        "gmina" : "Kocierzew Południowy",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      }, {
        "gmina" : "Nieborów",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      }, {
        "gmina" : "Zduny",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      }, {
        "gmina" : "Łowicz",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      }, {
        "gmina" : "Łyszkowice",
        "us" : [ "Urząd Skarbowy w Łowiczu" ]
      } ]
    }, {
      "powiat" : "łódzki wschodni",
      "gminy" : [ {
        "gmina" : "Andrespol",
        "us" : [ "Urząd Skarbowy Łódź-Widzew" ]
      }, {
        "gmina" : "Brójce",
        "us" : [ "Urząd Skarbowy Łódź-Widzew" ]
      }, {
        "gmina" : "Koluszki",
        "us" : [ "Urząd Skarbowy Łódź-Widzew" ]
      }, {
        "gmina" : "Nowosolna",
        "us" : [ "Urząd Skarbowy Łódź-Widzew" ]
      }, {
        "gmina" : "Rzgów",
        "us" : [ "Urząd Skarbowy Łódź-Widzew" ]
      }, {
        "gmina" : "Tuszyn",
        "us" : [ "Urząd Skarbowy Łódź-Widzew" ]
      } ]
    }, {
      "powiat" : "łęczycki",
      "gminy" : [ {
        "gmina" : "Daszyna",
        "us" : [ "Urząd Skarbowy w Łęczycy" ]
      }, {
        "gmina" : "Grabów",
        "us" : [ "Urząd Skarbowy w Łęczycy" ]
      }, {
        "gmina" : "Góra Świętej Małgorzaty",
        "us" : [ "Urząd Skarbowy w Łęczycy" ]
      }, {
        "gmina" : "Piątek",
        "us" : [ "Urząd Skarbowy w Łęczycy" ]
      }, {
        "gmina" : "Witonia",
        "us" : [ "Urząd Skarbowy w Łęczycy" ]
      }, {
        "gmina" : "Łęczyca",
        "us" : [ "Urząd Skarbowy w Łęczycy" ]
      }, {
        "gmina" : "Świnice Warckie",
        "us" : [ "Urząd Skarbowy w Łęczycy" ]
      } ]
    } ]
  }, {
    "woj" : "śląskie",
    "powiaty" : [ {
      "powiat" : "Bielsko-Biała",
      "gminy" : [ {
        "gmina" : "Bielsko-Biała",
        "us" : [ "Pierwszy Urząd Skarbowy w Bielsku-Białej", "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      } ]
    }, {
      "powiat" : "Bytom",
      "gminy" : [ {
        "gmina" : "Bytom",
        "us" : [ "Urząd Skarbowy w Bytomiu" ]
      } ]
    }, {
      "powiat" : "Chorzów",
      "gminy" : [ {
        "gmina" : "Chorzów",
        "us" : [ "Urząd Skarbowy w Chorzowie" ]
      } ]
    }, {
      "powiat" : "Częstochowa",
      "gminy" : [ {
        "gmina" : "Częstochowa",
        "us" : [ "Pierwszy Urząd Skarbowy w Częstochowie", "Drugi Urząd Skarbowy w Częstochowie" ]
      } ]
    }, {
      "powiat" : "Dąbrowa Górnicza",
      "gminy" : [ {
        "gmina" : "Dąbrowa Górnicza",
        "us" : [ "Urząd Skarbowy w Dąbrowie Górniczej" ]
      } ]
    }, {
      "powiat" : "Gliwice",
      "gminy" : [ {
        "gmina" : "Gliwice",
        "us" : [ "Pierwszy Urząd Skarbowy w Gliwicach", "Drugi Urząd Skarbowy w Gliwicach" ]
      } ]
    }, {
      "powiat" : "Jastrzębie-Zdrój",
      "gminy" : [ {
        "gmina" : "Jastrzębie-Zdrój",
        "us" : [ "Urząd Skarbowy w Jastrzębiu-Zdroju" ]
      } ]
    }, {
      "powiat" : "Jaworzno",
      "gminy" : [ {
        "gmina" : "Jaworzno",
        "us" : [ "Urząd Skarbowy w Jaworznie" ]
      } ]
    }, {
      "powiat" : "Katowice",
      "gminy" : [ {
        "gmina" : "Katowice",
        "us" : [ "Pierwszy Urząd Skarbowy w Katowicach", "Drugi Urząd Skarbowy w Katowicach" ]
      } ]
    }, {
      "powiat" : "Mysłowice",
      "gminy" : [ {
        "gmina" : "Mysłowice",
        "us" : [ "Urząd Skarbowy w Mysłowicach" ]
      } ]
    }, {
      "powiat" : "Piekary Śląskie",
      "gminy" : [ {
        "gmina" : "Piekary Śląskie",
        "us" : [ "Urząd Skarbowy w Piekarach Śląskich" ]
      } ]
    }, {
      "powiat" : "Ruda Śląska",
      "gminy" : [ {
        "gmina" : "Ruda Śląska",
        "us" : [ "Urząd Skarbowy w Rudzie Śląskiej" ]
      } ]
    }, {
      "powiat" : "Rybnik",
      "gminy" : [ {
        "gmina" : "Rybnik",
        "us" : [ "Urząd Skarbowy w Rybniku" ]
      } ]
    }, {
      "powiat" : "Siemianowice Śląskie",
      "gminy" : [ {
        "gmina" : "Siemianowice Śląskie",
        "us" : [ "Urząd Skarbowy w Siemianowicach Śląskich" ]
      } ]
    }, {
      "powiat" : "Sosnowiec",
      "gminy" : [ {
        "gmina" : "Sosnowiec",
        "us" : [ "Urząd Skarbowy w Sosnowcu" ]
      } ]
    }, {
      "powiat" : "Tychy",
      "gminy" : [ {
        "gmina" : "Tychy",
        "us" : [ "Urząd Skarbowy w Tychach" ]
      } ]
    }, {
      "powiat" : "Zabrze",
      "gminy" : [ {
        "gmina" : "Zabrze",
        "us" : [ "Urząd Skarbowy w Zabrzu" ]
      } ]
    }, {
      "powiat" : "bielski",
      "gminy" : [ {
        "gmina" : "Bestwina",
        "us" : [ "Urząd Skarbowy w Czechowicach-Dziedzicach" ]
      }, {
        "gmina" : "Buczkowice",
        "us" : [ "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      }, {
        "gmina" : "Czechowice-Dziedzice",
        "us" : [ "Urząd Skarbowy w Czechowicach-Dziedzicach" ]
      }, {
        "gmina" : "Jasienica",
        "us" : [ "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      }, {
        "gmina" : "Jaworze",
        "us" : [ "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      }, {
        "gmina" : "Kozy",
        "us" : [ "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      }, {
        "gmina" : "Porąbka",
        "us" : [ "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      }, {
        "gmina" : "Szczyrk",
        "us" : [ "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      }, {
        "gmina" : "Wilamowice",
        "us" : [ "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      }, {
        "gmina" : "Wilkowice",
        "us" : [ "Drugi Urząd Skarbowy w Bielsku-Białej" ]
      } ]
    }, {
      "powiat" : "bieruńsko-lędziński",
      "gminy" : [ {
        "gmina" : "Bieruń",
        "us" : [ "Urząd Skarbowy w Tychach" ]
      }, {
        "gmina" : "Bojszowy",
        "us" : [ "Urząd Skarbowy w Tychach" ]
      }, {
        "gmina" : "Chełm Śląski",
        "us" : [ "Urząd Skarbowy w Tychach" ]
      }, {
        "gmina" : "Imielin",
        "us" : [ "Urząd Skarbowy w Tychach" ]
      }, {
        "gmina" : "Lędziny",
        "us" : [ "Urząd Skarbowy w Tychach" ]
      } ]
    }, {
      "powiat" : "będziński",
      "gminy" : [ {
        "gmina" : "Bobrowniki",
        "us" : [ "Urząd Skarbowy w Będzinie" ]
      }, {
        "gmina" : "Będzin",
        "us" : [ "Urząd Skarbowy w Będzinie" ]
      }, {
        "gmina" : "Czeladź",
        "us" : [ "Urząd Skarbowy w Będzinie" ]
      }, {
        "gmina" : "Mierzęcice",
        "us" : [ "Urząd Skarbowy w Będzinie" ]
      }, {
        "gmina" : "Psary",
        "us" : [ "Urząd Skarbowy w Będzinie" ]
      }, {
        "gmina" : "Siewierz",
        "us" : [ "Urząd Skarbowy w Będzinie" ]
      }, {
        "gmina" : "Sławków",
        "us" : [ "Urząd Skarbowy w Będzinie" ]
      }, {
        "gmina" : "Wojkowice",
        "us" : [ "Urząd Skarbowy w Będzinie" ]
      } ]
    }, {
      "powiat" : "cieszyński",
      "gminy" : [ {
        "gmina" : "Brenna",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Chybie",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Cieszyn",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Dębowiec",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Goleszów",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Hażlach",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Istebna",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Skoczów",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Strumień",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Ustroń",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Wisła",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      }, {
        "gmina" : "Zebrzydowice",
        "us" : [ "Urząd Skarbowy w Cieszynie" ]
      } ]
    }, {
      "powiat" : "częstochowski",
      "gminy" : [ {
        "gmina" : "Blachownia",
        "us" : [ "Pierwszy Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Dąbrowa Zielona",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Janów",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Kamienica Polska",
        "us" : [ "Pierwszy Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Koniecpol",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Konopiska",
        "us" : [ "Pierwszy Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Kruszyna",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Kłomnice",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Lelów",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Mstów",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Mykanów",
        "us" : [ "Pierwszy Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Olsztyn",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Poczesna",
        "us" : [ "Pierwszy Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Przyrów",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Rędziny",
        "us" : [ "Drugi Urząd Skarbowy w Częstochowie" ]
      }, {
        "gmina" : "Starcza",
        "us" : [ "Pierwszy Urząd Skarbowy w Częstochowie" ]
      } ]
    }, {
      "powiat" : "gliwicki",
      "gminy" : [ {
        "gmina" : "Gierałtowice",
        "us" : [ "Drugi Urząd Skarbowy w Gliwicach" ]
      }, {
        "gmina" : "Knurów",
        "us" : [ "Drugi Urząd Skarbowy w Gliwicach" ]
      }, {
        "gmina" : "Pilchowice",
        "us" : [ "Drugi Urząd Skarbowy w Gliwicach" ]
      }, {
        "gmina" : "Pyskowice",
        "us" : [ "Drugi Urząd Skarbowy w Gliwicach" ]
      }, {
        "gmina" : "Rudziniec",
        "us" : [ "Drugi Urząd Skarbowy w Gliwicach" ]
      }, {
        "gmina" : "Sośnicowice",
        "us" : [ "Drugi Urząd Skarbowy w Gliwicach" ]
      }, {
        "gmina" : "Toszek",
        "us" : [ "Drugi Urząd Skarbowy w Gliwicach" ]
      }, {
        "gmina" : "Wielowieś",
        "us" : [ "Drugi Urząd Skarbowy w Gliwicach" ]
      } ]
    }, {
      "powiat" : "kłobucki",
      "gminy" : [ {
        "gmina" : "Krzepice",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      }, {
        "gmina" : "Kłobuck",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      }, {
        "gmina" : "Lipie",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      }, {
        "gmina" : "Miedźno",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      }, {
        "gmina" : "Opatów",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      }, {
        "gmina" : "Panki",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      }, {
        "gmina" : "Popów",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      }, {
        "gmina" : "Przystajń",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      }, {
        "gmina" : "Wręczyca Wielka",
        "us" : [ "Urząd Skarbowy w Kłobucku" ]
      } ]
    }, {
      "powiat" : "lubliniecki",
      "gminy" : [ {
        "gmina" : "Boronów",
        "us" : [ "Urząd Skarbowy w Lublińcu" ]
      }, {
        "gmina" : "Ciasna",
        "us" : [ "Urząd Skarbowy w Lublińcu" ]
      }, {
        "gmina" : "Herby",
        "us" : [ "Urząd Skarbowy w Lublińcu" ]
      }, {
        "gmina" : "Kochanowice",
        "us" : [ "Urząd Skarbowy w Lublińcu" ]
      }, {
        "gmina" : "Koszęcin",
        "us" : [ "Urząd Skarbowy w Lublińcu" ]
      }, {
        "gmina" : "Lubliniec",
        "us" : [ "Urząd Skarbowy w Lublińcu" ]
      }, {
        "gmina" : "Pawonków",
        "us" : [ "Urząd Skarbowy w Lublińcu" ]
      }, {
        "gmina" : "Woźniki",
        "us" : [ "Urząd Skarbowy w Lublińcu" ]
      } ]
    }, {
      "powiat" : "mikołowski",
      "gminy" : [ {
        "gmina" : "Mikołów",
        "us" : [ "Urząd Skarbowy w Mikołowie" ]
      }, {
        "gmina" : "Ornontowice",
        "us" : [ "Urząd Skarbowy w Mikołowie" ]
      }, {
        "gmina" : "Orzesze",
        "us" : [ "Urząd Skarbowy w Mikołowie" ]
      }, {
        "gmina" : "Wyry",
        "us" : [ "Urząd Skarbowy w Mikołowie" ]
      }, {
        "gmina" : "Łaziska Górne",
        "us" : [ "Urząd Skarbowy w Mikołowie" ]
      } ]
    }, {
      "powiat" : "myszkowski",
      "gminy" : [ {
        "gmina" : "Koziegłowy",
        "us" : [ "Urząd Skarbowy w Myszkowie" ]
      }, {
        "gmina" : "Myszków",
        "us" : [ "Urząd Skarbowy w Myszkowie" ]
      }, {
        "gmina" : "Niegowa",
        "us" : [ "Urząd Skarbowy w Myszkowie" ]
      }, {
        "gmina" : "Poraj",
        "us" : [ "Urząd Skarbowy w Myszkowie" ]
      }, {
        "gmina" : "Żarki",
        "us" : [ "Urząd Skarbowy w Myszkowie" ]
      } ]
    }, {
      "powiat" : "pszczyński",
      "gminy" : [ {
        "gmina" : "Goczałkowice-Zdrój",
        "us" : [ "Urząd Skarbowy w Pszczynie" ]
      }, {
        "gmina" : "Kobiór",
        "us" : [ "Urząd Skarbowy w Pszczynie" ]
      }, {
        "gmina" : "Miedźna",
        "us" : [ "Urząd Skarbowy w Pszczynie" ]
      }, {
        "gmina" : "Pawłowice",
        "us" : [ "Urząd Skarbowy w Pszczynie" ]
      }, {
        "gmina" : "Pszczyna",
        "us" : [ "Urząd Skarbowy w Pszczynie" ]
      }, {
        "gmina" : "Suszec",
        "us" : [ "Urząd Skarbowy w Pszczynie" ]
      } ]
    }, {
      "powiat" : "raciborski",
      "gminy" : [ {
        "gmina" : "Kornowac",
        "us" : [ "Urząd Skarbowy w Raciborzu" ]
      }, {
        "gmina" : "Krzanowice",
        "us" : [ "Urząd Skarbowy w Raciborzu" ]
      }, {
        "gmina" : "Krzyżanowice",
        "us" : [ "Urząd Skarbowy w Raciborzu" ]
      }, {
        "gmina" : "Kuźnia Raciborska",
        "us" : [ "Urząd Skarbowy w Raciborzu" ]
      }, {
        "gmina" : "Nędza",
        "us" : [ "Urząd Skarbowy w Raciborzu" ]
      }, {
        "gmina" : "Pietrowice Wielkie",
        "us" : [ "Urząd Skarbowy w Raciborzu" ]
      }, {
        "gmina" : "Racibórz",
        "us" : [ "Urząd Skarbowy w Raciborzu" ]
      }, {
        "gmina" : "Rudnik",
        "us" : [ "Urząd Skarbowy w Raciborzu" ]
      } ]
    }, {
      "powiat" : "rybnicki",
      "gminy" : [ {
        "gmina" : "Czerwionka-Leszczyny",
        "us" : [ "Urząd Skarbowy w Rybniku" ]
      }, {
        "gmina" : "Gaszowice",
        "us" : [ "Urząd Skarbowy w Rybniku" ]
      }, {
        "gmina" : "Jejkowice",
        "us" : [ "Urząd Skarbowy w Rybniku" ]
      }, {
        "gmina" : "Lyski",
        "us" : [ "Urząd Skarbowy w Rybniku" ]
      }, {
        "gmina" : "Świerklany",
        "us" : [ "Urząd Skarbowy w Rybniku" ]
      } ]
    }, {
      "powiat" : "tarnogórski",
      "gminy" : [ {
        "gmina" : "Kalety",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      }, {
        "gmina" : "Krupski Młyn",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      }, {
        "gmina" : "Miasteczko Śląskie",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      }, {
        "gmina" : "Ożarowice",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      }, {
        "gmina" : "Radzionków",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      }, {
        "gmina" : "Tarnowskie Góry",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      }, {
        "gmina" : "Tworóg",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      }, {
        "gmina" : "Zbrosławice",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      }, {
        "gmina" : "Świerklaniec",
        "us" : [ "Urząd Skarbowy w Tarnowskich Górach" ]
      } ]
    }, {
      "powiat" : "wodzisławski",
      "gminy" : [ {
        "gmina" : "Godów",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      }, {
        "gmina" : "Gorzyce",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      }, {
        "gmina" : "Lubomia",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      }, {
        "gmina" : "Marklowice",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      }, {
        "gmina" : "Mszana",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      }, {
        "gmina" : "Pszów",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      }, {
        "gmina" : "Radlin",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      }, {
        "gmina" : "Rydułtowy",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      }, {
        "gmina" : "Wodzisław Śląski",
        "us" : [ "Urząd Skarbowy w Wodzisławiu Śląskim" ]
      } ]
    }, {
      "powiat" : "zawierciański",
      "gminy" : [ {
        "gmina" : "Irządze",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Kroczyce",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Ogrodzieniec",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Pilica",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Poręba",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Szczekociny",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Włodowice",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Zawiercie",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Łazy",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      }, {
        "gmina" : "Żarnowiec",
        "us" : [ "Urząd Skarbowy w Zawierciu" ]
      } ]
    }, {
      "powiat" : "Świętochłowice",
      "gminy" : [ {
        "gmina" : "Świętochłowice",
        "us" : [ "Urząd Skarbowy w Chorzowie" ]
      } ]
    }, {
      "powiat" : "Żory",
      "gminy" : [ {
        "gmina" : "Żory",
        "us" : [ "Urząd Skarbowy w Żorach" ]
      } ]
    }, {
      "powiat" : "żywiecki",
      "gminy" : [ {
        "gmina" : "Czernichów",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Gilowice",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Jeleśnia",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Koszarawa",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Lipowa",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Milówka",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Radziechowy-Wieprz",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Rajcza",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Ujsoły",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Węgierska Górka",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Łodygowice",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Łękawica",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Ślemień",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Świnna",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      }, {
        "gmina" : "Żywiec",
        "us" : [ "Urząd Skarbowy w Żywcu" ]
      } ]
    } ]
  }, {
    "woj" : "świętokrzyskie",
    "powiaty" : [ {
      "powiat" : "Kielce",
      "gminy" : [ {
        "gmina" : "Kielce",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach", "Drugi Urząd Skarbowy w Kielcach" ]
      } ]
    }, {
      "powiat" : "buski",
      "gminy" : [ {
        "gmina" : "Busko-Zdrój",
        "us" : [ "Urząd Skarbowy w Busku-Zdroju" ]
      }, {
        "gmina" : "Gnojno",
        "us" : [ "Urząd Skarbowy w Busku-Zdroju" ]
      }, {
        "gmina" : "Nowy Korczyn",
        "us" : [ "Urząd Skarbowy w Busku-Zdroju" ]
      }, {
        "gmina" : "Pacanów",
        "us" : [ "Urząd Skarbowy w Busku-Zdroju" ]
      }, {
        "gmina" : "Solec-Zdrój",
        "us" : [ "Urząd Skarbowy w Busku-Zdroju" ]
      }, {
        "gmina" : "Stopnica",
        "us" : [ "Urząd Skarbowy w Busku-Zdroju" ]
      }, {
        "gmina" : "Tuczępy",
        "us" : [ "Urząd Skarbowy w Busku-Zdroju" ]
      }, {
        "gmina" : "Wiślica",
        "us" : [ "Urząd Skarbowy w Busku-Zdroju" ]
      } ]
    }, {
      "powiat" : "jędrzejowski",
      "gminy" : [ {
        "gmina" : "Imielno",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      }, {
        "gmina" : "Jędrzejów",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      }, {
        "gmina" : "Małogoszcz",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      }, {
        "gmina" : "Nagłowice",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      }, {
        "gmina" : "Oksa",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      }, {
        "gmina" : "Sobków",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      }, {
        "gmina" : "Sędziszów",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      }, {
        "gmina" : "Słupia",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      }, {
        "gmina" : "Wodzisław",
        "us" : [ "Urząd Skarbowy w Jędrzejowie" ]
      } ]
    }, {
      "powiat" : "kazimierski",
      "gminy" : [ {
        "gmina" : "Bejsce",
        "us" : [ "Urząd Skarbowy w Kazimierzy Wielkiej" ]
      }, {
        "gmina" : "Czarnocin",
        "us" : [ "Urząd Skarbowy w Kazimierzy Wielkiej" ]
      }, {
        "gmina" : "Kazimierza Wielka",
        "us" : [ "Urząd Skarbowy w Kazimierzy Wielkiej" ]
      }, {
        "gmina" : "Opatowiec",
        "us" : [ "Urząd Skarbowy w Kazimierzy Wielkiej" ]
      }, {
        "gmina" : "Skalbmierz",
        "us" : [ "Urząd Skarbowy w Kazimierzy Wielkiej" ]
      } ]
    }, {
      "powiat" : "kielecki",
      "gminy" : [ {
        "gmina" : "Bieliny",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Bodzentyn",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Chmielnik",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Chęciny",
        "us" : [ "Drugi Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Daleszyce",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Górno",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Masłów",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Miedziana Góra",
        "us" : [ "Drugi Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Mniów",
        "us" : [ "Drugi Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Morawica",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Nowa Słupia",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Piekoszów",
        "us" : [ "Drugi Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Pierzchnica",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Raków",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Sitkówka-Nowiny",
        "us" : [ "Drugi Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Strawczyn",
        "us" : [ "Drugi Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Zagnańsk",
        "us" : [ "Drugi Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Łagów",
        "us" : [ "Pierwszy Urząd Skarbowy w Kielcach" ]
      }, {
        "gmina" : "Łopuszno",
        "us" : [ "Drugi Urząd Skarbowy w Kielcach" ]
      } ]
    }, {
      "powiat" : "konecki",
      "gminy" : [ {
        "gmina" : "Fałków",
        "us" : [ "Urząd Skarbowy w Końskich" ]
      }, {
        "gmina" : "Gowarczów",
        "us" : [ "Urząd Skarbowy w Końskich" ]
      }, {
        "gmina" : "Końskie",
        "us" : [ "Urząd Skarbowy w Końskich" ]
      }, {
        "gmina" : "Radoszyce",
        "us" : [ "Urząd Skarbowy w Końskich" ]
      }, {
        "gmina" : "Ruda Maleniecka",
        "us" : [ "Urząd Skarbowy w Końskich" ]
      }, {
        "gmina" : "Smyków",
        "us" : [ "Urząd Skarbowy w Końskich" ]
      }, {
        "gmina" : "Stąporków",
        "us" : [ "Urząd Skarbowy w Końskich" ]
      }, {
        "gmina" : "Słupia Konecka",
        "us" : [ "Urząd Skarbowy w Końskich" ]
      } ]
    }, {
      "powiat" : "opatowski",
      "gminy" : [ {
        "gmina" : "Baćkowice",
        "us" : [ "Urząd Skarbowy w Opatowie" ]
      }, {
        "gmina" : "Iwaniska",
        "us" : [ "Urząd Skarbowy w Opatowie" ]
      }, {
        "gmina" : "Lipnik",
        "us" : [ "Urząd Skarbowy w Opatowie" ]
      }, {
        "gmina" : "Opatów",
        "us" : [ "Urząd Skarbowy w Opatowie" ]
      }, {
        "gmina" : "Ożarów",
        "us" : [ "Urząd Skarbowy w Opatowie" ]
      }, {
        "gmina" : "Sadowie",
        "us" : [ "Urząd Skarbowy w Opatowie" ]
      }, {
        "gmina" : "Tarłów",
        "us" : [ "Urząd Skarbowy w Opatowie" ]
      }, {
        "gmina" : "Wojciechowice",
        "us" : [ "Urząd Skarbowy w Opatowie" ]
      } ]
    }, {
      "powiat" : "ostrowiecki",
      "gminy" : [ {
        "gmina" : "Bałtów",
        "us" : [ "Urząd Skarbowy w Ostrowcu Świętokrzyskim" ]
      }, {
        "gmina" : "Bodzechów",
        "us" : [ "Urząd Skarbowy w Ostrowcu Świętokrzyskim" ]
      }, {
        "gmina" : "Kunów",
        "us" : [ "Urząd Skarbowy w Ostrowcu Świętokrzyskim" ]
      }, {
        "gmina" : "Ostrowiec Świętokrzyski",
        "us" : [ "Urząd Skarbowy w Ostrowcu Świętokrzyskim" ]
      }, {
        "gmina" : "Waśniów",
        "us" : [ "Urząd Skarbowy w Ostrowcu Świętokrzyskim" ]
      }, {
        "gmina" : "Ćmielów",
        "us" : [ "Urząd Skarbowy w Ostrowcu Świętokrzyskim" ]
      } ]
    }, {
      "powiat" : "pińczowski",
      "gminy" : [ {
        "gmina" : "Działoszyce",
        "us" : [ "Urząd Skarbowy w Pińczowie" ]
      }, {
        "gmina" : "Kije",
        "us" : [ "Urząd Skarbowy w Pińczowie" ]
      }, {
        "gmina" : "Michałów",
        "us" : [ "Urząd Skarbowy w Pińczowie" ]
      }, {
        "gmina" : "Pińczów",
        "us" : [ "Urząd Skarbowy w Pińczowie" ]
      }, {
        "gmina" : "Złota",
        "us" : [ "Urząd Skarbowy w Pińczowie" ]
      } ]
    }, {
      "powiat" : "sandomierski",
      "gminy" : [ {
        "gmina" : "Dwikozy",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      }, {
        "gmina" : "Klimontów",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      }, {
        "gmina" : "Koprzywnica",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      }, {
        "gmina" : "Obrazów",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      }, {
        "gmina" : "Samborzec",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      }, {
        "gmina" : "Sandomierz",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      }, {
        "gmina" : "Wilczyce",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      }, {
        "gmina" : "Zawichost",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      }, {
        "gmina" : "Łoniów",
        "us" : [ "Urząd Skarbowy w Sandomierzu" ]
      } ]
    }, {
      "powiat" : "skarżyski",
      "gminy" : [ {
        "gmina" : "Bliżyn",
        "us" : [ "Urząd Skarbowy w Skarżysku-Kamiennej" ]
      }, {
        "gmina" : "Skarżysko Kościelne",
        "us" : [ "Urząd Skarbowy w Skarżysku-Kamiennej" ]
      }, {
        "gmina" : "Skarżysko-Kamienna",
        "us" : [ "Urząd Skarbowy w Skarżysku-Kamiennej" ]
      }, {
        "gmina" : "Suchedniów",
        "us" : [ "Urząd Skarbowy w Skarżysku-Kamiennej" ]
      }, {
        "gmina" : "Łączna",
        "us" : [ "Urząd Skarbowy w Skarżysku-Kamiennej" ]
      } ]
    }, {
      "powiat" : "starachowicki",
      "gminy" : [ {
        "gmina" : "Brody",
        "us" : [ "Urząd Skarbowy w Starachowicach" ]
      }, {
        "gmina" : "Mirzec",
        "us" : [ "Urząd Skarbowy w Starachowicach" ]
      }, {
        "gmina" : "Pawłów",
        "us" : [ "Urząd Skarbowy w Starachowicach" ]
      }, {
        "gmina" : "Starachowice",
        "us" : [ "Urząd Skarbowy w Starachowicach" ]
      }, {
        "gmina" : "Wąchock",
        "us" : [ "Urząd Skarbowy w Starachowicach" ]
      } ]
    }, {
      "powiat" : "staszowski",
      "gminy" : [ {
        "gmina" : "Bogoria",
        "us" : [ "Urząd Skarbowy w Staszowie" ]
      }, {
        "gmina" : "Oleśnica",
        "us" : [ "Urząd Skarbowy w Staszowie" ]
      }, {
        "gmina" : "Osiek",
        "us" : [ "Urząd Skarbowy w Staszowie" ]
      }, {
        "gmina" : "Połaniec",
        "us" : [ "Urząd Skarbowy w Staszowie" ]
      }, {
        "gmina" : "Rytwiany",
        "us" : [ "Urząd Skarbowy w Staszowie" ]
      }, {
        "gmina" : "Staszów",
        "us" : [ "Urząd Skarbowy w Staszowie" ]
      }, {
        "gmina" : "Szydłów",
        "us" : [ "Urząd Skarbowy w Staszowie" ]
      }, {
        "gmina" : "Łubnice",
        "us" : [ "Urząd Skarbowy w Staszowie" ]
      } ]
    }, {
      "powiat" : "włoszczowski",
      "gminy" : [ {
        "gmina" : "Kluczewsko",
        "us" : [ "Urząd Skarbowy we Włoszczowie" ]
      }, {
        "gmina" : "Krasocin",
        "us" : [ "Urząd Skarbowy we Włoszczowie" ]
      }, {
        "gmina" : "Moskorzew",
        "us" : [ "Urząd Skarbowy we Włoszczowie" ]
      }, {
        "gmina" : "Radków",
        "us" : [ "Urząd Skarbowy we Włoszczowie" ]
      }, {
        "gmina" : "Secemin",
        "us" : [ "Urząd Skarbowy we Włoszczowie" ]
      }, {
        "gmina" : "Włoszczowa",
        "us" : [ "Urząd Skarbowy we Włoszczowie" ]
      } ]
    } ]
  } ]