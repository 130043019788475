import {User} from "../models/User";

export enum UserRole {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_HR = 'ROLE_HR',
    ROLE_USER = 'ROLE_USER',
}

export const UserRoleGroups = {
    admin: [UserRole.ROLE_ADMIN],
    hr: [UserRole.ROLE_ADMIN, UserRole.ROLE_HR],
    user: [UserRole.ROLE_USER],
    all: [UserRole.ROLE_USER, UserRole.ROLE_HR, UserRole.ROLE_ADMIN],
}

export const userHasRoles = ({roles = []}: User, requiredRoles: UserRole[]): boolean =>
    requiredRoles.some(role => roles.includes(role))
