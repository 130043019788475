import {AuthState} from "../../models/State";
import {LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT} from "./types";

const initialState: AuthState = {
    isLoggedIn: false,
    user: null
}

export default (state: AuthState = initialState, action): AuthState => {
    const {type, payload: {user} = {user: null}} = action
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user
            }
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            }
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null
            }
        default:
            return state
    }
}