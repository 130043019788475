import Select from "react-select";
import React, {useMemo} from "react";
import {Dictionary, DictionaryValue} from "../../../../../../models/Settings";
import {useSelector} from 'react-redux'
import {State} from "../../../../../../models/State";
import chroma from "chroma-js";
import {findStateColor} from "../../../../../../helpers/contract-state";

export interface ContractStateSelectProps {
    value: string | null,
    onChange: (value: string | null) => void
}

export default ({value, onChange}: ContractStateSelectProps) => {
    const dictionaries: Dictionary[] = useSelector(({settings}: State) => settings?.dictionaries || []);

    const options = useMemo(() => {
        if(dictionaries['contract_state']) {
            return dictionaries['contract_state'].map(({value, text: label}: DictionaryValue) =>
                ({label, value, color: findStateColor(value)})
            )
        } else {
            return []
        }
    }, [dictionaries])

    const valueData = useMemo(() => ({
        get: () => {
            return options.find(({value: searchesValue}) => searchesValue === value)
        },
        set: ({value: newVal}): void => {
            onChange(newVal)
        }
    }),[options, value])

    const dot = (color = '#ccc') => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    });

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color || 'gray');
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },
        input: styles => ({ ...styles, ...dot() }),
        placeholder: styles => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };


    return <Select placeholder="Wybierz status"
                   value={valueData.get()}
                   options={options}
                   styles={colourStyles}
                   onChange={option => valueData.set(option)}/>
}