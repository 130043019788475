import {TableFilters, TableService} from "../../models/Table";
import React, {useMemo} from "react";
import AsyncSelect from 'react-select/async';

export interface MultiServerSelectProps<T extends object> {
    readonly value: T[] | null,
    readonly label: (model: T) => string,
    readonly onSelect: (model: T[]) => void,
    readonly tableService: TableService<T>,
    readonly additionalFilters?: TableFilters,
    readonly isMulti?: boolean,
}

export default <T extends object>(
    {
        value,
        tableService,
        onSelect,
        label: displayLabel,
        additionalFilters = {limit: 10}
    }: MultiServerSelectProps<T>) => {

    const valueData = useMemo(() => ({
        get: () => (value?.map(item => ({value, label: displayLabel(item), ...item}))),
        set: (newVal): void => {
            onSelect(newVal?.map(item => ({id: item.value?.id, label: item.value?.label, ...item})) || [])
        }
    }), [value])

    const options =
        (query: string) =>
        tableService.tableData({
            ...additionalFilters,
            query
        }).then(response => response?.items || []).then(results => results.map(value => ({
            value,
            label: displayLabel(value),
            ...value
        })))

    return <AsyncSelect cacheOptions
                        defaultOptions
                        isMulti={true}
                        value={valueData.get()}
                        loadOptions={options}
                        onChange={e => valueData.set(e)}
                        placeholder="Wybierz"/>
}