import React, {useEffect, useMemo, useState} from 'react';

import {useParams} from "react-router-dom";
import Loader from "react-loader";
import {NotificationManager} from 'react-notifications';
import ContractService from "../../../services/ContractService";
import {Contract} from "../../../models/Contract";
import ContractForm from "../../../shared/components/admin/forms/ContractForm";
import TemplatesService from "../../../services/TemplatesService";
import UserService from "../../../services/UserService";
import ClientService from "../../../services/ClientService";

export default () => {
    const {id = null} = useParams<any>();
    const [loaded, setLoaded] = useState<boolean>(!id)
    const [formData, setFormData] = useState<Contract | null>(null)

    const initData = async (): Promise<void> => {
        if (id) {
            const contract = await ContractService.contract(Number(id))

            const templateId = contract?.template?.id
            const template = templateId
                ? await TemplatesService.template(templateId)
                : null

            const userId = contract?.user?.id
            const user = userId
                ? await UserService.user(userId)
                : null

            const clientId = contract?.client?.id
            const client = clientId
                ? await ClientService.client(clientId)
                : null

            const recruiterId = contract?.client?.id
            const recruiter = recruiterId
                ? await UserService.user(recruiterId)
                : null

            contract && setFormData(() => ({...contract, template, user, recruiter, client}))
        }
    }

    const method: (model: Contract) => Promise<Contract | null> = useMemo(() =>
            id ? ContractService.update : ContractService.store,
        [id, ContractService]
    )

    const handleSubmitForm = (contract: Contract): void => {
        setFormData(() => contract)
        setLoaded(false)
        method(contract).then(response => {
                if (response) {
                    NotificationManager.success('Umowa zapisana pomyślnie.')
                    window.location.href = '/admin/agreements'
                } else {
                    NotificationManager.error('Nie udało się zapisać umowy.')
                    setLoaded(true)
                }
            }
        )
    }

    useEffect((): void => {
        initData().then(() => setLoaded(true))
    }, [])

    return (
        <Loader loaded={loaded}>
            <ContractForm contractData={formData} onSubmitForm={handleSubmitForm}/>
        </Loader>
    )
}