import React from 'react';
import DashboardHeader from '../../../shared/components/DashboardHeader';
import ContractsTable from "../../../shared/components/admin/tables/ContractsTable";
import CardBlock from '../../../shared/components/CardBlock';

export default () =>
    <>
        <DashboardHeader title="Niedawno podpisane"/>
        <div className="tab-content mb-3">
                <div className="row">
                    <p className="h6 header-pretitle">
                        Skróty
                    </p>
                </div>
                <div className="row">
                    <div className="col col-lg-4 col-md-6 col-sm-12">
                        <CardBlock
                            link='/admin/agreements/add'
                            name='Utwórz nową umowę'
                            desc='Przejdź do tworzenia nowej umowy'
                            icon='file-plus'
                            buttonName='Utwórz'
                        />
                    </div>
                </div>
        </div>
        <div className="tab-content">

            <div className="row">
                <p className="h6 header-pretitle">
                    Niedawno podpisane
                </p>
            </div>
            <div className="tab-pane fade show active">
                <div className="card">
                    <ContractsTable/>
                </div>
            </div>
        </div>
    </>