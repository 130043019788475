import React, { useMemo, useState } from 'react';
import {Column, useTable, useFilters} from "react-table";
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
  } from "@dnd-kit/core";
  import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
  import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy
  } from "@dnd-kit/sortable";
  import { CSS } from "@dnd-kit/utilities";

type WithId = {
    id?: number | null | undefined
}


export interface ClientTableSortableProps<T extends object> {
    readonly data: Array<T&WithId>,
    readonly columns: Column<T&WithId>[],
    rowsSorted: (data: Array<T&WithId>) => void,
    displayFilter?: (row) => boolean
}

export default <T extends object>({data, columns, rowsSorted, displayFilter = undefined}: ClientTableSortableProps<T>) => {
    const {headerGroups, rows, prepareRow, getTableProps} = useTable({columns, data})
    const [activeId, setActiveId] = useState<number|null>();
    const items = useMemo(() => rows?.map(({ id }) => id), [data]);

    const DragHandle = ((props) => <span 
        style={{padding: '10px', background: 'var(--bs-light)', borderRadius: '5px'}} 
        {...props}>::</span>);

    const TableRow = ({row}) => {

        const {
            attributes,
            listeners,
            transform,
            transition,
            setNodeRef,
            isDragging
          } = useSortable({
            id: row.id
          });


        const style = {
            transform: CSS.Transform.toString(transform),
            transition: transition
        };
        
        return ((displayFilter && displayFilter(row?.original)) || !displayFilter) ? (
        <tr ref={setNodeRef} style={style} {...row.getRowProps()}>
            {isDragging ? (
                    <td colSpan={row.cells.length + 1} style={{background: "#f9f9f9"}}>&nbsp;</td>
                ) : <>
                    {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                        </td>
                    ))}
                    <td style={{padding: '10px'}}>
                        <DragHandle  {...attributes} {...listeners} />
                    </td>
                </>
            }
        </tr>
    ) : <></>}

    const TableBody = (({children}) => {
    return <tbody className="list">
            <SortableContext items={rows} strategy={verticalListSortingStrategy}>{children}</SortableContext>
        </tbody>
    });


    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    );

    function handleDragStart(event) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        console.log(event);
        if (active.id !== over?.id) {
            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            rowsSorted(arrayMove(data, oldIndex, newIndex));
        }

        setActiveId(null);
    }

    function handleDragCancel() {
        setActiveId(null);
    }

    const selectedRow = useMemo(() => {
        if (!activeId) {
        return null;
        }
        const row = rows.find(({ original }) => original.id === activeId);
        if(row) {
            prepareRow(row);
        }
        return row;
    }, [activeId, rows, prepareRow]);

    return (
        <>
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragCancel={handleDragCancel}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
        >
            <table className="table table-sm table-nowrap table-hover card-table" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                <span style={{cursor: 'pointer'}} className="text-muted list-sort" data-sort="products-product"
                                   key={column.id}>
                                    {column.render('Header')}
                                </span>
                            </th>
                        ))}
                        <th colSpan={1}></th>
                    </tr>
                ))}
                </thead>
                <TableBody>
                {data.length
                    ? rows.map(row => {
                        prepareRow(row)
                        return <TableRow key={row.id} row={row} />
                    })
                    : <tr>
                        <th>Brak danych</th>
                    </tr>
                }
                </TableBody>
            </table>
            <DragOverlay>
                {activeId && (
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr {...selectedRow?.getRowProps()}>
                            {selectedRow?.cells.map(cell => (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>)}
            </DragOverlay>
            </DndContext>
        </>
    )
}