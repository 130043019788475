import React, {useEffect, useState} from 'react';
import logoImage from '../assets/images/sally_full_logo.svg';import {useDispatch} from "react-redux";
import {LoginData} from "../models/Auth";
import {loginAction} from "../store/auth/actions";
import {LoginForm} from "../shared/forms/LoginForm";
import {LOGIN_FAIL, LOGIN_SUCCESS} from "../store/auth/types";
import {AppDispatch} from "../store/reducers";
import {loadSettings} from "../store/settings/actions";
import Loader from "react-loader";
import { useParams } from 'react-router-dom';
import UserService from '../services/UserService';

export const ActivateAccountPage = () => {
    
    let { token } = useParams<any>();
    const [loaded, setLoaded] = useState<boolean>(false)
    const [isFailed, setFailed] = useState<boolean>(false)
    const activateAccount = () => {
        UserService.activate(token).then((res) => {
            if(res?.data) {
                setLoaded(true);
            }
            if(res?.error) {
                setFailed(true);
            }
        })
    }

    useEffect(() => {
        activateAccount();
    }, []);

    return (
        <div
            className='d-flex align-items-center justify-content-center auth border-top border-top-2 border-primary h-100 min-vh-100'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <img src={logoImage} alt="Sally logo" style={{width: '185px', height: '75px'}}/>
                    </div>
                    <div className="col-12 col-md-5 col-xl-4 my-2">
                        <h1 className="display-4 text-left mb-3">
                            Sally
                        </h1>
                        <p className="text-muted text-left mb-5">
                            Panel zarządzania
                        </p>
                        {!loaded ?
                            <div style={{height: '30vh'}}>
                                <Loader loaded={loaded} options={{color: 'white'}} />
                            </div>
                            :
                            <div>
                                <p className="mb-3">Twoje konto zostało pomyślnie aktywowane!</p>
                                <a href="/account" className="btn btn-lg btn-block btn-primary mb-3">Przejdź do panelu klienta</a>
                            </div>
                        }
                        {isFailed && <small className="text-danger">Niepoprawny login lub hasło</small>}
                    </div>
                </div>
            </div>
        </div>
    )
}