import { ReactComponent } from '*.svg';
import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { renderPreview } from '../../../helpers/renderer';
import SignService from '../../../services/SignService';
import {NotificationManager} from 'react-notifications';

export const ContractStep4 = ({
    nextStep,
    prevStep,
    showStep,
    contract,
    token
}) => {

    const [content, setContent] = useState('');
    const [shouldRegister, setShouldRegister] = useState(false);

    const getPreviews = async () => {
        if(contract && contract?.link) {
            const previews = await SignService.preview(contract?.link);
            if(previews) {
                setContent(previews?.content);
            }
        }
    }

    const sign = async () => {
        const signed = await SignService.signContract(token, shouldRegister);
        if(signed) {
            nextStep();
        } else {
            NotificationManager.error('Wystąpił błąd i nie mogę podpisać umowy')
        }
    }

    React.useEffect(() => {
        if(showStep) {
            getPreviews();
        }
    }, [showStep]);

    return (
        <div className={`step step-4 ${showStep ? 'step-visible' : ''}`}>
            <div className='row mb-5'>
                <div className='col mw-650'>
                    Warunki opisane poniżej będą stanowiły treść umowy.
                </div>
            </div>
            <div className='row mt-5 mb-5'>
                <div className='col'>
                    <div className="card">
                        <div className="card-body contract-preview">
                            {renderPreview(content)}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-3 mt-2'>
                <div className='col'>
                    <label><input type="checkbox"
                        defaultChecked={shouldRegister}
                        onChange={() => setShouldRegister(!shouldRegister)}
                    />
                    Automatycznie utwórz konto w systemie Sally Sign, aby móc wygodnie przeglądać podpisane umowy.
                    </label>
                    
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <button className='btn btn-large btn-light mr-3 mb-3' onClick={prevStep}>
                        &lt; Wstecz
                    </button>
                    <button className='btn btn-large btn-success mb-3' onClick={sign}>
                        <Icon.Lock /> Podpisz umowę w sposób elektroniczny
                    </button>
                </div>
            </div>
        </div>
    )
}