import React, {useState} from 'react';
import logoImage from '../../../assets/images/logo_icon.svg';

import {
    Link,
    useLocation,
} from "react-router-dom";

export default ({show = true}) => {

    const [current, setCurrent] = useState('');

    let location = useLocation();

    React.useEffect(() => {
        if (location.pathname && location.pathname.startsWith('/account')) {
            setCurrent(location.pathname);
        }
    }, [location]);

    return show ? (
        <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
            <div>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse"
                        aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>

                <Link className="navbar-brand" to="/account">
                    <img src={logoImage} className="navbar-brand-img mx-auto" alt="Sally"/>
                </Link>

                <div className="collapse navbar-collapse" id="sidebarCollapse">

                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/account"
                                  className={"nav-link" + (current === '/account' ? ' active' : '')}>
                                <i className="fe fe-users"/> Moje umowy
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/account/edit" className={"nav-link" + (current.includes('/account/edit') ? ' active' : '')}>
                                <i className="fe fe-home"/> Moje dane
                            </Link>
                        </li>

                        <li className="nav-item">
                            <hr className="navbar-divider my-3" />
                        </li>
                        <li className="nav-item">
                            <Link to="/logout" className={"nav-link" + (current.includes('/logout') ? ' active' : '')}>
                                <i className="fe fe-log-out"/> Wyloguj się
                            </Link>
                        </li>
                    </ul>


                </div>

            </div>
        </nav>
    ) : null
}