import React from 'react';
import DashboardHeader from '../../../shared/components/DashboardHeader';
import UserTable from "../../../shared/components/admin/tables/UserTable";

export default () =>
    <>
        <DashboardHeader title="Użytkownicy"/>
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="card">
                    <UserTable/>
                </div>
            </div>
        </div>
    </>