import React, {FunctionComponent} from 'react';
import {RouteComponentProps, Route, Redirect} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {User} from "../../models/User";
import {userHasRoles, UserRole} from "../../routes/user-roles";
import {State} from "../../models/State";

interface AuthRoutePops {
    readonly path: string,
    readonly component: React.FC<RouteComponentProps>,
    readonly exact?: boolean,
    readonly requiredRoles: UserRole[]
}

export const AuthRoute: FunctionComponent<AuthRoutePops> = ({path, component: Component, exact = false, requiredRoles = []}) => {
    const user: User | null = useSelector(({auth}: State) => auth.user)
    const isAuthed = !!sessionStorage.getItem('token')
    const userHasRequiredRole = user && userHasRoles(user, requiredRoles)

    return <Route
        exact={exact}
        path={path}
        render={(props: RouteComponentProps) => isAuthed && userHasRequiredRole
            ? <Component {...props} />
            : <Redirect to="/"/>
        }
    />
}