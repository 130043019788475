import React, { useState } from 'react';
import logoImage from '../../../assets/images/sally_full_logo.svg';

import {
    Link,
    useLocation,
} from "react-router-dom";
import { User } from '../../../models/User';
import { useSelector } from 'react-redux';
import { State } from '../../../models/State';
import {userHasRoles, UserRoleGroups} from "../../../routes/user-roles";

export default ({
    show = true
}) => {

    const [current, setCurrent] = useState('');

    let location = useLocation();
    
    const user: User | null = useSelector(({auth: {user}}: State) => user)


    React.useEffect(() => {
        if(location.pathname && location.pathname.startsWith('/admin')) {
            setCurrent(location.pathname);
        }
    }, [location]);

    return show ? (
        <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark" id="sidebar">
            <div className="container-fluid">
            
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
                </button>
            
                <Link className="navbar-brand" to="/admin">
                    <img src={logoImage} className="navbar-brand-img" alt="Sally" />
                </Link>
            
                <div className="collapse navbar-collapse" id="sidebarCollapse">
            
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to="/admin" className={"nav-link" + (current === '/admin' ? ' active' : '')}>
                            <i className="fe fe-home"/> Najnowsze
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/admin/agreements" className={"nav-link" + (current.includes('/admin/agreements') ? ' active' : '')}>
                            <i className="fe fe-file"/> Umowy
                        </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/admin/users" className={"nav-link" + (current.includes('/admin/users') ? ' active' : '')}>
                        <i className="fe fe-users"/> Zleceniobiorcy
                    </Link>
                    </li>
                    {user && userHasRoles(user, UserRoleGroups.admin) && <>
                        <li className="nav-item">
                        <hr className="navbar-divider my-3" />
                        </li>
                        <li className="nav-item">
                        <Link to="/admin/recruiters" className={"nav-link" + (current.includes('/admin/recruiters') ? ' active' : '')}>
                            <i className="fe fe-user-check"/> Menedżerowie
                        </Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/admin/clients" className={"nav-link" + (current.includes('/admin/clients') ? ' active' : '')}>
                            <i className="fe fe-briefcase" /> Klienci
                        </Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/admin/templates" className={"nav-link" + (current.includes('/admin/templates') ? ' active' : '')}>
                            <i className="fe fe-clipboard"/> Szablony
                        </Link>
                        </li>
                        <li className="nav-item">
                        <Link to="/admin/settings" className={"nav-link" + (current.includes('/admin/settings') ? ' active' : '')}>
                            <i className="fe fe-settings"/> Administracja
                        </Link>
                        </li>
                    </>}
                    <li className="mt-auto"></li> 
                    <li className="nav-item nav-item-less">
                        <Link to="/admin" className={"nav-link" + (current.includes('/logout') ? ' active' : '')}>
                            Polityka prywatności
                        </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/logout" className={"nav-link" + (current.includes('/logout') ? ' active' : '')}>
                        <i className="fe fe-log-out"/> Wyloguj się
                    </Link>
                    </li>
                </ul>
            
                
                </div>
            
            </div>
        </nav>
    ) : null
}