export const ObjectAssignBySchema = (schema, model) => {
    if (schema && !Object.keys(schema).length) {
        return model
    }

    if(model) {
        for (const prop in schema) {
            if (typeof model[prop] === 'object') {
                schema[prop] = ObjectAssignBySchema(schema[prop], model[prop])
            } else {
                schema[prop] = model[prop]
            }
        }
    }

    return schema
}

export const isIterable = (value) => Symbol.iterator in Object(value);

export const computedArray = <T>(data: T[], onUpdate: (T) => void) => ({
    get: (): T[] => data,
    set: (newVal: T[]): void => {
        onUpdate(newVal)
    },
    update: (value: T, index: number): void => {
        const newValues = [...data]
        newValues.splice(index, 1, value)
        onUpdate(newValues)
    },
    add: (value: T): void => {
        const newValues = [...data]
        newValues.push(value)
        onUpdate(newValues)
    },
    delete: (index: number): void => {
        const newValues = [...data]
        newValues.splice(index, 1)
        onUpdate(newValues)
    }
})
