import {AxiosResponse} from 'axios'
import {Settings} from "../models/Settings";
import {authHeader} from "./auth-header";
import Api from "./Api";

export default class SettingsService {
    static settings = (): Promise<Settings | null> =>
        Api
            .get(`/settings`, {headers: authHeader()})
            .then((response: AxiosResponse<Settings>) => response.data || null)
            .catch(error => null)
}

