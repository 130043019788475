import React, { useState } from 'react';
import Modal from "../../Modal";
import {Template} from "../../../../models/Template";
import {AttributeValue} from "../../../../models/Attribute";
import {printTemplate, renderPreview} from "../../../../helpers/renderer";
import SignService from '../../../../services/SignService';
import { Contract } from '../../../../models/Contract';

export interface AttributesAddModalProps {
    readonly isOpen: boolean,
    readonly template: Template,
    readonly contractData: Partial<Contract>|null,
    readonly attributeValues: AttributeValue[],
    readonly onClose: () => void,
}

export default (
    {
        isOpen = false,
        template: {content_template, bill_template},
        attributeValues,
        contractData,
        onClose
    }: AttributesAddModalProps) => {

        const [content, setContent] = useState('');
        const [bill, setBill] = useState('');

        const getPreviews = async () => {
            if(isOpen && contractData && contractData?.link) {
                const previews = await SignService.preview(contractData?.link);
                if(previews) {
                    setContent(previews?.content);
                    setBill(previews?.bill);
                }
            }
        }

        React.useEffect(() => {
            getPreviews();
        }, [isOpen]);

    return <>
        <Modal isOpen={isOpen} title="Podgląd umowy" onRequestClose={onClose}>
            {{
                body: <>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Szablon umowy</h3>
                        </div>
                        <div className="card-body">
                            {renderPreview(content)}
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Szablon rachunku</h3>
                        </div>
                        <div className="card-body">
                            {renderPreview(bill)}
                        </div>
                    </div>
                </>
            }}
        </Modal>
    </>
}