import React, {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {User} from "../../../../models/User";
import UserRoleSelect from "./fields/select/UserRoleSelect";
import CountrySelect from "../../../forms/fields/select/CountrySelect";
import {UserRole} from "../../../../routes/user-roles";
import {validateEmail} from "../../../../helpers/validator";
import DashboardHeader from '../../DashboardHeader';

export interface UsersFormProps {
    readonly userData?: Partial<User> | null
    fixedRoles?: UserRole[]
    readonly onSubmitForm: (user: User) => void
}

interface FullName {
    name: string,
    surname: string
}

interface BuildingData {
    house: string,
    flat: string
}

export default ({userData = {}, onSubmitForm, fixedRoles = []}: UsersFormProps) => {
    const [errors, setErrors] = useState<string[]>([])
    const [form, setForm] = useState<User>({
        email: '',
        fb_profile: '',
        full_name: '',
        marketing_consent: false,
        phone: '',
        roles: [],
        workplace: '',
        school: '',
        address: {
            building: '',
            city: '',
            country: 'Polska',
            postal_code: '',
            street: ''
        }
    })

    const fullNameData = {
        get: (): FullName => {
            const [name = '', surname = ''] = form.full_name.split(' ')
            return {name, surname}
        },
        set: ({name = '', surname = ''}: FullName): void =>
            handleInputForm({full_name: `${name}${surname && (' ' + surname)}`})
    }

    const buildingData = {
        get: (): BuildingData => {
            const [house = '', flat = ''] = form.address?.building ? form.address?.building?.split('/') : ['',''];
            return {house, flat}
        },
        set: ({house = '', flat = ''}: BuildingData): void =>
            handleInputForm({address: {...form.address, building: `${house}${flat && ('/' + flat)}`}})
    }

    const handleInputForm = (input: Partial<User>): void => {
        setForm(oldValue => ({...oldValue, ...input}))
    }

    useEffect((): void => {
        userData && handleInputForm(userData)
    }, [userData])

    const onSubmit = (e): void => {
        e.preventDefault()
        !validate().length && onSubmitForm(form)
    }

    const validate = (): string[] => {
        const errors: string[] = []
        !fullNameData.get().name.length && errors.push('name')
        !fullNameData.get().surname.length && errors.push('surname')
        !validateEmail(form.email) && errors.push('email')
        !form.roles.length && errors.push('roles')
        setErrors(errors)
        errors.length && NotificationManager.error('Wypełnij wymagane pola.')
        return errors
    }

    return <>
        <DashboardHeader title={`${form.full_name ? form.full_name : 'Użytkownik'}`}/>
        <form className="form-inline">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group row">
                                <div className="col-sm-12 col-md row">
                                    <label className="col-auto col-form-label">Imię</label>
                                    <div className="col-auto">
                                        <input type="text"
                                            placeholder="Imię"
                                            className={`form-control ${errors.includes('name') && 'is-invalid'}`}
                                            value={fullNameData.get().name}
                                            onChange={({target: {value: name}}) =>
                                                fullNameData.set({
                                                    name,
                                                    surname: fullNameData.get().surname
                                                })}/>
                                        {errors.includes('name') &&
                                        <small className="text-danger">Pole imię nie może być puste</small>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md row">
                                    <label className="col-auto col-form-label">Nazwisko</label>
                                    <div className="col-auto">
                                        <input type="text"
                                            placeholder="Nazwisko"
                                            className={`form-control ${errors.includes('surname') && 'is-invalid'}`}
                                            value={fullNameData.get().surname}
                                            onChange={({target: {value: surname}}) =>
                                                fullNameData.set({
                                                    name: fullNameData.get().name,
                                                    surname
                                                })}/>
                                        {errors.includes('surname') &&
                                        <small className="text-danger">Pole nazwisko nie może być puste</small>}
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary mb-2 float-right col-auto"
                                        onClick={onSubmit}>Zapisz
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Dane użytkownika</h3>
                        </div>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Email</label>
                                    <input className={`form-control ${errors.includes('email') && 'is-invalid'}`}
                                           placeholder="Email"
                                           type="email"
                                           value={form.email}
                                           onChange={({target: {value: email}}) => {
                                               handleInputForm({email})
                                           }}/>
                                    {errors.includes('email') &&
                                    <small className="text-danger">Nieprawidłowy adres email</small>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Facebook</label>
                                    <input className="form-control"
                                           type="url"
                                           placeholder="https://facebook.com"
                                           value={String(form.fb_profile || '')}
                                           onChange={({target: {value: fb_profile}}) => {
                                               handleInputForm({fb_profile})
                                           }}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Telefon</label>
                                    <input className="form-control"
                                           type="tel"
                                           placeholder="Nr Telefonu"
                                           value={String(form.phone || '')}
                                           onChange={({target: {value: phone}}) => {
                                               handleInputForm({phone})
                                           }}/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Rola</label>
                                    <UserRoleSelect value={form.roles}
                                                    fixedOptions={fixedRoles}
                                                    onChange={roles => handleInputForm({roles})}/>
                                    {errors.includes('roles') &&
                                    <small className="text-danger">Pole rola nie może być puste</small>}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Wykształcenie</label>
                                    <input className="form-control"
                                           placeholder="Wykształcenie"
                                           type="text"
                                           value={form.school}
                                           onChange={({target: {value: school}}) => {
                                               handleInputForm({school})
                                           }}/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Miejsce zatrudnienia</label>
                                    <input className="form-control"
                                           type="text"
                                           placeholder="Miejsce zatrudnienia"
                                           value={form.workplace}
                                           onChange={({target: {value: workplace}}) => {
                                               handleInputForm({workplace})
                                           }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Dane adresowe</h3>
                        </div>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Kraj</label>
                                    <CountrySelect value={form.address.country}
                                                   onChange={country =>
                                                       handleInputForm({
                                                           address: {
                                                               ...form.address,
                                                               country: String(country)
                                                           }
                                                       })
                                                   }/>
                                    {errors.includes('country') &&
                                    <small className="text-danger">Pole kraj nie może być puste</small>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Miasto</label>
                                    <input className={`form-control ${errors.includes('city') && 'is-invalid'}`}
                                           type="text"
                                           placeholder="Miasto"
                                           value={form.address.city}
                                           onChange={({target: {value: city}}) => {
                                               handleInputForm({address: {...form.address, city}})
                                           }}/>
                                    {errors.includes('city') &&
                                    <small className="text-danger">Pole miasto nie może być puste</small>}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Kod pocztowy</label>
                                    <input className={`form-control ${errors.includes('postal_code') && 'is-invalid'}`}
                                           placeholder="Kod pocztowy"
                                           type="text"
                                           value={form.address.postal_code}
                                           onChange={({target: {value: postal_code}}) => {
                                               handleInputForm({address: {...form.address, postal_code}})
                                           }}/>
                                    {errors.includes('postal_code') &&
                                    <small className="text-danger">Pole kod pocztowy nie może być puste</small>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Ulica</label>
                                    <input className={`form-control ${errors.includes('street') && 'is-invalid'}`}
                                           type="text"
                                           placeholder="Ulica"
                                           value={form.address.street}
                                           onChange={({target: {value: street}}) => {
                                               handleInputForm({address: {...form.address, street}})
                                           }}/>
                                    {errors.includes('street') &&
                                    <small className="text-danger">Pole ulica nie może być puste</small>}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Nr domu</label>
                                    <input className={`form-control ${errors.includes('house') && 'is-invalid'}`}
                                           placeholder="Nr domu"
                                           type="text"
                                           value={buildingData.get().house}
                                           onChange={({target: {value: house}}) =>
                                               buildingData.set({
                                                   house,
                                                   flat: buildingData.get().flat
                                               })}/>
                                    {errors.includes('house') &&
                                    <small className="text-danger">Pole nr domu nie może być puste</small>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Nr mieszkania</label>
                                    <input className="form-control"
                                           placeholder="Nr mieszkania"
                                           type="text"
                                           value={buildingData.get().flat}
                                           onChange={({target: {value: flat}}) =>
                                               buildingData.set({
                                                   house: buildingData.get().house,
                                                   flat
                                               })}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}