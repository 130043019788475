import React from 'react';
import CardBlock from '../../../shared/components/CardBlock';
import DashboardHeader from '../../../shared/components/DashboardHeader';

export default () => {

    return (        
        <>
            <div className="main-content">
                <DashboardHeader title="Ustawienia" />
            </div>
            <div className="tab-content mb-3">
                <div className="row">
                    <p className="h6 header-pretitle">
                        Raporty
                    </p>
                </div>
                <div className="row">
                    <div className="col col-lg-4 col-md-6 col-sm-12">
                        <CardBlock
                            link='/admin/settings/'
                            name='Zestawienie PIT-11'
                            desc='Generowanie tabeli rocznej PIT-11'
                            icon='send'
                            buttonName='Przejdź'
                        />
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12">
                        <CardBlock
                            link='/admin/settings/'
                            name='Raport zdarzeń'
                            desc='Zapis akcji podejmowanych w systemie'
                            icon='paperclip'
                            buttonName='Przejdź'
                        />
                    </div>
                </div>
            </div>
            <div className="tab-content mb-3">
                <div className="row">
                    <p className="h6 header-pretitle">
                        Ustawienia
                    </p>
                </div>
                <div className="row">
                    <div className="col col-lg-4 col-md-6 col-sm-12">
                        <CardBlock
                            link='/admin/settings/lockrules/'
                            name='Blokady'
                            desc='Zdefiniuj warunki ograniczające wypełnienie umowy'
                            icon='lock'
                            buttonName='Przejdź'
                        />
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12">
                        <CardBlock
                            link='/admin/settings/warningrules/'
                            name='Ostrzeżenia'
                            desc='Zdefiniuj ostrzeżenia wyświetlane podczas podpisywania umowy'
                            icon='alert-triangle'
                            buttonName='Przejdź'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}