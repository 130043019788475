import React, {useEffect, useMemo, useState} from 'react';
import {Attribute, DefaultValue} from "../../../../models/Attribute";
import Modal from "../../Modal";
import uniqueId from "../../../../helpers/uniqueId";
import ConfirmButton from "../../ConfirmButton";
import IconSelect from '../../../forms/fields/select/IconSelect';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { AttributeFieldRenderType } from '../forms/fields/attribute/AttributeFieldRenderType';
import AttributeRenderTypeSelect from '../forms/fields/select/AttributeRenderTypeSelect';
import AttributeFieldGeneric from '../forms/fields/attribute/AttributeFieldGeneric';

export interface AttributesAddModalProps {
    readonly isOpen: boolean,
    readonly attribute?: Attribute | null,
    readonly onSubmit: (attribute: Attribute) => void,
    readonly onCancel: () => void,
}

export default ({isOpen = false, attribute = null, onSubmit, onCancel}: AttributesAddModalProps) => {

    const emptyData: Attribute = {
        id: null, name: '', default_value: [], display_name: '', list_order: 0,
        show_on_summary: false, icon: null, render_type: AttributeFieldRenderType.string
    }
    const [errors, setErrors] = useState<string[]>([])
    const [showEmojiPicker, setEmojiPicker] = useState<boolean>(false);
    const [attributeData, setAttributeData] = useState<Attribute>(() => (emptyData))
    const title = useMemo(() => attribute ? 'Edycja atrybutu' : 'Dodawanie Atrybutu', [attribute])

    useEffect(() => {
        isOpen && setAttributeData(() => ({...emptyData, uniqueId: uniqueId(), ...attribute}))
    }, [isOpen])

    const handleUpdateDefaultValues = (index: number, value: string | null = null, property: string = 'value'): void => {
        const values = attributeData.default_value
        value
            ? values[index][property] = value
            : values.splice(index, 1)
        handleUpdateAttributeData({default_value: values})
    }

    const handleUpdateAttributeData = (attribute: Partial<Attribute>): void =>
        setAttributeData(oldData => ({...oldData, ...attribute}))

    const handlePushDefaultValue = (): void => {
        const values = attributeData.default_value
        values.push({
            title: '',
            value: ''
        })
        handleUpdateAttributeData({default_value: values})
    }

    const handleCancel = (): void => {
        setAttributeData(() => emptyData)
        onCancel()
    }

    const handleSubmit = (): void => {
        if (!validate().length) {
            const values = attributeData.default_value.filter(value => !!value)
            onSubmit({...attributeData, default_value: values})
            setAttributeData(() => emptyData)
        }
    }

    const validate = (): string[] => {
        const errors: string[] = []
        !attributeData.name && errors.push('username')
        setErrors(errors)
        return errors
    }

    return <Modal isOpen={isOpen} title={title} onRequestClose={handleCancel}>
        {{
            body: <>
                <form>
                    
                    
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label>Nazwa w szablonie</label>
                                <input className={`form-control ${errors.includes('name') && 'is-invalid'}`} placeholder="Nazwa"
                                    value={attributeData.name}
                                    onChange={({target: {value: name}}) => {
                                        handleUpdateAttributeData({name})
                                    }}/>
                                {errors.includes('name') && <small className="text-danger">Nazwa jest wymagana</small>}
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label>Nazwa wyświetlana</label>
                                <input className={`form-control ${errors.includes('displayName') && 'is-invalid'}`} placeholder="Nazwa"
                                    value={attributeData.display_name}
                                    onChange={({target: {value: display_name}}) => {
                                        handleUpdateAttributeData({display_name})
                                    }}/>
                                {errors.includes('displayName') && <small className="text-danger">Nazwa jest wymagana</small>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label className="col-auto col-form-label">Typ pola</label>
                                <div className="col">
                                    <AttributeRenderTypeSelect
                                        onChange={render_type => handleUpdateAttributeData({render_type: AttributeFieldRenderType[render_type]})}
                                        value={attributeData.render_type || ''}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="col-auto col-form-label">Pokazuj w podsumowaniu</label>
                                <div className="col">
                                    <input type="checkbox"
                                        checked={attributeData.show_on_summary || false}
                                        onChange={e => handleUpdateAttributeData({show_on_summary: e.target.checked ? true : false})}/>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="d-block mb-2">Ikona</label>

                                <a className="btn btn-md btn-light" onClick={() => setEmojiPicker(true)}>
                                    {attributeData?.icon || 'Wybierz...'}
                                </a>
                                
                                {showEmojiPicker && <Picker 
                                    style={{ position: 'absolute', bottom: '20px', right: '20px' }}
                                    onSelect={(emoji) => {
                                            handleUpdateAttributeData({icon: emoji?.native});
                                            setEmojiPicker(false)
                                    }}
                                    title="Wybierz ikonę..."
                                    emoji=""
                                />}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <p><strong>Domyślne wartości:</strong></p>
                    {
                        attributeData.default_value.map((value, index) => (
                            <div key={index}>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>{`Tytuł wartości ${index + 1}`}</label>
                                                <input
                                                    className="form-control mb-1"
                                                    placeholder={`Tytuł ${index + 1}`} value={attributeData.default_value[index]?.title}
                                                    onChange={({target: {value}}) => {
                                                        handleUpdateDefaultValues(index, value, 'title')
                                                    }}/>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>{`Wartość ${index + 1}`}</label>
                                                <AttributeFieldGeneric 
                                                    currValue={attributeData.default_value[index]?.value} 
                                                    setCurrValue={(newVal) => handleUpdateDefaultValues(index, newVal, 'value')} 
                                                    attribute={attributeData} 
                                                />
                                            </div>

                                        </div>

                                    </div>
                                    <ConfirmButton
                                        modalTitle="Usunąć wartość"
                                        buttonText="Usuń wartość"
                                        onSubmit={() => {
                                            handleUpdateDefaultValues(index)
                                        }}/>
                                    <hr />
                            </div>
                        ))
                    }
                    <button type="button" className="btn-sm btn-primary" onClick={handlePushDefaultValue}>
                        Dodaj wartość
                    </button>
                </form>
            </>,

            footer: <>
                <div className="float-right">
                    <button type="button" className="btn btn-sm btn-primary" onClick={handleSubmit}>Zapisz</button>
                    <button type="button" className="btn btn-sm btn-danger ml-1" onClick={handleCancel}>Anuluj</button>
                </div>
            </>
        }}
    </Modal>
}