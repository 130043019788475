import {AxiosResponse} from 'axios'
import {authHeader} from "./auth-header";
import {TableFilters, TableResponse, TableService} from "../models/Table";
import {Contract} from "../models/Contract";
import Api from "./Api";
import {ObjectAssignBySchema} from "../helpers/init-data";

export default class ContractService implements TableService<Contract> {

    static contract = (id: number): Promise<null | Contract> =>
        Api.get(`/contracts/${id}`, {headers: authHeader()})
            .then((response: AxiosResponse<Contract>) => response.data)
            .catch(error => null)

    static store = (model: Contract): Promise<null | Contract> =>
        Api.post(`/contracts`, ContractService.initStoreBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<Contract>) => response.data)
            .catch(error => null)

    static update = (model: Contract): Promise<null | Contract> =>
        Api.put(`/contracts/${model.id}`, ContractService.initUpdateBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<Contract>) => response.data)
            .catch(error => null)

    static send = (id: number): Promise<null | Contract> =>
        Api.post(`/contracts/${id}/send`, {}, {headers: authHeader()})
            .then((response: AxiosResponse<Contract>) => response.data)
            .catch(error => null)

    tableData(filters: Partial<TableFilters> = {}): Promise<TableResponse<Contract> | null> {
        return Api.get(`/contracts`, {params: filters, headers: authHeader()})
            .then((response: AxiosResponse<TableResponse<Contract>>) => response.data)
            .catch(error => null)
    }

    static initStoreBody = (model: Contract): Partial<Contract> =>
        ObjectAssignBySchema({
            description: '',
            state: 'draft',
            amount_brutto: 0,
            amount_netto: 0,
            // link: '',
            // bank_account: '',
            // pesel: '',
            user: {
                id: null
            },
            recruiter: {
                id: null
            },
            template: {
                id: null
            },
            client: {
                id: null
            },
            warning_rules: model.warning_rules?.map(item => ObjectAssignBySchema({
                id: item.id
            }, item)),
            lock_rules: model.lock_rules?.map(item => ObjectAssignBySchema({
                id: item.id
            }, item)),
            attribute_values: model.attribute_values.map(attribute => ObjectAssignBySchema({
                value: '',
                attribute: {
                    id: null
                }
            }, attribute))
        }, model)

    static initUpdateBody = (model: Contract): Partial<Contract> =>
        ObjectAssignBySchema({
            number: '',
            description: '',
            state: 'draft',
            amount_brutto: 0,
            amount_netto: 0,
            user: {
                id: null
            },
            recruiter: {
                id: null
            },
            template: {
                id: null
            },
            client: {
                id: null
            },
            warning_rules: model.warning_rules?.map(item => ObjectAssignBySchema({
                id: item.id
            }, item)),
            lock_rules: model.lock_rules?.map(item => ObjectAssignBySchema({
                id: item.id
            }, item)),
            attribute_values: model.attribute_values.map(attribute => ObjectAssignBySchema({
                value: '',
                attribute: {
                    id: null
                }
            }, attribute))
        }, model)
}

