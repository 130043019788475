import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {State} from "../../../models/State";
import {User} from "../../../models/User";
import AccountForm from "../../../shared/components/user/forms/AccountForm";
import {NotificationManager} from 'react-notifications';
import UserService from "../../../services/UserService";
import Loader from "react-loader";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store/reducers";
import {loginAction} from "../../../store/auth/actions";
import {LOGIN_FAIL, LOGIN_SUCCESS} from "../../../store/auth/types";

export default () => {

    const user: User | null = useSelector(({auth: {user}}: State) => user)
    const [loaded, setLoaded] = useState<boolean>(true)
    const [formData, setFormData] = useState<Partial<User> | null>(user)
    const dispatcher = useDispatch<AppDispatch>();

    const handleSubmitForm = (form: Partial<User>): void => {
        setFormData(oldUser => ({...oldUser, ...user}))
        setLoaded(false)
        UserService.updateProfile(form).then(response => {
                if (response) {
                    setFormData(() => response)
                    if(form.password) {
                        dispatcher(loginAction({username: String(form.email), password: String(form.password)}))
                            .then(({type: loginStatus}) => {
                                if(loginStatus === LOGIN_FAIL) {
                                    NotificationManager.success('Wystąpił błąd podczas edycji.')
                                    window.location.href = '/'
                                }
                                loginStatus === LOGIN_SUCCESS && NotificationManager.success('Poprawnie edytowano użytkownika.')
                            }).finally(() => setLoaded(true))
                    } else {
                        NotificationManager.success('Poprawnie edytowano użytkownika.')
                        setLoaded(true)
                    }
                } else {
                    NotificationManager.error('Nie udało się zapisać użytkownika.')
                    setLoaded(true)
                }
            }
        )
    }

    return <>
        <Loader loaded={loaded}>
            <AccountForm accountData={formData} onSubmitForm={handleSubmitForm} />
            <div className="d-flex justify-content-center align-items-center my-5">
                <p>W celu usunięcia danych skontaktuj się z admin@sally.com</p>
            </div>
        </Loader>
    </>
}