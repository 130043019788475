import React from 'react';

import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";

import {RecruitersPage} from './recruiters/IndexPage';
import {TemplatesPage} from './templates/IndexPage';
import SettingsPage from './settings/IndexPage';
import {UsersPage} from './users/IndexPage';
import LeftNavigation from "../../shared/components/admin/LeftNavigation";
import {ContractsPage} from "./contracts/IndexPage";
import { ClientsPage } from './clients/IndexPage';
import {NewsPage} from "./news/IndexPage";

export const AdminPage = () => {

    const {path} = useRouteMatch();

    return (
        <div className='h-100 min-vh-100'>
            <LeftNavigation/>
            <div className="main-content">
                <div className="container-fluid">
                    <Switch>
                        <Route exact path={path}>
                            <NewsPage/>
                        </Route>
                        <Route path={`${path}/agreements`}>
                            <ContractsPage/>
                        </Route>
                        <Route path={`${path}/users`}>
                            <UsersPage/>
                        </Route>
                        <Route path={`${path}/recruiters`}>
                            <RecruitersPage/>
                        </Route>
                        <Route path={`${path}/clients`}>
                            <ClientsPage/>
                        </Route>
                        <Route path={`${path}/templates`}>
                            <TemplatesPage/>
                        </Route>
                        <Route path={`${path}/settings`}>
                            <SettingsPage/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    )
}