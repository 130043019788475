import {AxiosResponse} from 'axios'
import {authHeader} from "./auth-header";
import {TableFilters, TableResponse, TableService} from "../models/Table";
import Api from "./Api";
import {Client} from "../models/Client";
import {ObjectAssignBySchema} from "../helpers/init-data";

export default class ClientService implements TableService<Client> {

    static client = (id: number): Promise<null | Client> =>
        Api.get(`/clients/${id}`, {headers: authHeader()})
            .then((response: AxiosResponse<Client>) => response.data)
            .catch(error => null)

    static store = (model: Client): Promise<null | Client> =>
            Api.post(`/clients`, model, {headers: authHeader()})
                .then((response: AxiosResponse<Client>) => response.data)
                .catch(error => null)

    static update = (model: Client): Promise<null | Client> =>
        Api.put(`/clients/${model.id}`, ClientService.initUpdateBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<Client>) => response.data)
            .catch(error => null)

    tableData(filters: Partial<TableFilters> = {}): Promise<TableResponse<Client> | null> {
        return Api.get(`/clients`, {params: filters, headers: authHeader()})
            .then((response: AxiosResponse<TableResponse<Client>>) => response.data)
            .catch(error => null)
    }

    static initUpdateBody = (model: Client): Partial<Client> =>
        ObjectAssignBySchema({
            full_name: model.full_name,
            phone: model.phone,
            address: model.address,
            nip: model.nip,
            full_valid_name: model.full_valid_name,
            is_active: model.is_active,
            contact_people: model.contact_people
        }, model)
}

