import React, {useEffect, useState} from 'react';
import {Template} from "../../../../models/Template";
import AttributesClientTable from "../tables/AttributesClientTable";
import {Editor} from "@tinymce/tinymce-react";
import {NotificationManager} from 'react-notifications';
import { Client, ClientContactPerson } from '../../../../models/Client';
import CountrySelect from '../../../forms/fields/select/CountrySelect';
import DashboardHeader from '../../DashboardHeader';

export interface ClientFormProps {
    readonly clientData?: Partial<Client> | null
    readonly onSubmitForm: (template: Client) => void
}

export default ({clientData = {}, onSubmitForm}: ClientFormProps) => {
    const [errors, setErrors] = useState<string[]>([])
    const [form, setForm] = useState<Client>({
        full_name: '',
        full_valid_name: '',
        phone: '',
        nip: '',
        is_active: false,
        address: {
            building: '',
            city: '',
            country: '',
            postal_code: '',
            street: ''
        },
        contact_people: [],
        ...clientData
    })

    const handleInputForm = (input: Partial<Client>): void => {
        setForm(oldValue => ({...oldValue, ...input}))
    }

    useEffect((): void => {
        clientData && handleInputForm(clientData)
    }, [clientData])

    const onSubmit = (e): void => {
        e.preventDefault()
        !validate().length && onSubmitForm(form)
    }

    const validate = (): string[] => {
        const errors: string[] = []
        !form.full_name.length && errors.push('full_name')
        setErrors(errors)
        errors.length && NotificationManager.error('Wypełnij wymagane pola.')
        return errors
    }

    return <>
        <DashboardHeader title={`${form.full_name ? form.full_name : 'Klient'}`}/>
        <form className="form-inline">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group row justify-content-between">
                                <h4 className="h4">Klient</h4>
                                <div className="col-sm-5 row">
                                    <label className="col-auto col-form-label">Nazwa</label>
                                    <div className="col">
                                        <input type="text"
                                            className={`form-control ${errors.includes('full_name') && 'is-invalid'}`}
                                            placeholder="Nazwa"
                                            value={form.full_name}
                                            onChange={e => handleInputForm({full_name: e.target.value})}/>
                                        {errors.includes('name') &&
                                        <small className="text-danger">Nazwa nie może być pusta</small>}
                                    </div>
                                </div>
                                <div className="col-sm-5 row align-items-center">
                                    <label className="col-auto col-form-label">Aktywny</label>
                                    <div className="col">
                                        <input type="checkbox"
                                            checked={form.is_active}
                                            onChange={e => handleInputForm({is_active: e.target.checked ? true : false})}/>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary mb-2 float-right col-auto"
                                        onClick={onSubmit}>Zapisz
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Dane fakturowe</h3>
                        </div>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col-12 col-md-4">
                                    <label>Pełna nazwa firmy</label>
                                    <input className="form-control"
                                           type="text"
                                           placeholder="Pełna nazwa"
                                           value={String(form.full_valid_name)}
                                           onChange={({target: {value: full_valid_name}}) => {
                                               handleInputForm({full_valid_name})
                                           }}/> 
                                </div>
                                <div className="col-12 col-md-4">
                                    <label>NIP</label>
                                    <input className="form-control"
                                           type="text"
                                           placeholder="NIP"
                                           value={String(form.nip)}
                                           onChange={({target: {value: nip}}) => {
                                               handleInputForm({nip})
                                           }}/>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label>Kraj</label>
                                    <CountrySelect value={form.address.country}
                                                   onChange={country =>
                                                       handleInputForm({
                                                           address: {
                                                               ...form.address,
                                                               country: String(country)
                                                           }
                                                       })
                                                   }/>
                                    {errors.includes('country') &&
                                    <small className="text-danger">Pole kraj nie może być puste</small>}
                                </div>
                            </div>
                            <div className="row form-group">
                                
                                <div className="col-12 col-md-4">
                                    <label>Miasto</label>
                                    <input className={`form-control ${errors.includes('city') && 'is-invalid'}`}
                                           type="text"
                                           placeholder="Miasto"
                                           value={form.address.city}
                                           onChange={({target: {value: city}}) => {
                                               handleInputForm({address: {...form.address, city}})
                                           }}/>
                                    {errors.includes('city') &&
                                    <small className="text-danger">Pole miasto nie może być puste</small>}
                                </div>
                                <div className="col-12 col-md-4">
                                    <label>Kod pocztowy</label>
                                    <input className={`form-control ${errors.includes('postal_code') && 'is-invalid'}`}
                                           placeholder="Kod pocztowy"
                                           type="text"
                                           value={form.address.postal_code}
                                           onChange={({target: {value: postal_code}}) => {
                                               handleInputForm({address: {...form.address, postal_code}})
                                           }}/>
                                    {errors.includes('postal_code') &&
                                    <small className="text-danger">Pole kod pocztowy nie może być puste</small>}
                                </div>
                                <div className="col-12 col-md-4">
                                    <label>Ulica</label>
                                    <input className={`form-control ${errors.includes('street') && 'is-invalid'}`}
                                           type="text"
                                           placeholder="Ulica"
                                           value={form.address.street}
                                           onChange={({target: {value: street}}) => {
                                               handleInputForm({address: {...form.address, street}})
                                           }}/>
                                    {errors.includes('street') &&
                                    <small className="text-danger">Pole ulica nie może być puste</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Osoby kontaktowe</h3>
                        </div>
                        <div className="card-body">
                            {form.contact_people.map((item: ClientContactPerson, index: number) => (
                                <div className="row form-group">
                                <div className="col-12 col-md-3">
                                    <label>Imię i nazwisko</label>
                                    <input className="form-control"
                                           type="text"
                                           disabled={!(form.contact_people[index].name !== undefined)}
                                           placeholder="Imię i nazwisko"
                                           required={true} 
                                           value={String(form.contact_people[index].name || '')}
                                           onChange={({target: {value: name}}) => {
                                               const contact_people = form.contact_people;
                                               contact_people[index].name = name;
                                               handleInputForm({contact_people})
                                           }}/> 
                                </div>
                                <div className="col-12 col-md-3">
                                    <label>Telefon</label>
                                    <input className="form-control"
                                           type="tel"
                                           disabled={!(form.contact_people[index].name !== undefined)}
                                           placeholder="Telefon"
                                           value={String(form.contact_people[index].phone || '')}
                                           onChange={({target: {value: phone}}) => {
                                               const contact_people = form.contact_people;
                                               contact_people[index].phone = phone;
                                               handleInputForm({contact_people})
                                           }}/> 
                                </div>
                                <div className="col-12 col-md-3">
                                    <label>E-mail</label>
                                    <input className="form-control"
                                           type="email"
                                           disabled={!(form.contact_people[index].name !== undefined)}
                                           placeholder="Adres e-mail"
                                           value={String(form.contact_people[index].mail || '')}
                                           onChange={({target: {value: mail}}) => {
                                               const contact_people = form.contact_people;
                                               contact_people[index].mail = mail;
                                               handleInputForm({contact_people})
                                           }}/> 
                                </div>
                                <div className="col-12 col-md-3">
                                    {form.contact_people[index].name !== undefined && <button type="submit" className="btn btn-danger"
                                            onClick={() => {
                                                const contact_people = form.contact_people;
                                                contact_people[index] = {
                                                    id: contact_people[index].id,
                                                    client: null
                                                };
                                                handleInputForm({contact_people})
                                            }}>Usuń
                                    </button>}
                                </div>
                            </div>
                            ))}
                            <div className="row">
                            <button type="submit" className="btn btn-secondary"
                                        onClick={() => {
                                            const contact_people = form.contact_people;
                                            form.contact_people.push({
                                                name: '',
                                                phone: '',
                                                mail: '',
                                                client: form.id 
                                            })
                                            handleInputForm({contact_people})
                                        }}>Dodaj
                                </button>
                            </div>
                        </div>
                    </div>
                </div>            
        </form>
    </>
}