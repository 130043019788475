import {AxiosResponse} from 'axios'
import {LoginData} from "../models/Auth";
import {JwtTokenResponse} from "../models/Response";
import Api from "./Api";

export default class AuthService {
    static jwtToken = (loginData: LoginData): Promise<string> =>
        Api
            .post('/login', loginData, {headers: {'content-Type': 'application/json'}})
            .then((response: AxiosResponse<JwtTokenResponse>) => {
                    if (response.data.hasOwnProperty('token')) {
                        sessionStorage.setItem('token', response.data.token)
                        return response.data.token
                    }
                    return ''
                }
            ).catch(error => '')


    static logout = (): void => sessionStorage.removeItem("token");
}

