import React, {ReactNode} from 'react';
import ReactModal from "react-modal";

export interface ModalProps extends ReactModal.Props {
    readonly isOpen: boolean,
    readonly title?: string,
    readonly closeButtonText?: string
    readonly children?: {
        body?: ReactNode,
        footer?: ReactNode
    }
}

export default (props: ModalProps) => {

    const {
        onRequestClose,
        closeButtonText = 'Zamknij',
        title = 'Modal',
        style,
        children: {body, footer} = {body: undefined, footer: undefined},
        isOpen
    } = props

    const customStyles = {
        content: {
            left: '20%',
            right: '10%',
            bottom: 'auto',
            maxHeight: '80vh'
        },
        overlay: {
            zIndex: 9000,
            backgroundColor: 'rgba(0,0,0,0.8)'
        }
    };

    return <>
        <ReactModal
            onRequestClose={onRequestClose}
            contentLabel={'Nowy atrybut'}
            {...props}
            style={{...style, ...customStyles}}>
            <div>
                <div className="d-flex justify-content-between">
                    <h3 className="h3">{title}</h3>
                    <span style={{cursor: 'pointer'}} onClick={onRequestClose}>X</span>
                </div>
                <hr/>
                {body}
                {body && <hr/>}
                {footer
                ||
                <>
                    <div className="float-right">
                        <button type="button" className="btn btn-sm btn-primary"
                                onClick={onRequestClose}>{closeButtonText}
                        </button>
                    </div>
                </>
                }
            </div>
        </ReactModal>
    </>
}