import React from 'react';

import DashboardHeader from '../../../shared/components/DashboardHeader';
import TemplatesTable from "../../../shared/components/admin/tables/TemplatesTable";

export default () =>
    <>
        <DashboardHeader title="Szablony umów"/>
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="card">
                    <TemplatesTable/>
                </div>
            </div>
        </div>
    </>
