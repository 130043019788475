import React, {useMemo, useState} from 'react';
import ClientTable from "../../ClientTable";
import {Attribute, AttributeValue} from "../../../../models/Attribute";
import {Column} from "react-table";
import AttributesAddEditModal from "../modals/AttributesAddEditModal";
import CreatableSelect from "react-select/creatable";
import {computedArray} from "../../../../helpers/init-data";
import AttributeValueField from '../forms/fields/AttributeValueField';
import {NotificationManager} from 'react-notifications';

export interface ContractAttributesClientTable {
    readonly attributes: Attribute[],
    readonly values: AttributeValue[],
    readonly onUpdateAttr: (data: Attribute[]) => void
    readonly onUpdateVal: (attribute: AttributeValue[]) => void
}

export default ({attributes, values, onUpdateAttr, onUpdateVal}: ContractAttributesClientTable) => {

    const [isOpenModal, setOpenModal] = useState<boolean>(false)
    const [modalData, setModalData] = useState<Attribute | null>(null)
    const attributesData = computedArray<Attribute>(attributes, onUpdateAttr)
    const valuesData = computedArray<AttributeValue>(values, onUpdateVal)

    const renderValueInfo = (default_value) => {
        if (default_value.length === 1) {
            return default_value[0]
        } else if (default_value.length > 1) {
            return `${default_value.length} domyślne odpowiedzi`
        } else {
            return '(Brak)'
        }
    }

    const handleEditAttribute = (newAttribute: Attribute): void => {
        const indexToUpdate = findAttributeIndex(newAttribute)
        if (indexToUpdate > -1) {
            attributesData.update(newAttribute, indexToUpdate)
            NotificationManager.success(`Pomyślnie zaktualizowano atrybut ${newAttribute.name}`)
        } else {
            attributesData.add(newAttribute)
            NotificationManager.success(`Pomyślnie dodano atrybut ${newAttribute.name}`)
        }
    }

    const findAttributeIndex = (searchesAttribute: Attribute) =>
        attributesData.get().findIndex(({id, uniqueId}) => {
            if (id) return id === searchesAttribute.id
            if (uniqueId) return uniqueId && searchesAttribute.uniqueId
            return false
        })

    const handleAddEditValue = (newVal: AttributeValue): void => {
        const indexToUpdate = findValueIndex(newVal)
        if (indexToUpdate > -1) {
            valuesData.update(newVal, indexToUpdate)
        } else {
            valuesData.add(newVal)
        }
    }

    const findValueIndex = ({attribute:{id: searchesId}}: AttributeValue) =>
        valuesData.get().findIndex(({attribute: {id}}) => {
            if (id) return id === searchesId
            return false
        })

    const getDefaultValueByAttribute = (attribute: Attribute) => {
        const {value} = getValueByAttribute(attribute) || {value: null}
        return value ? {value, label: value || ''} : null
    }

    const getTextDefaultValueByAttribute = (attribute: Attribute) => {
        const {value} = getValueByAttribute(attribute) || {value: null}
        return value ? value : null
    }

    const getValueByAttribute = (attribute: Attribute): AttributeValue | null => values.find(value => value.attribute.id === attribute.id) || null

    const columns = useMemo<Column<Attribute>[]>(() => [
        {
            Header: 'Znacznik',
            accessor: ({name, display_name}) => <>
            <p><strong>{display_name}</strong></p>
            <p><small>[{name}]</small></p>
            </>,
        },
        {
            Header: 'Wartość',
            accessor: attribute =>
            <AttributeValueField 
                key={attribute.id}
                getDefaultValueByAttribute={getDefaultValueByAttribute}
                getTextDefaultValueByAttribute={getTextDefaultValueByAttribute}
                handleAddEditValue={handleAddEditValue}
                attribute={attribute}
                renderDefaultEditor={(attribute, callback) => <>
                    <button
                        type="button"
                        className="btn btn-sm btn-white mr-1"
                        onClick={() => {
                            setModalData(attribute)
                            setOpenModal(true)
                            callback()
                        }}>Edytuj domyślne wartości
                    </button>
                </>}
            />
        }
    ], [setModalData, setOpenModal, getDefaultValueByAttribute, handleAddEditValue])

    return <>
        <ClientTable<Attribute> data={attributes.filter((item) => !item?.archived)} columns={columns}/>
        <AttributesAddEditModal
            isOpen={isOpenModal}
            attribute={modalData}
            onCancel={() => {
                setOpenModal(false)
            }}
            onSubmit={(attribute) => {
                setOpenModal(false);
                handleEditAttribute(attribute)
                setModalData(() => null)
            }}/>
    </>
}