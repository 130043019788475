import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {logoutAction} from "../store/auth/actions";
import {Redirect} from "react-router-dom";
import {AppDispatch} from "../store/reducers";

export const LogoutPage = () => {

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(logoutAction())
    }, [dispatch])

    return <Redirect to="/" />
}