import Select from "react-select";
import React, {useMemo, useState} from "react";
import {Dictionary, DictionaryValue} from "../../../../../models/Settings";
import {useSelector} from 'react-redux'
import {State} from "../../../../../models/State";
import chroma from "chroma-js";
import {findStateColor} from "../../../../../helpers/contract-state";
import { Attribute, DefaultValue } from "../../../../../models/Attribute";
import AttributeFieldGeneric from "./attribute/AttributeFieldGeneric";

export interface AttributeValueFieldProps {
    attribute: Attribute,
    limit?: number,
    handleAddEditValue: (any) => any,
    getDefaultValueByAttribute: (any) => any,
    getTextDefaultValueByAttribute: (any) => any,
    renderDefaultEditor?: (Attribute?, Function?) => any,
}

export default ({attribute, handleAddEditValue, getDefaultValueByAttribute, 
  getTextDefaultValueByAttribute, renderDefaultEditor = () => {}, limit = 5}: AttributeValueFieldProps) => {
    
    const [isOpen, setOpen] = useState(false);
    const [currValue, setCurrValue] = useState(getTextDefaultValueByAttribute(attribute) || '');

    React.useEffect(() => {
        handleAddEditValue({attribute, value: currValue || null})
    }, [currValue])

    const toggleOpen = () => {
        setOpen(!isOpen);
    };

    const renderSingleAnswerButton = (dv: DefaultValue, callback: Function = () => {}) => (
      <button
        onClick={() => {
          setCurrValue(dv.value)
          callback()
        }}
        className="btn btn-dark btn-sm mr-3 mb-2"
        type="button"
      >
         {dv.title}
      </button>
    )

    return <>
        <AttributeFieldGeneric currValue={currValue} setCurrValue={setCurrValue} attribute={attribute} />
        <div className="buttons">
          {attribute.default_value.slice(0, limit).map(dv => renderSingleAnswerButton(dv))}
        <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
            <button
                onClick={toggleOpen}
                className="btn btn-light btn-sm mb-2"
                type="button"
            >
                <i className="fe fe-more-horizontal"></i> Więcej
            </button>
        }
        >
            {attribute.default_value.slice(limit).map(dv => renderSingleAnswerButton(dv, toggleOpen))}
            {attribute.default_value.length > limit ? <hr /> : <></>}
            {renderDefaultEditor(attribute, toggleOpen)}
        </Dropdown>
        </div>
    </>
}


const Menu = props => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 4,
          boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
          marginTop: 8,
          position: 'absolute',
          zIndex: 2,
          padding: 10
        }}
        {...props}
      />
    );
  };
  const Blanket = props => (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
        background: 'rgba(0,0,0,0.4)'
      }}
      {...props}
    />
  );

const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div style={{ position: 'relative', display: 'inline' }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );