import store from "../store"
import {AttributeValue} from "../models/Attribute";
import renderHTML from 'react-render-html';

export const printDictionary = (searchesValue: string, dictionaryName: string): string => {
    const {settings: {dictionaries}} = store.getState()
    if(dictionaries[dictionaryName]) {
        return dictionaries[dictionaryName].find(({value}) => value === searchesValue).text || searchesValue
    }
    return searchesValue
}

export const printTemplate = (template: string, attributes: AttributeValue[]) => {
    let html = template
    attributes.map(({value, attribute: {name}}) =>
        html = html.replace(`[${name}]`, value || `[${name}]`)
    )
    return html ? renderHTML(html) : ''
}

export const renderPreview = (html: string) => {
    return html ? renderHTML(html) : ''
}