import React from 'react';
import DashboardHeader from '../../../shared/components/DashboardHeader';

import {Route, Switch, useRouteMatch} from "react-router-dom";
import MainPage from './MainPage';
import { LockRulesPage } from './lockrules/IndexPage';
import { WarningRulesPage } from './warningrules/IndexPage';

export default () => {
    const {path} = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <MainPage/>
            </Route>
            <Route path={`${path}/lockrules`}>
                <LockRulesPage />
            </Route>
            <Route path={`${path}/warningrules`}>
                <WarningRulesPage />
            </Route>
        </Switch>
    )
}