import {AnyAction, combineReducers} from "redux";
import auth from "./auth/reducers";
import settings from "./settings/reducers";
import {ThunkDispatch} from "redux-thunk";
import {State} from "../models/State";

export const reducers = combineReducers({
    auth,
    settings
})

export type RootState = ReturnType<typeof reducers>

export type AppDispatch = ThunkDispatch<State, any, AnyAction>;


