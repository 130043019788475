import React, { useState } from 'react';
import * as Icon from 'react-feather';

export const BillStep1 = ({
    nextStep,
    showStep,
    contract
}) => {

    return (
        <div className={`step step-1 ${showStep ? 'step-visible' : ''}`}>
            <div className='row mb-3'>
                <div className='col col-auto'>
                    <Icon.Smile className="text-dark" />
                </div>
                <div className='col mw-650'>
                    <p className="lead text-dark">
                        <strong>
                        Cześć! <br />
                        Dobra robota.
                        </strong>
                    </p>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col col-auto'>
                    <Icon.Lock />
                </div>
                <div className='col mw-650'>
                    <p>
                        W związku z podpisaniem umowy jesteś zobowiązany wystawić nam rachunek. Jego przesłanie gwarantuje możliwość wypłacenia Tobie pieniędzy. Pomożemy Tobie w tym procesie.
                    </p>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col col-auto'>
                    <Icon.Book />
                </div>
                <div className='col mw-650'>
                    <p>
                        Pamiętaj! Umowa zawarta środkami komunikacji elektronicznej jest tak samo ważna jak ta w formie papierowej (Art. 60, 61 i 77 Kodeksu Cywilnego).
                    </p>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col col-auto'>
                    <Icon.HelpCircle />
                </div>
                <div className='col mw-650'>
                    <p>
                        Masz dodatkowe pytania? Sprawdź koniecznie nasz dział <a href="https://sally.agency/faq2020-personel/" target="_blank">FAQ</a> lub skontaktuj się z osobą, która wysłała Ci link do tej umowy.
                    </p>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <button className='btn btn-large btn-secondary' onClick={nextStep}>
                        Przejdź dalej
                    </button>
                </div>
            </div>
        </div>
    )
}