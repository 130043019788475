import React, {useState} from 'react';
import logoImage from '../assets/images/sally_full_logo.svg';import {useDispatch} from "react-redux";
import {LoginData} from "../models/Auth";
import {loginAction} from "../store/auth/actions";
import {LoginForm} from "../shared/forms/LoginForm";
import {LOGIN_FAIL, LOGIN_SUCCESS} from "../store/auth/types";
import {AppDispatch} from "../store/reducers";
import {loadSettings} from "../store/settings/actions";
import Loader from "react-loader";

export const LoginPage = () => {
    const [loginData, setLoginData] = useState<LoginData>({password: '', username: ''})
    const [loaded, setLoaded] = useState<boolean>(true)
    const [isFailed, setFailed] = useState<boolean>(false)
    const dispatcher = useDispatch<AppDispatch>();
    const onSubmit = (loginData: LoginData) => {
        setLoginData(loginData)
        setLoaded(false)
        dispatcher(loginAction(loginData)).then(({type: loginStatus}) => {
            loginStatus === LOGIN_FAIL && setFailed(true)
            loginStatus === LOGIN_SUCCESS && dispatcher(loadSettings())
        }).finally(() => setLoaded(true))
    }

    return (
        <div
            className='d-flex align-items-center justify-content-center auth border-top border-top-2 border-primary h-100 min-vh-100'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <img src={logoImage} alt="Sally logo" style={{width: '185px', height: '75px'}}/>
                    </div>
                    <div className="col-12 col-md-5 col-xl-4 my-2">
                        <h1 className="display-4 text-left mb-3">
                            Sally
                        </h1>
                        <p className="text-muted text-left mb-5">
                            Panel zarządzania
                        </p>
                        {!loaded ?
                            <div style={{height: '30vh'}}>
                                <Loader loaded={loaded} options={{color: 'white'}} />
                            </div>
                            :
                            <LoginForm onSubmit={onSubmit} password={loginData.password} username={loginData.password}/>
                        }
                        {isFailed && <small className="text-danger">Niepoprawny login lub hasło</small>}
                    </div>
                </div>
            </div>
        </div>
    )
}