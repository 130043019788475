import React from 'react';
import { Link } from 'react-router-dom';

export default ({
    name,
    desc,
    icon,
    link, 
    buttonName,
    ...props
}) => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row align-items-center">
                    <div className="col-auto text-center">

                        <div className="">
                            <i style={{fontSize: '24px'}} className={`fe fe-3x fe-${icon}`} /> 
                        </div>

                    </div>
                    <div className="col ml-n2">

                        <h4 className="mb-1">
                            {name}
                        </h4>

                        <p className="small text-muted mb-1">
                            {desc}
                        </p>

                    </div>
                    <div className="col-auto">

                        <Link className="btn btn-sm btn-primary d-inline-block" to={link}>
                            {buttonName}
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    )
}