import {Settings} from "../../models/Settings";

export const LOAD_SUCCESS = 'LOAD_SUCCESS'
export const LOAD_FAIL = "LOAD_FAIL"

interface LoadSettings {
    type: typeof LOAD_SUCCESS | typeof LOAD_FAIL
    payload: {settings: Settings | null}
}

export type SettingsActionTypes = LoadSettings