import React from 'react';
import DashboardHeader from '../../../shared/components/DashboardHeader';
import ContractsTable from "../../../shared/components/user/tables/ContractsTable";
import {useSelector} from "react-redux";
import {State} from "../../../models/State";
import {User} from "../../../models/User";

export default () => {

    const user: User | null = useSelector(({auth: {user}}: State) => user)

    return <>
        <DashboardHeader title="Umowy"/>
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="card">
                    <ContractsTable userId={user && user.id || null}/>
                </div>
            </div>
        </div>
    </>
}