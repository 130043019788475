import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import fund_logo from '../../../assets/images/fund_logo.png';
import {NotificationManager} from 'react-notifications';
import SignService from '../../../services/SignService';
import Select from "react-select";
import { padNumber } from '../../../helpers/number-utils';
import { officesGetGminy, officesGetPowiaty, officesGetUS, officesGetVoivodeships } from '../../../assets/data/officesStatic';
import CountrySelect from '../../../shared/forms/fields/select/CountrySelect';
import Cleave from 'cleave.js/react';
import { ContractWarningRule, ContractWarningRuleState } from '../../../models/ContractWarningRule';
import { ContractLockRule, ContractLockRuleState } from '../../../models/ContractLockRule';
import moment from 'moment';

enum RuleType {
    WARNING = 0,
    LOCK = 1
}

export const ContractStep3 = ({
    nextStep,
    prevStep,
    showStep,
    contract,
    token
}) => {

    const [errors, setErrors] = useState<string[]>([])

    const [voivodeship, setVoivodeship] = useState<any>(null)
    const [powiat, setPowiat] = useState<any>(null)
    const [gmina, setGmina] = useState<any>(null)
    const [us, setUs] = useState<any>(null)

    const [voivodeships, setVoivodeships] = useState<any>(officesGetVoivodeships());
    const [powiaty, setPowiaty] = useState<any>([]);
    const [gminy, setGminy] = useState<any>([]);
    const [uss, setUss] = useState<any>([]);

    const [warningRules, setWarningRules] = useState<ContractWarningRuleState[]>([]);
    const [lockRules, setLockRules] = useState<ContractLockRuleState[]>([]);

    useEffect(() => {

        setWarningRules(contract.warning_rules?.map((item: ContractWarningRule) => ({
            ...item,
            state: true
        })) || []);

        setLockRules(contract.lock_rules?.map((item: ContractLockRule) => ({
            ...item,
            state: true
        })) || []);

    }, [contract]);

    const [isUnderage, setIsUnderage] = useState<boolean>(false)

    const workplaceOptions = [
        {label: 'nie mam żadnej innej umowy', value: 'none'},
        {label: 'mam też umowę o pracę', value: 'has_work_contracted'},
        {label: 'mam też umowę inną niż o pracę', value: 'has_work'},
        {label: 'mam własną firmę i wystawię fakturę', value: 'has_company'},
    ];

    const schoolOptions = [
        {label: 'jestem uczniem szkoły ponadpodstawowej', value: 'high_school'},
        {label: 'jestem studentem studiów wyższych', value: 'university'},
        {label: 'żadne z powyższych', value: 'none'}
    ];

    const [form, setForm] = useState<any>({
        "bank_account": "",
        "pesel": "",
        "personal_details": {
            "full_name": "",
            "phone": "",
            "birthday": "",
            "email": "",
            "workplace": null,
            "school": null,
            "fb_profile": "",
            "address": {
                "street": "",
                "postal_code": "",
                "city": "",
                "country": "Polska",
                "building": "",
                "voivodeship": "",
                "powiat": "",
                "gmina": "",
            },
            "parent_full_name": "",
            "parent_phone": "",
            "parent_agree": false
        },
        "us": "",
        "company_name": "",
        "company_address": "",
        "company_nip": "",
        "company_custom_name": "",
        "company_custom_address": "",
        "company_custom_nip": "",
        "company_is_vat": false,
        "company_is_min": false,
        "school_name": "",
        "school_profile": "",
        "school_number": "",
        "amount_charity": 0,
    });

    const clsCharityButton = (val) => (form.amount_charity === val) ? 'btn btn-primary' : 'btn btn-light';

    useEffect(() => {
        handlePersonalDetailsAddress({gmina});
        setUss(officesGetUS(voivodeship?.value, powiat?.value, gmina?.value));
    }, [gmina]);

    useEffect(() => {
        handlePersonalDetailsAddress({powiat});
        setGminy(officesGetGminy(voivodeship?.value, powiat?.value));
    }, [powiat]);

    useEffect(() => {
        handlePersonalDetailsAddress({voivodeship});
        setPowiaty(officesGetPowiaty(voivodeship?.value));
    }, [voivodeship]);

    const setRule = (ruleName: string, ruleState: boolean, isLock: RuleType) => {

        const rules = isLock ? lockRules : warningRules;
        const rulesSetter = isLock ? setLockRules : setWarningRules;

        const findRuleName = rules.find((item: ContractWarningRuleState|ContractLockRuleState) => item.name === ruleName);
        if(findRuleName) {
            rulesSetter((prevState: ContractWarningRuleState[]|ContractLockRuleState[]) => {
                const newRules = prevState.filter((item: ContractWarningRuleState|ContractLockRuleState) => item.name !== ruleName);

                newRules.push({
                    ...findRuleName,
                    state: ruleState
                });

                return newRules;
            });
        }
    }

    const handleInputForm = (input: Partial<any>): void => {
        setForm(oldValue => ({...oldValue, ...input}))

        if(input?.pesel) {
            const peselYear = parseInt(input.pesel.substring(0,2), 10);
            const getCurrYear = parseInt(new Date().getFullYear().toString().substr(-2), 10);
            const getCurrYearSuffix = parseInt(new Date().getFullYear().toString().substr(0,2), 10);
            let countYear = getCurrYear;

            if(peselYear > getCurrYear) {
                countYear += 100;
            }
            

            if(input?.pesel.length >= 6) {
                let peselMonth = parseInt(input.pesel.substring(2,4), 10);
                const peselDay = parseInt(input.pesel.substring(4,6), 10);

                const yearSuffix = (peselYear > getCurrYear) ? (getCurrYearSuffix-1) : getCurrYearSuffix;

                if(peselMonth > 12) {
                    peselMonth -= 20;
                }

                handlePersonalDetails({
                    birthday: `${yearSuffix}${padNumber(peselYear)}-${padNumber(peselMonth)}-${padNumber(peselDay)}`
                });

                const nowDate = new Date();
                const birthdayDate = new Date(yearSuffix * 100 + peselYear, (peselMonth - 1), (peselDay));

                const diffed = moment.duration(Math.abs(nowDate.getTime() - birthdayDate.getTime())).years();


                if(diffed < 26) {
                    setRule('26up', false, RuleType.WARNING);
                    setRule('26up', false, RuleType.LOCK);
                } else {
                    setRule('26up', true, RuleType.WARNING);
                    setRule('26up', true, RuleType.LOCK);
                }

                if(diffed < 18) {
                    setIsUnderage(true);
                    setRule('adultonly', true, RuleType.LOCK);
                } else {
                    setIsUnderage(false);
                    setRule('adultonly', false, RuleType.LOCK);
                }
            }


        }
    }

    const handlePersonalDetails = (input: Partial<any>): void => {
        setForm(oldValue => ({...oldValue, personal_details: {...oldValue.personal_details, ...input}}))

        if(input?.school) {
            if(!['high_school', 'university'].includes(input.school)) {
                setRule('students', true, RuleType.LOCK);
                setRule('notstudent', true, RuleType.WARNING);
            } else {
                setRule('students', false, RuleType.LOCK);
                setRule('notstudent', false, RuleType.WARNING);                
            }
        }

        if(input?.company_is_vat && input?.company_is_vat === true) {
                setRule('invoice', false, RuleType.LOCK);
        }
    }

    const handlePersonalDetailsAddress = (input: Partial<any>): void => {
        setForm(oldValue => ({...oldValue, 
            personal_details: {...oldValue.personal_details, 
                address: {...oldValue.personal_details.address, ...input}
            }
        }));
    }

    const onSubmit = async (e:any) => {
        e.preventDefault()
        if(!validate().length) {
            const signed = await SignService.postContractData(token, form);
            if(signed) {
                nextStep();
            } else {
                NotificationManager.error('Wystąpił błąd i nie mogę podpisać umowy')
            }
        }
        

    }

    const validate = (): string[] => {
        const errors: string[] = []
        !form.bank_account && errors.push('bank_account')
        !form.pesel && errors.push('pesel')
        setErrors(errors)
        errors.length && NotificationManager.error('Wypełnij wymagane pola.')
        return errors
    }

    const countDisabled = () => lockRules.some((item: ContractLockRuleState) => item.state);

    return (
        <div className={`step step-3 ${showStep ? 'step-visible' : ''}`}>
            <form className="form-inline">
                <div className='row mb-5'>
                    <div className='col mw-650'>
                        Wprowadzone dane posłużą wyłącznie do stworzenia umowy.
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col">
                        <hr />
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col mw-650'>
                        <h3 className="font-weight-bold text-dark"><i className="fe fe-user"></i> Dane osobowe</h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col col-12 col-md-4 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Imię i nazwisko</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.full_name}
                                    onChange={e => handlePersonalDetails({full_name: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-4 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Telefon</label>
                            <Cleave type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.phone} options={{blocks: [3,3,3], delimeters:[' ',' ',' ']}}
                                    onChange={e => handlePersonalDetails({phone: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-4 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">E-mail</label>
                            <input type="email" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.email}
                                    onChange={e => handlePersonalDetails({email: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-4 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">PESEL</label>
                            <Cleave type="text" className="form-control" id="" placeholder="PESEL"
                                    value={form.pesel} options={{blocks: [11]}}
                                    onChange={e => handleInputForm({pesel: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-4 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Data urodzenia</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.birthday} disabled={true}
                                    onChange={e => handlePersonalDetails({birthday: e.target.value})}  />
                        </div>
                    </div>
                    <div className='col col-12 col-md-4 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Profil na Facebooku (link)</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.fb_profile}
                                    onChange={e => handlePersonalDetails({fb_profile: e.target.value})}  />
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col">
                        <hr />
                    </div>
                </div>
                {isUnderage && <>
                    <div className='row mb-5'>
                        <div className='col mw-650'>
                            <h3 className="font-weight-bold text-dark"><i className="fe fe-users"></i> Dane osobowe rodzica lub opiekuna prawnego</h3>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col col-12 col-md-4 mb-3'>
                            <div className="form-group">
                                <label htmlFor="">Imię i nazwisko rodzica / opiekuna prawnego</label>
                                <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                        value={form.personal_details.parent_full_name}
                                        onChange={e => handlePersonalDetails({parent_full_name: e.target.value})} />
                            </div>
                        </div>
                        <div className='col col-12 col-md-4 mb-3'>
                            <div className="form-group">
                                <label htmlFor="">Telefon rodzica / opiekuna prawnego</label>
                                <Cleave type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                        value={form.personal_details.parent_phone} options={{blocks: [3,3,3], delimeters:[' ',' ',' ']}}
                                        onChange={e => handlePersonalDetails({parent_phone: e.target.value})} />
                            </div>
                        </div>
                        <div className='col col-12 col-md-4 mb-3'>
                            <div className="form-group">
                                
                                <input type="checkbox"  id="parentAgree" className="form-check-input" 
                                        checked={form.personal_details.parent_agree}
                                        onChange={e => handlePersonalDetails({parent_agree: e.target.checked ? true : false})} />
                                <label className="form-check-label ml-3" htmlFor="parentAgree">Wyrażam zgodę na zawarcie tej umowy</label>
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <hr />
                        </div>
                    </div>
                </>}
                <div className='row mb-5'>
                    <div className='col mw-650'>
                        <h3 className="font-weight-bold text-dark"><i className="fe fe-map-pin"></i> Aktualny adres zamieszkania</h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Kraj</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                value={form.personal_details.address.country} disabled={true}
                                onChange={e => handlePersonalDetailsAddress({country: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Województwo</label>
                            <Select placeholder="Wybierz województwo"
                                value={voivodeship}
                                options={voivodeships}
                                onChange={option => setVoivodeship(option)}/>
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Powiat</label>
                            <Select placeholder="Wybierz powiat"
                                value={powiat}
                                disabled={!voivodeship}
                                options={powiaty}
                                onChange={option => setPowiat(option)}/>
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Gmina</label>
                            <Select placeholder="Wybierz gminę"
                                value={gmina}
                                disabled={!voivodeship || !powiat}
                                options={gminy}
                                onChange={option => setGmina(option)}/>
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Kod pocztowy</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.address.postal_code}
                                    onChange={e => handlePersonalDetailsAddress({postal_code: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Miejscowość</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.address.city}
                                    onChange={e => handlePersonalDetailsAddress({city: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Ulica</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.address.street}
                                    onChange={e => handlePersonalDetailsAddress({street: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Numer mieszkania/domu</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.personal_details.address.building}
                                    onChange={e => handlePersonalDetailsAddress({building: e.target.value})} />
                        </div>
                    </div>
                </div>
                <div className='row mt-5 mb-5'>
                    <div className='col'>
                        <hr />
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col mw-650'>
                        <h3 className="font-weight-bold text-dark"><i className="fe fe-dollar-sign"></i> Dane do rozliczeń</h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Urząd Skarbowy</label>
                            <Select placeholder="Wybierz urząd"
                                value={us}
                                disabled={!voivodeship || !powiat || !gmina}
                                options={uss}
                                onChange={option => {
                                    setUs(option);
                                    handleInputForm({us: option.value})
                                }}/>
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Numer konta</label>
                            <Cleave type="text" className="form-control" id="" placeholder="Numer konta"
                                    value={form.bank_account} options={{blocks: [2,4,4,4,4,4,4], delimeter: ' '}}
                                    onChange={e => handleInputForm({bank_account: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Aktualne zatrudnienie</label>
                            <Select placeholder="Wybierz..."
                                value={form.personal_details.workplace ? workplaceOptions.find(item => item.value == form.personal_details.workplace) : null}
                                options={workplaceOptions}
                                onChange={option => handlePersonalDetails({workplace: option?.value})}/>
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Status nauki</label>
                            <Select placeholder="Wybierz..."
                                value={form.personal_details.school ? schoolOptions.find(item => item.value == form.personal_details.school) : null}
                                options={schoolOptions}
                                onChange={option => handlePersonalDetails({school: option?.value})}/>
                        </div>
                    </div>
                    {(
                        form.personal_details.workplace == 'has_work_contracted' ||
                        form.personal_details.workplace == 'has_work'
                    ) && <>
                        <div className='col col-12 col-md-3 mb-3'>
                            <div className="form-group">
                                <label htmlFor="">Nazwa pracodawcy</label>
                                <input type="text" className="form-control" id="" placeholder="Uzupełnij dane" 
                                    value={form.company_name} 
                                    onChange={e => handleInputForm({company_name: e.target.value})}  />
                            </div>
                        </div>
                        <div className='col col-12 col-md-3 mb-3'>
                            <div className="form-group">
                                <label htmlFor="">Adres pracodawcy</label>
                                <input type="text" className="form-control" id="" placeholder="Uzupełnij dane" 
                                    value={form.company_address} 
                                    onChange={e => handleInputForm({company_address: e.target.value})}  />
                            </div>
                        </div>
                        <div className='col col-12 col-md-3 mb-3'>
                            <div className="form-group">
                                <label htmlFor="">NIP pracodawcy</label>
                                <Cleave options={{blocks: [10]}} type="text" className="form-control" id="" placeholder="Uzupełnij dane" 
                                    value={form.company_nip} 
                                    onChange={e => handleInputForm({company_nip: e.target.value})}  />
                            </div>
                        </div>
                        <div className='col col-12 col-md-3 mb-3'>
                            <div className="form-group">
                                <input type="checkbox" id="minKraj" className="form-check-input"   checked={form.company_is_min}
                                    onChange={e => handleInputForm({company_is_min: e.target.checked ? true : false})} />
                                <label className="form-check-label ml-3" htmlFor="minKraj">Moja miesięczna pensja to co najmniej minimum krajowe</label>
                            </div>
                        </div>
                    </>}
                    {(
                        form.personal_details.workplace == 'has_company' 
                    ) && <>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Nazwa firmy</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane" 
                                    value={form.company_custom_name} 
                                    onChange={e => handleInputForm({company_custom_name: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Adres firmy</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane" 
                                    value={form.company_custom_address} 
                                    onChange={e => handleInputForm({company_custom_address: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">NIP firmy</label>
                            <Cleave options={{blocks: [10]}} type="text" 
                                    value={form.company_custom_nip} 
                                    onChange={e => handleInputForm({company_custom_nip: e.target.value})} className="form-control" id="" placeholder="Uzupełnij dane" />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <input type="checkbox" id="VAT" className="form-check-input"  checked={form.company_is_vat}
                                    onChange={e => handleInputForm({company_is_vat: e.target.checked ? true : false})} />
                            <label className="form-check-label ml-3" htmlFor="VAT">Jestem płatnikiem VAT</label>
                        </div>
                    </div>
                    
                    
                    </>}
                    {(
                        form.personal_details.school == 'high_school' 
                    ) && <>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Nazwa szkoły</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.school_name} 
                                    onChange={e => handleInputForm({school_name: e.target.value})}  />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Profil</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane" 
                                    value={form.school_profile} 
                                    onChange={e => handleInputForm({school_profile: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Nr legitymacji</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.school_number} 
                                    onChange={e => handleInputForm({school_number: e.target.value})} />
                        </div>
                    </div>
                    
                    
                    </>}
                    {(
                        form.personal_details.school == 'university' 
                    ) && <><div className='col col-12 col-md-3 mb-3'>
                    <div className="form-group">
                            <label htmlFor="">Nazwa uczelni</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.school_name} 
                                    onChange={e => handleInputForm({school_name: e.target.value})}  />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Wydział</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.school_profile} 
                                    onChange={e => handleInputForm({school_profile: e.target.value})} />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Nr albumu</label>
                            <input type="text" className="form-control" id="" placeholder="Uzupełnij dane"
                                    value={form.school_number} 
                                    onChange={e => handleInputForm({school_number: e.target.value})} />
                        </div>
                    </div>
                    
                    
                    </>}
                    {(
                        form.personal_details.school == 'university' ||
                        form.personal_details.school == 'high_school' 
                    ) && <><div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Skan legitymacji - przód</label>
                            <input type="file" className="form-control" id="" placeholder="Studia/Praca" />
                        </div>
                    </div>
                    <div className='col col-12 col-md-3 mb-3'>
                        <div className="form-group">
                            <label htmlFor="">Skan legitymacji - tył</label>
                            <input type="file" className="form-control" id="" placeholder="Studia/Praca" />
                        </div>
                    </div></>}
                </div>
                <div className='row mt-5 mb-5 row-fund'>
                    <div className='col mw-650 mb-2'>
                        Chcę przekazać część mojego wynagrodzenia fundacji Międzynarodowy Ruch na Rzecz Zwierząt - Viva!
                    </div>
                    
                    <div className="btn-group foundation" role="group" aria-label="Wybierz kwotę">
                        {[1,2,3,5,10,20].map((item: number) => (
                            <button key={`charity_${item}`} 
                            onClick={(e) => handleInputForm({"amount_charity": item})}
                            type="button" className={clsCharityButton(item)}>{item} zł</button>
                        ))}
                    </div>

                    <div>
                        <img className="fund-logo" src={fund_logo} alt="logo fundacji Międzynarodowy Ruch na Rzecz Zwierząt - Viva!" />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        {warningRules
                            .filter((item: ContractWarningRuleState) => item.state === true)
                            .map((item: ContractWarningRuleState) => (
                                <div key={`w_${item.name}`} className="alert alert-warning" role="alert">
                                    {item.message_user}
                                </div>
                            ))}
                        {lockRules
                            .filter((item: ContractLockRuleState) => item.state === true)
                            .map((item: ContractLockRuleState) => (
                                <div key={`l_${item.name}`} className="alert alert-danger" role="alert">
                                    {item.message_user}
                                </div>
                            ))}
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button type="button" className='btn btn-large btn-light mr-3 mb-3' onClick={prevStep}>
                            &lt; Wstecz
                        </button>
                        <button type="submit" className='btn btn-large btn-secondary mb-3' onClick={onSubmit} disabled={countDisabled()}>
                            Uzupełniłem pola i przechodzę do podsumowania
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}