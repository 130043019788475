import React, {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {User} from "../../../../models/User";
import {validateEmail} from "../../../../helpers/validator";

export interface AccountFormProps {
    readonly accountData?: Partial<User> | null
    readonly onSubmitForm: (user: AccountForm) => void
}

interface AccountForm extends Partial<User>{
    repeat_pass: string
}

export default ({accountData = {}, onSubmitForm}: AccountFormProps) => {
    const [errors, setErrors] = useState<string[]>([])
    const [form, setForm] = useState<AccountForm>({
        full_name: '',
        phone: '',
        password: '',
        email: '',
        repeat_pass: '',
        ...accountData
    })

    const handleInputForm = (input: Partial<AccountForm>): void => {
        setForm(oldValue => ({...oldValue, ...input}))
    }

    useEffect((): void => {
        accountData && handleInputForm(accountData)
    }, [accountData])

    const onSubmit = (e): void => {
        e.preventDefault()
        !validate().length && onSubmitForm(form)
    }

    const validate = (): string[] => {
        const errors: string[] = [];
        !validateEmail(String(form.email)) && errors.push('email')
        !form.phone?.length && errors.push('phone')
        if(form.password || form.repeat_pass) {
            form.repeat_pass !== form.password && errors.push('repeat_pass')
        }
        setErrors(errors)
        errors.length && NotificationManager.error('Niepoprawnie wypełniony formularz.')
        return errors
    }

    return <>
        <form className="form-inline">
            <div className="card">
                <div className="card-body">
                    <div className="form-group row justify-content-between">
                        <h4 className="h4">Moje dane</h4>
                    </div>
                </div>
            </div>
        </form>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3 d-flex justify-content-center mt-3">{ form.full_name }</h3>
                        </div>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Email</label>
                                    <input className={`form-control ${errors.includes('email') && 'is-invalid'}`}
                                           placeholder="Email"
                                           type="email"
                                           value={form.email}
                                           onChange={({target: {value: email}}) => {
                                               handleInputForm({email})
                                           }}/>
                                    {errors.includes('email') &&
                                    <small className="text-danger">Nieprawidłowy adres email</small>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Telefon</label>
                                    <input className={`form-control ${errors.includes('phone') && 'is-invalid'}`}
                                           type="tel"
                                           placeholder="Nr Telefonu"
                                           value={String(form.phone)}
                                           onChange={({target: {value: phone}}) => {
                                               handleInputForm({phone})
                                           }}/>
                                    {errors.includes('phone') &&
                                    <small className="text-danger">Pole telefon nie może być puste</small>}
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-12 col-md-6">
                                    <label>Hasło</label>
                                    <input className={`form-control ${errors.includes('pass') && 'is-invalid'}`}
                                           placeholder="Hasło"
                                           type="password"
                                           value={String(form.password)}
                                           onChange={({target: {value: password}}) => {
                                               handleInputForm({password})
                                           }}/>
                                    {errors.includes('repeat_pass') &&
                                    <small className="text-danger">Podane hasła są różne</small>}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label>Powtórz hasło</label>
                                    <input className={`form-control ${errors.includes('repeat_pass') && 'is-invalid'}`}
                                           type="password"
                                           placeholder="Powtórz hasło"
                                           value={form.repeat_pass}
                                           onChange={({target: {value: repeat_pass}}) => {
                                               handleInputForm({repeat_pass})
                                           }}/>
                                    {errors.includes('repeat_pass') &&
                                    <small className="text-danger">Podane hasła są różne</small>}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <button type="submit" className="btn btn-primary mb-2 float-right col-sm-1"
                                        onClick={onSubmit}>Zapisz
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
    </>
}