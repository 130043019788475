import React, {FunctionComponent, useMemo} from 'react';
import ServerTable, {TableHeadActionsProps} from "../../ServerTable";
import {TableColumn} from "../../../../models/Table";
import {Link} from 'react-router-dom';
import TemplatesService from "../../../../services/TemplatesService";
import {Template} from "../../../../models/Template";

export default () => {
    const tableService = new TemplatesService()
    const columns = useMemo<TableColumn<Template>[]>(() => [
        {
            Header: 'Nazwa',
            accessor: ({name}) => name,
            sortBy: 'number'
        },
        {
            Header: 'Wystąpień',
            accessor: ({contracts}) => contracts ? contracts.length : 0,
            sortBy: ''
        },
        {
            Header: 'Opcje',
            accessor: ({id}) => <Link to={`/admin/templates/${id}`}>
                <button className="btn btn-sm btn-primary">Edytuj</button>
            </Link>,
            sortBy: ''
        },
    ], [])

    const header: FunctionComponent<TableHeadActionsProps> = ({onInput}) =>
        <div className="input-group">
            <input className="form-control" type="text" placeholder="Szukaj..." aria-label="Szukaj..."
                   onChange={e => {
                       onInput({query: e.target.value})
                   }}/>
            <Link to={`/admin/templates/add`}>
                <button className="btn btn-lg btn-primary">Dodaj nowy</button>
            </Link>
        </div>

    return <ServerTable<Template> tableService={tableService} columns={columns}>
        {{header}}
    </ServerTable>
}