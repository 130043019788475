import {User} from "../../models/User";

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"

interface LoginActionInterface {
    type: typeof LOGIN_SUCCESS | typeof LOGIN_FAIL
    payload: {user: User | null}
}

interface LogoutActionInterface {
    type: typeof LOGOUT
    payload: {user: null}
}

export type AuthActionTypes = LoginActionInterface | LogoutActionInterface