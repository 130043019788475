import React from 'react';
import {Column, useTable} from "react-table";

export interface ClientTableProps<T extends object> {
    readonly data: T[],
    readonly columns: Column<T>[],
}

export default <T extends object>({data, columns}: ClientTableProps<T>) => {
    const {headerGroups, rows, prepareRow, getTableProps} = useTable({columns, data})

    return (
        <>
            <table className="table table-sm table-nowrap table-hover card-table" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                                <span style={{cursor: 'pointer'}} className="text-muted list-sort" data-sort="products-product"
                                   key={column.id}>
                                    {column.render('Header')}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody className="list">
                {data.length
                    ? rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        )
                    })
                    : <tr>
                        <th>Brak danych</th>
                    </tr>
                }
                </tbody>
            </table>
        </>
    )
}