import {AxiosResponse} from 'axios'
import {authHeader} from "./auth-header";
import {TableFilters, TableResponse, TableService} from "../models/Table";
import Api from "./Api";
import {Client} from "../models/Client";
import {ObjectAssignBySchema} from "../helpers/init-data";
import { ContractWarningRule } from '../models/ContractWarningRule';

export default class ContractWarningRuleService implements TableService<ContractWarningRule> {

    static client = (id: number): Promise<null | ContractWarningRule> =>
        Api.get(`/warningrules/${id}`, {headers: authHeader()})
            .then((response: AxiosResponse<ContractWarningRule>) => response.data)
            .catch(error => null)

    static store = (model: ContractWarningRule): Promise<null | ContractWarningRule> =>
            Api.post(`/warningrules`, model, {headers: authHeader()})
                .then((response: AxiosResponse<ContractWarningRule>) => response.data)
                .catch(error => null)

    static update = (model: ContractWarningRule): Promise<null | ContractWarningRule> =>
        Api.put(`/warningrules/${model.id}`, ContractWarningRuleService.initUpdateBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<ContractWarningRule>) => response.data)
            .catch(error => null)

    tableData(filters: Partial<TableFilters> = {}): Promise<TableResponse<ContractWarningRule> | null> {
        return Api.get(`/warningrules`, {params: filters, headers: authHeader()})
            .then((response: AxiosResponse<TableResponse<ContractWarningRule>>) => response.data)
            .catch(error => null)
    }

    static initUpdateBody = (model: ContractWarningRule): Partial<ContractWarningRule> =>
        ObjectAssignBySchema({
            name: model.name,
            message_admin: model.message_admin,
            message_user: model.message_user,
            sets_price_to_brutto: model.sets_price_to_brutto
        }, model)
}

