import {AxiosResponse} from 'axios'
import {authHeader} from "./auth-header";
import {TableFilters, TableResponse, TableService} from "../models/Table";
import {Template} from "../models/Template";
import {ObjectAssignBySchema} from "../helpers/init-data";
import Api from "./Api";
import NotificationsService from './NotificationsService';

export default class TemplatesService implements TableService<Template> {

    static template = (id: number): Promise<null | Template> =>
        Api.get(`/templates/${id}`, {headers: authHeader()})
            .then((response: AxiosResponse<Template>) => response.data)
            .catch(error => null)

    static store = (model: Template): Promise<null | Template> =>
        Api.post(`/templates`, TemplatesService.initStoreBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<Template>) => response.data)
            .catch(error => null)


    static update = (model: Template): Promise<null | Template> =>
        Api.put(`/templates/${model.id}`, TemplatesService.initStoreBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<Template>) => response.data)
            .catch(error => {
                NotificationsService.errorForm('Nie udało się zapisać szablonu.', error?.response?.data);
                return null;
            })

    static preview = (model: Template): Promise<any> =>
        Api.post(`/templates/${model.id}/preview`, [], {headers: authHeader(), responseType: 'blob'})
            .then((response: any) => {
                const file = new Blob(
                    [response.data], 
                    {type: 'application/pdf'});//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
            })
            .catch(error => null)

    static previewBill = (model: Template): Promise<any> =>
        Api.post(`/templates/${model.id}/preview-bill`, [], {headers: authHeader(), responseType: 'blob'})
            .then((response: any) => {
                const file = new Blob(
                    [response.data], 
                    {type: 'application/pdf'});//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
            })
            .catch(error => null)


    tableData(filters: Partial<TableFilters> = {}): Promise<TableResponse<Template> | null> {
        return Api.get(`/templates`, {params: filters, headers: authHeader()})
            .then((response: AxiosResponse<TableResponse<Template>>) => response.data)
            .catch(error => null)
    }

    static initStoreBody = (model: Template): Partial<Template> =>
        ObjectAssignBySchema({
            name: '',
            content_template: '',
            bill_template: '',
            warning_rules: model.warning_rules?.map(item => ObjectAssignBySchema({
                id: item.id
            }, item)),
            lock_rules: model.lock_rules?.map(item => ObjectAssignBySchema({
                id: item.id
            }, item)),
            attributes: model.attributes.map(attribute => ObjectAssignBySchema({
                id: 0,
                name: '',
                default_value: attribute.default_value.map(dv => ObjectAssignBySchema({
                    title: '',
                    value: ''
                }, dv)),
                display_name: '',
                icon: '',
                list_order: 0,
                archived: false,
                show_on_summary: false,
                render_type: ''
            }, attribute))
        }, model)
}

