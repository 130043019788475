import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { Attribute, AttributeWithValue } from '../../../models/Attribute';

export const ContractStep2 = ({
    nextStep,
    prevStep,
    showStep,
    contract,
    attributes
}) => {

    const findInAttributes = (name: string) => {
        const find = attributes.find((item: AttributeWithValue) => item.name == name);
        return find?.value || 'b/d';
    }

    return (
        <div className={`step step-2 ${showStep ? 'step-visible' : ''}`}>
            <div className='row mb-5'>
                <div className='col'>
                    Warunki opisane poniżej będą stanowiły załącznik do umowy.
                </div>
            </div>
            <div className='row'>
                {attributes.filter((item: Attribute) => item.show_on_summary).map((item: Attribute) => (
                    <div key={`att_${item.name}`} className='col col-12 col-md-3 mb-5'>
                        <div className='row'>
                            <div className='col col-auto'>
                                {item?.icon || ''}
                            </div>
                            <div className='col mw-650'>
                                <p>
                                    <small>{item.display_name}:</small>
                                </p>
                                <p>
                                    {item.value}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='row'>
                <div className='col'>
                    <button className='btn btn-large btn-light mr-3' onClick={prevStep}>
                        &lt; Wstecz
                    </button>
                    <button className='btn btn-large btn-secondary' onClick={nextStep}>
                        Zgadza się - przejdź dalej
                    </button>
                </div>
            </div>
        </div>
    )
}