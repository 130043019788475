import React, {useEffect, useMemo, useState} from 'react';

import {useParams} from "react-router-dom";
import Loader from "react-loader";
import {NotificationManager} from 'react-notifications';
import { ContractLockRule } from '../../../../models/ContractLockRule';
import ContractLockRuleService from '../../../../services/ContractLockRuleService';
import ContractLockRuleForm from '../../../../shared/components/admin/forms/ContractLockRuleForm';

export default () => {
    const {id = null} = useParams<any>();
    const [loaded, setLoaded] = useState<boolean>(!id)
    const [formData, setFormData] = useState<ContractLockRule | null>(null)

    useEffect((): void => {
        id && ContractLockRuleService.client(Number(id)).then(c => {
            c && setFormData(() => c)
            setLoaded(true)
        })
    }, [id])

    const method: (model: ContractLockRule) => Promise<ContractLockRule | null> = useMemo(() =>
            id ? ContractLockRuleService.update : ContractLockRuleService.store,
        [id, ContractLockRuleService]
    )

    const handleSubmitForm = (c: ContractLockRule): void => {
        setFormData(() => c)
        setLoaded(false)
        method(c).then(response => {
                if (response) {
                    NotificationManager.success('Blokada zapisana pomyślnie.')
                    window.location.href = '/admin/settings/lockrules'
                } else {
                    NotificationManager.error('Nie udało się zapisać blokady.')
                    setLoaded(true)
                }
            }
        )
    }

    return (
        <Loader loaded={loaded}>
            <ContractLockRuleForm lockRuleData={formData} onSubmitForm={handleSubmitForm}/>
        </Loader>
    )
}