import {AxiosResponse} from 'axios'
import {authHeader} from "./auth-header";
import {User} from "../models/User";
import {TableFilters, TableResponse, TableService} from "../models/Table";
import Api from "./Api";
import {Template} from "../models/Template";
import {ObjectAssignBySchema} from "../helpers/init-data";

export default class UserService implements TableService<User> {

    static profile = (): Promise<null | User> =>
        Api.get(`/profile`, {headers: {...authHeader()}})
            .then((response: AxiosResponse<User>) => response.data)
            .catch(error => null)

    static activate = (token: string): Promise<{data?: any, error?: any}> =>
        Api.get(`/registration/activate/${token}`)
            .then((response: AxiosResponse<User>) => ({data: response.data}))
            .catch(error => ({error: error.data}))

    static updateProfile = (user: Partial<User>): Promise<null | User> =>
        Api.put(`/profile`, UserService.initUpdateProfileBody(user), {headers: {...authHeader()}})
            .then((response: AxiosResponse<User>) => response.data)
            .catch(error => null)

    static user = (id: number): Promise<null | User> =>
        Api.get(`/users/${id}`, {headers: authHeader()})
            .then((response: AxiosResponse<User>) => response.data)
            .catch(error => null)

    static store = (model: User): Promise<null | User> =>
        Api.post(`/users`, UserService.initStoreBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<User>) => response.data)
            .catch(error => null)

    static update = (model: User): Promise<null | User> =>
        Api.put(`/users/${model.id}`, UserService.initStoreBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<User>) => response.data)
            .catch(error => null)

    tableData(filters: Partial<TableFilters> = {}): Promise<TableResponse<User> | null> {
        return Api.get(`/users`, {params: filters, headers: authHeader()})
            .then((response: AxiosResponse<TableResponse<User>>) => response.data)
            .catch(error => null)
    }

    static initStoreBody = (model: Partial<User>): Partial<User> =>
        ObjectAssignBySchema({
            email: '',
            fb_profile: '',
            full_name: '',
            marketing_consent: false,
            phone: '',
            roles: [],
            workplace: '',
            school: '',
            address: {
                building: '',
                city: '',
                country: '',
                postal_code: '',
                street: ''
            }
        }, model)

    static initUpdateProfileBody = (model: Partial<User>): Partial<User> => {
        const body = ObjectAssignBySchema({
            email: '',
            full_name: '',
            phone: '',
            workplace: '',
            school: '',
            fb_profile: '',
            marketing_consent: true,
            address: {
                street: '',
                building: '',
                postal_code: '',
                city: '',
                country: ''
            }
        }, model)

        if(model.password) {
            body.password = model.password
        }

        return body
    }

}

