import React, {useEffect, useState} from 'react';
import {Template} from "../../../../models/Template";
import AttributesClientTable from "../tables/AttributesClientTable";
import {Editor} from "@tinymce/tinymce-react";
import {NotificationManager} from 'react-notifications';
import { Client, ClientContactPerson } from '../../../../models/Client';
import CountrySelect from '../../../forms/fields/select/CountrySelect';
import { ContractLockRule } from '../../../../models/ContractLockRule';
import DashboardHeader from '../../DashboardHeader';

export interface ContractLockRuleFormProps {
    readonly lockRuleData?: Partial<ContractLockRule> | null
    readonly onSubmitForm: (template: ContractLockRule) => void
}

export default ({lockRuleData = {}, onSubmitForm}: ContractLockRuleFormProps) => {
    const [errors, setErrors] = useState<string[]>([])
    const [form, setForm] = useState<ContractLockRule>({
        name: '',
        message_admin: '',
        message_user: '',
        ...lockRuleData
    })

    const handleInputForm = (input: Partial<ContractLockRule>): void => {
        setForm(oldValue => ({...oldValue, ...input}))
    }

    useEffect((): void => {
        lockRuleData && handleInputForm(lockRuleData)
    }, [lockRuleData])

    const onSubmit = (e): void => {
        e.preventDefault()
        !validate().length && onSubmitForm(form)
    }

    const validate = (): string[] => {
        const errors: string[] = []
        !form.name.length && errors.push('name')
        setErrors(errors)
        errors.length && NotificationManager.error('Wypełnij wymagane pola.')
        return errors
    }

    return <>
        <DashboardHeader title={`Blokada ${form?.name || ''}`}/>
        <form className="form-inline">
            <div className="card">
                <div className="card-body">
                    <div className="form-group row justify-content-between mb-0">
                        <div className="col-sm-5 row">
                            <label className="col-auto col-form-label">Identyfikator</label>
                            <div className="col">
                                <input type="text"
                                    className={`form-control ${errors.includes('name') && 'is-invalid'}`}
                                    placeholder="Nazwa"
                                    value={form.name}
                                    onChange={e => handleInputForm({name: e.target.value})}/>
                                {errors.includes('name') &&
                                <small className="text-danger">Nazwa nie może być pusta</small>}
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mb-2 float-right col-auto"
                                onClick={onSubmit}>Zapisz
                        </button>
                    </div>
                </div>
            </div>
        </form>
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="h3">Ustawienia</h3>
                        </div>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col-12 col-md-4">
                                    <label>Nazwa dla administratora</label>
                                    <input className="form-control"
                                        type="text"
                                        placeholder="Nazwa dla administratora"
                                        value={String(form.message_admin)}
                                        onChange={({target: {value: message_admin}}) => {
                                            handleInputForm({message_admin})
                                        }}/> 
                                </div>
                                <div className="col-12 col-md-4">
                                    <label>Treść dla użytkownika</label>
                                    <input className="form-control"
                                        type="text"
                                        placeholder="Treść dla użytkownika"
                                        value={String(form.message_user)}
                                        onChange={({target: {value: message_user}}) => {
                                            handleInputForm({message_user})
                                        }}/> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </>
}