import { ReactComponent } from '*.svg';
import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { renderPreview } from '../../../helpers/renderer';
import SignService from '../../../services/SignService';
import {NotificationManager} from 'react-notifications';

export const BillStep2 = ({
    nextStep,
    prevStep,
    showStep,
    contract,
    token
}) => {

    const [content, setContent] = useState('');

    const getPreviews = async () => {
        if(contract && contract?.link_bill) {
            const previews = await SignService.previewBill(contract?.link_bill);
            if(previews) {
                setContent(previews?.bill);
            }
        }
    }

    const sign = async () => {
        const signed = await SignService.signBill(token);
        if(signed) {
            nextStep();
        } else {
            NotificationManager.error('Wystąpił błąd i nie mogę przesłać rachunku')
        }
    }

    React.useEffect(() => {
        if(showStep) {
            getPreviews();
        }
    }, [showStep]);

    return (
        <div className={`step step-4 ${showStep ? 'step-visible' : ''}`}>
            <div className='row mb-5'>
                <div className='col mw-650'>
                    Potwierdź treść rachunku:
                </div>
            </div>
            <div className='row mt-5 mb-5'>
                <div className='col'>
                    <div className="card">
                        <div className="card-body contract-preview">
                            {renderPreview(content)}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <button className='btn btn-large btn-light mr-3 mb-3' onClick={prevStep}>
                        &lt; Wstecz
                    </button>
                    <button className='btn btn-large btn-success mb-3' onClick={sign}>
                        <Icon.Lock /> Wyślij rachunek w sposób elektroniczny
                    </button>
                </div>
            </div>
        </div>
    )
}