import React from 'react';
import {Route, Switch, useRouteMatch} from "react-router-dom";
import AccountEdit from "./AccountEdit";


export const AccountPage = () => {
    const {path} = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <AccountEdit/>
            </Route>
        </Switch>
    )
}