import Select from "react-select";
import React, {useMemo} from "react";
import {useSelector} from 'react-redux'
import {Dictionary, DictionaryValue} from "../../../../models/Settings";
import {State} from "../../../../models/State";

export interface ContractStateSelectProps {
    value: string | null,
    onChange: (value: string | null) => void
}

export default ({value, onChange}: ContractStateSelectProps) => {
    const dictionaries: Dictionary[] = useSelector(({settings}: State) => settings?.dictionaries || []);

    const options = useMemo(() => {
        if(dictionaries['country']) {
            return dictionaries['country'].map(({text: label}: DictionaryValue) =>
                ({label, value: label})
            )
        } else {
            return []
        }
    }, [dictionaries])

    const valueData = useMemo(() => ({
        get: () => {
            return options.find(({value: searchesValue}) => searchesValue === value)
        },
        set: ({value: newVal}): void => {
            onChange(newVal)
        }
    }),[options, value])

    return <Select placeholder="Wybierz kraj"
                   value={valueData.get()}
                   options={options}
                   onChange={option => valueData.set(option)}/>
}