import React from 'react';

import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import {ContractsPage} from "./contracts/IndexPage";
import LeftNavigation from "../../shared/components/user/LeftNavigation";
import {AccountPage} from "./account/IndexPage";

export const UserPage = () => {

    const {path} = useRouteMatch();

    return (
        <div className='h-100 min-vh-100'>
            <LeftNavigation/>
            <div className="main-content">
                <div>
                    <Switch>
                        <Route exact path={`${path}/edit`}>
                            <AccountPage/>
                        </Route>
                        <Route path={path}>
                            <ContractsPage/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    )
}