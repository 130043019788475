import Select from "react-select";
import React, {useMemo} from "react";
import {Dictionary, DictionaryValue} from "../../../../../../models/Settings";
import {useSelector} from 'react-redux'
import {State} from "../../../../../../models/State";
import chroma from "chroma-js";
import {findStateColor} from "../../../../../../helpers/contract-state";
import { AttributeFieldRenderTypeNames } from "../attribute/AttributeFieldRenderType";

export interface AttributeRenderTypeSelectProps {
    value: string | null,
    onChange: (value: string) => void
}

export default ({value, onChange}: AttributeRenderTypeSelectProps) => {

    const options = Object.keys(AttributeFieldRenderTypeNames).map(
        (item) => ({
            label: AttributeFieldRenderTypeNames[item],
            value: item
        })
    );

    const valueData = useMemo(() => ({
        get: () => {
            return options.find(({value: searchesValue}) => searchesValue === value)
        },
        set: (newVal): void => {
            console.log(newVal, 'newval')
            onChange(newVal || '')
        }
    }),[options, value])


    return <Select placeholder="Wybierz typ pola"
                   value={valueData.get()}
                   options={options}
                   onChange={option => valueData.set(option?.value)}/>
}