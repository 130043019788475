
import {NotificationManager} from 'react-notifications';

export default class NotificationsService {

    static success(message: string): void {
        NotificationManager.success(message)
    }

    static error(message: string): void {
        NotificationManager.error(message)
    }

    static errorForm(message: string, response: any): void {
        const errorMessage = message + (response?.logId ? ' '+response.logId : '');
        NotificationManager.error(errorMessage)
    }
}

