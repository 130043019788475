import React from 'react';

import {Route, Switch, useRouteMatch} from "react-router-dom";
import AddEditPage from './AddEditPage';
import ListPage from "./ListPage";

export const WarningRulesPage = () => {
    const {path} = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ListPage/>
            </Route>
            <Route path={`${path}/add`}>
                <AddEditPage/>
            </Route>
            <Route path={`${path}/:id`}>
                <AddEditPage/>
            </Route>
        </Switch>
    )
}