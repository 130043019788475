import {LOAD_FAIL, LOAD_SUCCESS} from "./types";
import {Settings} from "../../models/Settings";

const initialState: Settings = {
    dictionaries: []
}

export default (state: Settings = initialState, action): Settings => {
    const {type, payload} = action
    switch (type) {
        case LOAD_SUCCESS:
            return {
                ...state,
                dictionaries: payload.settings.dictionaries || []
            }
        case LOAD_FAIL:
            return {
                ...state,
                dictionaries: []
            }
        default:
            return state
    }
}