import React from 'react'
import { AttributeFieldRenderTypeComponent } from './AttributeFieldRenderType'
import { IAttributeFieldProps } from './IAttributeField'

function AttributeFieldGeneric(props: IAttributeFieldProps) {

    if (props?.attribute 
        && props.attribute.render_type !== undefined 
        && AttributeFieldRenderTypeComponent[props.attribute.render_type] !== undefined) {

        return React.createElement(AttributeFieldRenderTypeComponent[props.attribute.render_type], props);
    
      } else {
        return (
          <div>Pole tego typu nie jest wspierane!</div>
        )

      }
}

export default AttributeFieldGeneric