import {AxiosResponse} from 'axios'
import {authHeader} from "./auth-header";
import {TableFilters, TableResponse, TableService} from "../models/Table";
import Api from "./Api";
import {Client} from "../models/Client";
import {ObjectAssignBySchema} from "../helpers/init-data";
import { ContractLockRule } from '../models/ContractLockRule';

export default class ContractLockRuleService implements TableService<ContractLockRule> {

    static client = (id: number): Promise<null | ContractLockRule> =>
        Api.get(`/lockrules/${id}`, {headers: authHeader()})
            .then((response: AxiosResponse<ContractLockRule>) => response.data)
            .catch(error => null)

    static store = (model: ContractLockRule): Promise<null | ContractLockRule> =>
            Api.post(`/lockrules`, model, {headers: authHeader()})
                .then((response: AxiosResponse<ContractLockRule>) => response.data)
                .catch(error => null)

    static update = (model: ContractLockRule): Promise<null | ContractLockRule> =>
        Api.put(`/lockrules/${model.id}`, ContractLockRuleService.initUpdateBody(model), {headers: authHeader()})
            .then((response: AxiosResponse<ContractLockRule>) => response.data)
            .catch(error => null)

    tableData(filters: Partial<TableFilters> = {}): Promise<TableResponse<ContractLockRule> | null> {
        return Api.get(`/lockrules`, {params: filters, headers: authHeader()})
            .then((response: AxiosResponse<TableResponse<ContractLockRule>>) => response.data)
            .catch(error => null)
    }

    static initUpdateBody = (model: ContractLockRule): Partial<ContractLockRule> =>
        ObjectAssignBySchema({
            name: model.name,
            message_admin: model.message_admin,
            message_user: model.message_user
        }, model)
}

