import AttributeFieldDate from './AttributeFieldDate';
import AttributeFieldEditor from './AttributeFieldEditor';
import AttributeFieldNumber from './AttributeFieldNumber';
import AttributeFieldString from './AttributeFieldString';

export enum AttributeFieldRenderType {
    string = 'string',
    editor = 'editor',
    number = 'number',
    date = 'date',
    null = 'null'
}

export const AttributeFieldRenderTypeComponent = {
    [AttributeFieldRenderType.string]: AttributeFieldString,
    [AttributeFieldRenderType.date]: AttributeFieldDate,
    [AttributeFieldRenderType.editor]: AttributeFieldEditor,
    [AttributeFieldRenderType.null]: AttributeFieldString,
    [AttributeFieldRenderType.number]: AttributeFieldNumber,
}

export const AttributeFieldRenderTypeNames = {
    [AttributeFieldRenderType.string]: 'Pole tekstowe',
    [AttributeFieldRenderType.date]: 'Pole z datą',
    [AttributeFieldRenderType.editor]: 'Rozbudowany edytor',
    [AttributeFieldRenderType.number]: 'Pole liczbowe',
}