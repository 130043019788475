import React, {FunctionComponent, useMemo} from 'react';
import ServerTable, {TableHeadActionsProps} from "../../ServerTable";
import {TableColumn} from "../../../../models/Table";
import {Link} from 'react-router-dom';
import ClientService from '../../../../services/ClientService';
import { ContractWarningRule } from '../../../../models/ContractWarningRule';
import ContractWarningRuleService from '../../../../services/ContractWarningRuleService';

export default () => {
    const tableService = new ContractWarningRuleService()
    const columns = useMemo<TableColumn<ContractWarningRule>[]>(() => [
        {
            Header: 'Nazwa',
            accessor: ({name}) => name,
            sortBy: 'number'
        },
        {
            Header: 'Tekst',
            accessor: ({message_admin}) => message_admin,
            sortBy: ''
        },
        {
            Header: 'Opcje',
            accessor: ({id}) => <Link to={`/admin/settings/warningrules/${id}`}>
                <button className="btn btn-sm btn-primary">Edytuj</button>
            </Link>,
            sortBy: ''
        },
    ], [])

    const header: FunctionComponent<TableHeadActionsProps> = ({onInput}) =>
        <div className="input-group">
            <input className="form-control" type="text" placeholder="Szukaj..." aria-label="Szukaj..."
                onChange={e => {
                    onInput({query: e.target.value})
                }}/>
            <Link to={`/admin/settings/warningrules/add`}>
                <button className="btn btn-lg btn-primary">Dodaj nowy</button>
            </Link>
        </div>

    return <ServerTable<ContractWarningRule> tableService={tableService} columns={columns}>
        {{header}}
    </ServerTable>
}