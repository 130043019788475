import React from 'react';

import {Route, Switch, useRouteMatch} from "react-router-dom";
import ListPage from "./ListPage";


export const ContractsPage = () => {
    const {path} = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ListPage/>
            </Route>
        </Switch>
    )
}