import React from 'react';
import ContractLockRulesTable from '../../../../shared/components/admin/tables/ContractLockRulesTable';
import ContractWarningRulesTable from '../../../../shared/components/admin/tables/ContractWarningRulesTable';
import DashboardHeader from '../../../../shared/components/DashboardHeader';


export default () =>
    <>
        <DashboardHeader title="Ostrzeżenia"/>
        <div className="tab-content">
            <div className="tab-pane fade show active">
                <div className="card">
                    <ContractWarningRulesTable/>
                </div>
            </div>
        </div>
    </>
