import React, {FunctionComponent, useMemo} from 'react';
import ServerTable, {TableHeadActionsProps} from "../../ServerTable";
import {TableColumn} from "../../../../models/Table";
import {Contract} from "../../../../models/Contract";
import ContractService from "../../../../services/ContractService";
import {Link} from 'react-router-dom';
import {printDictionary} from "../../../../helpers/renderer";
import {findStateColor} from "../../../../helpers/contract-state";

export interface ContractsTableProps {
    readonly userId: number | null,
}

export default ({userId}: ContractsTableProps) => {

    const tableService = new ContractService()
    const columns = useMemo<TableColumn<Contract>[]>(() => [
        {
            Header: 'Identyfikator',
            accessor: ({number}) => number,
            sortBy: 'number'
        },
        {
            Header: 'Termin wykonania',
            accessor: ({sign_date}) => sign_date || 'Brak',
            sortBy: 'send_date'
        },
        {
            Header: 'Status',
            accessor: ({state}) =>
                    <span className="badge" style={{
                        backgroundColor: findStateColor(state),
                        color: "black"
                    }}>{printDictionary(String(state), 'contract_state')}</span>,
            sortBy: 'state'
        },
        {
            Header: 'Opcje',
            accessor: ({id}) =>
                <button className="btn btn-sm btn-primary">Uzupełnij</button>
            , sortBy: ''
        },
    ], [])

    const header: FunctionComponent<TableHeadActionsProps> = ({onInput}) =>
        <div>
            <div className="d-flex justify-content-center align-items-center mt-4 mb-3">
                <h4 className="h4">Umowy, które zawierasz lub musisz wypełnić</h4>
            </div>
            <div className="input-group">
                <input className="form-control" type="text" placeholder="Szukaj..." aria-label="Szukaj..."
                       onChange={e => {
                           onInput({query: e.target.value})
                       }}/>
                <Link to={`/admin/agreements/add`}>
                    <button className="btn btn-lg btn-primary">Dodaj nową</button>
                </Link>
            </div>
        </div>

    return (
        <ServerTable<Contract> tableService={tableService} columns={columns} filters={{user: userId}}>
            {{
                header
            }}
        </ServerTable>
    )
}