import {LoginData} from "../../models/Auth";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import {AuthActionTypes, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT} from "./types";
import {User} from "../../models/User";

export const fetchUserAction = () => dispatch =>
    UserService.profile().then(user => dispatch(setUserData(user)))

export const loginAction = (loginData: LoginData) => dispatch =>
    AuthService.jwtToken(loginData).then(token =>
        UserService.profile().then(user => dispatch(setUserData(user))
        )
    )

export const setUserData = (user: User | null): AuthActionTypes =>
    ({
        type: user !== null ? LOGIN_SUCCESS : LOGIN_FAIL,
        payload: {user}
    })


export const logoutAction = (): AuthActionTypes => {
    AuthService.logout()
    return {
        type: LOGOUT,
        payload: {user: null}
    }
}
